import React from "react"
import { Link } from "react-router-dom"

import Pagebanner from "../../layouts/PageBanner"
import CategoriesIcon from "../../components/CategoriesIcon"

//images
import bg from "../../assets/images/banner/bnr4.jpg"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import { IMAGES } from "../../constant/theme"
// import large1 from "../../assets/images/project/large/pic1.jpg"
// import large2 from "../../assets/images/project/large/pic2.jpg"
// import large3 from "../../assets/images/project/large/pic3.jpg"

import OurMission from "../../components/OurMission"
import UpdateBlog from "../../components/Home/UpdateBlog"

const wrapperBlog = [
  {icon:"flaticon-vr-glasses", title:"Provide a unified forum for Tamils in this area", content:"SATS aims to provide a platform to facilitate interaction among the area tamil people"},
  {icon:"flaticon-transformation", title:"Promote Tamil culture", content:"கல் தோன்றி மண் தோன்றாக் காலத்தே வாளோடு முன் தோன்றிய மூத்தக்குடி தமிழ் குடி Tamil culture is unique in many respects. SATS strives to promote and preserve Tamil culture"},
  {icon:"flaticon-doctor-bag", title:"Celebrate Tamil festivals", content:"To celebrate festivals such as Pongal, Tamil New Year and Deepavali every year."},
  {icon:"flaticon-open-book", title:"Organize special projects in Tamil Nadu", content:"For the development of Tamil Nadu, several SATS members are involved in various projects such as College Scholarship fundand Charity clubs."},
  {icon:"flaticon-open-book", title:"Promote Tamil scholars and artists", content:"To encourage Tamil Scholars and artists from Tamil Nadu to contribute for the growth of Tamil language."},
  {icon:"flaticon-open-book", title:"Help other Tamils", content:"To help other Tamils in San Antonio and surrounding areas feel at home."},
  {icon:"flaticon-open-book", title:"Actively participate in Federation of Tamil Associations of North America (FeTNA)", content:"To foster unity among different tamilsangams and to support FeTNA, SATS actively participates in the annual FeTNA conference."},
  {icon:"flaticon-open-book", title:"Make Tamil youth aware of our heritage", content:"To educate and encourage Tamil youth to learn, appreciate, and abide by the Tamil culture"},
];


const MissionVision = () => {
  
  return (
    <>
      <div className="page-content bg-white">
        <Pagebanner pagetitle="Mission / Vision" maintitle="Home" />

        <section className="content-inner-2 bg-light section-wrapper5 section-pattren1" style={{paddingTop:30}}>
          <div className="container text-dark">
            <div className="row about-bx3 align-items-center ">
              <OurMission />
            </div>
          </div>
          <div className="container">
            <div className="row">
              {wrapperBlog.map((item, ind) => (
                <div className="col-lg-6 col-md-12 col-sm-12" key={ind}>
                  <div className="icon-bx-wraper box-hover style-2 m-b30">

                    <div className="icon-content">
                      <h6 className="dz-tilte m-b10 text-capitalize">{item.title}</h6>
                      <p style={{ fontSize: 14, lineHeight: 1.3 }}>{item.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img src={shape2} className="shape-6 move-2" alt="shape" />
            <img src={shape3} className="shape-5 move-1" alt="shape" />
            <img src={shape5} className="shape-1 rotating" alt="shape" />
            <img src={shape1} className="shape-3 move-1" alt="shape" />
            <img src={shape6} className="shape-4 rotating" alt="shape" />
            <img src={shape6} className="shape-2 rotating" alt="shape" />
        </section>

        <section className="content-inner-2 bg-light section-wrapper5 section-pattren1" style={{paddingTop:0}}>
        <div className="container text-dark">
              <div className="row align-items-center  right">
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="dz-content section-head">
                    <h2 className="sub-title">Our Vision</h2>
                    <div id="content2 ">
                      <p className="mt-3 ">
                        To cultivate, promote, foster, and develop the
                        advancement of knowledge in Tamil language, literature
                        and culture.
                      </p>
                      <p className="mt-3 ">
                        To cultivate, promote, and foster the exchange of ideas
                        and understanding between the Tamil people and other
                        cultures.
                      </p>
                      <p className="mt-3 ">
                        To promote better understanding and foster friendship
                        among various Tamil associations in North America and to
                        encourage the formation of new Tamil Sangams.
                      </p>
                      <p className="mt-3  ">
                        To act upon charitable causes directly concerning the
                        welfare of Tamil community living throughout the world.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 m-b30">
                  <div className="dz-media p-5 ">
                    <div>
                      <img
                        src={IMAGES.Vision2}
                        alt=""
                        style={{
                          objectFit: "contain",
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <img src={shape2} className="shape-6 move-2" alt="shape" />
            <img src={shape3} className="shape-5 move-1" alt="shape" />
            <img src={shape5} className="shape-1 rotating" alt="shape" />
            <img src={shape1} className="shape-3 move-1" alt="shape" />
            <img src={shape6} className="shape-4 rotating" alt="shape" />
            <img src={shape6} className="shape-2 rotating" alt="shape" />
        </section>
       
        
      </div>
    </>
  )
}

export default MissionVision
