import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import {
  ApiServer,
  ApiServer2,
  ImageApi2donate,
  donateApi,
} from "../helpers/Api_help"

// const BAMCreateApi = `http://172.25.5.181:8062/manage-user/register`
const BAMCreateApi = `${ApiServer}manage-user/register`
const BAMUpdateApi = `${ApiServer}manage-user/update-profileinfo`
const ImageUpdateApi = `${ApiServer}manage-user/update-profile-image`
const CreateDonateApi = `${ApiServer}donate-user/register-donator-info`
const SponsersFormApi = `${ApiServer}sats-sponsors/create-sponsors`
const SubscribeFormApi = `${ApiServer}sats-subscriber/create-subscriber`
const UpdateSubscriberapi = `${ApiServer}manage-user/active-inactive-unsubscribe`
const upgradeMembershipApi = `${ApiServer}payment-info/upgrade-subscription`
const VolunteerFormApi = `${ApiServer}sats-volunteerinfo/create-volunteer`
const YouthWingFormApi = `${ApiServer}sats-youth-wing/create-youth-wing-user`
const contactUsFormApi = `${ApiServer}sats-contact/send-contact-us`
const DonateHistoryApi = `${ApiServer}donate-payment/donate-history`
const CreateFamilyMemberApi = `${ApiServer}manage-user/family-register`
const deleteFamilyMemberApi = `${ApiServer}manage-user/delete-family-member`
const UpdateFamilyMemberApi = `${ApiServer}manage-user/update-family-member`
const initialState = {
  // user_log:
  SuccessBAM: false,
  FailBAM: false,
  SuccessSpons: false,
  FailSpons: false,
  SuccessSubs: false,
  FailSubs: false,
  DonateSus: false,

  // registerationDetails: [],
  donateDetails: "",
  BAError: "",
  SuccessVolunteer: false,
  FailVolunteer: false,
  SuccessYouthwing: false,
  FailYouthwing: false,
  SuccesscontactUs: false,
  FailcontactUs: false,
  SuccessFamily: false,
  FailFamily: false,
  profileres: {},
}

const FormDataStore = createSlice({
  name: "FormElement",
  initialState: initialState,
  reducers: {
    SetmemberisSuccess: (state, action) => {
      state.SuccessBAM = true
      state.FailBAM = false
      state.profileres = action.payload
    },
    // SetregisterFormDetails: (state, action) => {
    //   state.registerationDetails = action.payload
    // },
    SetmemberisFail: (state, action) => {
      state.FailBAM = true
      state.SuccessBAM = false
      state.BAError = action.payload
    },
    SetFamilyisSuccess: (state, action) => {
      state.SuccessFamily = true
    },
    SetFamilyisFail: (state, action) => {
      state.FailFamily = action.payload
    },
    SetDonateSuccess: (state, action) => {
      state.DonateSus = true
      state.donateDetails = action.payload
    },
    SetDonateFail: (state, action) => {
      state.DonateSus = false
      state.FailBAM = true
      state.donateDetails = action.payload
    },
    ResetBAM: (state, action) => {
      state.SuccessBAM = false
      state.FailBAM = false
      state.BAError = ""
      state.SuccessSpons = false
      state.FailSpons = false
      state.SuccessSubs = false
      state.FailSubs = false
      state.SuccessNewsletter = false
      state.FailNewsletter = false
      state.SuccessVolunteer = false
      state.FailVolunteer = false
      state.SuccessYouthwing = false
      state.FailYouthwing = false
      state.SuccesscontactUs = false
      state.FailcontactUs = false
      state.DonateSus = false
      state.SuccessFamily = false
      state.FailFamily = false
      // state.profileres = false
    },
    // SetregisterFormDetails: (state, action) => {
    //   state.registerationDetails = action.payload
    // },
    SetSponsSuccess: (state, action) => {
      state.SuccessSpons = true
      state.FailSpons = false
    },

    SetSponsFail: (state, action) => {
      state.FailSpons = true
      state.SuccessSpons = false
    },
    SetVolunteerSuccess: (state, action) => {
      state.SuccessVolunteer = true
      state.FailVolunteer = false
    },

    SetVolunteerFail: (state, action) => {
      state.FailVolunteer = true
      state.SuccessVolunteer = false
    },
    SetYouthWingSuccess: (state, action) => {
      state.SuccessYouthwing = true
      state.FailYouthwing = false
    },

    SetYouthWingFail: (state, action) => {
      state.FailYouthwing = true
      state.SuccessYouthwing = false
    },
    SetcontactUsSuccess: (state, action) => {
      state.SuccesscontactUs = true
      state.FailcontactUs = false
    },

    SetcontactUsFail: (state, action) => {
      state.FailcontactUs = true
      state.SuccesscontactUs = false
    },
    SetSubsSuccess: (state, action) => {
      state.SuccessSubs = true
      state.FailSubs = false
    },

    SetSubsFail: (state, action) => {
      state.FailSubs = true
      state.SuccessSubs = false
    },

    SetDonateSus: (state, action) => {
      state.DonateSus = true
    },
  },
})

export const UpadteBAM = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      // const formData = new FormData()
      // formData.append("file", data.profile_img),
      //   formData.append("values", JSON.stringify(data))
      // console.log("formData", formData)
      let responseData = await axios.post(BAMUpdateApi, data)
      // console.log("UpdateBAM", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetmemberisSuccess())
      }
    } catch (error) {
      console.log("UpdateBAM", error)
      dispatch(SetmemberisFail())
    }
  }
}
export const CreateBAM = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(BAMCreateApi, data)
      // console.log("CreateBAM", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetmemberisSuccess(responseData.data.data))
      }
    } catch (error) {
      console.log("CreateBAMerr", error.response.data.message)
      dispatch(SetmemberisFail(error.response.data.message))
    }
  }
}
export const UpdateTosubscriber = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(UpdateSubscriberapi, data)
      // console.log("CreateBAM", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetmemberisSuccess(responseData.data.data))
      }
    } catch (error) {
      console.log("CreateBAMerr", error.response.data.message)
      dispatch(SetmemberisFail(error.response.data.message))
    }
  }
}
export const UpdateImageProfile = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      // console.log("data", data)
      const formData = new FormData()
      formData.append("file", data.image)
      formData.append("_id", JSON.stringify(data._id))

      let responseData = await axios.post(ImageUpdateApi, formData)

      // console.log("UpdateImageProfile", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetmemberisSuccess(responseData.data.data))
      }
    } catch (error) {
      console.log("UpdateImageProfileerr", error.response.data.message)
      dispatch(SetmemberisFail(error.response.data.message))
    }
  }
}

export const CreateDonate = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(CreateDonateApi, data)
      // console.log("CreateDonate", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetDonateSuccess(responseData.data.data))
      }
    } catch (error) {
      console.log("CreateDonateerr", error.response.data.message)
      dispatch(SetDonateFail(error.response.data.message))
    }
  }
}
export const DonateHistory = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(DonateHistoryApi, data)
      // console.log("CreateDonateHistory", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetDonateSus())
      }
    } catch (error) {
      console.log("CreateDonateerrHistory")
    }
  }
}
export const SponsersForm = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(SponsersFormApi, data)
      // console.log("SponsersForm", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSponsSuccess())
      }
    } catch (error) {
      console.log("SponsersFormerr", error.response.data.message)
      dispatch(SetSponsFail())
    }
  }
}
export const UpgradeMembership = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(upgradeMembershipApi, data)
      // console.log("UpgradeMembership", responseData)
      if (responseData.data.response_code === 200) {
        console.log()
        // dispatch(SetSponsSuccess())
      }
    } catch (error) {
      console.log("UpgradeMembership", error.response.data.message)
      // dispatch(SetSponsFail())
    }
  }
}
export const SubscribeForm = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(SubscribeFormApi, data)
      // console.log("SubscribeForm", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSubsSuccess())
      }
    } catch (error) {
      console.log("SubscribeFormerr", error.response.data.message)
      dispatch(SetSubsFail())
    }
  }
}

export const VolunteerForm = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(VolunteerFormApi, data)
      // console.log("VolunteerForm", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetVolunteerSuccess())
      }
    } catch (error) {
      console.log("VolunteerFormerr", error.response.data.message)
      dispatch(SetVolunteerFail())
    }
  }
}

export const YouthWingForm = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(YouthWingFormApi, data)
      // console.log("YouthWingForm", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetYouthWingSuccess())
      }
    } catch (error) {
      console.log("YouthWingFormerr", error.response.data.message)
      dispatch(SetYouthWingFail())
    }
  }
}
export const contactUsForm = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(contactUsFormApi, data)
      // console.log("contactUsForm", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetcontactUsSuccess())
      }
    } catch (error) {
      console.log("contactUsFormerr", error.response.data.message)
      dispatch(SetcontactUsFail())
    }
  }
}

export const CreateFamilyMember = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(CreateFamilyMemberApi, data)
      // console.log("CreateBAM", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetFamilyisSuccess())
      }
    } catch (error) {
      console.log("CreateBAMerr", error.response.data.message)
      dispatch(SetFamilyisFail(error.response.data.message))
    }
  }
}

export const deleteFamilyMember = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(deleteFamilyMemberApi, data)
      // console.log("CreateBAM", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetFamilyisSuccess())
      }
    } catch (error) {
      console.log("CreateBAMerr", error.response.data.message)
      dispatch(SetFamilyisFail(error.response.data.message))
    }
  }
}

export const UpdateFamilyMember = (data) => {
  return async (dispatch) => {
    dispatch(ResetBAM())
    try {
      let responseData = await axios.post(UpdateFamilyMemberApi, data)
      // console.log("CreateBAM", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetFamilyisSuccess())
      }
    } catch (error) {
      console.log("CreateBAMerr", error.response.data.message)
      dispatch(SetFamilyisFail(error.response.data.message))
    }
  }
}

export const {
  SetNewsLetterSuccess,
  SetSuccessDonatehistory,
  SetNewsLetterFail,
  SetSubsSuccess,
  SetSubsFail,
  SetDonateFail,
  SetDonateSuccess,
  SetmemberisFail,
  SetmemberisSuccess,
  SetSponsSuccess,
  SetSponsFail,
  SetVolunteerFail,
  SetVolunteerSuccess,
  SetcontactUsFail,
  SetcontactUsSuccess,
  SetYouthWingFail,
  SetYouthWingSuccess,
  SetFamilyisSuccess,
  SetFamilyisFail,
  ResetBAM,
  userLoginOn,
  SetDonateSus,
} = FormDataStore.actions
export default FormDataStore.reducer
