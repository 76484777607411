import React from "react"
import { Link } from "react-router-dom"
import bg from "../assets/images/background/SATS-BACKDROP_plain-1920px.jpg"
const PageBanner = ({ maintitle, pagetitle, bgColor }) => {
  return (
    <>
      <div
        className="dz-bnr-inr dz-bnr-inr-sm style-1 text-center overlay-primary-middle "
        style={{ backgroundImage: "url(" + bg + ")" }}
      >
        <div className="container mt-5 pt-5">
          <div className="dz-bnr-inr-entry ">
            <h1>{pagetitle}</h1>
            {/* <nav aria-label="breadcrumb" className="breadcrumb-row">
              <ul className="breadcrumb">
                <li className="breadcrumb-item ">
                  <Link
                    to={"/"}
                    className="text-primary h5 fw-bold border-bottom border-primary"
                  >
                    {maintitle}
                  </Link>
                </li>
                <li className="breadcrumb-item active">{pagetitle}</li>
              </ul>
            </nav> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default PageBanner
