import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import authService from "./authService"

const initialState = {
  User_Id: localStorage.getItem("Login") ? true : false,
  LoginUser: null,
  ForgetpasswordUser: null,
  ResetPasswordlinkUser: null,
  isLoading: false,
  message: "",
  isSuccess: false,
  isError: false,
  isLogout: false,
  LoginStatus: "",
}

export const Forgetpassword = createAsyncThunk(
  "auth/forgetpassword",
  async (user, thunkAPI) => {
    try {
      return await authService.Forgetpassword(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const LoginStatusCheck = createAsyncThunk(
  "auth/LoginStatus",
  async (user, thunkAPI) => {
    try {
      return await authService.LoginStatusCheck({ email: user })
    } catch (error) {
      const message =
        (error.response && error.response.data) || error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const Login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    console.log("xxx", user)
    return await authService.login(user)
  } catch (error) {
    console.log(error)
    const message = (error.response && error.response.data) || error.toString()
    console.log(message)
    return thunkAPI.rejectWithValue(message)
  }
})

export const ResetPasswordlink = createAsyncThunk(
  "auth/ResetPasswordlink",
  async (user, thunkAPI) => {
    try {
      return await authService.ResetPasswordlink(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data ) ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    Reset: (state) => {
      state.isLoading = false
      state.message = ""
      state.isSuccess = false
      state.isError = false
      state.isLogout = false
      // state.LoginUser = null
      // stateForgetpasswordUser = null
      // state.ResetPasswordlinkUser = null
    },
    Logout: (state) => {
      localStorage.removeItem("Login")
      localStorage.removeItem("Register")
      localStorage.removeItem("user_id")
      state.User_Id = null
      state.LoginUser = null
      state.isLoading = false
      state.message = "LogOut"
      state.isSuccess = false
      state.isError = false
      state.isLogout = true
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(Forgetpassword.pending, (state) => {
        state.isSuccess = false
        state.isError = false
        state.isLoading = true
      })
      .addCase(Forgetpassword.fulfilled, (state, action) => {
        console.log("check is success", action.payload)
        state.isLoading = false
        state.isSuccess = true
        state.ForgetpasswordUser = action.payload.message
        state.LoginUser = action.payload
        state.ResetPasswordlinkUser = null
        state.LoginStatus = action.payload
        state.message = "SuccessFull Submitted"
      })
      .addCase(Forgetpassword.rejected, (state, action) => {
        state.isLogout = false
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.LoginUser = action.payload
        state.LoginStatus = action.payload
        state.ForgetpasswordUser = action.payload
        state.LoginUser = null
        state.ResetPasswordlinkUser = null
      })
      .addCase(Login.pending, (state) => {
        state.isSuccess = false
        state.isError = false
        state.isLoading = true
      })
      .addCase(Login.fulfilled, (state, action) => {
        
        /* location.reload() */
        state.isLoading = false
        state.isSuccess = true
        state.ForgetpasswordUser = null
        state.ResetPasswordlinkUser = null
        state.LoginUser = action.payload
        state.LoginStatus = action.payload
        // state.User_Id = action.payload.data._id
        state.message = "SuccessFull Submitted"
        localStorage.removeItem("Register")
      })
      .addCase(Login.rejected, (state, action) => {
        console.log("login rejected", action.payload)
        state.isLogout = false
        state.isLoading = false
        state.isSuccess = false
        state.isError = true
        state.LoginUser = action.payload
        state.LoginStatus = action.payload
        state.ForgetpasswordUser = null
        state.ResetPasswordlinkUser = null
      })
      .addCase(ResetPasswordlink.pending, (state) => {
        state.isSuccess = false
        state.isError = false
        state.isLoading = true
      })
      .addCase(ResetPasswordlink.fulfilled, (state, action) => {
        console.log("reset password --->", action.payload)
        state.isLoading = false
        state.isSuccess = true
        state.ResetPasswordlinkUser = action.payload
        state.ForgetpasswordUser = null
        state.LoginUser = action.payload
        state.LoginStatus = action.payload

        state.message = "SuccessFull Submitted"
        /*  state.RegisterUser = null
        state.LoginUser = null */
      })
      .addCase(ResetPasswordlink.rejected, (state, action) => {
        console.log("reset password link --->", action.payload)
        state.isLogout = false
        state.isSuccess = false
        state.isError = true
        state.LoginUser = action.payload
        state.LoginStatus = action.payload
        state.ResetPasswordlinkUser = action.payload
        state.ForgetpasswordUser = null
        state.LoginUser = null
      })
      .addCase(LoginStatusCheck.pending, (state) => {
        state.isSuccess = false
        state.isError = false
        state.isLoading = false
      })
      .addCase(LoginStatusCheck.fulfilled, (state, action) => {
        console.log("login Status check --->", action.payload)
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.ForgetpasswordUser = null
        state.LoginUser = action.payload
        state.LoginStatus = action.payload
        state.ResetPasswordlinkUser = null
        state.message = "SuccessFull Submitted"
        state.LoginStatus = action.payload
      })
      .addCase(LoginStatusCheck.rejected, (state, action) => {
        state.isLogout = false
        state.isLoading = false
        state.isSuccess = false
        state.ForgetpasswordUser = null
        state.LoginUser = null
        state.ResetPasswordlinkUser = null
        state.isError = true
        state.LoginStatus = action.payload
      })
  },
})

export const { Reset, Logout } = authSlice.actions
export default authSlice.reducer
