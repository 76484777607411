import React, { useEffect, useState } from "react"
import MembershipButton from "../../components/Paypal/MembershipButton"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { SlClose } from "react-icons/sl"
// import BgImage from "../../assets/images/background/bg10.jpg"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Modal } from "react-bootstrap"
import moment from "moment"
import PageBanner from "../../layouts/PageBanner"
import { IMAGES } from "../../constant/theme"
import { Label, FormFeedback, Input, Form, Col, Row } from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import { fetchMembershipTypes } from "../../features/FetchData"
import { CreateBAM, ResetBAM } from "../../features/FormDataStore"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Country } from "../../components/country-state-city/countries"
import { State } from "../../components/country-state-city/states"
const Register = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [PaypalModal, setPaypalModal] = useState(false)
  const dispatch = useDispatch()
  const { profileres, BAError, SuccessBAM, FailBAM } = useSelector(
    (state) => state.FormDataReducer
  )
  const { MembershipTypes } = useSelector((state) => state.FetchDataReducer)

  const [MemShipagree, setmemShipagree] = useState(false)
  const [ConfirmMemshipModal, setConfirmMemshipModal] = useState(false)
  const [promoCode, setPromoCode] = useState('');
  const [validPromoItemId, setValidPromoItemId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [promoAmount, setPromoAmount] = useState(0);
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoCodeSubmitted, setPromoCodeSubmitted] = useState(false); // New state

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
    setPromoCodeSubmitted(false); // Reset submission status on typing
  };

  const handleCheckPromoCode = () => {
    setPromoCodeSubmitted(true); // Mark as submitted when Apply is clicked

    if (selectedItem) {
      const promo = selectedItem.promo_code.find(
        (code) => code.promo_name === promoCode
      );
      if (promo) {
        setValidPromoItemId(selectedItem._id);
        setPromoAmount(promo.promo_amount);
        setPromoCodeApplied(true); // Successful promo code application
      } else {
        setValidPromoItemId(null);
        setPromoAmount(0);
        setPromoCodeApplied(false); // Unsuccessful promo code application
      }
    }
  };

  const applyDiscount = (item) => {
    return validPromoItemId === item._id ? item.fees - promoAmount : item.fees;
  };

  useEffect(() => {
    dispatch(fetchMembershipTypes())
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      firstname: "",
      lastname: "",
      gender: "",
      mobile: "",
      membership_type: "",
      membership_fees: "",
      membership_type_id: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      country_code: "US",
      country: "United States",
      zipcode: "",
      dob: "",
      currency: "USD",
      login_status: 0,
      subscribe: false,
      dobcheck: false,
    },
    validationSchema: Yup.object({
      membership_type: Yup.string().required("Select the Membership"),
      email: Yup.string()
        .transform((value) => value.replace(/\s/g, ""))
        .email("Invalid email address")
        .required("Email Id is required"),
      firstname: Yup.string().required("Enter your First Name"),
      lastname: Yup.string().required("Enter your Last Name"),
      country: Yup.string().required("Select your Country"),
      state: Yup.string().required("Select your State"),
      zipcode: Yup.string().required("Enter your Zipcode"),
      mobile: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 ,+1 (201)78987678 (Enter country code and Mobile number)"
        )
        .required("Mobile Number is required"),
      gender: Yup.string().required("Select Your Gender"),
      city: Yup.string().required("Enter your City"),
      // dob: Yup.string()
      // .required("Date-Month is required"),
      // .matches(
      //   /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])$/,
      //   " date format Should be (date-Month) example : 27-07"
      // )

      // subscribe: Yup.bool().oneOf([true], "You must agree to the terms"),
      dobcheck: Yup.bool().oneOf(
        [true],
        "You must agree to the terms for Allow To Submission"
      ),
    }),
    onSubmit: (values, actions) => {
      // console.log(values)
      values["dob"] = startDate
      dispatch(CreateBAM(values))
    },
  })

  useEffect(() => {
    if (SuccessBAM) {
      toast.info("Registeration Payment process start!", {
        position: "top-right",
      })
      // alert("Successfully")
      setPaypalModal(true)
    }
    if (FailBAM) {
      toast.error(BAError, {
        position: "top-right",
      })
      // alert(BAError)
    }

    dispatch(ResetBAM())
  }, [SuccessBAM, FailBAM])

  const filterstates = State.filter(
    (sta) => sta.country_name == validation.values.country
  )
  // const[rem]=useState()
  function daysRemaining() {
    const today = moment()
    let year = today.year()
    var curMonth = moment().format("MM")

    if (curMonth > 4) {
      const nextApril30d = moment(`${year + 1}-04-30`)
      const Dateexpiry = moment(nextApril30d._i).format("Do MMMM YYYY")

      const remainingDays = nextApril30d.diff(today, "days")

      // console.log(remainingDays)
      return Dateexpiry
    } else {
      const nextApril30s = moment(`${year}-04-30`)
      const Dateexpiry = moment(nextApril30s._i).format("Do MMMM YYYY")
      const remainingDays = nextApril30s.diff(today, "days")
      // console.log(remainingDays)

      return Dateexpiry
    }
  }
  const [TempMembership, setTempMembership] = useState()
  const memShipConfirm = (item) => {
    if (item.membership_name_id === "1") {
      setmemShipagree(true)
      setConfirmMemshipModal(false)
    } else {
      setmemShipagree(false)
      setConfirmMemshipModal(true)
    }
    setTempMembership(item)


  }
  useEffect(() => {
    if (MemShipagree) {
      const updatedFees = promoCodeApplied
        ? applyDiscount(TempMembership) // Apply discount if promo code was successful
        : TempMembership?.fees; // Use original fees if promo code was not successful

      validation.setFieldValue("membership_type", TempMembership?.membership_name);
      validation.setFieldValue("membership_fees", updatedFees);
      validation.setFieldValue("membership_type_id", TempMembership?.membership_name_id);

      // Uncomment if you need to set expiration date based on membership type
      // if (TempMembership?.membership_name === "Life Membership") {
      //   validation.setFieldValue("membership_expired_on");
      // } else {
      //   validation.setFieldValue("membership_expired_on", daysRemaining());
      // }
    } else {
      validation.setFieldValue("membership_type", "");
      validation.setFieldValue("membership_fees", "");
      validation.setFieldValue("membership_type_id", "");
    }
  }, [TempMembership, MemShipagree, promoCodeApplied, validPromoItemId, promoAmount]);

  const handleKeyDownonlynumber = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDownmobile = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    const handleKeyDownmobile = (event) => {
      // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
      if (
        !/^[0-9+()\-\s]*$/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "Delete" &&
        event.key !== "ArrowLeft" &&
        event.key !== "ArrowRight"
      ) {
        event.preventDefault()
      }
    }
  }
  const handleKeyDowndob = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9-\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDowncharcters = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[A-Za-z\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Registration" />
        <section
          className="content-inner-1 gradient-white mb-3 section-wrapper5"
          style={{
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-md-9 col-sm-12">
                <div className="">
                  <div className="contact-area">
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault()
                        validation.handleSubmit()
                      }}
                    >
                      <Row className="col-12 d-flex flex-wrap ">
                        <div className="row">
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <Label htmlFor="firstname">
                              First Name<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="firstname"
                              className={`form-control`}
                              placeholder="Enter Your First Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstname || ""}
                              onKeyDown={handleKeyDowncharcters}
                              invalid={
                                validation.touched.firstname &&
                                  validation.errors.firstname
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.firstname &&
                              validation.errors.firstname ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.firstname}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <Label htmlFor="lastname">
                              Last Name<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="lastname"
                              className={`form-control`}
                              placeholder="Enter Your Last Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastname || ""}
                              onKeyDown={handleKeyDowncharcters}
                              invalid={
                                validation.touched.lastname &&
                                  validation.errors.lastname
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.lastname &&
                              validation.errors.lastname ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.lastname}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3 col-12">
                            <Label htmlFor="email">
                              Email<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="email"
                              className={`form-control`}
                              placeholder="Enter Your Email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                  validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                              validation.errors.email ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>
                        <div className="row">
                          <div className="mb-3 col-sm-12 col-md-6 col-lg-6">
                            <Label htmlFor="mobile">
                              Mobile Number
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="mobile"
                              type="tel"
                              min={4}
                              className="form-control  "
                              placeholder="Enter Mobile Number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.mobile || ""}
                              maxLength={20}
                              onKeyDown={handleKeyDownmobile}
                              invalid={
                                validation.touched.mobile &&
                                  validation.errors.mobile
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.mobile &&
                              validation.errors.mobile ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.mobile}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className=" mb-3 col-sm-12 col-md-6 col-lg-3">
                            <Label htmlFor="dob">
                              Date of Birth
                              <span className="text-danger">*</span>
                            </Label>
                            <DatePicker
                              selected={startDate}
                              showMonthDatePicker
                              dateFormat="MM/dd"
                              customInput={<Input
                                name="dob"
                                className="form-control "
                                placeholder="Date-Month"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={startDate}
                                // onKeyDown={handleKeyDowndob}
                                maxLength={5}
                                invalid={
                                  validation.touched.dob && validation.errors.dob
                                    ? true
                                    : false
                                }
                              />}
                              onChange={(date) => {
                                console.log("date", date)
                                setStartDate(date)
                              }
                              }
                            />
                            {/* <Input
                              name="dob"
                              className="form-control "
                              placeholder="Date-Month"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.dob || ""}
                              onKeyDown={handleKeyDowndob}
                              maxLength={5}
                              invalid={
                                validation.touched.dob && validation.errors.dob
                                  ? true
                                  : false
                              }
                            /> */}
                            {validation.touched.dob && validation.errors.dob ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.dob}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className="mb-3 col-12">
                            <Label htmlFor="address">Address #1 Line</Label>
                            <Input
                              name="address"
                              className="form-control  "
                              placeholder="Enter Your Address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address || ""}
                              invalid={
                                validation.touched.address &&
                                  validation.errors.address
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address &&
                              validation.errors.address ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.address}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 col-12">
                            <Label htmlFor="homeadd2">Address #2 Line</Label>
                            <Input
                              name="address2"
                              className="form-control  "
                              placeholder="Enter Your Address"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.address2 || ""}
                              invalid={
                                validation.touched.address2 &&
                                  validation.errors.address2
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.address2 &&
                              validation.errors.address2 ? (
                              <FormFeedback type="invalid">
                                {validation.errors.address2}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="row">
                          <div className=" mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="city">
                              City<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="city"
                              className="form-control  "
                              placeholder="Enter the City"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.city || ""}
                              invalid={
                                validation.touched.city &&
                                  validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                              validation.errors.city ? (
                              <FormFeedback
                                type="invalid"
                                style={{ fontSize: "12px" }}
                              >
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="country">
                              Country<span className="text-danger">*</span>
                            </Label>
                            <select
                              id="country"
                              name="country"
                              className={`form-select ${validation.touched.country &&
                                validation.errors.country &&
                                "is-invalid"
                                }`}
                              onChange={(e) => {
                                const V_code = Country.filter(
                                  (country) => country.name === e.target.value
                                )
                                console.log(
                                  V_code?.[0].name,
                                  V_code?.[0].iso2,
                                  V_code?.[0].currency
                                )
                                validation.setFieldValue(
                                  "country",
                                  V_code?.[0].name
                                )
                                validation.setFieldValue(
                                  "country_code",
                                  V_code?.[0].iso2
                                )
                                validation.setFieldValue(
                                  "currency",
                                  V_code?.[0].currency
                                )
                              }}
                              value={validation.values.country}
                            >
                              <option value=" ">Select Country </option>
                              {Country.map((country) => (
                                <option value={country.name} key={country.id}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.country &&
                              validation.errors.country ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                              >
                                {validation.errors.country}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-3 col-sm-12 col-md-4 col-lg-4">
                            <Label htmlFor="state">
                              State<span className="text-danger">*</span>
                            </Label>
                            <select
                              id="state"
                              name="state"
                              className={`form-select ${validation.touched.state &&
                                validation.errors.state &&
                                "is-invalid"
                                }`}
                              onChange={(e) =>
                                validation.setFieldValue(
                                  "state",
                                  e.target.value
                                )
                              }
                              value={validation.values.state}
                            >
                              <option value=" ">Select State </option>
                              {filterstates.map((stat, ind2) => (
                                <option
                                  value={stat.name}
                                  key={ind2}
                                  className="d-flex flex-grow-1"
                                >
                                  {stat.name}
                                </option>
                              ))}
                            </select>
                            {validation.touched.state &&
                              validation.errors.state ? (
                              <div
                                style={{
                                  fontSize: "12px",
                                  color: "red",
                                }}
                              >
                                {validation.errors.state}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className=" mb-3 col-sm-12 col-md-4 col-lg-4">
                          <Label htmlFor="zipcode">
                            <span className="text-danger">*</span>Zip Code
                          </Label>
                          <Input
                            name="zipcode"
                            className="form-control"
                            placeholder="Enter the zipcode"
                            type="tel"
                            min={1}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.zipcode || ""}
                            onKeyDown={handleKeyDownonlynumber}
                            invalid={
                              validation.touched.zipcode &&
                                validation.errors.zipcode
                                ? true
                                : false
                            }
                          />
                          {validation.touched.zipcode &&
                            validation.errors.zipcode ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.zipcode}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3 col-12 col-sm-11">
                          <Label htmlFor="gender">
                            Gender <span className="text-danger">*</span>
                          </Label>
                          <fieldset className="d-flex flex-column flex-lg-row  flex-sm-column">
                            <div>
                              <input
                                type="radio"
                                name="gender"
                                id="track"
                                className="me-2 form-check-input "
                                value={validation.values.gender || ""}
                                defaultChecked={
                                  validation.values.gender === "male"
                                }
                                onClick={(e) => {
                                  validation.setFieldValue("gender", "male")
                                }}
                              />
                              <label htmlFor="track" className="me-3">
                                Male
                              </label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="gender"
                                className="me-2 form-check-input"
                                id="event"
                                value={validation.values.gender || ""}
                                defaultChecked={
                                  validation.values.gender === "female"
                                }
                                onClick={(e) => {
                                  validation.setFieldValue("gender", "female")
                                }}
                              />
                              <label htmlFor="event" className="me-3">
                                Female
                              </label>
                            </div>
                          </fieldset>
                          {validation.touched.gender &&
                            validation.errors.gender ? (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {validation.errors.gender}
                            </div>
                          ) : null}
                        </div>

                        {/* <div className="col-12 col-md-11 col-lg-11 mb-3 d-flex flex-column">
                          <Label htmlFor="member">
                            SATS Membership
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="btn-group d-flex flex-nowrap" role="group">
                            {MembershipTypes.map((item, ind) => (
                              <React.Fragment key={ind}>
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id={item._id}
                                  autoComplete="off"
                                  value={validation.values.membership_type || ""}
                                  defaultChecked={
                                    validation.values.membership_type_id === item.membership_name_id
                                  }
                                  onClick={() => {
                                    memShipConfirm(item);
                                    setSelectedItem(item);
                                    setPromoCode('');
                                    setValidPromoItemId(null);
                                    setPromoAmount(0);
                                    setPromoCodeApplied(false);
                                    setPromoCodeSubmitted(false); // Reset submission status
                                  }}
                                  aria-invalid={!!validation.errors.membership_type}
                                />
                                <label
                                  className="btn btn-outline-warning d-flex flex-column"
                                  htmlFor={item._id}
                                >
                                  <p style={{ textAlign: 'left' }}>
                                    {`${item.membership_name} ${item.term === 1 ? '(Yr)' : ''}`}
                                  </p>
                                  <h6>
                                    {validPromoItemId === item._id ? (
                                      <>
                                        <span className="strikethrough" style={{ textDecoration: 'line-through' }}>${item.fees}</span>{' '}
                                        <span>${applyDiscount(item)}</span>
                                      </>
                                    ) : (
                                      `$${item.fees}`
                                    )}
                                    *
                                  </h6>
                                </label>
                              </React.Fragment>
                            ))}
                          </div>
                          <div className="text-center mt-1">
                            <span className="text-danger">*</span>{' '}
                            <small>PayPal Transaction Fee (2.25%+31 Cents) Extra</small>
                          </div>
                          {validation.touched.membership_type && validation.errors.membership_type && (
                            <div style={{ fontSize: '12px', color: 'red' }}>
                              {validation.errors.membership_type}
                            </div>
                          )}

                          {selectedItem && (
                            <div className="mt-3">
                              <Label htmlFor="promo_code">Promo Code</Label>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  id="promo_code"
                                  className="form-control promo-input"
                                  value={promoCode}
                                  placeholder="Discount Code"
                                  onChange={handlePromoCodeChange}
                                  maxLength={8}
                                  style={{ borderRadius: "25px", maxWidth: "300px", height: "50px", fontSize: "18px", marginRight: "20px" }}
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary ml-2"
                                  style={{ maxWidth: "250px", height: "50px", fontSize: "15px", borderRadius: "25px" }}
                                  onClick={handleCheckPromoCode}
                                >
                                  Apply
                                </button>
                              </div>
                              {promoCodeSubmitted && promoCodeApplied && validPromoItemId === selectedItem._id && (
                                <div style={{ fontSize: '12px', color: 'green' }}>
                                  Promo code applied successfully!
                                </div>
                              )}
                              {promoCodeSubmitted && !promoCodeApplied && (
                                <div style={{ fontSize: '12px', color: 'red' }}>
                                  Invalid promo code
                                </div>
                              )}
                            </div>
                          )}
                        </div> */}

                        <div className="col-12 col-md-11 col-lg-11 mb-3 d-flex flex-column">
                          <Label htmlFor="member">
                            SATS Membership
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="btn-group d-flex flex-nowrap" role="group">
                            {MembershipTypes.map((item, ind) => (
                              <React.Fragment key={ind}>
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="btnradio"
                                  id={item._id}
                                  autoComplete="off"
                                  value={validation.values.membership_type || ""}
                                  defaultChecked={
                                    validation.values.membership_type_id === item.membership_name_id
                                  }
                                  onClick={() => {
                                    memShipConfirm(item);
                                    setSelectedItem(item);
                                    setPromoCode('');
                                    setValidPromoItemId(null);
                                    setPromoAmount(0);
                                    setPromoCodeApplied(false);
                                    setPromoCodeSubmitted(false); // Reset submission status
                                  }}
                                  aria-invalid={!!validation.errors.membership_type}
                                />
                                <label
                                  className="btn btn-outline-warning d-flex flex-column"
                                  htmlFor={item._id}
                                >
                                  <p style={{ textAlign: 'left' }}>
                                    {`${item.membership_name} ${item.term === 1 ? '(Yr)' : ''}`}
                                  </p>
                                  <h6>
                                    {validPromoItemId === item._id ? (
                                      <>
                                        <span className="strikethrough" style={{ textDecoration: 'line-through' }}>${item.fees}</span>{' '}
                                        <span>${applyDiscount(item)}</span>
                                      </>
                                    ) : (
                                      `$${item.fees}`
                                    )}
                                    *
                                  </h6>
                                </label>
                              </React.Fragment>
                            ))}
                          </div>
                          <div className="text-center mt-1">
                            <span className="text-danger">*</span>{' '}
                            <small>PayPal Transaction Fee (2.25%+31 Cents) Extra</small>
                          </div>
                          {validation.touched.membership_type && validation.errors.membership_type && (
                            <div style={{ fontSize: '12px', color: 'red' }}>
                              {validation.errors.membership_type}
                            </div>
                          )}

                          {/* Conditionally render the Promo Code input based on membership_name_id */}
                          {selectedItem && selectedItem.membership_name_id === "2" && (
                            <div className="mt-3">
                              <Label htmlFor="promo_code">Promo Code</Label>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  id="promo_code"
                                  className="form-control promo-input"
                                  value={promoCode}
                                  placeholder="Discount Code"
                                  onChange={handlePromoCodeChange}
                                  maxLength={8}
                                  style={{ borderRadius: "25px", maxWidth: "300px", height: "50px", fontSize: "18px", marginRight: "20px" }}
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary ml-2"
                                  style={{ maxWidth: "250px", height: "50px", fontSize: "15px", borderRadius: "25px" }}
                                  onClick={handleCheckPromoCode}
                                >
                                  Apply
                                </button>
                              </div>
                              {promoCodeSubmitted && promoCodeApplied && validPromoItemId === selectedItem._id && (
                                <div style={{ fontSize: '12px', color: 'green' }}>
                                  Promo code applied successfully!
                                </div>
                              )}
                              {promoCodeSubmitted && !promoCodeApplied && (
                                <div style={{ fontSize: '12px', color: 'red' }}>
                                  Invalid promo code
                                </div>
                              )}
                            </div>
                          )}
                        </div>


                        <div className=" mb-2 ms-3 ps-5 col-11 form-check">
                          <Label htmlFor="dobcheck">
                            <span className="text-danger">*</span>I agree that I
                            am above 21 years of age and allow SATS to use my
                            Date of Birth for its operational purposes.
                          </Label>
                          <Input
                            name="dobcheck"
                            className="form-check-input check-sm"
                            type="checkbox"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.dobcheck || ""}
                            invalid={
                              validation.touched.dobcheck &&
                                validation.errors.dobcheck
                                ? true
                                : false
                            }
                          />
                          {validation.touched.dobcheck &&
                            validation.errors.dobcheck ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.dobcheck}
                            </FormFeedback>
                          ) : null}
                        </div>
                        {/* <div className=" mb-3 ms-3 ps-5 col-11 form-check">
                          <Label htmlFor="subscribe">
                            I wish to subscribe newsletter
                          </Label>
                          <Input
                            name="subscribe"
                            class="form-check-input"
                            type="checkbox"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.subscribe || ""}
                            invalid={
                              validation.touched.subscribe &&
                              validation.errors.subscribe
                                ? true
                                : false
                            }
                          />
                          {validation.touched.subscribe &&
                          validation.errors.subscribe ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.subscribe}
                            </FormFeedback>
                          ) : null}
                        </div> */}
                      </Row>
                      <Col className="col-12 d-flex mt-3">
                        <div className=" text-end mt-3 col-12">
                          <button
                            type="submit"
                            className="btn btn-warning mx-3"
                            disabled={validation.values.dobcheck !== true}
                          >
                            {"Submit"}
                          </button>
                        </div>
                      </Col>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer style={{ zIndex: "10999" }} />
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={PaypalModal}
          onHide={() => setPaypalModal(false)}
          centered
          backdrop="static"
        >
          <h2 className="title">
            Payment Process{" "}
            <span
              className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
              style={{
                fontSize: "2rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setPaypalModal(false)
              }}
            >
              <SlClose />
            </span>
          </h2>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              //ValidationRegister.handleSubmit()
            }}
          >
            <MembershipButton
              data2={profileres}
              setModal={setPaypalModal}
              upgrade={"Res"}
              purpose={"Registration"}
              membershipSelected={TempMembership}
              ReturnUpgradeshow={null}
            />
            {/* <PaymentPaypal data2={validation.values} /> */}
          </Form>
        </Modal>
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={ConfirmMemshipModal}
          onHide={() => setConfirmMemshipModal(false)}
          backdrop="static"
          centered
        >
          <h2 className="title">Are you Sure</h2>
          <p className="text-justify">
            Membership expires on
            <span className="fw-bold"> {daysRemaining()}</span> . If you agree
            with the Membership plan, please confirm it.
          </p>
          <div className="d-flex gap-1 justify-content-end">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setmemShipagree(true)
                setConfirmMemshipModal(false)
              }}
            >
              Confirm
            </button>
            <button
              className="btn btn-light btn-sm"
              onClick={() => {
                setmemShipagree(false)
                setConfirmMemshipModal(false)
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default Register
