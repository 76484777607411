import React, { useState, useEffect, useReducer } from "react"
import { ThemeContext } from "../context/ThemeContext"
import moment from "moment"
import MembershipButton from "../components/Paypal/MembershipButton"
import { Link, useNavigate } from "react-router-dom"
import { Modal, Spinner, Button } from "react-bootstrap"
import Collapse from "react-bootstrap/Collapse"
import { MenuListArray2 } from "./Menu"
import { IMAGES } from "../constant/theme"
import { fetchMembershipTypes, fetchMyProfileData, RemoveProfileData } from "../features/FetchData"
import { FormFeedback, FormGroup, Input, Label, Form } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { SlLogin, SlLogout } from "react-icons/sl"
import { CgProfile } from "react-icons/cg"
import { GiHumanTarget } from "react-icons/gi"
import { SlClose } from "react-icons/sl"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast, ToastContainer } from "react-toastify"
import { phoneNumber } from "../helpers/Api_help"
import {
  Reset,
  Forgetpassword,
  ResetPasswordlink,
  Login,
  Logout,
  LoginStatusCheck,
} from "../features/auth/authSlice"

//import logo from './../assets/images/logo-2.png';

const Header = ({ onShowDonate, changeStyle, changeLogo }) => {
  //form submit
  const nav = useNavigate()
  const {
    User_Id,

    isLogout,
    ForgetpasswordUser,

    LoginStatus,
    isLoading,
  } = useSelector((state) => state.auth)

  const { SuccessBAM, UpadteBAM } = useSelector(
    (state) => state.FormDataReducer
  )
  /* for sticky header */
  const [headerFix, setheaderFix] = useState(false)

  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setheaderFix(window.scrollY > 150)
    })
  }, [])

  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    var mainMenu = document.getElementById("OpenMenu")
    if (mainMenu) {
      if (sidebarOpen) {
        mainMenu.classList.add("show")
      } else {
        mainMenu.classList.remove("show")
      }
    }
  })

  

  // Menu dropdown list
  const reducer = (previousState, updatedState) => ({
    ...previousState,
    ...updatedState,
  })
  const initialState = {
    active: "",
    activeSubmenu: "",
  }
  const [state, setState] = useReducer(reducer, initialState)

  const handleMenuActive = (status) => {
    setState({ active: status })
    if (state.active === status) {
      setState({ active: "" })
    }
  }
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status })
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" })
    }
  }
  //let path = window.location.pathname;
  // const [sideOverlay, setSideOverlay] = useState(false)

  //Modal
  const dispatch = useDispatch()
  const [LoginError, setLoginError] = useState("")
  const [changePwdError, setChangePwdError] = useState("")
  const [logoutModal, setLogoutModal] = useState(false)
  const [loginModal, setloginModal] = useState(false)
  const [resetModal, setResetModal] = useState(false)
  const [passwordModal, setpasswordModal] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [ForgotSubmitBtnDisable, setForgotSubmitBtnDisable] = useState(false)

  // [Upgrade]
  const { MembershipTypes, MyProfileInfo } = useSelector(
    (state) => state.FetchDataReducer
  )



  // console.log("user_id", User_Id_)
  useEffect(() => {
    // setForgotSubmitBtnDisable(false)
    dispatch(fetchMembershipTypes())
  }, [])

  useEffect(() => {
    if (User_Id_) {
      //dispatch(fetchMyProfileData(User_Id_))
    }
  }, [])

  

  // LOGOUT
  const logout = () => {
    dispatch(Logout())
    setLogoutModal(false)
  }

  useEffect(() => {
    // Set data in sessionStorage
    //sessionStorage.setItem('myData', 'Some value');

    // Specify session time (in milliseconds)
    const sessionTime = 30 * 60 * 1000; // 30 minutes

    console.log("------ Session started ------")

    // Set a timeout to clear sessionStorage after the specified session time
    const timeoutId = setTimeout(() => {
      // Clear the item from sessionStorage
      console.log("------ Session time out ------")
      dispatch(Logout())
      dispatch(RemoveProfileData())
      localStorage.removeItem("userInfo")
      localStorage.removeItem("user_id")
      nav("/")
    }, sessionTime);

    // Clean up the timeout when the component unmounts or when the session ends
    return () => clearTimeout(timeoutId);
  }, []); // Run effect only once when the component mounts

  

  useEffect(() => {
    if (isLogout) {
      dispatch(RemoveProfileData())
      localStorage.removeItem("userInfo")
      localStorage.removeItem("user_id")
      nav("/")
    }
    dispatch(Reset())
  }, [isLogout])
 

  return (
    <>
      <header className={`site-header mo-left header style-2 ${changeStyle}`}>
        {/* ---- Top Bar ------ */}
        <div className="top-bar" style={{ backgroundColor: "#6c91f3" }}>
          <div className="container-fluid">
            <div className="dz-topbar-inner d-flex justify-content-between align-items-center">
              <div className="dz-topbar-left">
                <ul>
                  <li>Welcome to SA Tamil Sangam</li>
                  {userInfo || localStorage.getItem("Login") ? (
                    <li>
                      <Link
                        to={"#"}
                        className="badge badge-danger btn-login me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogout"
                        onClick={() => setLogoutModal(true)}
                      >
                        <SlLogout />
                        <span className="ms-2">Logout</span>
                      </Link>
                      <Link
                        to={"/myprofile"}
                        className="badge badge-info btn-login me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogout"
                      >
                        <CgProfile />
                        <span className="ms-2">{userInfo?userInfo.firstname:"My Profile"}</span>
                      </Link>
                      <Link
                        to={"/mywallet"}
                        className="badge badge-warning btn-login"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogout"
                      >
                        {/* <CgProfile /> */}
                        <span className="ms-2">My Wallet</span>
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={"/login"}
                        className="badge badge-success btn-login me-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogin"
                        // onClick={LoginToggle}
                      >
                        <SlLogin />
                        <span className="ms-2">Sign In</span>
                      </Link>
                      <Link
                        to={"/registration"}
                        className="badge badge-secondary "
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogin"
                        onClick={() => {
                          setloginModal(false)
                        }}
                      >
                        <GiHumanTarget />
                        <span className="ms-2">New Member? Sign Up</span>
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
              <div className="dz-topbar-right">
                <ul>
                  <li>
                    <Link
                      to={"/donate-form"}
                      className="btn btn-sm btn-info btnhover1 text-center py-1"
                      data-bs-target="#modalDonate"
                      //onClick={()=>onShowDonate(true)}
                    >
                      Donate
                      <i className="flaticon-heart text-light ms-1"></i>
                    </Link>
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <i className="fas fa-phone"></i> {phoneNumber}
                  </li>
                  <li style={{ fontSize: "12px" }}>
                    <i className="fas fa-envelope"></i>sats@satamilsangam.org
                  </li>

                  {/* <li>
                    <a
                      href={"https://cuckooradio.com/?clientToken=b1Xoe2To3J"}
                      target="_blank"
                    >
                      <img
                        src={IMAGES.SATsRadio}
                        style={{ objectFit: "cover", width: "70%" }}
                      />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* <div className="dz-topbar-inner d-flex justify-content-center align-items-center">
              <img src={IMAGES.contentLogo} alt="" />
            </div> */}
          </div>
        </div>

        {/* ---- Menu Bar ------ */}
        <div
          className={`sticky-header main-bar-wraper navbar-expand-lg ${
            headerFix ? "is-fixed" : ""
          }`}
        >
          <div className="main-bar clearfix ">
            <div className="container-fluid clearfix">
              {changeLogo ? (
                <>
                  <div
                    className="logo-header mostion logo-dark"
                    // style={{ paddingLeft: "3rem", paddingTop: "11px" }}
                  >
                    <Link to={"/"}>
                      <img src={IMAGES.logoSats} alt="" />
                    </Link>
                  </div>
                  <div
                    className="logo-header mostion logo-light"
                    // style={{ paddingLeft: "3rem", paddingTop: "11px" }}
                  >
                    <Link to={"/"}>
                      <img src={IMAGES.logoSats} alt="" />
                    </Link>
                  </div>
                </>
              ) : (
                <div
                  className="logo-header mostion logo-dark"
                  // style={{ paddingLeft: "3rem", paddingTop: "11px" }}
                >
                  <Link to={"/"}>
                    <img src={IMAGES.logoSats} alt="" />
                  </Link>
                </div>
              )}
              <button
                type="button"
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className={`navbar-toggler navicon justify-content-end ${
                  sidebarOpen ? "open" : "collapsed"
                }`}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              {/* <div className="extra-nav">
                <div className="extra-cell d-flex align-items-center">
                  <Link
                    to={"#"}
                    className="menu-btn"
                    onClick={() => setSideOverlay(!sideOverlay)}
                  >
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="22"
                        y="11"
                        width="4"
                        height="4"
                        rx="2"
                        fill="#003B4A"
                      />
                      <rect x="11" width="4" height="4" rx="2" fill="#003B4A" />
                      <rect x="22" width="4" height="4" rx="2" fill="#003B4A" />
                      <rect
                        x="11"
                        y="11"
                        width="4"
                        height="4"
                        rx="2"
                        fill="#003B4A"
                      />
                      <rect
                        x="11"
                        y="22"
                        width="4"
                        height="4"
                        rx="2"
                        fill="#003B4A"
                      />
                      <rect width="4" height="4" rx="2" fill="#003B4A" />
                      <rect y="11" width="4" height="4" rx="2" fill="#003B4A" />
                      <rect
                        x="22"
                        y="22"
                        width="4"
                        height="4"
                        rx="2"
                        fill="#003B4A"
                      />
                      <rect y="22" width="4" height="4" rx="2" fill="#003B4A" />
                    </svg>
                  </Link>
                </div>
              </div> */}

              <div
                className={`header-nav navbar-collapse collapse justify-content-center ${
                  sidebarOpen ? "show" : ""
                }`}
                // style={{ background: "#416bdb" }}
              >
                <div className="logo-header logo-dark ">
                  {changeLogo ? (
                    <Link to={"/"} onClick={() => setSidebarOpen(false)}>
                      <img src={IMAGES.logoSats} alt="" />
                    </Link>
                  ) : (
                    <Link to={"/"} onClick={() => setSidebarOpen(false)}>
                      <img src={IMAGES.logoSats} alt="" />
                    </Link>
                  )}
                </div>
                <ul className="nav navbar-nav navbar navbar-left dz-social">
                  {MenuListArray2.map((data, index) => {
                    let menuClass = data.classChange
                    if (menuClass !== "sub-menu-down") {
                      return (
                        <li className={menuClass} key={"m" + index}>
                          <Link
                            to={data.to}
                            onClick={() => setSidebarOpen(false)}
                          >
                            {data.title}
                          </Link>
                        </li>
                      )
                    } else {
                      return (
                        <li
                          className={`${menuClass} ${
                            state.active === data.title ? "open" : ""
                          }`}
                          key={"m" + index}
                        >
                          {data.content && data.content.length > 0 ? (
                            <Link
                              to={"#"}
                              onClick={() => {
                                handleMenuActive(data.title)
                              }}
                            >
                              {data.title}
                            </Link>
                          ) : (
                            <Link
                              to={data.to}
                              onClick={() => setSidebarOpen(false)}
                            >
                              {data.title}
                            </Link>
                          )}
                          <Collapse
                            in={state.active === data.title ? true : false}
                          >
                            <ul
                              className={`sub-menu ${
                                menuClass === "mm-collapse" ? "open" : ""
                              }`}
                            >
                              {data.content &&
                                data.content.map((data, index) => {
                                  return (
                                    <li
                                      key={"w" + index}
                                      className={`${
                                        state.activeSubmenu === data.title
                                          ? "open"
                                          : ""
                                      }`}
                                    >
                                      {data.content &&
                                      data.content.length > 0 ? (
                                        <>
                                          <Link
                                            to={data.to}
                                            onClick={() => {
                                              handleSubmenuActive(data.title)
                                              setSidebarOpen(false)
                                            }}
                                          >
                                            {data.title}
                                            <i className="fa fa-angle-right" />
                                          </Link>
                                          <Collapse
                                            in={
                                              state.activeSubmenu === data.title
                                                ? true
                                                : false
                                            }
                                          >
                                            <ul
                                              className={`sub-menu ${
                                                menuClass === "mm-collapse"
                                                  ? "open"
                                                  : ""
                                              }`}
                                            >
                                              {data.content &&
                                                data.content.map(
                                                  (data, index) => {
                                                    return (
                                                      <li key={"s" + index}>
                                                        <Link
                                                          to={data.to}
                                                          onClick={() =>
                                                            setSidebarOpen(
                                                              false
                                                            )
                                                          }
                                                        >
                                                          {data.title}
                                                        </Link>
                                                      </li>
                                                    )
                                                  }
                                                )}
                                            </ul>
                                          </Collapse>
                                        </>
                                      ) : (
                                        <Link
                                          to={data.to}
                                          onClick={() => {
                                            setSidebarOpen(false)
                                          }}
                                        >
                                          {data.title}
                                        </Link>
                                      )}
                                    </li>
                                  )
                                })}
                            </ul>
                          </Collapse>
                        </li>
                      )
                    }
                  })}
                </ul>
                <div className="header-bottom">
                  {User_Id || localStorage.getItem("Login") ? (
                    <>
                      <Link
                        to={"#"}
                        className="btn btn-light btn-login btn-sm mb-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogout"
                        onClick={() => {
                          setSidebarOpen(false)
                          setLogoutModal(true)
                        }}
                      >
                        <SlLogout />
                        <span className="ms-2">Logout</span>
                      </Link>
                      <Link
                        to={"/myprofile"}
                        className="btn btn-primary btn-login btn-sm mb-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogout"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <CgProfile />
                        <span className="ms-2">My Profile</span>
                      </Link>
                      <Link
                        to={"/mywallet"}
                        className="btn btn-warning btn-login btn-sm mb-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogout"
                        onClick={() => setSidebarOpen(false)}
                      >
                        {/* <CgProfile /> */}
                        <span className="ms-2">My Wallet</span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to={"/login"}
                        className="btn btn-light btn-login btn-sm mb-2"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogin"
                        onClick={() => {
                          setSidebarOpen(false)
                          // LoginToggle()
                        }}
                      >
                        <SlLogin />
                        <span className="ms-2">Sign In</span>
                      </Link>

                      <Link
                        to={"/registration"}
                        className="btn btn-light btn-login btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#modalLogin"
                        onClick={() => {
                          setSidebarOpen(false)
                          setloginModal(false)
                        }}
                      >
                        <GiHumanTarget />
                        <span className="ms-2">Sign Up</span>
                      </Link>
                    </>
                  )}
                  <li>
                    <Link
                      to={"/donate-form"}
                      className="btn btn-sm btn-login btn-info btnhover1 text-center my-2 "
                      data-bs-target="#modalDonate"
                      //onClick={()=>onShowDonate(true)}
                      onClick={() => setSidebarOpen(false)}
                    >
                      Donate
                      <i className="flaticon-heart text-light ms-1"></i>
                    </Link>
                  </li>

                  <div className="dz-social-icon">
                    <ul>
                      <li>
                        <Link
                          to={"/donate-form"}
                          className="btn  btnhover1 text-center"
                          data-bs-target="#modalDonate"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <i className="flaticon-heart text-light ms-0"></i>
                        </Link>
                      </li>
                      <li>
                        <a
                          className="fab fa-facebook-f "
                          href="https://www.facebook.com/groups/412326935471793/"
                          target="_blank"
                          rel="noreferrer"
                        ></a>
                      </li>
                      <li>
                        <a
                          className="fab "
                          href="https://twitter.com/satamilsangam"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <svg
                            style={{ height: 17, verticalAlign: "text-top" }}
                            viewBox="0 0 24 24"
                            aria-label="X"
                            role="img"
                          >
                            <g>
                              <path
                                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-google-plus"
                          rel="noreferrer"
                          href="https://plus.google.com/109637063396653454683/posts"
                        ></a>
                      </li>
                      <li>
                        <a
                          className="fab fa-instagram"
                          href="https://www.instagram.com/satamilsangam/"
                          target="_blank"
                          rel="noreferrer"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <div className={`contact-sidebar ${sideOverlay ? "active" : ""}`}>
        <div className="contact-box">
          <div className="logo-contact logo-dark">
            {changeLogo ? (
              <Link to={"/"}>
                <img src={IMAGES.logo3} alt="" />
              </Link>
            ) : (
              <Link to={"/"}>
                <img src={IMAGES.logo2} alt="" />
              </Link>
            )}
          </div>
          <div className="m-b50 contact-text">
            <div className="dz-title">
              <h4>About us</h4>
              <div className="dz-separator style-1 text-primary mb-0"></div>
            </div>
            <p>
              Aliquam erat massa porttitor vel egestas sit tristique ultricies
              lorem aliquet venenatis libero a nulla placerat egestas.
            </p>
            <Link to={"/about-us"} className="btn btn-primary btn-sm">
              READ MORE
            </Link>
          </div>
          <div className="dz-title">
            <h4>Contact Info</h4>
            <div className="dz-separator style-1 text-primary mb-0"></div>
          </div>
          <div className="icon-bx-wraper left">
            <div className="icon-md m-r20">
              <span className="icon-cell">
                <i className="las la-phone-volume"></i>
              </span>
            </div>
            <div className="icon-content">
              <h5 className="tilte">Call Now</h5>
              <p className="m-b0">
                +91 123 456 7890,
                <br /> +91 987 654 3210
              </p>
            </div>
          </div>
          <div className="icon-bx-wraper left">
            <div className="icon-md m-r20">
              <span className="icon-cell">
                <i className="las la-envelope-open"></i>
              </span>
            </div>
            <div className="icon-content">
              <h5 className="tilte">Email Now</h5>
              <p className="m-b0">info@gmail.com, services@gmail.com</p>
            </div>
          </div>
          <div className="icon-bx-wraper left">
            <div className="icon-md m-r20">
              <span className="icon-cell">
                <i className="las la-map-marker"></i>
              </span>
            </div>
            <div className="icon-content">
              <h5 className="tilte">Location</h5>
              <p className="m-b0">15/B Miranda House, New York, US</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="menu-close"
        onClick={() => setSideOverlay(!sideOverlay)}
      ></div> */}
      <Modal
        className="modal fade modal-wrapper auth-modal"
        id="modalLogout"
        show={logoutModal}
        onHide={setLogoutModal}
        centered
      >
        <h2 className="title">Are you sure to logout?</h2>
        <Form>
          <div className="form-group">
            <button
              type="button"
              className="btn btn-outline-danger btn-block"
              onClick={() => logout()}
            >
              Logout Confirm
            </button>
          </div>
          <Link
            to={"#"}
            className="sign-text d-block"
            data-bs-toggle="collapse"
            onClick={() => setLogoutModal(false)}
          >
            Back
          </Link>
        </Form>
      </Modal>

      {/* //LOGIN RESET REGISTER */}
      {/* <Modal
        className="modal fade modal-wrapper auth-modal"
        id="modalLogin"
        show={loginModal}
        onHide={setloginModal}
        backdrop="static"
        centered
      >
        <h2 className="title">
          Sign In
          <span
            className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
            style={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={() => setloginModal(false)}
          >
            <SlClose />
          </span>
        </h2>

        <Form
          onSubmit={(e) => {
            e.preventDefault()
            ValidationLogin.handleSubmit()
          }}
        >
          <FormGroup className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              type="email"
              name="email"
              className="form-control"
              placeholder="Enter email here"
              // onFocus={}
              onChange={ValidationLogin.handleChange}
              onBlur={(e) => {
                ValidationLogin.setFieldValue("email", e.target.value)
                dispatch(LoginStatusCheck(e.target.value))
              }}
              value={ValidationLogin.values.email || ""}
              invalid={
                ValidationLogin.touched.email && ValidationLogin.errors.email
                  ? true
                  : false
              }
            />
            {ValidationLogin.touched.email && ValidationLogin.errors.email ? (
              <FormFeedback type="invalid">
                {ValidationLogin.errors.email}
              </FormFeedback>
            ) : null}
            <div
              className="my-2"
              style={{
                fontSize: "12px",
                color: "red",
              }}
            >
              {emailError}
            </div>
          </FormGroup>
          <FormGroup className=" mb-3">
            <Label className="form-label">
              {LoginStatus.message === "User is not logged in"
                ? "Temporary Password"
                : "Password"}
            </Label>
            <Input
              type={"password"}
              name="password"
              className="input-group form-control"
              aria-label="Small"
              placeholder="Enter password"
              onChange={ValidationLogin.handleChange}
              onBlur={ValidationLogin.handleBlur}
              value={ValidationLogin.values.password || ""}
              invalid={
                ValidationLogin.touched.password &&
                ValidationLogin.errors.password
                  ? true
                  : false
              }
            />

            {ValidationLogin.touched.password &&
            ValidationLogin.errors.password ? (
              <FormFeedback type="invalid">
                {ValidationLogin.errors.password}
              </FormFeedback>
            ) : null}
          </FormGroup>
          {LoginStatus.message !== "Your plan is expired" && (
            <div className="reset-password mb-3">
              <Link
                to={"#"}
                className="btn-link collapsed"
                role="button"
                aria-controls="reset-password"
                // onClick={() => setOpenCollapse(!openCollapse)}
                onClick={() => (ResetToggle(), setloginModal(false))}
              >
                Forgot Password?
              </Link>
            </div>
          )}

          {LoginStatus.message === "Your plan is expired" && (
            <div className="reset-password mb-3">
              <Link
                to={"#"}
                className="btn-link collapsed"
                role="button"
                aria-controls="reset-password"
                // onClick={() => setOpenCollapse(!openCollapse)}
                onClick={() => (setUpgradeModal(true), setloginModal(false))}
              >
                Upgrade Plan?
              </Link>
            </div>
          )}

          <div
            className="my-2 text-center text-capitalize"
            style={{
              fontSize: "12px",
              color: "red",
            }}
          >
            {LoginError}
          </div>
          {isLoading === true ? (
            <Button variant="primary" disabled>
              <Button
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          ) : (
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-outline-primary btn-block"
              >
                Login
              </button>
            </div>
          )}

          <div className="sign-text">
            <span>
              Don't have an SA tamil Account?
              <Link
                to={"/registration"}
                onClick={(e) => setloginModal(false)}
                className="btn-link collapsed"
              >
                <span> Sign up</span>
              </Link>
            </span>
          </div>
        </Form>
      </Modal> */}
      {/* <Modal
        className="modal fade modal-wrapper auth-modal"
        show={resetModal}
        onHide={() => setResetModal(false)}
        backdrop="static"
        centered
      >
        <div id="reset-password">
          <h2 className="title">
            Forgot Password?{" "}
            <span
              className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
              style={{
                fontSize: "2rem",
                cursor: "pointer",
              }}
              onClick={() => (
                ResetToggle(),
                setloginModal(true),
                setForgotSubmitBtnDisable(false)
              )}
            >
              <SlClose />
            </span>
          </h2>

          <Form
            onSubmit={(e) => {
              e.preventDefault()
              ValidationReset.handleSubmit()
            }}
          >
            <div className="form-group password-icon-bx">
              <i className="fa fa-lock"></i>
              <p>
                Enter your email address associated with your account, and we'll
                email you a link to reset your password...
              </p>
            </div>
            {isLoading === true ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              >
                Loading...
              </Spinner>
            ) : (
              <>
                <FormGroup className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Enter your Email here..!"
                    onChange={ValidationReset.handleChange}
                    onBlur={ValidationReset.handleBlur}
                    value={ValidationReset.values.email || ""}
                    invalid={
                      ValidationReset.touched.email &&
                      ValidationReset.errors.email
                        ? true
                        : false
                    }
                  />
                  {ValidationReset.touched.email &&
                  ValidationReset.errors.email ? (
                    <FormFeedback type="invalid">
                      {ValidationReset.errors.email}
                    </FormFeedback>
                  ) : null}
                </FormGroup>

                <div className="form-group">
                  <button
                    // type="submit"
                    disabled={ForgotSubmitBtnDisable}
                    className="btn btn-outline-primary btn-block"
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </Form>
        </div>
      </Modal> */}
      {/* <Modal
        className="modal fade modal-wrapper auth-modal"
        show={passwordModal}
        backdrop="static"
        onHide={() => setpasswordModal(false)}
        centered
      >
        <h3 className="title">
          Reset Password{" "}
          <span
            className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
            style={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setpasswordModal(false)
              setForgotSubmitBtnDisable(false)
            }}
          >
            <SlClose />
          </span>
        </h3>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            forgetpwd.handleSubmit()
          }}
        >
          <FormGroup className=" mb-2">
            <Label className="form-label">Temporary Password</Label>
            <Input
              type={"password"}
              name="temporaryPassword"
              className="input-group form-control"
              placeholder="Enter the Password"
              onChange={forgetpwd.handleChange}
              onBlur={forgetpwd.handleBlur}
              value={forgetpwd.values.temporaryPassword || ""}
              invalid={
                forgetpwd.touched.temporaryPassword &&
                forgetpwd.errors.temporaryPassword
                  ? true
                  : false
              }
            />

            {forgetpwd.touched.temporaryPassword &&
            forgetpwd.errors.temporaryPassword ? (
              <FormFeedback type="invalid">
                {forgetpwd.errors.temporaryPassword}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup className="mb-2">
            <Label className="form-label">New Password</Label>
            <Input
              type="password"
              name="newPassword"
              className="form-control"
              placeholder="Enter Your New Password"
              onChange={forgetpwd.handleChange}
              onBlur={forgetpwd.handleBlur}
              value={forgetpwd.values.newPassword || ""}
              invalid={
                forgetpwd.touched.newPassword && forgetpwd.errors.newPassword
                  ? true
                  : false
              }
            />
            {forgetpwd.touched.newPassword && forgetpwd.errors.newPassword ? (
              <FormFeedback type="invalid">
                {forgetpwd.errors.newPassword}
              </FormFeedback>
            ) : null}
          </FormGroup>
          <FormGroup className="mb-2">
            <Label className="form-label">Confirm Password</Label>
            <Input
              type="password"
              name="confirmPassword"
              className="form-control"
              placeholder="Enter Your Confirm Password"
              onChange={forgetpwd.handleChange}
              onBlur={forgetpwd.handleBlur}
              value={forgetpwd.values.confirmPassword || ""}
              invalid={
                forgetpwd.touched.confirmPassword &&
                forgetpwd.errors.confirmPassword
                  ? true
                  : false
              }
            />
            {forgetpwd.touched.confirmPassword &&
            forgetpwd.errors.confirmPassword ? (
              <FormFeedback type="invalid">
                {forgetpwd.errors.confirmPassword}
              </FormFeedback>
            ) : null}
          </FormGroup>

          <div
            className="my-1 text-center text-capitalize"
            style={{
              fontSize: "12px",
              color: "red",
            }}
          >
            {changePwdError}
          </div>

          {isLoading === true ? (
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          ) : (
            <div className="sign-text">
              <span>
                <button
                  className="btn btn-outline-primary btn-block"
                  data-bs-toggle="collapse"
                >
                  Submit
                </button>
              </span>
            </div>
          )}
        </Form>
      </Modal> */}

      {/* //upgrade */}
      {/* <Modal
        className="modal fade modal-wrapper  auth-modal"
        show={UpgradeModal}
        onHide={setUpgradeModal}
        centered
        backdrop="static"
      >
        <h3 className="title">Are you Want to Upgrade your </h3>
        <span
          className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
          style={{
            fontSize: "2rem",
            cursor: "pointer",
          }}
          onClick={() => {
            setUpgradeModal(false)
            setmemShipagree(false)
          }}
        >
          {" "}
          <SlClose />
        </span>
        <div className="col-12 col-md-11 col-lg-11  d-flex flex-column">
          {" "}
          <Label htmlFor="member">
            SATS Membership
            <span className="text-danger">*</span>
          </Label>
          <div className="btn-group d-flex flex-nowrap " role="group">
            {MembershipTypes.map((item, ind) => (
              <>
                <input
                  type="radio"
                  className="btn-check" //flex-fill
                  name="btnradio"
                  id={item._id}
                  autoComplete="off"
                  value={validation.values.membership_type || ""}
                  defaultChecked={
                    validation.values.membership_type === item.membership_name
                  }
                  onClick={(e) => {
                    memShipConfirm(item)
                  }}
                  invalid={validation.errors.membership_type ? true : false}
                />
                <label
                  className="btn btn-outline-warning d-flex flex-column"
                  htmlFor={item._id}
                >
                  <p>{item.membership_name}</p>

                  <h6>${item.fees}*</h6>
                </label>
              </>
            ))}
          </div>
          <div className="text-center">
            <span className="text-danger">*</span>{" "}
            <small>PayPal Transaction Fee (2.25%+31 Cents) Extra</small>
          </div>
          {validation.touched.membership_type &&
          validation.errors.membership_type ? (
            <div style={{ fontSize: "12px", color: "red" }}>
              {validation.errors.membership_type}
            </div>
          ) : null}
          {MemShipagree && (
            <div className="mt-3">
              <MembershipButton
                data2={validation.values}
                setModal={setUpgradeModal}
              />
            </div>
          )}
        </div>
      </Modal> */}
      {/* <Modal
        className="modal fade modal-wrapper auth-modal"
        show={ConfirmMemshipModal}
        onHide={() => setConfirmMemshipModal(false)}
        backdrop="static"
        centered
      >
        <h2 className="title text-warning">Are you Sure</h2>
        <p className="text-justify">
          Membership expires on
          <span className="fw-bold"> {daysRemaining()}</span> . If you agree
          with the Membership plan, please confirm it.
        </p>
        <div className="d-flex gap-1 justify-content-end">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setmemShipagree(true)
              setConfirmMemshipModal(false)
            }}
          >
            Confirm
          </button>
          <button
            className="btn btn-light btn-sm"
            onClick={() => {
              setmemShipagree(false)
              setConfirmMemshipModal(false)
            }}
          >
            Cancel
          </button>
        </div>
      </Modal> */}

      <ToastContainer style={{ zIndex: "10999" }} />
    </>
  )
}

export default Header
