import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import PageBanner from "../../layouts/PageBanner"

import bg from "../../assets/images/banner/bnr1.jpg"
// import { Sponsers } from "../../constant/theme"
import { useDispatch, useSelector } from "react-redux"
import { fetchSponsers } from "../../features/FetchData"
import { ApiServer } from "../../helpers/Api_help"
// const cardiconBlog = [
//   { title: "Technology", icon: "flaticon-vr-glasses" },
//   { title: "Education", icon: "flaticon-open-book" },
//   { title: "Videos", icon: "flaticon-video" },
//   { title: "Medical", icon: "flaticon-doctor-bag" },
//   { title: "Love", icon: "flaticon-like-1" },
//   { title: "Design", icon: "flaticon-transformation" },
//   { title: "Health", icon: "flaticon-raw-food" },
//   { title: "Sports", icon: "flaticon-coins-1" },
//   { title: "Care", icon: "flaticon-responsibility" },
//   { title: "Support", icon: "flaticon-handshake" },
//   { title: "Fashion", icon: "flaticon-coin-stack" },
//   { title: "Events", icon: "flaticon-calendar" },
// ]

export const SponsersData = ({ data }) => {
  console.log("data.logo", data.logo)
  return (
    <div className="widget-post-bx ">
      {/* {data.map((item, ind) => ( */}
      <div
        // key={ind}
        className="mt-1 mb-1"
        style={{
          borderBottom: "2px solid #dedede",
        }}
      >
        <div className="dz-card blog-half" style={{ paddingBottom: 10 }}>
          <div className="dz-media">
            <Link to={"#!"}>
              {
                data.logo &&
                <img
                  src={`${ApiServer}assets/sponsorship/${data.logo}`}
                  alt="NOT FOUND"
                  className="rounded"
                  style={{ objectFit: "contain", width: "50%" }}
                />
              }

            </Link>
          </div>
          <div className="dz-info">
            <h5 className="dz-title">
              <Link to={"#!"}>{data.sp_name}</Link>
            </h5>

            {data.website_url && (
              <a
                href={data.website_url}
                target="_blank"
                className="btn btn-sm  btn-info"
              >
                Website
              </a>
            )}
          </div>
        </div>
      </div>
      {/*   ))} */}
    </div>
  )
}

const OurSponsors = () => {
  const { Sponser, Success, fail } = useSelector(
    (state) => state.FetchDataReducer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    console.log("Sponser", Sponser)
    dispatch(fetchSponsers())
  }, [])

  // const Platinum = Sponser.filter(
  //   (item) => item.esp_category === "Platinum Sponsors"
  // )
  // const Gold = Sponser.filter((item) => item.esp_category === "Gold Sponsors")
  // const Silver = Sponser.filter(
  //   (item) => item.esp_category === "Silver Sponsors"
  // )
  // const Bronze = Sponser.filter(
  //   (item) => item.esp_category === "Bronze Sponsors"
  // )
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Our Sponsors" background={bg} />
        <section className="content-inner-2">
          <div className="container">

            <div className="comments-area mt-5" id="comments">
              <div className="widget-title style-1">
                <h4 className="title">Grand Sponsors</h4>
              </div>

              {Sponser.map(
                (item, ind) =>
                  item.sponsorship_level === "Grand" && (
                    <SponsersData data={item} key={ind + "gnd"} />
                  )
              )}
            </div>

            <div className="comments-area" id="comments">
              <div className="widget-title style-1">
                <h4 className="title">Platinum Sponsors</h4>
              </div>

              {Sponser.map(
                (Platinum, ind) =>
                  Platinum.sponsorship_level === "Platinum" && (
                    <SponsersData data={Platinum} key={ind} />
                  )
              )}
            </div>

            <div className="comments-area mt-5" id="comments">
              <div className="widget-title style-1">
                <h4 className="title">Gold Sponsors</h4>
              </div>

              {Sponser.map(
                (Gold, ind) =>
                  Gold.sponsorship_level === "Gold" && (
                    <SponsersData data={Gold} key={ind} />
                  )
              )}
            </div>

            <div className="comments-area mt-5" id="comments">
              <div className="widget-title style-1">
                <h4 className="title">Silver Sponsors</h4>
              </div>

              {Sponser.map(
                (Silver, ind) =>
                  Silver.sponsorship_level === "Silver" && (
                    <SponsersData data={Silver} key={ind} />
                  )
              )}
            </div>

            <div className="comments-area mt-5" id="comments">
              <div className="widget-title style-1">
                <h4 className="title">Bronze Sponsors</h4>
              </div>

              {Sponser.map(
                (Bronze, ind) =>
                  Bronze.sponsorship_level === "Bronze" && (
                    <SponsersData data={Bronze} key={ind} />
                  )
              )}
            </div>

          </div>
        </section>
      </div>
    </>
  )
}

export default OurSponsors
