import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Button,
} from "reactstrap"
import { Spinner, Modal } from "react-bootstrap"
import MembershipButton from "../../components/Paypal/MembershipButton"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment"
import { useNavigate, Link } from "react-router-dom"
import { IMAGES } from "../../constant/theme"
import PageBanner from "../../layouts/PageBanner"
import {
  fetchMyProfileData,
  fetchMembershipTypes,
} from "../../features/FetchData"
import { useDispatch, useSelector } from "react-redux"
import {
  Reset,
  Forgetpassword,
  ResetPasswordlink,
  Login,
  Logout,
  LoginStatusCheck,
} from "../../features/auth/authSlice"
import { useFormik } from "formik"
import * as Yup from "yup"
import swal from "sweetalert"

const UserLogin = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()

  const {
    User_Id,
    LoginUser,
    isLogout,
    ForgetpasswordUser,
    ResetPasswordlinkUser,
    LoginStatus,
    isLoading,
    message,
    isSuccess,
    isError,
  } = useSelector((state) => state.auth)
  const { MembershipTypes, MyProfileInfo } = useSelector(
    (state) => state.FetchDataReducer
  )
  const [emailError, setEmailError] = useState("")
  const [UpgradeModal, setUpgradeModal] = useState(false)
  const [MemShipagree, setmemShipagree] = useState(false)
  const [ConfirmMemshipModal, setConfirmMemshipModal] = useState(false)
  const [TempMembership, setTempMembership] = useState()
  const [changePwdError, setChangePwdError] = useState("")
  const [LoginError, setLoginError] = useState("")
  const [resetlinkError, setResetlinkerror] = useState("")
  const [showForm, setShowForm] = useState(1) // 1-login 2-resetlink 3-forgotpassword 4-upgrademodal
  const [disabledLogin, setDisabledLogin] = useState(false)
  const [disabledForget, setDisabledForget] = useState(false)

  const [disabledresetlink, setResetlinkDisabled] = useState(false)

  useEffect(() => {
    setLoginError("")
    dispatch(fetchMembershipTypes())
  }, [])



  const ValidationLogin = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .transform((value) => value.replace(/\s/g, ""))
        .email("Invalid Mail Format"),

      password: LoginStatus?.message_code !== "7" && Yup.string().required("Please enter your password"),
    }),
    onSubmit: (values, actions) => {

      if (LoginStatus?.message_code === "7") {
        forgetpwd.resetForm()
        setShowForm(3)
      }
      else
      {
        const userData = {
          email: values.email,
          password: values.password,
        }
        dispatch(Login(userData))
        setDisabledLogin(true)
  
      }
    },
  })


  /* validation Reset */
  const ValidationReset = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .transform((value) => value.replace(/\s/g, ""))
        .email("Invalid Mail Format")
        .required("Please Enter Your Email"),
    }),
    onSubmit: (values, actions) => {
      setResetlinkDisabled(true)
      const userData = {
        email: values.email,
      }
      dispatch(ResetPasswordlink(userData))
    },
  })



  const forgetpwd = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      temporaryPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    validationSchema: Yup.object({
      // email: Yup.string()
      //   .transform((value) => value.replace(/\s/g, ""))
      //   .email("Invalid Mail Format"),
      //   //.required("Please Enter Your Email"),
      temporaryPassword: Yup.string().required("Please Enter your password"),
      newPassword: Yup.string()
        .required("Please Enter your new password")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: (values, actions) => {
      values["email"]=LoginStatus?.data.email
      console.log("forgetpwd")
      setDisabledForget(true)
      dispatch(Forgetpassword(values))
    },
  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: MyProfileInfo?._id || "",
      email: MyProfileInfo?.email || "",
      firstname: MyProfileInfo?.firstname || "",
      lastname: MyProfileInfo?.lastname || "",
      gender: MyProfileInfo?.gender || "",
      mobile: MyProfileInfo?.mobile || "",
      membership_type: MyProfileInfo?.membership_type || "",
      membership_fees: MyProfileInfo?.membership_fees || "",
      membership_type_id: MyProfileInfo?.membership_type_id || "",
      member_id: MyProfileInfo?.member_id || "",
      address: MyProfileInfo?.address || "",
      address2: MyProfileInfo?.address2 || "",
      city: MyProfileInfo?.city || "",
      state: MyProfileInfo?.state || "",
      country_code: MyProfileInfo?.country_code || "",
      country: MyProfileInfo?.country || "",
      zipcode: MyProfileInfo?.zipcode || "",
      dob: MyProfileInfo?.dob || "",
      profile_img: MyProfileInfo?.profile_img || "",
      spouse: MyProfileInfo?.spouse || "",
      children: MyProfileInfo?.children || "",
      currency: MyProfileInfo?.currency || "",
      credit: MyProfileInfo?.credit || 0,
      credit_expired_on: MyProfileInfo?.credit_expired_on || null,

      // login_status: MyProfileInfo?.login_status || "",
      // status: MyProfileInfo?.status || "", //subscribe
      // dobcheck: MyProfileInfo?.dobcheck || "",
      // membership_expired_on: MyProfileInfo?.membership_expired_on || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, actions) => {
      // console.log("njkk", values)
      // dispatch(UpadteBAM(values))
    },
  })
  // console.log("Myprofile", MyProfileInfo)
  useEffect(() => {
    if (isLogout) {
      nav("/")
    }


    if (isError) {
      console.log("isError", isError, LoginStatus?.message_code)
      if (LoginStatus?.message_code === "2") {
        // ValidationLogin.resetForm()
        setDisabledLogin(false)
        dispatch(fetchMyProfileData(LoginStatus?.data))
        setLoginError(LoginStatus?.message)
      }

      if (LoginStatus?.message_code === "1") {
        setDisabledLogin(false)
        setLoginError(LoginStatus?.message)
      }

      if (LoginStatus?.message_code === "0") {
        setDisabledLogin(false)
        setLoginError(LoginStatus?.message)
      }

      if (LoginStatus?.message_code === "10") {
        console.log("am in")
        setResetlinkDisabled(false)
        setResetlinkerror(LoginStatus?.message)
      }

      if (LoginStatus?.message_code === "8") {
        console.log("am in")
        setDisabledForget(false)
        setChangePwdError(LoginStatus?.message)
      }

      // // console.log(
      // //   "error",
      // //   LoginUser,
      // //   ForgetpasswordUser,
      // //   ResetPasswordlinkUser,
      // //   LoginStatus
      // // )
      // if (LoginUser) {
      //   // console.log("LoginUseeffect", LoginUser)
      //   setLoginError(LoginUser?.message)
      //   if (LoginUser?.message_code === "4") {
      //     setLoginError(LoginUser?.message)

      //     dispatch(fetchMyProfileData({ _id: LoginStatus?.data }))
      //   }
      //   if (LoginUser?.message_code === "5") {
      //     setLoginError(LoginUser?.message)

      //     // dispatch(fetchMyProfileData({ _id: LoginStatus?.data }))
      //   }
      //   setDisabledLogin(false)
      // }
      // console.log("ForgetpasswordUsereffect", ForgetpasswordUser)
      // if (ForgetpasswordUser) {
      //   // console.log("ForgetpasswordUsereffect", ForgetpasswordUser)

      //   setChangePwdError(ForgetpasswordUser)
      //   // toast.error("Password Change Failed", {
      //   //   position: "top-right",
      //   // })
      //   ValidationLogin.resetForm()
      //   setDisabledForget(false)
      // }
      // if (ResetPasswordlinkUser) {
      //   console.log("ResetPasswordlinkUser", ResetPasswordlinkUser)
      //   toast.error(ResetPasswordlinkUser, {
      //     position: "top-right",
      //   })
      //   setResetlinkerror(ResetPasswordlinkUser)
      //   setResetlinkDisabled(false)
      // }

      // if (LoginStatus) {
      //   if (LoginStatus?.message_code === "1") {
      //     // setEmailError("Email does not exist")
      //     setEmailError(LoginStatus?.message)
      //     // console.log("LoginStatus", LoginStatus?.message)
      //     // ValidationLogin.setFieldError("email", "Email does not exist")
      //   }
      // }
    }

    console.log("LoginStatus?.message_code", isSuccess, LoginStatus?.message_code)

    if (isSuccess) {

      if (LoginStatus?.message_code === "3") {
        ValidationLogin.resetForm()
        nav("/")
        setDisabledLogin(false)
      }

      if (LoginStatus?.message_code === "4") {
        setShowForm(3)
        ValidationReset.resetForm()
        forgetpwd.setFieldValue("email", LoginUser.data.email)
        setDisabledLogin(false)
      }

      if (LoginStatus?.message_code === "5") {
        swal(
          "Password changed",
          `Your password is successfully updated. Now redirecting to login page`,
          "success"
        )
        setShowForm(1)
      }
      

    }

    // if (isSuccess) {

    //   if (LoginStatus?.message_code === "3") {
    //     ValidationLogin.resetForm()
    //       nav("/")
    //       setDisabledLogin(false)
    //   }


    //   if (LoginUser) {
    //     // console.log("LoginUseeffect", LoginUser)

    //     setLoginError("")

    //     if (LoginStatus?.message_code === "3") {
    //       // setloginModal(false)
    //       // ValidationLogin.resetForm()
    //       // LoginToggle()
    //       // toast.success(LoginStatus?.message, {
    //       //   position: "top-right",
    //       // })
    //       // nav("/myprofile")
    //       ValidationLogin.resetForm()
    //       nav("/")
    //       setDisabledLogin(false)
    //     }
    //     if (LoginStatus?.message_code === "7") {
    //       // setloginModal(false)
    //       // LoginToggle()
    //       setShowForm(3)
    //       ValidationLogin.resetForm()
    //       forgetpwd.setFieldValue("email", LoginUser.data.email)

    //       setDisabledLogin(false)
    //     }
    //   }
    //   if (ForgetpasswordUser) {
    //     // console.log("ForgetpasswordUsereffect", ForgetpasswordUser)
    //     setChangePwdError()

    //     if (ForgetpasswordUser === "Password reset successfully") {
    //       // ForgotToggle()
    //       // LoginToggle()
    //       swal(
    //         "Password changed",
    //         `Your password is successfully updated. Now redirecting to login page`,
    //         "success"
    //       )
    //       setShowForm(1)
    //     }

    //     forgetpwd.resetForm()
    //     ValidationLogin.resetForm()

    //     setDisabledForget(false)
    //   }

    //   if (ResetPasswordlinkUser) {
    //     // console.log("ResetPasswordlinkUser", ResetPasswordlinkUser)
    //     setShowForm(3)

    //     toast.success("Please Check your Email!", {
    //       position: "top-right",
    //     })
    //     forgetpwd.setFieldValue("email", ResetPasswordlinkUser?.data?.email)
    //     ValidationReset.resetForm()
    //     setResetlinkDisabled(false)
    //   }
    //   if (LoginStatus) {
    //     // console.log(LoginStatus)
    //     if (LoginStatus?.message_code === "6") {
    //       setEmailError()
    //     } else if (LoginStatus?.message_code === "7") {
    //       setEmailError()
    //     } else if (LoginStatus?.message_code === "4") {
    //       setEmailError("Your Membership is expired")

    //       dispatch(fetchMyProfileData({ _id: LoginStatus?.data }))
    //     }
    //   }
    // }

    dispatch(Reset())
  }, [
    LoginUser,
    isLogout,
    isSuccess,
    isError,
  ])

  const memShipConfirm = (item) => {
    console.log(item)

    if (item.membership_name_id === "1") {
      setmemShipagree(true)
      setConfirmMemshipModal(false)
    } else {
      setmemShipagree(false)
      setConfirmMemshipModal(true)
    }
    setTempMembership(item)
  }
  useEffect(() => {
    if (MemShipagree) {
      validation.setFieldValue(
        "membership_type",
        TempMembership?.membership_name
      )
      validation.setFieldValue("membership_fees", TempMembership?.fees)
      validation.setFieldValue(
        "membership_type_id",
        TempMembership?.membership_name_id
      )
      // console.log("gay", TempMembership.sno)
    } else {
      validation.setFieldValue("membership_type", "")
      validation.setFieldValue("membership_fees", "")
      validation.setFieldValue("membership_type_id", "")
    }
  }, [TempMembership, MemShipagree])

  function daysRemaining() {
    const today = moment()
    let year = today.year()
    var curMonth = moment().format("MM")

    if (curMonth > 4) {
      const nextApril30d = moment(`${year + 1}-04-30`)
      const Dateexpiry = moment(nextApril30d._i).format("Do MMMM YYYY")

      const remainingDays = nextApril30d.diff(today, "days")

      // console.log(remainingDays)
      return Dateexpiry
    } else {
      const nextApril30s = moment(`${year}-04-30`)
      const Dateexpiry = moment(nextApril30s._i).format("Do MMMM YYYY")
      const remainingDays = nextApril30s.diff(today, "days")
      // console.log(remainingDays)

      return Dateexpiry
    }
  }
  // [Upgrade]

  const ReturnUpgradeshow = (num) => {
    setShowForm(num)
    ValidationLogin.resetForm()
    setLoginError("")
  }
  return (
    <>
      <div className="page-content bg-white">
        {/* <PageBanner maintitle="Home" pagetitle="Login" /> */}
        <section
          className="content-inner-1 gradient-white mb-3 section-wrapper5 mt-5"
          style={{
            backgroundPosition: "center",
          }}
        >
          <div className="justify-content-center container mt-3">
            <div className="row d-flex justify-content-center">
              <Col lg={3} className="align-self-center me-5 d-none d-md-block">
                <img src={IMAGES.logoSats}></img>
              </Col>
              <Col lg={5} className="">
                <h4 className="text-primary title text-center d-block">
                  {showForm === 1
                    ? "Sign In"
                    : showForm === 2
                      ? "Forgot Password"
                      : showForm === 3
                        ? "Reset Password"
                        : showForm === 4
                          ? "Renew / Upgrade"
                          : ""}
                </h4>
                {showForm === 1 && (
                  <Form
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault()
                      ValidationLogin.handleSubmit()
                    }}
                  >
                    <FormGroup className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Email"
                        required
                        onFocus={(e) => {
                          if(e.target.value !== ""){
                          ValidationLogin.setFieldValue("email", e.target.value)
                          dispatch(LoginStatusCheck(e.target.value))
                          }
                        }}
                        onChange={ValidationLogin.handleChange}
                        onBlur={ValidationLogin.handleBlur}
                        value={ValidationLogin.values.email}
                        invalid={
                          ValidationLogin.touched.email &&
                            ValidationLogin.errors.email
                            ? true
                            : false
                        }
                      />
                      {ValidationLogin.touched.email &&
                        ValidationLogin.errors.email ? (
                        <FormFeedback type="invalid">
                          {ValidationLogin.errors.email}
                        </FormFeedback>
                      ) : null}
                      {/* <div
                      className="my-2"
                      style={{
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      {emailError}
                    </div> */}
                    </FormGroup>

                    {LoginStatus?.message_code !== "7" && <FormGroup className=" mb-3">
                      <Label className="form-label">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        autoComplete="new-password"
                        className="input-group form-control"
                        aria-label="Small"
                        placeholder="Enter Password"
                        required
                        onFocus={(e) => {
                          if(ValidationLogin.values.email !== ""){
                          console.log("ValidationLogin.values.email", ValidationLogin.values.email)
                         // ValidationLogin.setFieldValue("email", e.target.value)
                          dispatch(LoginStatusCheck(ValidationLogin.values.email))
                          }
                        }}
                        onChange={ValidationLogin.handleChange}
                        onBlur={ValidationLogin.handleBlur}
                        value={ValidationLogin.values.password}
                        invalid={
                          ValidationLogin.touched.password &&
                            ValidationLogin.errors.password
                            ? true
                            : false
                        }
                      />

                      {ValidationLogin.touched.password &&
                        ValidationLogin.errors.password ? (
                        <FormFeedback type="invalid">
                          {ValidationLogin.errors.password}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>}


                    <div className="reset-password mb-3 text-end">
                      <Link
                        to={"#"}
                        className={` collapsed ${LoginUser?.message === "Your plan is expired"
                          ? "btn-disabled"
                          : "btn-link"
                          } `}
                        role="button"
                        style={{ fontSize: "12px" }}
                        aria-controls="reset-password"
                        onClick={() => setShowForm(2)}
                      // onClick={() => (ResetToggle(), setloginModal(false))}
                      >
                        Forgot Password?
                      </Link>
                    </div>

                    <div
                      className="my-2 text-center"
                      style={{
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      {LoginError}
                    </div>
                    {LoginUser?.message_code === "2" && (
                      <div
                        className="mb-3 text-center"
                        style={{ fontSize: "12px" }}
                      >
                        {/* Your Membership is expired.<br></br> */}
                        <Link
                          to={"#"}
                          onClick={(e) => {
                            dispatch(fetchMyProfileData(LoginStatus?.data))
                            setShowForm(4)
                          }}
                          className="btn-link collapsed text-center"
                        >
                          Click here to renew / upgrade your membership.
                        </Link>
                      </div>
                    )}

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-block"
                        disabled={disabledLogin}
                      >
                        {disabledLogin && (
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        {LoginStatus.message_code === "7" ? "Submit" : "Login"}
                      </button>
                    </div>

                    <div className="sign-text">
                      <span>
                        Don't have an SA Tamil Sangam Account?
                        <Link
                          to={"/registration"}
                          // onClick={(e) => setloginModal(false)}
                          className="btn-link collapsed"
                        >
                          <span> Sign up</span>
                        </Link>
                      </span>
                    </div>
                  </Form>
                )}
                {showForm === 2 && (
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault()
                      ValidationReset.handleSubmit()
                    }}
                  >
                    <div className="mb-3">
                      <p style={{ lineHeight: 1.4, fontSize: "14px" }}>
                        Enter your email address associated with your account,
                        and we'll email you a Temporary password to reset your
                        password...
                      </p>
                    </div>

                    <>
                      <FormGroup className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          onChange={ValidationReset.handleChange}
                          onBlur={ValidationReset.handleBlur}
                          value={ValidationReset.values.email}
                          invalid={
                            ValidationReset.touched.email &&
                              ValidationReset.errors.email
                              ? true
                              : false
                          }
                        />
                        {ValidationReset.touched.email &&
                          ValidationReset.errors.email ? (
                          <FormFeedback type="invalid">
                            {ValidationReset.errors.email}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <div
                        className="my-2 text-center text-capitalize"
                        style={{
                          fontSize: "12px",
                          color: "red",
                        }}
                      >
                        {resetlinkError}
                      </div>
                      <div className="form-group mt-1">
                        <button
                          // type="submit"
                          disabled={disabledresetlink}
                          className="btn btn-outline-primary btn-block"
                        >
                          {disabledresetlink && (
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                          )}
                          Submit
                        </button>
                      </div>
                    </>
                  </Form>
                )}
                {showForm === 3 && (
                  <Form
                  autoComplete="off"
                    onSubmit={(e) => {
                      console.log("form submitted")
                      e.preventDefault()
                      forgetpwd.handleSubmit()
                    }}
                  >
                    <FormGroup className="mb-3">
                      <Label className="form-label">Temporary Password</Label>
                      <Input
                        type="password"
                        name="temporaryPassword"
                        autoComplete="new-password"
                        className="form-control"
                        placeholder="Enter Password"
                        onChange={forgetpwd.handleChange}
                        onBlur={forgetpwd.handleBlur}
                        value={forgetpwd.values.temporaryPassword || ""}
                        invalid={
                          forgetpwd.touched.temporaryPassword &&
                            forgetpwd.errors.temporaryPassword
                            ? true
                            : false
                        }
                      />

                      {forgetpwd.touched.temporaryPassword &&
                        forgetpwd.errors.temporaryPassword ? (
                        <FormFeedback type="invalid">
                          {forgetpwd.errors.temporaryPassword}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label className="form-label">New Password</Label>
                      <Input
                        type="password"
                        name="newPassword"
                        autoComplete="new-password"
                        className="form-control"
                        placeholder="Enter New Password"
                        onChange={forgetpwd.handleChange}
                        onBlur={forgetpwd.handleBlur}
                        value={forgetpwd.values.newPassword || ""}
                        invalid={
                          forgetpwd.touched.newPassword &&
                            forgetpwd.errors.newPassword
                            ? true
                            : false
                        }
                      />
                      {forgetpwd.touched.newPassword &&
                        forgetpwd.errors.newPassword ? (
                        <FormFeedback type="invalid">
                          {forgetpwd.errors.newPassword}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>
                    <FormGroup className="mb-3">
                      <Label className="form-label">Confirm Password</Label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Enter Confirm Password"
                        onChange={forgetpwd.handleChange}
                        onBlur={forgetpwd.handleBlur}
                        value={forgetpwd.values.confirmPassword || ""}
                        invalid={
                          forgetpwd.touched.confirmPassword &&
                            forgetpwd.errors.confirmPassword
                            ? true
                            : false
                        }
                      />
                      {forgetpwd.touched.confirmPassword &&
                        forgetpwd.errors.confirmPassword ? (
                        <FormFeedback type="invalid">
                          {forgetpwd.errors.confirmPassword}
                        </FormFeedback>
                      ) : null}
                    </FormGroup>

                    <div
                      className="my-2 text-center text-capitalize"
                      style={{
                        fontSize: "12px",
                        color: "red",
                      }}
                    >
                      {changePwdError}
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-outline-primary btn-block mt-1"
                        data-bs-toggle="collapse"
                        disabled={disabledForget}
                        type="submit"
                      >
                        {disabledForget && (
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
                {showForm === 4 && (
                  <div className="col-12 col-md-11 col-lg-11 mt-5  d-flex flex-column">
                    {" "}
                    {/* // flex-wrap */}
                    <Label htmlFor="member">
                      SATS Membership
                      <span className="text-danger">*</span>
                    </Label>
                    <div className="btn-group d-flex flex-nowrap " role="group">
                      {MembershipTypes.map((item, ind) => (
                        <div key={"mtps" + ind}>
                          <input
                            type="radio"
                            className="btn-check" //flex-fill
                            name="btnradio"
                            id={item._id}
                            autoComplete="off"
                            value={validation.values.membership_type || ""}
                            defaultChecked={
                              validation.values.membership_type_id ===
                              item.membership_name_id
                            }
                            onClick={(e) => {
                              memShipConfirm(item)
                            }}
                            invalid={
                              validation.errors.membership_type ? true : false
                            }
                          />
                          <label
                            className="btn btn-outline-warning d-flex flex-column"
                            htmlFor={item._id}
                          >
                            <p>{item.membership_name}</p>

                            <h6>${item.fees}*</h6>
                          </label>
                        </div>
                      ))}
                    </div>
                    <div className="text-center">
                      <span className="text-danger">*</span>{" "}
                      <small>
                        PayPal Transaction Fee (2.25%+31 Cents) Extra
                      </small>
                    </div>
                    {validation.touched.membership_type &&
                      validation.errors.membership_type ? (
                      <div style={{ fontSize: "12px", color: "red" }}>
                        {validation.errors.membership_type}
                      </div>
                    ) : null}
                    <div className="mt-3">
                      {MyProfileInfo.credit > 0 && (
                        <p style={{ fontSize: "13px", lineHeight: 1.3 }}>
                          Your available credit : {validation.values?.credit}
                          <br></br>
                          Credit expired on :
                          {validation.values?.credit_expired_on === null
                            ? "Not available"
                            : moment
                              .parseZone(validation.values?.credit_expired_on)
                              .format("MM/DD/YYYY")}
                        </p>
                      )}
                    </div>
                    {MemShipagree && (
                      <div className="mt-3">
                        <MembershipButton
                          data2={validation.values}
                          membershipSelected={TempMembership}
                          setModal={setUpgradeModal}
                          upgrade={"Renew"}
                          purpose={"Membership Renew"}
                          ReturnUpgradeshow={ReturnUpgradeshow}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </div>
          </div>
        </section>
      </div>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={ConfirmMemshipModal}
        onHide={() => setConfirmMemshipModal(false)}
        backdrop="static"
        centered
      >
        <h2 className="title text-warning">Are you Sure</h2>
        <p className="text-justify">
          Membership expires on
          <span className="fw-bold"> {daysRemaining()}</span> . If you agree
          with the Membership plan, please confirm it.
        </p>
        <div className="d-flex gap-1 justify-content-end">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setmemShipagree(true)
              setConfirmMemshipModal(false)
            }}
          >
            Confirm
          </button>
          <button
            className="btn btn-light btn-sm"
            onClick={() => {
              setmemShipagree(false)
              setConfirmMemshipModal(false)
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <ToastContainer style={{ zIndex: "10999" }} />
    </>
  )
}

export default UserLogin
