export const MenuListArray2 = [
  {
    title: "Home",
    to: "/",
  },
  {
    title: "About Us",
    to: "/",
    classChange: "sub-menu-down",
    content: [
      {
        title: "About SATS",
        to: "about-us",
      },
      // {
      //   title: "Vision ",
      //   to: "#!",
      // },
      {
        title: "Mission / Vision",
        to: "missionvision",
      },
      {
        title: "Current Committee",
        to: "currentcommittee",
      },
      {
        title: "Volunteers",
        to: "volunteers",
      },
      {
        title: "ByLaws",
        to: "bylaws",
      },
      {
        title: "Previous Committee",
        to: "previouscommittee",
      },
      // {
      //   title: "FeTNA",
      //   to: "fetna",
      // },
    ],
  },
  {
    title: "Malligai Malar",
    to: "/",
    classChange: "sub-menu-down",
    content: [
      {
        title: "Malligai Malar",
        to: "malligaimalar",
      },
      // {
      //   title: "Event Media Links",
      //   to: "eventmedialinks",
      // },
    ],
  },
  {
    title: "FeTNA Malar",
    to: "/",
    classChange: "sub-menu-down",
    content: [
      {
        title: "Malar 2024",
        to: "fetnamalar",
      },
    ],
  },
  {
    title: "Members",
    to: "/",
    classChange: "sub-menu-down",
    content: [
      {
        title: "Membership Benefits",
        to: "membership",
      },
      {
        title: "Become a Member",
        to: "becomemember",
      },
      {
        title: "Lifetime Members",
        to: "lifeMemberShip",
      },
    ],
  },
  {
    title: "Events",
    to: "/",
    classChange: "sub-menu-down",
    content: [
      {
        title: "Upcoming Events",
        to: "UpComingEvent",
      },
      {
        title: "Past Events",
        to: "pastEvent",
      },
      // {
      //   title: "Event Media Links",
      //   to: "#!",
      // },
    ],
  },
  {
    title: "Gallery",
    to: "gallery",
  },
  {
    title: "Sponsors",
    to: "/",
    classChange: "sub-menu-down",
    content: [
      {
        title: "Sponsorship Levels",
        to: "sponserlevel",
      },
      {
        title: "Our Sponsors",
        to: "oursponsers",
      },
      // {
      //   title: "Event Media Links",
      //   to: "eventmedialinks",
      // },
    ],
  },
  {
    title: "Projects",
    classChange: "sub-menu-down",
    to: "/",
    content: [
      {
        title: "School Project - Munduvelampatti Village",
        to: "school",
      },
      {
        title: "Thaimadi Project",
        to: "thaimadi",
      },
      {
        title: "Cyclone Gaja - Ambalapattu Village Project",
        to: "cyclone",
      },
      {
        title: "Chennai Flood",
        to: "flood",
      },
    ],
  },
  {
    title: "Youth Wing",
    to: "satsyouthwing",
    // classChange: "sub-menu-down",
    // content: [
    // {
    //   title: "SATS Fit",
    //   to: "satsfit",
    // },
    // {
    //   title: "SATS Garden",
    //   to: "satsgarden",
    // },
    // {
    //   title: "SATS Movie Club",
    //   to: "satsmovieclub",
    // },
    // {
    //   title: "SATS Sports",
    //   to: "satssports",
    // },
    // {
    //   title: "SATS Thinnai Book Club",
    //   to: "satsthinnaibookclub",
    // },
    // {
    //   title: "SATS YouthWing",
    //   to: "satsyouthwing",
    // },
    // ],
  },
  // {
  //   title: "Contact us",
  //   to: "contact-us",
  // },
]
