import { configureStore } from "@reduxjs/toolkit"
import authReducer from "../features/auth/authSlice"
import EventTBReducer from "../features/Event/EventTBSlice"
import BookingReducer from "../features/Event/BookingSlice"
import FetchData from "../features/FetchData"
import FormDataStore from "../features/FormDataStore"
export const store = configureStore({
  reducer: {
    auth: authReducer,
    EventTBReducer: EventTBReducer,
    BookingReducer: BookingReducer,
    FetchDataReducer: FetchData,
    FormDataReducer: FormDataStore,
  },
})
