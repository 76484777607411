import React, { useRef, useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import Webcam from "react-webcam"
const WebcamCapture = ({
  UpdateImageProfile,
  setProfile,
  setWebcam,
  setProfileImageModal,
  MyProfileInfo,
}) => {
  // const videoConstraints = {
  //     width: 1280,
  //     height: 720,
  //     facingMode: "user"
  //   };
  //   const [image, setimage] = useState()
  const dispatch = useDispatch()
  const webcamRef = useRef(null)
  const capture = useCallback(() => {
    const base64Image = webcamRef.current.getScreenshot()

    //Decode the Base64 data
    const decodedImage = atob(base64Image.split(",")[1])
    // Create a Uint8Array from the decoded data
    const arrayBuffer = new ArrayBuffer(decodedImage.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < decodedImage.length; i++) {
      uint8Array[i] = decodedImage.charCodeAt(i)
    }
    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: "image/jpeg" })
    const fileName = `captured_image_${Date.now()}.jpg`
    const file = new File([blob], fileName, { type: "image/jpeg" })
    // Create a URL for the Blob
    console.log("decodedImage", decodedImage)
    console.log("uint8Array", uint8Array)
    console.log("blob", file)
    console.log()
    console.log()
    // setProfile(blob)
    setWebcam(false)
    setProfileImageModal(false)
    dispatch(
      UpdateImageProfile({
        _id: MyProfileInfo._id,
        // image: blob,
        image: file,
      })
    )

    // const imageUrl = URL.createObjectURL(blob)
    // console.log(imageUrl, blob)
    // setimage(imageUrl)
  }, [webcamRef])

  return (
    <>
      <Webcam
        audio={false}
        height={600}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={420}
        // videoConstraints={videoConstraints}
      />
      <button onClick={capture} className="btn-sm btn btn-primary mb-2">
        Capture photo
      </button>
      <button onClick={setWebcam} className="btn-sm btn btn-primary ">
        Cancel
      </button>
      {/* <img src={image} alt="capture" /> */}
    </>
  )
}

export default WebcamCapture
