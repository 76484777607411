import React from "react"
import { Link } from "react-router-dom"
import imgMalar from "../../assets/images/MalligaiMalar/malarcoverpage.png"
import PageBanner from "../../layouts/PageBanner"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import "swiper/swiper.min.css"
import { MdOutlineAdsClick } from "react-icons/md"
import { BsFilePdfFill } from "react-icons/bs"
import { Card, Row, Col } from "react-bootstrap"

const MalligaiMalar = () => {
  const malar = [
    {
      title: "Volume 7 - Issue 3:Dec, 2024",
      Clickhere:
        "https://www.peecho.com/configurator/checkout?publicationId=1466635&offeringId=2177064",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/San_Antonio_Tamil_Sangam_december_2024.pdf",
      iframesrc:
        "https://issuu.com/satsemalar/docs/dec_2024_vol7_issue3?fr=xKAE9_zU1NQ",
    },
    {
      title: "Volume 6 - Issue 3:Dec, 2023",
      Clickhere:
        "https://www.peecho.com/checkout/uplift/1404096#/?publicationId=1404096",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/sats_maligai_malar_dec_2023.pdf",
      iframesrc:
        "https://issuu.com/satsemalar/docs/san_antonio_tamil_sangam?fr=xKAE9_zU1NQ",
    },
    {
      title: "Volume 6 - Issue 2:Aug, 2023",
      Clickhere:
        "https://www.peecho.com/checkout/uplift/1319326#/?publicationId=1319326",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/SATS_MALIGAI_MALAR_AUGUST_2023.pdf",
      iframesrc:
        "https://issuu.com/satsemalar/docs/sats_maligai_malar_august_2023_5b5a02b2ca594e?fr=xKAE9_zU1NQ",
    },
    {
      title: "Volume 6 - Issue 1:Apr, 2023",
      Clickhere:
        "https://www.peecho.com/checkout/uplift/1261052#/?publicationId=1261052",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/SATS_MALIGAI_MALAR_APR_2023.pdf",
      iframesrc:
        "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_maligai_malar_apr_2023",
    },
    {
      title: "Volume 5 - Issue 3:Dec, 2022",
      Clickhere:
        "https://www.peecho.com/checkout/uplift/1217025#/?publicationId=1217025",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/SATS_MALIGAI_MALAR_DEC_2022.pdf",
      iframesrc:
        "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_maligai_malar_dec_2022",
    },
    {
      title: "Volume 5 - Issue 2:Aug, 2022",
      Clickhere:
        "https://www.peecho.com/checkout/uplift/1160208#/?publicationId=1160208",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/SATS_MALIGAI_MALAR_AUGUST_2022.pdf",
      iframesrc:
        "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_maligai_malar_august_2022",
    },
    {
      title: "Volume 4 - Issue 1:Apr, 2022",
      Clickhere:
        "https://www.peecho.com/checkout/uplift/1115650#?publicationId=1115650",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/sats_malligaimalar_apr_2022.pdf",
      iframesrc:
        "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_malligaimalar_apr_2022",
    },
    {
      title: "Volume 1 - Silver Jubilee Special Issue 1:NOV, 2021",
      Clickhere:
        "https://www.peecho.com/checkout/uplift/1076390#/?publicationId=1076390",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/SATS_SILVER_JUBILEE_MALAR_NOV_2021_1.pdf",
      iframesrc:
        "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_silver_jubilee_malar_nov_2021",
    },
    {
      title: "Volume 3 - Issue 3:DEC, 2020",
      Clickhere:
        "https://www.peecho.com/checkout/154639354059119817/770268/sats-malligai-malar-dec-2020",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/SATS_Malligai_Malar_DEC_2020.pdf",
      iframesrc:
        "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_malligai_malar_dec_2020",
    },
    {
      title: "Volume 3 - Issue 2:Aug, 2020",
      Clickhere: "https://www.peecho.com/checkout/154639354059119817/722709/",
      pdflink:
        "https://api.satamilsangam.org/uploads/PDF/SATSMalligaiMalarAugust2020..pdf",
      iframesrc:
        "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=malligai_malar_final_15.08.2020",
    },
  ]

  const cardiconBlog = [
    { title: "Technology", icon: "flaticon-vr-glasses" },
    { title: "Education", icon: "flaticon-open-book" },
    { title: "Videos", icon: "flaticon-video" },
    { title: "Medical", icon: "flaticon-doctor-bag" },
    { title: "Love", icon: "flaticon-like-1" },
    { title: "Design", icon: "flaticon-transformation" },
    { title: "Health", icon: "flaticon-raw-food" },
    { title: "Sports", icon: "flaticon-coins-1" },
    { title: "Care", icon: "flaticon-responsibility" },
    { title: "Support", icon: "flaticon-handshake" },
    { title: "Fashion", icon: "flaticon-coin-stack" },
    { title: "Events", icon: "flaticon-calendar" },
  ]
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Malligai Malar" />
        <section className="content-inner container ">
          <img src={imgMalar} width={"100%"} height={"400px"}></img>
          <div className="container text-dark text-justify ">
            <div className="seaction_head">
              <p className="sub-title text-primary">
                சான் ஆண்டோனியோ தமிழ்ச்சங்கத்தின் 'மல்லிகை மலர்'
              </p>
              <p>
                ஏப்ரல் 14 ஆம் நாள் தமிழ் வருடப்பிறப்பு, ஆகஸ்ட் 15 ஆம் நாள்
                இந்திய சுதந்திர தினம் மற்றும் டிசம்பர் 11 ஆம் நாள் மகாகவி
                பாரதியார் பிறந்தநாள் என வருடத்தின் முக்கியமான இம்மூன்று
                தினங்களில் பூக்கும் இம்மலர், இங்குள்ள மக்களின் திறமைகளை
                வெளிக்கொணரவே 2018ல் ஆரம்பிக்கப்பட்டது. கதை, கவிதை, சங்க
                நிகழ்வுகள், சமையல், சிறார்களின் ஓவியம், அறிவியல், யோகா,
                புகைப்படம், மருத்துவம் என அனைத்து ரசனைகளுடன் கூடிய வண்ணமயமான
                இதழ் இது!
              </p>
              <p>மின்னஞ்சல் முகவரி(Email) : satsemalar@gmail.com</p>
              <p>நன்றியுடன்,</p>
              <p>ஆசிரியர் : ஷீலா ரமணன்</p>
              <p>ஆசிரியர் குழு : கார்த்திகேயன் ராசிபாளையம், யுவராஜ் முனியன் </p>
              <p>ஒருங்கிணைப்பாளர்கள் : வாசுதேவன் நடராஜன், துரை நடராஜன் </p>
              
            </div>
            <div className="content-inner-2" style={{paddingTop:30}}>
              <div className="container">
                <div className="row">
                  {malar.map((item, ind) => (
                    <div
                      className="col-xl-4 col-lg-4 col-sm-12 border-bottom mt-1"
                      key={ind}
                    >
                      <div className="icon-bx-wraper style-1 m-b30 text-center">
                        <div className="icon-lg m-b30">
                          <Link
                            to={"/project-categories"}
                            className="icon-cell"
                          >
                            {/* <i className={item.icon}></i> */}
                            <li className="my-3 text-center">
                              <a className="bg-light" href="#!">
                                <iframe
                                  src={item.iframesrc}
                                  width="100%"
                                  height="250px"
                                  allowFullScreen={true}
                                ></iframe>
                              </a>
                            </li>
                          </Link>
                        </div>
                        <div className="icon-content">
                          <h6 className="dz-tilte text-capitalize">
                            <Link to={"/project-categories"}>{item.title}</Link>
                          </h6>
                          <ul className="">
                            <li>
                              To Print, Click here
                              <a
                                href={item.Clickhere}
                                target="_blank"
                                className="btn-sm btn-danger mx-1 text-light"
                              >
                                <MdOutlineAdsClick className="" />
                              </a>
                            </li>
                            <li>
                              PDF book version-click here-
                              <a
                                href={item.pdflink}
                                target="_blank"
                                className="btn-sm btn-primary mx-1 link-warning "
                              >
                                <BsFilePdfFill className="" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* {OLD} */}
            {/* <div className="container mt-5 col-12">
              <div className="d-flex flex-wrap justify-content-between justify-content-sm-center ">
                {malar.map((item, index) => (
                  <div
                    key={index}
                    className=" col-lg-3 col-md-3 col-sm-6 p-3 m-3 bg-light rounded"
                  >
                    <ul className="text-start">
                      <h6>{item.title}</h6>
                      <li>
                        To Print, Click here
                        <a
                          href={item.Clickhere}
                          target="_blank"
                          className="btn-sm btn-danger mx-1 text-light"
                        >
                          <MdOutlineAdsClick className="" />
                        </a>
                      </li>
                      <li>
                        PDF book version-click here-
                        <a
                          href={item.pdflink}
                          target="_blank"
                          className="btn-sm btn-primary mx-1 link-warning "
                        >
                          <BsFilePdfFill className="" />
                        </a>
                      </li>
                      <li className="my-3 text-center">
                        Online version-click below
                        <a className="bg-light" href="#!">
                          <iframe
                            src={item.iframesrc}
                            width="200px"
                            height="250px"
                            frameBorder="0"
                            allowFullScreen={true}
                          ></iframe>
                        </a>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </section>
      </div>
    </>
  )
}

export default MalligaiMalar
