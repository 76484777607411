import React from "react"
import { Link } from "react-router-dom"

import PageBanner from "../../layouts/PageBanner"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import PPt from "../../assets/images/project/Natural disaster in Chennai.pptx"
import { Table } from "reactstrap"
const Flood = () => {
  const floodDonate = [
    { no: "1", name: "Akila Dharani", Amount: "50" },
    { no: "2", name: "Anuradha Lakshminarayanan", Amount: "	INR 1000" },
    { no: "3", name: "Badrinath Lakshmanaswamy", Amount: "96.4" },
    { no: "4", name: "Biju Chandroth", Amount: "100" },
    { no: "5", name: "	Dawn Elmore", Amount: "10" },
    { no: "6", name: "Debra E. Sevening", Amount: "100" },
    { no: "7", name: "Dineshkumar Balaguru", Amount: "50" },
    { no: "8", name: "Ganesh Muthuvelu", Amount: "50" },
    { no: "9", name: "Ganesh Ramu - Chithra", Amount: "200" },
    { no: "10", name: "Gunasekar Rajavel", Amount: "20" },
    { no: "11", name: "Gurunathan Thangavelu", Amount: "100" },
    { no: "12", name: "Internet Profit Web Solutions", Amount: "25" },
    { no: "13", name: "Kalicharan Sundram", Amount: "30" },
    { no: "14", name: "Kannan Paramasivam", Amount: "50" },
    { no: "15", name: "Karthik Govind", Amount: "50" },
    { no: "16", name: "Karthikeyan Subbahnaidu", Amount: "151" },
    { no: "17", name: "Kishore Srinivasan", Amount: "50" },
    { no: "18", name: "Kumar Sasi", Amount: "100" },
    { no: "19", name: "Mukund Dayal", Amount: "101" },
    { no: "20", name: "Nageswararao Thummapudi", Amount: "50" },
    { no: "21", name: "Pavana Annalur", Amount: "50" },
    { no: "22", name: "Prakash Thangamuthu", Amount: "50" },
    { no: "23", name: "Prasanna Ramamoorthy", Amount: "50" },
    { no: "24", name: "Praveena Iruku", Amount: "50" },
    { no: "25", name: "Raja Pitchiah", Amount: "96.8" },
    { no: "26", name: "	Rajaguru Paramasamy", Amount: "150" },
    { no: "27", name: "Rajakumar Mariappan", Amount: "200" },
    {
      no: "28",
      name: "Ramani Ragunath",
      Amount: "100",
    },
    { no: "29", name: "Ramesh Kanumuri", Amount: "80" },
    { no: "30", name: "Rhodes Driving School Inc", Amount: "200" },
    { no: "31", name: "	Saravanan Sadasivam", Amount: "50" },
    { no: "32", name: "Sundar Kothandaraman", Amount: "25" },
    { no: "33", name: "Suresh Kumar", Amount: "100" },
    { no: "34", name: "	Swati", Amount: "30" },
    { no: "35", name: "Vidya Farook", Amount: "100" },
    { no: "36", name: "Vijay Valli", Amount: "50" },
    {
      no: "37",
      name: "Vidya Farook – Kids Birthday Party Gift",
      Amount: "600",
    },
  ]
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Home"
          pagetitle="Project"
        />

        <section className="content-inner bg-light section-pattren1">
          <div className="container">

            <div className="row align-items-center flex-column-reverse flex-lg-row">
              <div className="col-lg-6 align-self-center">
                <div className="section-head m-b30">
                  <h2 className="title mb-3">Chennai-Flood</h2>
                </div>
              </div>
            </div>

            <div className="container text-dark">
            <div>
              <h3 className="fw-bold text-primary sub-title">
                SATS Chennai Flood Relief Fund Report
              </h3>

                <div className="mb-4">
                  <p>
                    Due to the heavy cyclonic rains resulting in severe floods
                    across the entire city in Chennai, there has been lot of chaos
                    and people have been stranded. They do not have essential needs
                    such as food, clothing and shelter.
                    <br />
                    A BIG Thanks to all SATS members, patrons, sponsors, and
                    volunteers for supporting the SATS fundraise.
                  </p>
                </div>

                <div className="mb-3">
                  <p>Please find the details of projects that SATS contributed.</p>
                  <div className="col-12 d-flex flex-wrap gap-2 justify-content-start">
                    <div className="col-md-5 col-sm-12 mt-3">
                      <h5>1. Project Blanket: 2000 USD</h5>
                      <ul className="mx-3">
                        <li>Manavata - US Chapter</li>
                        <li className="fw-bold">
                          Cheque to be in favor of: Manavata Inc.
                        </li>
                        <li>
                        <p style={{lineHeight:1.3}}>Address : <br/>4221 Shelter Bay Ave,<br/>
                          Mill Valley,<br/>
                          CA 94941-6016.<br/>
                          Phone: +408 242 9851<br/>
                          <a
                            href={"http://manavata.org/organization/org-home/18"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            http://manavata.org/organization/org-home/18
                          </a>
                          </p>
                        </li>
                        
                      </ul>
                    </div>
                    <div className="col-md-5 col-sm-12 mt-3">
                      <h5>2. Hope kids project: 4000 USD</h5>
                      <ul className="mx-3">
                        <li>Larencce Charitable Trust</li>
                        <li className="fw-bold">
                          Cheque to be in favor of: Larencce Charitable Trust
                        </li>
                        <li>
                          <p style={{lineHeight:1.3}}>Address : <br/>No. 2/4, 1st Cross Street, <br/>
                          3rd Avenue, Ashok Nagar, <br/>
                          Chennai - 600 083. <br/>
                          Phone: 044-32536575 <br/>
                          <a
                            href={"http://larenccecharitabletrust.com/about-trust"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            http://larenccecharitabletrust.com/about-trust
                          </a>
                          </p>
                        </li>
                        <li>
                         
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-sm-12 mt-3">
                      <h5>3. Sewa International - San Antonio, Texas: 1500 USD</h5>
                      <ul className="mx-3">
                        <li className="fw-bold">
                          Cheque to be in favor of: Sewa International
                        </li>
                        <li>
                          <a
                            href={"https://sewausa.org/"}
                            target="_blank"
                            rel="noreferrer"
                          >
                            https://sewausa.org/
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              
              
              
              <div className="mt-5">
                <h5
                  className="d-inline-block mt-3"
                  style={{ borderBottom: "2px solid black" }}
                >
                  Thank You So Much For Your Generous Donation.
                </h5>
                <Table bordered hover responsive size="sm">
                  <thead className="text-center ">
                    <tr>
                      <th className="text-dark fw-bold">S.NO</th>
                      <th className="text-dark fw-bold">Full Name</th>
                      <th className="text-dark fw-bold">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {floodDonate.map((item, ind) => (
                      <tr className="table-danger" key={ind}>
                        <td>{item.no}</td>
                        <td>{item.name}</td>
                        <td>{item.Amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="mt-5">
                <div>
                  <h5>
                    Refer this PPT link -
                    <a href={PPt} className="link-primary" download>
                      Click Here
                    </a>
                    .
                  </h5>
                  <ul className="d-flex flex-column gap-2">
                    <li>
                      <a
                        href="http://www.dinamalar.com/news_detail.asp?id=1401144"
                        target="_blank"
                        rel="noreferrer"
                      >
                        http://www.dinamalar.com/news_detail.asp?id=1401144
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/watch?v=9s5tnkEa-g4"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.youtube.com/watch?v=9s5tnkEa-g4
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/watch?v=Udw2zGCAYq4"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.youtube.com/watch?v=Udw2zGCAYq4
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtu.be/Wct84ZK-ExQ"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://youtu.be/Wct84ZK-ExQ
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtu.be/rKLW2Acn9FY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://youtu.be/rKLW2Acn9FY
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-5 text-justify">
                  <h6
                    className="d-inline-block fw-bold"
                    style={{ borderBottom: "2px solid black" }}
                  >
                    Thoughts from Dr. Venkat Srinivasan
                  </h6>
                  <ul className="d-flex flex-column gap-2 ms-2">
                    <li>
                      <span className="fw-bold me-1" ss>
                        MISSION:
                      </span>
                      This should be taken by all of us as an opportunity to
                      give back to our beloved city/sister city.
                    </li>
                    <li>
                      <span className="fw-bold me-1" ss>
                        GOAL:
                      </span>
                      Let us aim high-One crore can be a target to shoot for.
                      "Ulakathin oru kodiyil irunthu oru kodi"
                    </li>
                    <li>
                      <span className="fw-bold me-1" ss>
                        INSTITUTIONS
                      </span>
                      This should be done under the umbrella of institutions
                      rather than individuals-ANUJA as we can then involve the
                      non-Indian public also (since SA is Chennai's sister city)
                      and SATS to co-ordinate with the Tamil community
                    </li>
                    <li>
                      <span className="fw-bold me-1" ss>
                        TANGIBLE TARGET RECIPIENTS:
                      </span>
                      We need to identify the recipient organization quickly.
                      The credibility of the fund raising efforts will depend on
                      this. If possible, identifying a specific task like buying
                      medicines or creating shelters etc. would be even better.
                    </li>
                    <li>
                      <span className="fw-bold me-1" ss>
                        TIME IS OF THE ESSENCE:
                      </span>
                      We need to get the message across the city that Anuja
                      SA/SATS would be spearheading the efforts. Otherwise, many
                      individuals might end up contributing to various entities
                      and the efforts may get fragmented.
                    </li>
                    <li>
                      <span className="fw-bold me-1" ss>
                        COMMUNICATION :
                      </span>
                      A team should be sending communications directly to the
                      Indian public as well as the local press, TV stations,
                      etc. of the devastation that has happened, the scale of
                      the loss and the scope of our fund raising efforts. Images
                      and crisp writing are necessary.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <h6
                    className="d-inline-block fw-bold"
                    style={{ borderBottom: "2px solid black" }}
                  >
                    FUND RAISING IDEAS:
                  </h6>
                  <ul className="d-flex flex-column gap-2 ms-2">
                    <li>
                      1. Ayirathial Oruvar: Identify 100 people who can
                      raise/contribute $1000 each-$100,000
                    </li>
                    <li>
                      2. Oru Naal podhuma?: Contribute one day's earnings-Have
                      people sign up for this. Identify one day, say, Pongal,
                      Jan 14 as the day we work for Chennai.
                    </li>
                    <li>
                      3. Raffle: Research Legal requirements Get a good
                      prize-Flight tickets, vacation packages, etc. Tickets-
                      Fewer number of high priced ($100) tickets or a larger
                      number of $1 tickets. Distribute $200 worth tickets to 200
                      people- $40,000
                    </li>
                    <li>
                      4. Chillarai from children: All children should collect
                      small change from each household-usually $10 worth-$2000.
                      Small amount but gives sense of participation to children
                    </li>
                    <li>
                      5. Contribution jars in grocery stores, MD offices,
                      restaurants with pictures on the jars
                    </li>
                    <li>
                      6. A community Pooja on New Year's eve- $100 / family x
                      200 families-$20,000. coordinate with HTSA
                    </li>
                    <li>
                      7. Tap big donors who can contribute or match-10 donors
                      who can give $5000 each?
                    </li>
                    <li>
                      8. Involve other organizations TIPPS-request them to
                      apportion from their Gala coming up shortly
                    </li>
                    <li>9. Fund raising banquet/silent auction</li>
                    <li>10. Charity walk</li>
                    <li>
                      11. Request each performance on Pongal celebrations to
                      have a sponsor
                    </li>
                    <li>
                      12. Calendar-with each page sponsored as an advertisement.
                      Can be linked to idea #7- distributed to all members of
                      the community
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          </div>

          <img src={shape6} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape2} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>

      </div>
    </>
  )
}

export default Flood
