import axios from "axios"

import { ApiServer } from "../../helpers/Api_help"

const Forgetpassword_API_URL = `${ApiServer}manage-user/reset-password`
const ResetPassword_API_URL = `${ApiServer}manage-user/forgot-password`
const Login_API_URL = `${ApiServer}manage-user/login`
const LoginCheck_API_URL = `${ApiServer}manage-user/check-login-status`

const Forgetpassword = async (userData) => {
  const response = await axios.post(Forgetpassword_API_URL, userData)

  // if (response.data.response_code === 200) {
  //   window.localStorage.setItem(
  //     "Login",
  //     JSON.stringify("Password reset successfully")
  //   )
  //   window.localStorage.setItem(
  //     "user_id",
  //     JSON.stringify({ _id: response.data.data._id })
  //   )
  // }
  console.log("ForgetpasswordUsereffect", response)
  return response.data
}
const login = async (userData) => {
  const response = await axios.post(Login_API_URL, {
    email: userData.email,
    password: userData.password,
  })

    if (response.data.response_code === 200) {
      window.localStorage.setItem("Login", JSON.stringify("Login successful"))
      window.localStorage.setItem(
        "user_id",
        JSON.stringify({ _id: response.data.data._id })
      )
      window.localStorage.setItem("userInfo", JSON.stringify(response.data.data))

  }

  return response.data
}
const ResetPasswordlink = async (userData) => {
  const response = await axios.post(ResetPassword_API_URL, userData)

  // if (response.data) {
  //   window.localStorage.setItem("ResetPassword", JSON.stringify(response.data))
  // }

  return response.data
}

const LoginStatusCheck = async (userData) => {
  const response = await axios.post(LoginCheck_API_URL, userData)

  // if (response.data) {
  //   window.localStorage.setItem("ResetPassword", JSON.stringify(response.data))
  // }

  return response.data
}

const authService = {
  LoginStatusCheck,
  Forgetpassword,
  login,
  ResetPasswordlink,
}

export default authService
