import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import PageBanner from "../../layouts/PageBanner"
import UpdateBlog from "../../components/Home/UpdateBlog"

import bg from "../../assets/images/banner/bnr2.jpg"

import { fetchCommittee } from "../../features/FetchData"
import { useDispatch, useSelector } from "react-redux"
import ApiServer from "../../helpers/Api_help"

export const CommitteeData = ({ dataTransfer }) => {
  return (
    <div className="row ">
      {dataTransfer.map((item, ind) => (
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 " key={ind}>
          <div className="dz-team style-1 m-b30">
            <div className="dz-media">
              <Link to={"#!"}>
                <img
                  className="curcomImg"
                  src={`${ApiServer}assets/committee/${item.image_name}`}
                  alt=""
                  style={{
                    height: "300px",
                    // objectFit: "cover",

                    // objectFit: "fill",
                    objectFit: "conatin",
                    // objectFit: "center",
                  }}
                />
              </Link>
              {/* <ul className="team-social">
                <li>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="fab fa-facebook-f"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="fab fa-instagram"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="fab fa-twitter"
                  ></a>
                </li>
              </ul> */}
            </div>
            <div className="dz-content">
              <h5 className="dz-name">{item.firstname}</h5>
              <span className="dz-position text-primary">
                {item.designation !== "" ? item.designation : ""}
              </span>
              {/* <small>{item.contact_no !== "" ? item.contact_no : ""}</small> */}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const CurrentCommittee = () => {
  const { Committe, Success, fail } = useSelector(
    (state) => state.FetchDataReducer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCommittee())
  }, [])

  const TheSigningOfficers = Committe.filter(
    (item) => item.member_category_id === "1"
  )
  const ExecutiveCommitteMembers = Committe.filter(
    (item) => item.member_category_id === "2"
  )
  const BoardofTrustees = Committe.filter(
    (item) => item.member_category_id === "3"
  )
  const HonoraryExecutiveCommitteMembers = Committe.filter(
    (item) => item.member_category_id === "4"
  )

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Current Committee" />

        <section className="content-inner-2">
          <div className="container">
            <div className="mb-5">
              <h1 className="text-primary fw-bold ">The Signing Officers</h1>
              <p className="h6">
                Team of Executive Commitee, Members and Trustees
              </p>
            </div>
            <CommitteeData dataTransfer={TheSigningOfficers} />
          </div>
          <div className="container">
            <div className="mb-5">
              <h1 className="text-primary  fw-bold">
                Executive Committee Members
              </h1>
            </div>
            <CommitteeData dataTransfer={ExecutiveCommitteMembers} />
          </div>
          <div className="container">
            <div className="mb-5">
              <h1 className="text-primary  fw-bold ">Board of Trustees</h1>
            </div>
            <CommitteeData dataTransfer={BoardofTrustees} />
          </div>
          <div className="container">
            <div className="mb-5">
              <h1 className="text-primary  fw-bold ">
                Honorary Executive Committee Members
              </h1>
            </div>
            <CommitteeData dataTransfer={HonoraryExecutiveCommitteMembers} />
          </div>
        </section>
        {/* <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div> */}
      </div>
    </>
  )
}

export default CurrentCommittee
