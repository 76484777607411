import React from "react"
import { Link } from "react-router-dom"

import PageBanner from "../layouts/PageBanner"
import shape2 from "../assets/images/pattern/shape2.png"
import shape3 from "../assets/images/pattern/shape3.png"
import shape5 from "../assets/images/pattern/shape5.png"
import shape1 from "../assets/images/pattern/shape1.png"
import shape6 from "../assets/images/pattern/shape6.png"
import PPt from "../assets/images/project/Natural disaster in Chennai.pptx"
import { Table } from "reactstrap"
import NewtoSAimg from "../assets/images/new_to_sa.jpg"
const NewtoSA = () => {
  return (
    <>
      <div className="page-content ">
        <PageBanner maintitle="Home" pagetitle="New to SA"  />
        <section className="content-inner-2  text-dark section-pattren1 container overflow-hidden">
          <div className=" dz-card blog-single sidebar">
            <div className="dz-media">
              <img
                src={NewtoSAimg}
                style={{ objectFit: "conatin", height: "450px" }}
              />
            </div>
            <div className="dz-info">
              <div className="dz-meta">
                <p>
                  Whether you are new to SA, or been living here for a while, we
                  hope this page gives you a “one stop” resource for all things
                  you want to buy or do in this city.
                </p>
              </div>
              <div className="dz-post-text">
                <h5 className="">Uniquely San Antonio</h5>
                <ul>
                  <li>
                    <a href="https://www.thesanantonioriverwalk.com/">
                      https://www.thesanantonioriverwalk.com/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.thealamo.org/">
                      http://www.thealamo.org/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Indian Regional Organizations:</h5>
                <ul>
                  <li>
                    SATS-
                    <a href="https://www.satamilsangam.org/">
                      https://www.satamilsangam.org/
                    </a>
                  </li>
                  <li>
                    IASA-
                    <a href="http://www.indiasa.org/">
                      http://www.indiasa.org/
                    </a>
                  </li>
                  <li>
                    TASA-
                    <a href="http://www.tasatexas.org/">
                      http://www.tasatexas.org/
                    </a>
                  </li>
                  <li>
                    UTSA ISA-
                    <a href="https://utsa.collegiatelink.net/organization/isaatutsa">
                      https://utsa.collegiatelink.net/organization/isaatutsa
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Indian Grocery Stores</h5>
                <ul>
                  <li>
                    <a href="http://www.alibabasanantonio.com/">
                      http://www.alibabasanantonio.com/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Himalayan Bazar</h5>
                <ul>
                  <li>
                    <a href="http://mustafagrocers.com/">
                      http://mustafagrocers.com/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.thealamo.org/">
                      http://www.thealamo.org/
                    </a>
                  </li>
                </ul>
                <p>India Store - @ 5751 Evers Rd, San Antonio, TX 78238</p>
                <p>
                  Bengal Grocery Fish And Halal Meat @ 5727 Evers Rd, San
                  Antonio, TX 78238
                </p>
                <p>Adiba Grocery @ 5712 Wurzbach Rd, San Antonio, TX 78238</p>
              </div>
              <div className="dz-post-text">
                <h5 className="">Indian Restaurants</h5>
                <ul>
                  <li>
                    <a href="https://www.cafebaharsa.com/">
                      https://www.cafebaharsa.com/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.biryanipotusa.com/san-antonio-tx.html">
                      http://www.biryanipotusa.com/san-antonio-tx.html
                    </a>
                  </li>
                  <li>
                    <a href="http://www.tasteofindiasa.com/">
                      http://www.tasteofindiasa.com/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.orderstart.com/indiatajpalace">
                      https://www.orderstart.com/indiatajpalace
                    </a>
                  </li>
                  <li>
                    <a href="http://www.indiaovensatx.com/">
                      http://www.indiaovensatx.com/
                    </a>
                  </li>
                </ul>
                <p>Pavani Express - @ 5755 Evers Rd, San Antonio, TX 78238</p>
                <p>India Oven @ 1031 Patricia, San Antonio, TX 78213</p>
                <p>
                  Tarka Indian Kitchen - @ 427 North Loop 1604 W #101, San
                  Antonio, TX 78232
                </p>
                <p>
                  Amaravati Indian Cuisine @ 8846 Huebner Rd, San Antonio, TX
                  78240
                </p>
                <p>
                  Bombay Hall Indian Cusine @ 8783 Wurzbach Rd, San Antonio, TX
                  78240
                </p>
                <p>
                  Kohinoor Restaurant @ 9425 Fredericksburg Rd, San Antonio, TX
                  78240
                </p>
                <p>
                  Spice Fine Indian Cuisine Biryani Place - @ 4987 NW Loop 410,
                  San Antonio, TX 78229
                </p>
                <p>
                  Simi's India Cuisine @ 4535 Fredericksburg Rd #109, San
                  Antonio, TX 78201 India palace
                </p>
              </div>
              <div className="dz-post-text">
                <h5 className="">Activities for kids</h5>
                <ul>
                  <li>
                    <a href="https://www.thedoseum.org/">
                      https://www.thedoseum.org/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.sanantonioaquarium.net/">
                      http://www.ripleys.com/sanantonio/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Temples/Places of worship</h5>
                <ul>
                  <li>
                    <a href="http://www.hindutemplesatx.org/">
                      http://www.hindutemplesatx.org/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.baps.org/globalnetwork/america/sanantonio.htm">
                      http://www.baps.org/globalnetwork/america/sanantonio.htm
                    </a>
                  </li>
                  <li>
                    <a href="http://www.icsaonline.org/">
                      http://www.icsaonline.org/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.sikhdharamsal.org/">
                      http://www.sikhdharamsal.org/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.cjclife.com/">
                      http://www.cjclife.com/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Theatres</h5>
                <ul>
                  <li>
                    <a href="https://www.majesticempire.com/">
                      https://www.majesticempire.com/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.woodlawntheatre.org/">
                      http://www.woodlawntheatre.org/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.classictheatre.org/">
                      http://www.classictheatre.org/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.tobincenter.org/">
                      https://www.tobincenter.org/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.satheatre.com/">
                      https://www.satheatre.com/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Cinema</h5>
                <ul>
                  <li>
                    <a href="https://www.santikos.com/san-antonio/palladium-imax">
                      https://www.santikos.com/san-antonio/palladium-imax
                    </a>
                  </li>
                  <li>
                    <a href="https://www.regmovies.com/theaters/regal-huebner-oaks-stadium-14-rpx/31378">
                      https://www.regmovies.com/theaters/regal-huebner-oaks-stadium-14-rpx/31378
                    </a>
                  </li>
                  <li>
                    <a href="https://www.santikos.com/san-antonio/embassy-14">
                      https://www.santikos.com/san-antonio/embassy-14
                    </a>
                  </li>
                  <li>
                    <a href="https://www.regmovies.com/theaters/regal-fiesta-stadium-16/4258">
                      https://www.regmovies.com/theaters/regal-fiesta-stadium-16/4258
                    </a>
                  </li>
                  <li>
                    <a href="https://www.regmovies.com/theaters/regal-live-oak-stadium-18-rpx/6561">
                      https://www.regmovies.com/theaters/regal-live-oak-stadium-18-rpx/6561
                    </a>
                  </li>
                  <li>
                    <a href="https://www.amctheatres.com/movie-theatres/san-antonio/amc-rivercenter-11-with-alamo-imax">
                      https://www.amctheatres.com/movie-theatres/san-antonio/amc-rivercenter-11-with-alamo-imax
                    </a>
                  </li>
                  <li>
                    <a href="https://www.regmovies.com/theaters/regal-northwoods-stadium-14/5800">
                      https://www.regmovies.com/theaters/regal-northwoods-stadium-14/5800
                    </a>
                  </li>
                  <li>
                    <a href="https://www.regmovies.com/theaters/regal-alamo-quarry-stadium-16/4252">
                      https://www.regmovies.com/theaters/regal-alamo-quarry-stadium-16/4252
                    </a>
                  </li>
                  <li>
                    <a href="https://www.santikos.com/san-antonio/bijou">
                      https://www.santikos.com/san-antonio/bijou
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Outdoor Activities</h5>
                <ul>
                  <li>
                    <a href="http://tpwd.texas.gov/state-parks/enchanted-rock">
                      http://tpwd.texas.gov/state-parks/enchanted-rock
                    </a>
                  </li>
                  <li>
                    <a href="http://www.sanantonioaquarium.net/">
                      http://www.wildliferanchtexas.com/
                    </a>
                  </li>
                  <li>
                    <a href="https://sazoo.org/">https://sazoo.org/</a>
                  </li>
                  <li>
                    <a href="http://www.wonderworldpark.com/">
                      http://www.wonderworldpark.com/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.naturalbridgecaverns.com/(S(3cn20ojjucdcf2lrbvctytlj))/Index.aspx">
                      http://www.naturalbridgecaverns.com/(S(3cn20ojjucdcf2lrbvctytlj))/Index.aspx
                    </a>
                  </li>
                  <li>
                    <a href="http://www.awsfzoo.com/">
                      http://www.awsfzoo.com/
                    </a>
                  </li>
                  <li>
                    <a href="http://www.sabot.org/">http://www.sabot.org/</a>
                  </li>
                  <li>
                    <a href="http://saparksfoundation.org/japanese-tea-garden/">
                      http://saparksfoundation.org/japanese-tea-garden/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Amusement Parks</h5>
                <ul>
                  <li>
                    <a href="https://seaworld.com/san-antonio/">
                      https://seaworld.com/san-antonio/
                    </a>
                  </li>

                  <li>
                    <a href="https://www.sixflags.com/fiestatexas">
                      https://www.sixflags.com/fiestatexas
                    </a>
                  </li>
                  <li>
                    <a href="http://www.schlitterbahn.com/new-braunfels">
                      http://www.schlitterbahn.com/new-braunfels
                    </a>
                  </li>
                  <li>
                    <a href="http://www.splashtownsa.com/">
                      http://www.splashtownsa.com/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Indoor Activities</h5>
                <ul>
                  <li>
                    <a href="http://www.daveandbusters.com/san-antonio/balcones-heights">
                      http://www.daveandbusters.com/san-antonio/balcones-heights
                    </a>
                  </li>

                  <li>
                    <a href="https://topgolf.com/us/san-antonio/">
                      https://topgolf.com/us/san-antonio/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.iflyworld.com/san-antonio/">
                      https://www.iflyworld.com/san-antonio/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.mainevent.com/">
                      https://www.mainevent.com/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Parks</h5>
                <ul>
                  <li>
                    <a href="http://fosana.org/portfolio_page/emilie-and-albert-friedrich-wilderness-park/">
                      http://fosana.org/portfolio_page/emilie-and-albert-friedrich-wilderness-park/
                    </a>
                  </li>

                  <li>
                    <a href="http://fosana.org/portfolio_page/dwight-d-eisenhower-park/">
                      http://fosana.org/portfolio_page/dwight-d-eisenhower-park/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bexar.org/1240/County-Parks">
                      https://www.bexar.org/1240/County-Parks
                    </a>
                  </li>

                  <li>
                    <a href="https://www.philhardbergerpark.org/">
                      https://www.philhardbergerpark.org/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.brackenridgepark.org/">
                      https://www.brackenridgepark.org/
                    </a>
                  </li>

                  <li>
                    <a href="http://www.sanantonio.gov/ParksAndRec/Home/directory_mcallister">
                      http://www.sanantonio.gov/ParksAndRec/Home/directory_mcallister
                    </a>
                  </li>
                  <li>
                    <a href="https://www.nps.gov/saan/index.htm">
                      https://www.nps.gov/saan/index.htm
                    </a>
                  </li>

                  <li>
                    <a href="http://www.wildliferanchtexas.com/">
                      http://www.wildliferanchtexas.com/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Gyms</h5>
                <ul>
                  <li>
                    <a href="https://www.lifetimefitness.com/life-time-locations/tx-san-antonio-at-the-rim.html">
                      https://www.lifetimefitness.com/life-time-locations/tx-san-antonio-at-the-rim.html
                    </a>
                  </li>
                  <li>
                    <a href="https://freedomfitness.com/signup/membership-pricing-table.cfm?location=SATO">
                      https://freedomfitness.com/signup/membership-pricing-table.cfm?location=SATO
                    </a>
                  </li>
                  <li>
                    <a href="http://www.planetfitness.com/gyms/san-antonio-nw-military-tx-308">
                      http://www.planetfitness.com/gyms/san-antonio-nw-military-tx-308
                    </a>
                  </li>
                  <li>
                    <a href="http://www.goldsgym.com/san-antonio-rogers-ranch/">
                      http://www.goldsgym.com/san-antonio-rogers-ranch/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.anytimefitness.com/gyms/2301/San-Antonio-TX-78257/?">
                      https://www.anytimefitness.com/gyms/2301/San-Antonio-TX-78257/?
                    </a>
                  </li>

                  <li>
                    <a href="utm_source=google&utm_medium=local&utm_campaign=localmaps&utm_content=2301">
                      utm_source=google&utm_medium=local&utm_campaign=localmaps&utm_content=2301
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Shopping</h5>
                <ul>
                  <li>
                    <a href="https://www.theshopsatlacantera.com/en.html">
                      https://www.theshopsatlacantera.com/en.html
                    </a>
                  </li>
                  <li>
                    <a href="https://www.northstarmall.com/en.html">
                      https://www.northstarmall.com/en.html
                    </a>
                  </li>

                  <li>
                    <a href="http://www.simon.com/mall/ingram-park-mall">
                      http://www.simon.com/mall/ingram-park-mall
                    </a>
                  </li>
                  <li>
                    <a href="http://www.shophuebneroaks.com/">
                      http://www.shophuebneroaks.com/
                    </a>
                  </li>
                  <li>
                    <a href="https://rollingoaksmall.com/">
                      https://rollingoaksmall.com/
                    </a>
                  </li>

                  <li>
                    <a href="http://www.shoprivercenter.com/">
                      http://www.shoprivercenter.com/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Museums</h5>
                <ul>
                  <li>
                    <a href="https://www.wittemuseum.org/">
                      https://www.wittemuseum.org/
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dz-post-text">
                <h5 className="">Night Life in SA</h5>
                <ul>
                  <li>
                    <a href="http://www.sanantonio.com/dance-clubs">
                      http://www.sanantonio.com/dance-clubs
                    </a>
                  </li>

                  <li>
                    <a href="http://www.sanantonio.com/sports-bars">
                      http://www.sanantonio.com/sports-bars
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <img src={shape6} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape2} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>
      </div>
    </>
  )
}

export default NewtoSA
