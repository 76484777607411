import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { AiOutlinePlusCircle } from "react-icons/ai"
import { BiEdit } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import Avatarpng from "../assets/images/lifememberavatar.png"
import Webcam from "react-webcam"
import * as Yup from "yup"
import { SlClose } from "react-icons/sl"

import { useFormik, Field } from "formik"
import ApiServer from "../helpers/Api_help"
import { Button, Modal, Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  fetchMembershipTypes,
  fetchMyProfileData,
  fetchFamilyMember,
  ResetTB,
} from "../features/FetchData"
import {
  ResetBAM,
  UpadteBAM,
  UpdateImageProfile,
  UpdateTosubscriber,
  UpgradeMembership,
  UpdateFamilyMember,
  CreateFamilyMember,
  deleteFamilyMember,
} from "../features/FormDataStore"
import { ToastContainer, toast } from "react-toastify"
import PageBanner from "../layouts/PageBanner"
import moment from "moment"
import { BiSolidCameraPlus } from "react-icons/bi"
import { FcCamera, FcGallery } from "react-icons/fc"
import classnames from "classnames"
import { IMAGES } from "../constant/theme"
import { Country } from "../components/country-state-city/countries"
import { State } from "../components/country-state-city/states"
import MembershipButton from "../components/Paypal/MembershipButton"
// import bg from "../assets/images/background/SATS-BACKDROP_plain-1920px.jpg"
import {
  FormFeedback,
  Label,
  Input,
  CardImg,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardImgOverlay,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  CardText,
  Form,
  Table,
} from "reactstrap"
import WebcamCapture from "../components/WebcamCapture"

const MyProfile = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()

  const [FamilyModal, setFamilyModal] = useState(false)
  const [DeleteModal, setDeleteModal] = useState(false)
  const [dis, setDis] = useState(true)
  const [webcam, setWebcam] = useState(false)
  const [profile, setProfile] = useState("")
  const [ProfileImageModal, setProfileImageModal] = useState(false)
  const [disabledfamilySubmit, setDisabledfamilySubmit] = useState(false)

  //upgrade
  const [UpgradeModal, setUpgradeModal] = useState(false)
  const [MemShipagree, setmemShipagree] = useState(false)
  const [ConfirmMemshipModal, setConfirmMemshipModal] = useState(false)
  const [TempMembership, setTempMembership] = useState()

  const User_Id = JSON.parse(localStorage.getItem("user_id"))
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const { SuccessBAM, SuccessFamily, FailFamily, FailBAM, profileres } =
    useSelector((state) => state.FormDataReducer)
  const { Success, MembershipTypes, MyProfileInfo, FamilyDetails } =
    useSelector((state) => state.FetchDataReducer)

    const [startDate, setStartDate] = useState(new Date());

  const [error, setError] = useState()
  useEffect(() => {
    dispatch(fetchMembershipTypes())
   // dispatch(fetchFamilyMember({ family_member_id: User_Id?._id || null }))
    dispatch(fetchMyProfileData(userInfo))
    console.log("userInfo", userInfo)
  }, [])

  useEffect(() => {
    if (profileres) {
      dispatch(fetchMyProfileData(userInfo))
    }
    dispatch(ResetBAM())
  }, [profileres])

  useEffect(()=>{
    console.log("MyProfileInfo", MyProfileInfo)
    setStartDate(MyProfileInfo.dob ? new Date(MyProfileInfo.dob) : new Date())
  },[MyProfileInfo])



  // useEffect(() => {
  //   if (Success) {
  //     if (MyProfileInfo?.member_type_id !== "2") {
  //       dispatch(fetchFamilyMember({ family_member_id: MyProfileInfo?._id }))
  //     }
  //   }
  //   dispatch(ResetTB())
  // }, [Success])
  //family_member_id

  



  // const { spouse, children } = MyProfileInfo
  // console.log(spouse, children?.length)
  // const [SpouseDetails, setSpouseDetails] = useState(MyProfileInfo?.spouse)
  // const [ChildDetails, setChildDetails] = useState(MyProfileInfo?.children)

  const [selectedFamily, setSelectedFamily] = useState()
  const [deleteFamily, setDeleteFamily] = useState()
  // const [selectedSpouse, setSelectedSpouse] = useState()

  useEffect(() => {
    if (SuccessFamily) {
      // dispatch(fetchFamilyMember({ family_member_id: MyProfileInfo?._id }))
      setError()
      ValidationFamily.resetForm()
      setFamilyModal(false)
      dispatch(fetchFamilyMember({ member_id: userInfo.member_id || null }))
      setDisabledfamilySubmit(false)
      //nav("/")
    }
    if (FailFamily) {
      console.log("error", FailFamily)
      setError(FailFamily)
      setDisabledfamilySubmit(false)
    }
    dispatch(ResetBAM())
  }, [SuccessFamily, FailFamily])


  useEffect(() => {
    // setSpouseDetails(MyProfileInfo?.spouse)
    // setChildDetails(MyProfileInfo?.children)
    setProfile(`${ApiServer}uploads/${MyProfileInfo?.profile_img}`)
  }, [MyProfileInfo])

  // const [activeTab1, setactiveTab1] = useState("1")
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  // console.log("MyProfileInfo", MyProfileInfo)
  // console.log(ChildDetails, SpouseDetails)
  useEffect(() => {
    if (SuccessBAM) {
      toast.success("Successfully Updated...!", {
        position: "top-center",
      })
      nav("/")
    }
    if (FailBAM) {
      toast.error(" Failed to Update ...!", {
        position: "top-center",
      })
    }
    dispatch(ResetBAM())
  }, [SuccessBAM, FailBAM])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
     // _id: MyProfileInfo?._id || "",
      email: MyProfileInfo?.email || "",
      firstname: MyProfileInfo?.firstname || "",
      lastname: MyProfileInfo?.lastname || "",
      gender: MyProfileInfo?.gender || "",
      mobile: MyProfileInfo?.mobile || "",
      // membership_type: MyProfileInfo?.membership_type || "",
      // membership_fees: MyProfileInfo?.membership_fees || "",
      // membership_type_id: MyProfileInfo?.membership_type_id || null,
      address: MyProfileInfo?.address || "",
      address2: MyProfileInfo?.address2 || "",
      city: MyProfileInfo?.city || "",
      state: MyProfileInfo?.state || "",
      country_code: MyProfileInfo?.country_code || "",
      country: MyProfileInfo?.country || "",
      zipcode: MyProfileInfo?.zipcode || "",
      dob: MyProfileInfo?.dob || "",
      profile_img: MyProfileInfo?.profile_img || "",
      //spouse: MyProfileInfo?.spouse || "",
      //children: MyProfileInfo?.children || "",
      //currency: MyProfileInfo?.currency || "",
      //login_status: MyProfileInfo?.login_status || "",
      //status: MyProfileInfo?.status || "", //subscribe
      // dobcheck: MyProfileInfo?.dobcheck || false,
      //membership_expired_on: MyProfileInfo?.membership_expired_on || null,
    },
    validationSchema: Yup.object({
      // member_type_id: Yup.string().required("Select the Membership"),
      email: Yup.string()
        .transform((value) => value.replace(/\s/g, ""))
        .email("Invalid email Id")
        .required("Email Id is required"),
      firstname: Yup.string().required("Enter Your First Name"),
      lastname: Yup.string().required("Enter Your Last Name"),
      country: Yup.string().required("Select Your Country"),
      state: Yup.string().required("Select Your State"),

      // gender: Yup.string().required("Select Your Gender"),
      // dob: Yup.string().required("Enter Your Date of Birth"),
      city: Yup.string().required("Enter Your City"),
      mobile: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 (Enter country code and Mobile number)"
        )
        .required("Enter the Mobile Number"),
      gender: Yup.string().required("Select Your Gender"),
      // dob: Yup.string()
      //   .matches(
      //     /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])$/,
      //     " date format Should be (date-Month) example : 27-07"
      //   )
      //   .required("Date-Month is required"),
      Subscribe: Yup.bool().oneOf([true], "You must agree to the terms"),
      // dobcheck: Yup.bool().oneOf(
      //   [true],
      //   "You must agree to the terms for Allow To Submission"
      // ),
      // address: Yup.string().required("Enter Your Address"),
    }),
    onSubmit: (values, actions) => {
      // validation.setFieldValue("spouse", SpouseDetails)
      // values["spouse"] = SpouseDetails
      // values["children"] = ChildDetails
      // validation.setFieldValue("children", ChildDetails)
      delete values.membership_fees
      delete values.membership_type
      delete values.membership_type_id
      delete values.login_status

      values["dob"] = startDate

      console.log("njkk", values)
      dispatch(UpadteBAM(values))
      setDis(true)
    },
    onSubmissionError: (errors) => {
      console.log("scrollTo")
    },
  })

  // const childrenData = async (data) => {
  //   const value1 = await [...ChildDetails, data]
  //   // const value1 = ChildDetails.push(data)
  //   console.log(value1, data)
  //   setChildDetails(value1)
  // }

  // const DeleteChild = (id) => {
  //   console.log(id)
  //   let data = ChildDetails.filter((data, ind) => data._id !== id)
  //   console.log(data)
  //   setChildDetails(data)
  // }

  const ValidationFamily = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: selectedFamily?.firstname || "",
      lastname: selectedFamily?.lastname || "",

      email: selectedFamily?.email || "",
      gender: selectedFamily?.gender || "",
      // membership_type:
      //   selectedFamily?.membership_type || MyProfileInfo?.membership_type,
      // membership_fees:
      //   selectedFamily?.membership_fees || MyProfileInfo?.membership_fees,
      // membership_type_id:
      //   selectedFamily?.membership_type_id || MyProfileInfo?.membership_type_id,
      // address: selectedFamily?.address || MyProfileInfo?.address,
      // address2: selectedFamily?.address2 || MyProfileInfo?.address2,
      // city: selectedFamily?.city || MyProfileInfo?.city,
      // state: selectedFamily?.state || MyProfileInfo?.state,
      // country_code: selectedFamily?.country_code || MyProfileInfo?.country_code,
      // country: selectedFamily?.country || MyProfileInfo?.country,
      // zipcode: selectedFamily?.zipcode || MyProfileInfo?.zipcode,
      // currency: "USD",
      // login_status: 0,
      // family_member_sats_id:
      // selectedFamily?.family_member_sats_id || MyProfileInfo?.member_id, // parent member id
      family_member_id: selectedFamily?.family_member_id || MyProfileInfo?._id, // parent _id
      family_member_type: selectedFamily?.family_member_type || "", // Spouse or Child
      // member_type: selectedFamily?.member_type || "family", // member or family
      // member_type_id: selectedFamily?.member_type_id || "2", // member(1) or family(2)
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Enter Name"),
      gender: Yup.string().required("select Gender "),
      // email: Yup.string()
      //   .transform((value) => value.replace(/\s/g, ""))
      //   .email("Invalid email Id")
      //   .required("Email Id is required"),
      family_member_type: Yup.string().required("select member type"),
      // age: Yup.string().required("Enter Your child Age"),
    }),
    onSubmit: (values, actions) => {
      // console.log("Childss", values)

      // console.log(selectedFamily?.ind)
      // if (selectedFamily?.ind || selectedFamily?.ind === 0) {
      //   let edit = ChildDetails.map((val, ind) => {
      //     if (ind === selectedFamily?.ind) {
      //       return {
      //         // ...val,
      //         firstname: values.firstname,
      //         gender: values.gender,
      //         age: values.age,
      //         _id: selectedFamily._id,
      //       }
      //     }
      //     return val
      //   })
      //   console.log("value change", edit)
      //   setChildDetails(edit)
      // } else {
      //   childrenData(values)
      // }
      setError("")
      if (selectedFamily?._id) {
        values["_id"] = selectedFamily?._id
        dispatch(UpdateFamilyMember(values))
      } else {
        dispatch(CreateFamilyMember(values))
      }

      setSelectedFamily()
      setDisabledfamilySubmit(true)
    },
  })

  useEffect(() => {
    if (!FamilyModal) {
      setSelectedFamily()
    }
  }, [setFamilyModal])

  // const ValidationSpouse = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     firstname: selectedSpouse?.firstname || "",
  //     email: selectedSpouse?.email || "",
  //     gender: selectedSpouse?.gender || "",
  //     membership_type:
  //       selectedSpouse?.membership_type || MyProfileInfo?.membership_type,
  //     membership_fees:
  //       selectedSpouse?.membership_fees || MyProfileInfo?.membership_fees,
  //     membership_type_id:
  //       selectedSpouse?.membership_type_id || MyProfileInfo?.membership_type_id,
  //     address: selectedSpouse?.address || MyProfileInfo?.address,
  //     address2: selectedSpouse?.address2 || MyProfileInfo?.address2,
  //     city: selectedSpouse?.city || MyProfileInfo?.city,
  //     state: selectedSpouse?.state || MyProfileInfo?.state,
  //     country_code: selectedSpouse?.country_code || MyProfileInfo?.country_code,
  //     country: selectedSpouse?.country || MyProfileInfo?.country,
  //     zipcode: selectedSpouse?.zipcode || MyProfileInfo?.zipcode,
  //     currency: "USD",
  //     login_status: 0,
  //     family_member_sats_id:
  //       selectedSpouse?.family_member_sats_id || MyProfileInfo?.member_id, // parent member id
  //     family_member_id: selectedSpouse?.family_member_id || MyProfileInfo?._id, // parent _id
  //     family_member_type: selectedSpouse?.family_member_type || "Spouse", // Spouse or Child
  //     member_type: selectedSpouse?.member_type || "family", // member or family
  //     member_type_id: selectedSpouse?.member_type_id || "2", // member(1) or family(2)
  //     // age: SpouseDetails?.age || "",
  //   },
  //   validationSchema: Yup.object({
  //     firstname: Yup.string().required("Enter Your Spouse Name"),
  //     gender: Yup.string().required("select Your Spouse Gender "),
  //     email: Yup.string()
  //       .email("Invalid email address")
  //       .required("Email address is required"),
  //     // age: Yup.string().required("Enter Your Spouse Age"),
  //   }),
  //   onSubmit: (values, actions) => {
  //     console.log("spouse", values)
  //     setSpouseDetails(values)
  //     // console.log(SpouseDetails.length)
  //     setSpouseModal(false)
  //     actions.resetForm()
  //     //   dispatch(ResetPassword(userData))
  //   },
  // })

  const filterstates = State.filter(
    (sta) => sta.country_name == validation.values.country
  )

  const memShipConfirm = (item) => {
    console.log(item)

    if (item.membership_name_id === "1") {
      setmemShipagree(true)
      setConfirmMemshipModal(false)
    } else {
      setmemShipagree(false)
      setConfirmMemshipModal(true)
    }
    setTempMembership(item)
  }
  useEffect(() => {
    if (MemShipagree) {
      validation.setFieldValue(
        "membership_type",
        TempMembership?.membership_name
      )
      validation.setFieldValue("membership_fees", TempMembership?.fees)
      validation.setFieldValue(
        "membership_type_id",
        TempMembership?.membership_name_id
      )
      // if (TempMembership?.membership_name === "Life Membership") {
      //   validation.setFieldValue("membership_expired_on", "lifetime")
      // } else {
      //   validation.setFieldValue("membership_expired_on", daysRemaining())
      // }
      console.log("gay", TempMembership)
    } else {
      validation.setFieldValue("membership_type", "")
      validation.setFieldValue("membership_fees", "")
      validation.setFieldValue("membership_type_id", "")
    }
  }, [TempMembership, MemShipagree])

  function daysRemaining() {
    const today = moment(MyProfileInfo?.membership_expired_on)
    // const today = moment("4/30/2023")

    let year = today.year()
    var curMonth = moment().format("MM")

    if (curMonth > 4) {
      const nextApril30d = moment(`${year + 1}-04-30`)
      const Dateexpiry = moment(nextApril30d._i).format("Do MMMM YYYY")

      const remainingDays = nextApril30d.diff(today, "days")

      // console.log(remainingDays)
      return Dateexpiry
    } else {
      const nextApril30s = moment(`${year + 1}-04-30`)
      const Dateexpiry = moment(nextApril30s._i).format("Do MMMM YYYY")
      const remainingDays = nextApril30s.diff(today, "days")
      // console.log(remainingDays)

      return Dateexpiry
    }
  }

  //[UPGRADE]

  return (
    <>
      <div className="page-content bg-white ">
        <Form
        // onSubmit={(event) => {
        //   event.preventDefault()
        //   validation.handleSubmit()
        // }}
        >
          <PageBanner maintitle="Home" pagetitle="My Profile" />

          <section className="content-inner " style={{ paddingTop: 10 }}>
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="col">
                    <div
                      className="col-sm-12 col-md-12 text-center"
                      style={{ position: "relative" }}
                    >
                      <img
                        src={
                          MyProfileInfo?.profile_img
                            ? `${ApiServer}uploads/${MyProfileInfo?.profile_img}`
                            : Avatarpng
                        }
                        style={{
                          width: "150px",
                          height: "150px",
                          objectFit: "cover",
                          alignSelf: "center",
                        }}
                        className="rounded-circle m-5 "
                      />
                      <div
                        className=""
                        style={{
                          position: "absolute",
                          top: "9.3rem",
                          right: 0,
                        }}
                      >
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            setProfileImageModal(true)
                          }}
                        >
                          <BiSolidCameraPlus
                            className="text-primary fw-5 bg-light rounded-circle"
                            style={{ fontSize: "2.5rem" }}
                          />
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12">
                      <ul>
                        <li>
                          <span>
                            {validation.values.firstname}{" "}
                            {validation.values.lastname}
                          </span>
                        </li>
                        <li style={{ lineHeight: 1.3 }} className="my-2">
                          <span style={{ fontWeight: "bold", fontSize: 14 }}>
                            Member Id
                          </span>
                          <br />
                          <span>{MyProfileInfo?.member_id}</span>
                        </li>
                        <li style={{ lineHeight: 1.3 }} className="my-2">
                          <span style={{ fontWeight: "bold", fontSize: 14 }}>
                            Membership Type
                          </span>
                          <br />
                          <span>{MyProfileInfo?.membership_type}</span>
                        </li>
                        {MyProfileInfo?.membership_type_id !== "1" && (
                          <li style={{ lineHeight: 1.3 }} className="my-2">
                            <span style={{ fontWeight: "bold", fontSize: 14 }}>
                              Membership expiry On
                            </span>
                            <br />
                            <span>
                              {MyProfileInfo?.membership_expired_on
                                ? moment
                                    .parseZone(
                                      MyProfileInfo?.membership_expired_on
                                    )
                                    .format("Do MMMM YYYY")
                                : null}

                              {/* {MyProfileInfo?.membership_expired_on} */}
                            </span>
                          </li>
                        )}
                        {/* <li style={{ lineHeight: 1.3 }} className="my-2">
                          <span style={{ fontWeight: "bold", fontSize: 14 }}>
                            Newsletter
                          </span>
                          <br />
                          <button
                            type="button"
                            className={`btn ${
                              MyProfileInfo?.status === 1
                                ? "btn-info"
                                : "btn-primary"
                            } btn-sm p-2`}
                            onClick={() =>
                              dispatch(
                                UpdateTosubscriber({
                                  _id: MyProfileInfo?._id,
                                  status: MyProfileInfo?.status,
                                })
                              )
                            }
                          >
                            {MyProfileInfo?.status === 1
                              ? "Unsubscribe Me"
                              : "wish to subscribe"}
                          </button>
                        </li> */}
                        {MyProfileInfo?.membership_type_id === "1" ||
                        MyProfileInfo.member_type_id === "2" ? (
                          ""
                        ) : (
                          <li style={{ lineHeight: 1.5 }} className="my-2">
                            <span style={{ fontWeight: "bold", fontSize: 14 }}>
                              Wish to upgrade your membership?
                            </span>
                            <br />
                            <button
                              type="button"
                              className="btn btn-warning btn-sm "
                              onClick={() => {
                                // setUpgradeModal
                                // nav("/login")
                                nav(`/upgradeplan`)
                              }}
                            >
                              Upgrade Here
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-9 mt-5 ps-3"
                  style={{ borderLeft: "1px solid #dedede" }}
                >
                  <div>
                    <Nav
                      pills
                      className="navtab-bg nav-justified border-bottom border-info-1 pb-2"
                    >
                      <NavItem>
                        {MyProfileInfo?.membership_type_id === "3" ||
                        MyProfileInfo.member_type_id === "2" ? (
                          <NavLink
                            style={{
                              cursor: "pointer",
                              background: "transparent",
                              color: "black",
                            }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block h4 bg-light p-2">
                              Personal Information
                            </span>
                          </NavLink>
                        ) : (
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-user"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Personal Information
                            </span>
                          </NavLink>
                        )}
                      </NavItem>
                      {MyProfileInfo?.membership_type_id === "3" ||
                      MyProfileInfo.member_type_id === "2" ? (
                        ""
                      ) : (
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "2",
                            })}
                            onClick={() => {
                              toggleCustom("2")
                              dispatch(
                                dispatch(fetchFamilyMember({ member_id: userInfo.member_id }))
                              )
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              Family Information
                            </span>
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>

                    <TabContent activeTab={customActiveTab} className="p-3 ">
                      <TabPane tabId="1" className="">
                        <div className="d-block">
                          {dis !== false && (
                            <button
                              type="button"
                              className="btn btn-warning text-dark btn-sm mb-2"
                              onClick={() => setDis(false)}
                            >
                              {`Edit `}
                              <BiEdit style={{ fontSize: "1rem" }} />
                            </button>
                          )}
                        </div>
                        <div className="row">
                          <div className="row">
                            <div className="mb-3 col-md-6 col-6">
                              <Label htmlFor="email">
                                First Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="firstname"
                                className={`form-control `}
                                placeholder="Enter Your First Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstname || ""}
                                invalid={
                                  validation.touched.firstname &&
                                  validation.errors.firstname
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              />
                              {validation.touched.firstname &&
                              validation.errors.firstname ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.firstname}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-md-6 col-6 mb-3">
                              <Label htmlFor="email">
                                Last Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="lastname"
                                className={`form-control`}
                                placeholder="Enter Your Last Name"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastname || ""}
                                invalid={
                                  validation.touched.lastname &&
                                  validation.errors.lastname
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              />
                              {validation.touched.lastname &&
                              validation.errors.lastname ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.lastname}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 col-12 mb-3">
                              <Label htmlFor="email">
                                Email
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="email"
                                className={`form-control`}
                                placeholder="Enter Your Email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                                disabled={true}
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className=" col-md-3 col-3 mb-3">
                              <Label htmlFor="dob">
                                Date of Birth (MM/DD)
                                <span className="text-danger">*</span>
                              </Label>
                              <DatePicker
                                selected={startDate}
                                showMonthDatePicker
                                dateFormat="MM/dd"
                                disabled={dis}
                                customInput={<Input
                                  name="dob"
                                  className="form-control "
                                  placeholder="Date-Month"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={startDate}
                                  disabled={dis}
                                  // onKeyDown={handleKeyDowndob}
                                  maxLength={5}
                                  invalid={
                                    validation.touched.dob && validation.errors.dob
                                      ? true
                                      : false
                                  }
                                />}
                                onChange={(date) => {
                                  console.log("date", date)
                                  setStartDate(date)
                                }
                                }
                              />
                              {/* <Input
                                name="dob"
                                className="form-control "
                                placeholder="Date-Month"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.dob || ""}
                                invalid={
                                  validation.touched.dob &&
                                    validation.errors.dob
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              /> */}
                              {validation.touched.dob &&
                              validation.errors.dob ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.dob}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="col-md-6 col-6 mb-3">
                              <Label htmlFor="mobile">
                                Mobile Number
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="mobile"
                                type="tel"
                                min={1}
                                className="form-control  "
                                placeholder="Enter Mobile Number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobile || ""}
                                invalid={
                                  validation.touched.mobile &&
                                  validation.errors.mobile
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              />
                              {validation.touched.mobile &&
                              validation.errors.mobile ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.mobile}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12 col-12 mb-3">
                              <Label htmlFor="address">Address #1 Line</Label>
                              <Input
                                name="address"
                                className="form-control  "
                                placeholder="Enter Your Address"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address || ""}
                                invalid={
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.address}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-md-12 col-12 mb-3">
                              <Label htmlFor="homeadd2">Address #2 Line</Label>
                              <Input
                                name="address2"
                                className="form-control  "
                                placeholder="Enter Your Address"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address2 || ""}
                                invalid={
                                  validation.touched.address2 &&
                                  validation.errors.address2
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              />
                              {validation.touched.address2 &&
                              validation.errors.address2 ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.address2}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className=" col-md-4 col-4 mb-3">
                              <Label htmlFor="city">
                                City
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="city"
                                className="form-control  "
                                placeholder="Enter the City"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                                invalid={
                                  validation.touched.city &&
                                  validation.errors.city
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              />
                              {validation.touched.city &&
                              validation.errors.city ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.city}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="col-md-4 col-4 mb-3">
                              <Label htmlFor="country">
                                Country
                                <span className="text-danger">*</span>{" "}
                              </Label>
                              <select
                                id="country"
                                name="country"
                                className={`form-select ${
                                  validation.touched.country &&
                                  validation.errors.country &&
                                  "is-invalid"
                                }`}
                                onChange={(e) => {
                                  const V_code = Country.filter(
                                    (country) => country.name === e.target.value
                                  )

                                  validation.setFieldValue(
                                    "country",
                                    e.target.value
                                  )
                                  validation.setFieldValue(
                                    "country_code",
                                    V_code?.[0].iso2
                                  )
                                  validation.setFieldValue(
                                    "currency",
                                    V_code?.[0].currency
                                  )
                                }}
                                value={validation.values.country}
                                disabled={dis}
                              >
                                <option value={validation.values.country}>
                                  {validation.values.country ||
                                    "Select  Country"}
                                </option>
                                {Country.map((country) => (
                                  <option value={country.name} key={country.id}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.country &&
                              validation.errors.country ? (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {validation.errors.country}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 col-4 mb-3">
                              <Label htmlFor="state">
                                State
                                <span className="text-danger">*</span>
                              </Label>
                              <select
                                id="state"
                                name="state"
                                className={`form-select ${
                                  validation.touched.state &&
                                  validation.errors.state &&
                                  "is-invalid"
                                }`}
                                onChange={(e) =>
                                  validation.setFieldValue(
                                    "state",
                                    e.target.value
                                  )
                                }
                                value={validation.values.state}
                                disabled={dis}
                              >
                                <option value={validation.values.state}>
                                  {validation.values.state || "Select State"}
                                </option>
                                {filterstates.map((state) => (
                                  <option
                                    value={state.name}
                                    key={state.id}
                                    className="d-flex flex-grow-1"
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                              {validation.touched.state &&
                              validation.errors.state ? (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {validation.errors.state}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className=" col-lg-4 col-4 mb-3">
                              <Label htmlFor="zipcode">
                                Zip Code
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="zipcode"
                                className="form-control  "
                                placeholder="Enter the zipcode"
                                type="tel"
                                min={1}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.zipcode || ""}
                                invalid={
                                  validation.touched.zipcode &&
                                  validation.errors.zipcode
                                    ? true
                                    : false
                                }
                                disabled={dis}
                              />
                              {validation.touched.zipcode &&
                              validation.errors.zipcode ? (
                                <FormFeedback
                                  type="invalid"
                                  style={{ fontSize: "12px" }}
                                >
                                  {validation.errors.zipcode}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className="mb-3 col-12 col-sm-12">
                              <Label htmlFor="gender">
                                Gender
                                <span className="text-danger">*</span>
                              </Label>
                              <fieldset>
                                <input
                                  type="radio"
                                  name="gender"
                                  id="track"
                                  className="me-2 form-check-input "
                                  value={validation.values.gender || ""}
                                  checked={validation.values.gender === "male"}
                                  onClick={(e) => {
                                    validation.setFieldValue("gender", "male")
                                  }}
                                  disabled={dis}
                                />
                                <label htmlFor="track" className="me-3">
                                  Male
                                </label>

                                <input
                                  type="radio"
                                  name="gender"
                                  className="me-2 form-check-input"
                                  id="event"
                                  value={validation.values.gender || ""}
                                  checked={
                                    validation.values.gender === "female"
                                  }
                                  onClick={(e) => {
                                    validation.setFieldValue("gender", "female")
                                  }}
                                  disabled={dis}
                                />
                                <label htmlFor="event" className="me-3">
                                  Female
                                </label>
                              </fieldset>
                              {validation.errors.gender ? (
                                <div
                                  style={{
                                    fontSize: "12px",
                                    color: "red",
                                  }}
                                >
                                  {validation.errors.gender}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </TabPane>
                      <TabPane tabId="2" className="mt-2">
                        {/* <div className="d-block">
                          {dis !== false && (
                            <button
                              type="button"
                              className="btn btn-warning text-dark btn-sm mb-2"
                              onClick={() => setDis(false)}
                            >
                              {`Edit `}
                              <BiEdit style={{ fontSize: "1rem" }} />
                            </button>
                          )}
                        </div> */}
                        <Row>
                          {/* {SpouseDetails?.firstname != null && (
                            <Table
                              bordered
                              hover
                              responsive
                              className="text-right mb-2 "
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan={4}
                                    className="text-center fw-bold"
                                  >
                                    Spouse Details
                                  </th>
                                </tr>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Gender</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{SpouseDetails?.firstname}</td>
                                  <td>{SpouseDetails?.email}</td>
                                  <td>{SpouseDetails?.gender}</td>
                                  <td className="d-flex">
                                    <button
                                      type="button"
                                      className="btn btn-info  mx-3 p-2"
                                      onClick={(e) => {
                                        setSpouseModal(true)
                                      }}
                                      disabled={dis}
                                    >
                                      <BiEdit style={{ fontSize: "1rem" }} />
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger  mx-3 p-2"
                                      onClick={(e) => {
                                        setSpouseDetails()
                                      }}
                                      disabled={dis}
                                    >
                                      <MdDelete style={{ fontSize: "1rem" }} />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          )} */}
                          {FamilyDetails?.length > 0 && (
                            <Table
                              bordered
                              hover
                              responsive
                              className="text-right "
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan={5}
                                    className="text-center fw-bold"
                                  >
                                    Family Details
                                  </th>
                                </tr>
                                <tr className="text-center" style={{fontSize:13}}>
                                  <th>Member id</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Gender</th>
                                  <th>Member Type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {FamilyDetails?.map((ch, ind) => (
                                  <tr key={ind+"fmbrs"} className="text-center">
                                    <td style={{ fontSize: "13px" }}>
                                      {ch?.member_id}
                                    </td>
                                    <td style={{ fontSize: "13px" }}>
                                      {ch?.firstname}
                                    </td>
                                    <td style={{ fontSize: "13px" }}>
                                      {ch?.email}
                                    </td>
                                    <td style={{ fontSize: "13px" }}>
                                      {ch?.gender}
                                    </td>
                                    <td style={{ fontSize: "13px" }}>
                                      {ch?.family_member_type}
                                    </td>
                                    <td className="d-flex">
                                      <button
                                        type="button"
                                        className="btn btn-info text-end p-2 me-2"
                                        onClick={(e) => {
                                          setSelectedFamily(ch)

                                          setFamilyModal(true)
                                        }}
                                        // disabled={dis}
                                      >
                                        <BiEdit style={{ fontSize: "1rem" }} />
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger text-end  p-2"
                                        onClick={(e) => {
                                          setDeleteFamily({ _id: ch._id })
                                          setDeleteModal(true)
                                        }}
                                        // disabled={dis}
                                      >
                                        <MdDelete
                                          style={{ fontSize: "1rem", color:"#fff" }}
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}

                          <Col>
                            <CardText className="mt-3 ">
                              <div className=" d-flex justify-content-end gap-1 col-12 mb-4">
                                {/* {SpouseDetails?.firstname == null && (
                                  <button
                                    type="button"
                                    onClick={() => setSpouseModal(true)}
                                    className="btn btn-sm btn-outline-primary mx-2 "
                                    // disabled={dis}
                                  >
                                    <AiOutlinePlusCircle className="fs-5" />
                                    <span className="ps-2">Add Spouse</span>
                                  </button>
                                )} */}

                                {FamilyDetails?.length < 5 && (
                                  <button
                                    type="button"
                                    onClick={() => setFamilyModal(true)}
                                    className="btn btn-sm btn-outline-primary  "
                                    // disabled={dis}
                                  >
                                    <AiOutlinePlusCircle className="fs-5" />
                                    <span className="ps-2">
                                      Add Family Member
                                    </span>
                                  </button>
                                )}
                              </div>
                            </CardText>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>

                    <Col className="col-12 d-flex">
                      {dis === false && (
                        <div className=" text-end col-12 mb-4">
                          <button
                            type="button"
                            className="btn btn-light px-3 mx-3"
                            onClick={() => setDis(true)}
                          >
                            {"Cancel"}
                          </button>
                          <button
                            type="submit"
                            onClick={(event) => {
                              event.preventDefault()
                              validation.handleSubmit()
                            }}
                            className="btn btn-info px-3 me-3"
                          >
                            {"Update"}
                          </button>
                        </div>
                      )}
                    </Col>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Form>
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={FamilyModal}
          onHide={() => setFamilyModal(false)}
          centered
          backdrop="static"
        >
          <h3 className="title">
            {selectedFamily?._id ? "Edit" : "Add"} Family
          </h3>
          <span
            className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
            style={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setFamilyModal(false)
              setSelectedFamily()
            }}
          >
            {" "}
            <SlClose />
          </span>
          <Form>
            <div className="mb-1  col-12">
              <Label htmlFor="firstname">Name</Label>
              <Input
                name="firstname"
                className="form-control  "
                placeholder="Enter  Name"
                type="text"
                onChange={ValidationFamily.handleChange}
                onBlur={ValidationFamily.handleBlur}
                value={ValidationFamily.values.firstname || ""}
                invalid={
                  ValidationFamily.touched.firstname &&
                  ValidationFamily.errors.firstname
                    ? true
                    : false
                }
              />
              {ValidationFamily.touched.firstname &&
              ValidationFamily.errors.firstname ? (
                <FormFeedback type="invalid">
                  {ValidationFamily.errors.firstname}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-1 col-12">
              <Label htmlFor="email">
                Email
                {/* <span className="text-danger">*</span> */}
              </Label>
              <Input
                name="email"
                className={`form-control`}
                placeholder="Enter  Email"
                type="email"
                onChange={ValidationFamily.handleChange}
                onBlur={ValidationFamily.handleBlur}
                value={ValidationFamily.values.email || ""}
                invalid={
                  ValidationFamily.touched.email &&
                  ValidationFamily.errors.email
                    ? true
                    : false
                }
                // disabled={selectedFamily?.email ? true : false}
              />
              {ValidationFamily.touched.email &&
              ValidationFamily.errors.email ? (
                <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                  {ValidationFamily.errors.email}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-1  col-12">
              <Label htmlFor="gender"> Gender</Label>
              <select
                id="gender"
                name="gender"
                className={`form-select ${
                  ValidationFamily.touched.gender &&
                  ValidationFamily.errors.gender &&
                  "is-invalid"
                }`}
                value={ValidationFamily.values.gender}
                onChange={(e) =>
                  ValidationFamily.setFieldValue("gender", e.target.value)
                }
              >
                <option value={""}>Select Gender</option>
                <option value={"male"}>Male</option>
                <option value={"female"}>Female</option>
              </select>
              {ValidationFamily.touched.gender &&
              ValidationFamily.errors.gender ? (
                <div
                  style={{
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {ValidationFamily.errors.gender}
                </div>
              ) : null}
            </div>
            <div className="mb-2  col-12">
              <Label htmlFor="family_member_type">Relative</Label>
              <select
                id="family_member_type"
                name="family_member_type"
                className={`form-select ${
                  ValidationFamily.touched.family_member_type &&
                  ValidationFamily.errors.family_member_type &&
                  "is-invalid"
                }`}
                value={ValidationFamily.values.family_member_type}
                onChange={(e) =>
                  ValidationFamily.setFieldValue(
                    "family_member_type",
                    e.target.value
                  )
                }
              >
                <option value={""}>Select Relative</option>
                <option value={"spouse"}>Spouse</option>
                <option value={"child"}>Child</option>
                <option value={"others"}>Others</option>
              </select>
              {ValidationFamily.touched.family_member_type &&
              ValidationFamily.errors.family_member_type ? (
                <div
                  style={{
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {ValidationFamily.errors.family_member_type}
                </div>
              ) : null}
            </div>
            {/* <div className="mb-3  col-12">
              <Label htmlFor="age">Child Age</Label>
              <Input
                name="age"
                className="form-control  "
                placeholder="Enter the Age"
                type="number"
                onChange={ValidationFamily.handleChange}
                onBlur={ValidationFamily.handleBlur}
                value={ValidationFamily.values.age || ""}
                invalid={
                  ValidationFamily.touched.age && ValidationFamily.errors.age
                    ? true
                    : false
                }
              />
              {ValidationFamily.touched.age && ValidationFamily.errors.age ? (
                <FormFeedback type="invalid">
                  {ValidationFamily.errors.age}
                </FormFeedback>
              ) : null}
            </div> */}
            <p style={{ fontSize: "12px" }} className="text-danger text-center">
              {error ? error : ""}
            </p>
            <div className="form-group">
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  ValidationFamily.handleSubmit()
                }}
                disabled={disabledfamilySubmit}
                className="btn btn-outline-primary btn-block"
              >
                {disabledfamilySubmit && (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                )}
                {selectedFamily?._id ? "Update" : "Submit"}
              </button>
            </div>
            {/* <Link
              to={"#"}
              className="sign-text d-block"
              data-bs-toggle="collapse"
              onClick={() => setFamilyModal(false)}
            >
              Back
            </Link> */}
          </Form>
        </Modal>
        {/* <Modal
          className="modal fade modal-wrapper auth-modal"
          show={SpouseModal}
          onHide={setSpouseModal}
          centered
        >
          <h2 className="title">Add Spouse</h2>
          <Form>
            <div className="mb-3 col-12">
              <Label htmlFor="firstname">First Name</Label>
              <Input
                name="firstname"
                className="form-control  "
                placeholder="Enter the  Name"
                type="text"
                onChange={ValidationSpouse.handleChange}
                onBlur={ValidationSpouse.handleBlur}
                value={ValidationSpouse.values.firstname || ""}
                invalid={
                  ValidationSpouse.touched.firstname &&
                  ValidationSpouse.errors.firstname
                    ? true
                    : false
                }
              />
              {ValidationSpouse.touched.firstname &&
              ValidationSpouse.errors.firstname ? (
                <FormFeedback type="invalid">
                  {ValidationSpouse.errors.firstname}
                </FormFeedback>
              ) : null}
            </div>

            <div className=" mb-3 col-12">
              <Label htmlFor="email">Email</Label>
              <Input
                name="email"
                className="form-control  "
                placeholder="Enter the  Email"
                type="email"
                onChange={ValidationSpouse.handleChange}
                onBlur={ValidationSpouse.handleBlur}
                value={ValidationSpouse.values.email || ""}
                invalid={
                  ValidationSpouse.touched.email &&
                  ValidationSpouse.errors.email
                    ? true
                    : false
                }
              />
              {ValidationSpouse.touched.email &&
              ValidationSpouse.errors.email ? (
                <FormFeedback type="invalid">
                  {ValidationSpouse.errors.email}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3 col-12">
              <Label htmlFor="gender"> Gender</Label>
              <select
                id="gender"
                name="gender"
                className="form-control"
                value={ValidationSpouse.values.gender}
                onChange={(e) =>
                  ValidationSpouse.setFieldValue("gender", e.target.value)
                }
              >
                <option value={""}>Select Your Gender</option>
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
              </select>
            </div>
            <div className="mb-3 col-12">
              <Label htmlFor="age">Age </Label>
              <Input
                name="age"
                className="form-control  "
                placeholder="Enter the Age"
                type="number"
                onChange={ValidationSpouse.handleChange}
                onBlur={ValidationSpouse.handleBlur}
                value={ValidationSpouse.values.age || ""}
                invalid={
                  ValidationSpouse.touched.age && ValidationSpouse.errors.age
                    ? true
                    : false
                }
              />
              {ValidationSpouse.touched.age && ValidationSpouse.errors.age ? (
                <FormFeedback type="invalid">
                  {ValidationSpouse.errors.age}
                </FormFeedback>
              ) : null}
            </div>
            <div className="form-group">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  ValidationSpouse.handleSubmit()
                }}
                type="button"
                className="btn btn-outline-primary btn-block"
              >
                Submit
              </button>
            </div>
            <Link
              to={"#"}
              className="sign-text d-block"
              data-bs-toggle="collapse"
              onClick={() => setSpouseModal(false)}
            >
              Back
            </Link>
          </Form>
        </Modal> */}
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={ProfileImageModal}
          onHide={setProfileImageModal}
          centered
        >
          <h2 className="title">Profile Image Picker</h2>
          <div className="d-flex justify-content-around">
            <label htmlFor="profile_img">
              <FcGallery
                style={{
                  fontSize: "5rem",
                  cursor: "pointer",
                  border: "grey 2px solid",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              />
            </label>
            <input
              id="profile_img"
              type="file"
              name="profile_img"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                console.log(e.target.files[0])
                // setProfile(e.target.files[0])
                dispatch(
                  UpdateImageProfile({
                    _id: MyProfileInfo._id,
                    image: e.target.files[0],
                  })
                )
                setProfileImageModal(false)
              }}
            ></input>

            <label
              type="button"
              onClick={() => {
                setWebcam(true)

                // setProfileImageModal(false)
              }}
            >
              <FcCamera
                style={{
                  fontSize: "5rem",
                  cursor: "pointer",
                  border: "grey 2px solid",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              />
            </label>
          </div>
        </Modal>
        <Modal
          className="modal fade modal-wrapper  bg-light d-flex flex-wrap justify-content-around align-items-start"
          show={webcam}
          onHide={setWebcam}
          fullscreen={true}
          size="sm"
          centered
        >
          {webcam == true && (
            <WebcamCapture
              UpdateImageProfile={UpdateImageProfile}
              MyProfileInfo={MyProfileInfo}
              setWebcam={setWebcam}
              setProfile={setProfile}
              setProfileImageModal={setProfileImageModal}
            />
          )}
        </Modal>
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={UpgradeModal}
          onHide={setUpgradeModal}
          centered
          backdrop="static"
          // max-width="500px"
          // style={{ Width: "700px" }}
        >
          <h3 className="title">Are you Want to Upgrade your </h3>
          <span
            className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
            style={{
              fontSize: "2rem",
              cursor: "pointer",
            }}
            onClick={() => {
              setUpgradeModal(false)
              setmemShipagree(false)
            }}
          >
            {" "}
            <SlClose />
          </span>
          <div className="col-12 d-flex flex-column">
            {/* // flex-wrap */}
            <Label htmlFor="member">
              SATS Membership
              <span className="text-danger">*</span>
            </Label>
            <div
              className="btn-group d-flex flex-wrap"
              role="group"
              // style={{ width: "500px" }}
            >
              {MembershipTypes.map((item, ind) => (
                <>
                  <input
                    type="radio"
                    className="btn-check" //flex-fill
                    name="btnradio"
                    id={item._id}
                    autoComplete="off"
                    value={validation.values.membership_type || ""}
                    defaultChecked={
                      validation.values.membership_type === item.membership_name
                    }
                    onClick={(e) => {
                      memShipConfirm(item)
                    }}
                    invalid={validation.errors.membership_type ? true : false}
                  />
                  <label
                    className="btn btn-outline-warning d-flex flex-column"
                    htmlFor={item._id}
                    style={{ fontSize: "12px" }}
                  >
                    <p>{`${item.membership_name}  ${
                      item.term === 1 ? "(Yr)" : ""
                    }`}</p>

                    <h6>${item.fees}*</h6>
                  </label>
                </>
              ))}
            </div>
            <div className="text-center">
              <span className="text-danger">*</span>{" "}
              <small>PayPal Transaction Fee (2.25%+31 Cents) Extra</small>
            </div>
            {validation.touched.membership_type &&
            validation.errors.membership_type ? (
              <div style={{ fontSize: "12px", color: "red" }}>
                {validation.errors.membership_type}
              </div>
            ) : null}
            {MemShipagree && (
              <div className="mt-3">
                <MembershipButton
                  data2={validation.values}
                  membershipSelected={TempMembership}
                  setModal={setUpgradeModal}
                  upgrade={"up"}
                  purpose={"Membership Upgrade"}
                />
              </div>

              // <Button
              //   onClick={() => {
              //     dispatch(UpgradeMembership(validation.values))
              //   }}
              // >
              //   Next Step
              // </Button>
            )}
          </div>
        </Modal>
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={ConfirmMemshipModal}
          onHide={() => setConfirmMemshipModal(false)}
          backdrop="static"
          centered
        >
          <h2 className="title text-warning">Are you Sure</h2>
          <p className="text-justify">
            Membership expires on
            <span className="fw-bold"> {daysRemaining()}</span> . If you agree
            with the Membership plan, please confirm it.
          </p>
          <div className="d-flex gap-1 justify-content-end">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setmemShipagree(true)
                setConfirmMemshipModal(false)
                console.log(validation.values)
              }}
            >
              Confirm
            </button>
            <button
              className="btn btn-light btn-sm"
              onClick={() => {
                setmemShipagree(false)
                setConfirmMemshipModal(false)
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={DeleteModal}
          onHide={() => setDeleteModal(false)}
          backdrop="static"
          centered
        >
          <h2 className="title text-warning">Are you Sure?</h2>
          <p className="text-center">Do you want to delete this Family member?</p>
          <div className="d-flex gap-1 justify-content-end">
            <button
              className="btn btn-light btn-sm"
              onClick={() => {
                setDeleteModal(false)
                setDeleteFamily()
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setDeleteModal(false)
                dispatch(deleteFamilyMember(deleteFamily))
              }}
            >
              Confirm
            </button>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default MyProfile
