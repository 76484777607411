import React, { useState, useContext, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Modal } from "react-bootstrap"
import CountUp from "react-countup"
import ModalVideo from "react-modal-video"
import "react-modal-video/css/modal-video.min.css"
import { ImageApi } from "../helpers/Api_help"
// import ComingSoon from "./ComingSoon"
import Coimingsoongif from "../assets/comingsoon.gif"
//componenet
import MainSliderIndex3 from "../components/Home3/MainSliderIndex3"
import TrendingSlider2 from "../components/Home3/TrendingSlider2"
import SatServices from "../components/Home3/SatServices"
import SuccessStoriesSlider from "../components/Home3/SuccessStoriesSlider"
import EventsHome from "../components/Home3/EventsHome"
import PartnershipSlider from "../components/Home/PartnershipSlider"
import { useDispatch, useSelector } from "react-redux"
import { fetchUpcomingEvents } from "../features/Event/EventTBSlice"
import {
  fetchSponsers,
  fetchBanner,
  fetchCommittee,
} from "../features/FetchData"

import TeamSlider from "./../components/Home/TeamSlider"
import RecentProjectsSlider from "./../components/Home/RecentProjectsSlider"
import UpdateBlog from "./../components/Home/UpdateBlog"

//images
import bgImage from "../assets/images/banner/bnr1.jpg"
import pic1 from "../assets/images/main-slider/slider2/pic1.png"
import about from "../assets/images/about/pic1.jpg"
import signature from "../assets/images/about/signature.png"
import bg4 from "../assets/images/background/bg4.jpg"

//layouts
import Header2 from "../layouts/Header2"
import Footer3 from "../layouts/Footer3"
import { ThemeContext } from "../context/ThemeContext"
import { IMAGES } from "../constant/theme"

const counterBlog = [
  { title: "Completed Projects", number: "1854" },
  { title: "Countries Served", number: "35", symbal: "+" },
  { title: "People With Clean Water", number: "29", symbal: "M" },
  { title: "People With Clean Tank", number: "41", symbal: "M" },
]

const Home3 = () => {
  const modalRef = useRef()
  // const { changeBackground, changePrimaryColor } = useContext(ThemeContext)
  // useEffect(() => {
  //   changeBackground({ value: "data-typography-2", label: "data-typography-2" })
  //   changePrimaryColor("color-skin-3")
  // }, [])
  const dispatch = useDispatch()
  const { UpcomingData } = useSelector((state) => state.EventTBReducer)
  const { Sponser, Banner, Success, fail } = useSelector(
    (state) => state.FetchDataReducer
  )
  const { Committe } = useSelector((state) => state.FetchDataReducer)
  const [readModal, setReadModal] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const nav = useNavigate()
  // const FormSubmit = (e) => {
  //   e.preventDefault()
  //   nav("/contact-us")
  // }
  useEffect(() => {
    dispatch(fetchUpcomingEvents())
  }, [])

  useEffect(() => {
    dispatch(fetchSponsers())
  }, [])
  useEffect(() => {
    dispatch(fetchBanner())
  }, [])

  useEffect(() => {
    dispatch(fetchCommittee())
  }, [])

  // console.log("banner data", Banner)
  // console.log("Sponser data", Sponser)

  return (
    <>
      <div className="page-wraper ">
        {/* <div className="page-sidebar">
          <ul className="dz-social">
            <li>
              <a href="https://www.facebook.com/" target={"_blank"}>
                Facebook
              </a>
            </li>
            <li>
              <a href="https://twitter.com/" target={"_blank"}>
                Twitter
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/" target={"_blank"}>
                Linkedin
              </a>
            </li>
          </ul>
          <Link
            to={"#"}
            className="btn-bottom btn btn-primary light"
            data-bs-toggle="modal"
            data-bs-target="#modalDonate"
          >
            Donate Now
          </Link>
        </div> */}
        {/* <Header2 changeStyle="header-transparent" changeLogo={true} /> */}
        <div className="page-content bg-white">
          {Banner.length > 0 && <MainSliderIndex3 Banner={Banner} />}

          <section
            className="content-inner"
            style={{ paddingTop: 0, paddingBottom:20 }}
          >
            <div className="container">
              <div className="row about-bx5 align-items-center">
                <div className="col-lg-6 m-b30">
                  <div className="dz-about-media">
                    {/* <div className="img-wrapper">
                      <img src={IMAGES.Aboutascover} alt="" className="img1" />
                      <Link
                        to={"#"}
                        className="popup-youtube video-btn"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa-solid fa-play"></i>
                      </Link>
                    </div> */}
                    <div className="img2">
                      <img src={IMAGES.Aboutascover2} alt="img2" />
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 ">
                  <div className="section-head" style={{ marginBottom: 15 }}>
                    {/* <h4 className="sub-title">About Us</h4> */}
                    <h3 style={{ lineHeight: 1.2 }} className="pb-3">
                      <span style={{ fontSize: "25px" }}>வணக்கம்!! </span>
                      <br />
                      <span style={{ color: "#fea400" }}>
                        சான் ஆண்டோனியோ <br /> தமிழ்ச் சங்கம்!
                      </span>{" "}
                      <br />
                      <span style={{ fontSize: "20px" }}>
                        உங்களை அன்புடன் வரவேற்கிறது
                      </span>
                    </h3>
                    <h5>Welcome To San Antonio Tamil Sangam</h5>
                  </div>

                  <p className="mt-2">
                    San Antonio Tamil Sangam (SATS) is registered, non-profit,
                    tax-exempt 501(c)(3) organization. The SATS was founded in
                    1990 by a group of Tamil people living in San Antonio.
                  </p>
                  <div className="">
                    <div className="col-lg-12 col-sm-12">
                      <ul className="d-flex justify-content-between">
                        {/* <li
                          className="mt-2 col-5"
                          style={{ borderRight: "4px solid #fea500" }}
                        >
                          <Link
                            to={"#"}
                            className=" btn btn-sm btn-warning"
                            data-bs-toggle="modal"
                            data-bs-target="#modalDonate"
                          >
                            Donate Now
                          </Link>
                        </li> */}
                        <li className="mt-2 col-5">
                          <Link
                            to={"about-us"}
                            className="btn btn-sm btn-warning"
                          >
                            About Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <hr className="container my-2" />
          {UpcomingData.length > 0 ? (
            <section className="pt-5 pb-5">
              <div className="container">
                <div className="section-head d-flex align-items-center flex-column mb-0">
                  <h6 className="sub-title" style={{ color: "#fea400" }}>
                    SA TAMIL SANGAM
                  </h6>

                  <div className="mb-3">
                    <h2 className="title">Upcoming Events</h2>
                  </div>
                  <EventsHome UpcomingData={UpcomingData} />
                  <Link
                    to={"pastEvent"}
                    className="btn btn-warning btn-sm mt-2"
                  >
                    Past Events
                  </Link>
                </div>
              </div>
            </section>
          ) : (
            <section className="pt-5 pb-5">
              <div className="container">
                <div className="section-head d-flex align-items-center flex-column mb-0">
                  <h6 className="sub-title" style={{ color: "#fea400" }}>
                    SA TAMIL SANGAM
                  </h6>
                  <h2 className="title">Upcoming Events</h2>
                  <p>
                    Stay connected for upcoming events. Till that vist our past
                    events.
                  </p>
                  <Link to={"pastEvent"} className="btn btn-warning btn-sm">
                    Past Events
                  </Link>
                </div>
              </div>
            </section>
          )}

          <hr className="container my-2" />

          <section className="content-inner-3 section-pattren1">
            <div className="container">
              <div
                className="section-head text-center wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <h6 className="sub-title" style={{ color: "#fea400" }}>
                  SATS COMMITTEE MEMBERS
                </h6>
                <h2 className="title">Meet Our Committee Members</h2>
              </div>
              <TeamSlider Committe={Committe} />
            </div>
          </section>

          <hr className="my-2" />

          <section className="content-inner-3 overflow-hidden section-pattren1 pb-5">
            <div className="container pb-5">
              <div
                className="section-head text-center wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <h2>SATS Projects</h2>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}
              </div>
              <RecentProjectsSlider />
            </div>
          </section>

          <hr className="container my-2" />

          {/* Section-7 */}
          {Sponser.length > 0 && (
            <div className="clients-wrapper  pb-5">
              <div className="container">
                <h2 className="title text-center  text-dark pb-5">SPONSORS</h2>

                <PartnershipSlider Sponser={Sponser} />
              </div>
            </div>
          )}

          <hr className="container my-2" />

          <section className="content-inner-2  pb-5" style={{ paddingTop: 50 }}>
            <div className="container">
              <div
                className="about-bx2"
                style={{ boxShadow: "none", border: "1px solid #dedede" }}
              >
                <div className="row g-0">
                  <div className="col-lg-4">
                    <div className="dz-media">
                      <img src={about} alt="image" />
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="dz-info">
                      <div className="mt-3" style={{ lineHeight: "30px" }}>
                        <p
                          style={{ fontSize: 20 }}
                          className="fw-bolder text-dark text-justify"
                        >
                          சங்கம் வைத்து மொழி (தமிழ்) வளர்த்த பண்பாட்டில் வந்த
                          நாம், இடம்பெயர்ந்து வாழ்ந்தாலும் நம் முன்னோர்கள்
                          கூற்றுப்படி எல்லோரும் ஒன்று கூடி நம் தாய் தமிழையும்,
                          தமிழரின் பண்பாட்டையும் வளர்ப்போம்.
                        </p>
                        <h5 className="fw-bolder text-end ">
                          -சுப்ரமணிய பாரதியார்
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section
            className="content-inner  m-2 p-4"
            
          >
            <div className="container ">
              <div className="row align-items-center border-bottom border-info">
                <div
                  className="col-lg-8 col-md-12 wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="section-head">
                    <h2 className="title">Upcoming Events</h2>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-12 m-b30 text-end d-none d-lg-block wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Link to={"pastEvent"} className="btn btn-primary btn-sm">
                    Past Events
                  </Link>
                </div>
              </div>
            </div>
            <div className="container position-relative">
              {UpcomingData.length > 0 ? (
                <EventsHome UpcomingData={UpcomingData} />
              ) : (
                <div className="d-flex flex-md-row flex-sm-column gap-1">
                  <p className="text-success mt-5 fw-bold align-items-center">
                    Stay connected for upcoming events!
                  </p>
                   <img
                    src={Coimingsoongif}
                    alt="coming"
                    className="rounded-circle col-md-3 col-sm-5 p-2"
                    style={{ height: "200px" }}
                  /> 
                </div>
              )}
            </div>
          </section> */}

          {/*     <section className="content-inner">
            <div className="container">
              <div className="row align-items-center content-bx style-2">
                <div
                  className="col-lg-6 m-b30 wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <div className="dz-media video-bx4 rounded">
                    <img src={IMAGES.ProjectPic13} alt="image" />
                    <Link
                      to={"#"}
                      className="popup-youtube vedio-btn"
                      onClick={() => setOpen(true)}
                    >
                      <i className="fa-solid fa-play"></i>
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-6 m-b30 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <div className="inner-content">
                    <div className="section-head">
                      <h2 className="title">
                        Save The
                        <br /> Planet For Better Future
                      </h2>
                      <p className="max-w400">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        Suspendisse.
                      </p>
                    </div>
                    <Link to={"/project-story"} className="btn btn-primary">
                      Project Story
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
        </div>
        {/*  <Footer3 /> */}

        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="V4_P3gbeiE4"
          onClose={() => setOpen(false)}
        />
      </div>
    </>
  )
}

export default Home3
