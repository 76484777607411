let direction = 'ltr';

export const dezThemeSet = [
	{ /* Default */
		//typography: "poppins",
		//version: "default",
		//layout: "vertical",
		//headerBg: "color_1",
		//navheaderBg: "color_1",
		//sidebarBg: "color_1",
		//sidebarStyle: "full",
		//sidebarPosition: "fixed",
		//headerPosition: "fixed",
		//containerLayout: "full",
		//direction: direction
	},
	// { /* Demo Theme 1 */
	// 	typography: "poppins",
	// 	version: "light",
	// 	layout: "verticle",
	// 	navheaderBg: "color_12",
	// 	headerBg: "color_12",
	// 	sidebarStyle: "full",
	// 	sidebarBg: "color_1",
	// 	sidebarPosition: "fixed",
	// 	headerPosition: "fixed",
	// 	containerLayout: "wide",
	// 	primary: "color_12",
	// 	direction: direction
	// },
	//  { /* Demo Theme 2 */
	// 	typography: "poppins",
	// 	version: "light",
	// 	layout: "vertical",
	// 	navheaderBg: "color_4",
	// 	headerBg: "color_4",
	// 	sidebarStyle: "overlay",
	// 	sidebarBg: "color_1",
	// 	sidebarPosition: "fixed",
	// 	headerPosition: "fixed",
	// 	containerLayout: "boxed",
	// 	primary: "color_10",
	// 	direction: direction
	// },
	// {
	// 	/* Demo Theme 3 */
	// 	typography: "poppins",
	// 	version: "light",
	// 	layout: "vertical",
	// 	navheaderBg: "color_7",
	// 	headerBg: "color_1",
	// 	sidebarStyle: "compact",
	// 	sidebarBg: "color_7",
	// 	sidebarPosition: "fixed",
	// 	headerPosition: "fixed",
	// 	containerLayout: "wide",
	// 	primary: "color_7",
	// 	direction: direction
	// },
	// {
	// 	/* Demo Theme 4 */	
	// 	typography: "poppins",
	// 	version: "light",
	// 	layout: "horizontal",
	// 	navheaderBg: "color_10",
	// 	headerBg: "color_10",
	// 	sidebarStyle: "full",
	// 	sidebarBg: "color_1",
	// 	sidebarPosition: "fixed",
	// 	headerPosition: "fixed",
	// 	containerLayout: "wide",
	// 	primary: "color_10",
	// 	direction: direction
		
	// },
	// {
	// 	/* Demo Theme 5 */
	// 	typography: "poppins",
	// 	version: "light",
	// 	layout: "horizontal",
	// 	navheaderBg: "color_12",
	// 	headerBg: "color_12",
	// 	sidebarStyle: "full",
	// 	sidebarBg: "color_12",
	// 	sidebarPosition: "fixed",
	// 	headerPosition: "fixed",
	// 	containerLayout: "wide",
	// 	primary: "color_12",
	// 	direction: direction
	// },
	// {
	// 	/* Demo Theme 6 */
	// 	typography: "poppins",
	// 	version: "light",
	// 	layout: "vertical",
	// 	navheaderBg: "color_11",
	// 	headerBg: "color_1",
	// 	sidebarStyle: "mini",
	// 	sidebarBg: "color_11",
	// 	sidebarPosition: "fixed",
	// 	headerPosition: "fixed",
	// 	containerLayout: "wide",
	// 	primary: "color_11",
	// 	direction: direction
	// },
	
];
	