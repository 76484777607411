import React from "react"
import { Link } from "react-router-dom"

import PageBanner from "../../layouts/PageBanner"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import PPt from "../../assets/images/project/Natural disaster in Chennai.pptx"
import { IMAGES, Project } from "../../constant/theme"
import { Table } from "reactstrap"
const Cyclone = () => {
  const cyclonedonate = [
    { no: "1", name: "Ravishankar Sundaramoorthy", Amount: "$100.00" },
    { no: "2", name: "Karthik Baskaran", Amount: "$25.00" },
    { no: "3", name: "Tamilselvam Nehru", Amount: "$50.00" },
    { no: "4", name: "Sundar Thambiraj", Amount: "$100.00" },
    { no: "5", name: "Anirudh Kumar", Amount: "$25.00" },
    { no: "6", name: "VijayaKumar Parathasarthy", Amount: "$75.00" },
    { no: "7", name: "Suresh Sivalingam", Amount: "$50.00" },
    { no: "8", name: "Sandeep Subramanian", Amount: "$11.00" },
    { no: "9", name: "Kalaichelvi Asokan", Amount: "$100.00" },
    { no: "10", name: "Sriramprasad Kasthura Mohanram", Amount: "	$25.00" },
    { no: "11", name: "Chandrasekaran Thumatialagarswamy", Amount: "$250.00" },
    { no: "12", name: "Sundar Kothandaraman", Amount: "$200.00" },
    { no: "13", name: "Mariselvam Palaniselvam", Amount: "$100.00" },
    { no: "14", name: "Vinoth Kumar Sampath Kumar", Amount: "$50.00" },
    { no: "15", name: "Rajaguru Paramasamy", Amount: "$250.00" },
    { no: "16", name: "Ruban Thangaraj", Amount: "$200.00" },
    { no: "17", name: "Sumitha Mudaliyar", Amount: "$25.00" },
    { no: "18", name: "Rameshkumar Ramamoorthy", Amount: "$51.44" },
    { no: "19", name: "Gunasekar rajavel", Amount: "$25.00" },
    { no: "20", name: "Kumar Veerappan", Amount: "$102.56" },
    { no: "21", name: "Periasamy Arulmani", Amount: "$100.00" },
    { no: "22", name: "	Sudha and Prashant", Amount: "$100.00" },
    { no: "23", name: "	Nandini Elangovan", Amount: "$200.00" },
    { no: "24", name: "Sivasakthi Pothiraj", Amount: "$50.00" },
    { no: "25", name: "Maheshwer Annamalai", Amount: "$100.00" },
    { no: "26", name: "Akila Dharani", Amount: "$100.00" },
    { no: "27", name: "	Mahesh Visvanathan", Amount: "$100.00" },
    {
      no: "28",
      name: "Dr. Bakthavathasalam Athreya and Jayashri",
      Amount: "$100.00",
    },
    { no: "29", name: "Kalapana Iyengar", Amount: "$50.00" },
    { no: "30", name: "	Karthikeyan Subbahnaidu", Amount: "$100.00" },
  ]
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Project" />

        <section className="content-inner bg-light section-pattren1">
          <div className="container">

            <div className="row align-items-center flex-column-reverse flex-lg-row">
              <div className="col-lg-6 align-self-center">
                <div className="section-head m-b30">
                  <h2 className="title mb-3">Cyclone Gaja</h2>
                </div>
              </div>
            </div>

            <div className=" dz-card blog-single sidebar">
              <div className="dz-media">
                <img
                  src={Project.cyclone}
                  style={{ objectFit: "conatin", height: "350px" }}
                />
              </div>
              <div className="dz-info">
                <div className="dz-meta">
                  <ul>
                    <li className="post-date">
                      <i className="fa-solid fa-calendar-days"></i> November 16th,
                      2018
                    </li>
                    <li className="post-author">
                      <Link to={"#"}>
                        <i className="fa-solid fa-minus"></i> Tamil Nadu
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="dz-post-text">
                  <div>
                    <h1 className="dz-title">Ambalapattu Village Project</h1>
                    <p>
                      Cyclone Gaja, which made landfall on November 16th has left a
                      trail of destruction in Thanjavur, Tiruvarur, and Nagapattinam
                      districts of Tamilnadu. The devastation caused by this
                      powerful cyclone with wind speeds reaching up to 120 kmph has
                      claimed over 45 lives.
                    </p>
                    <p>
                      Ambalapattu, which was a beautiful village in Thanjavur
                      district is one of the villages that faced the full brunt of
                      this storm. Thousands of trees including banyan trees that
                      were over 40 years old have been uprooted from the village.
                      Coconut farms and banana plantations have been completely
                      ruined. Several hundred farmers have lost their livelihood and
                      left in a completely hopeless situation. Hundreds of houses
                      have been severely damaged forcing many to take shelter in
                      nearby temples.
                    </p>
                    <div className="row mb-3">
                      <div className="col-6">
                        <img src={Project.cycloneBefore} alt="" />
                      </div>
                      <div className="col-6">
                        <img src={Project.cycloneAfter} alt="" />
                      </div>
                    </div>

                  </div>
                  <div>
                    <p>
                      The electricity infrastructure have been seriously damaged and
                      likely to require several weeks of sustained efforts before
                      power can be restored in the village. This has seriously
                      impacted water sources and communication channels. Schools are
                      badly damaged and needs significant work before kids could go
                      back to school.
                    </p>
                    <blockquote className="block-quote style-1 mt-3">
                      <p>
                        On behalf of the affected people of Ambalapattu, San Antonio
                        Tamil Sangam would like to request and appeal to you to
                        contribute generously for this noble cause. Your
                        contribution will make an impact, whether you donate $5 or
                        $500. Every little bit helps. Thank you for your support.
                      </p>

                      <cite> San Antonio Tamil Sangam </cite>
                    </blockquote>
                  </div>
                  <p>
                    To donate for "Cyclone Gaja - Ambalapattu Village Project -
                    Donation" please use this link -
                    <a
                      href="https://www.satamilsangam.org/donate-payment"
                      target="_blank"
                      rel="noreferrer"
                    >
                      click here
                    </a>
                    . If writing a check for your donation, make your check
                    payable to "SA Tamil Sangam", in the memo section mention
                    "Cyclone Gaja - Ambalapattu Village Project"
                  </p>
                  <ul className="mb-0 pb-0">
                    <li>
                      <ul className="m-0 p-0" type="none">
                        <li>Treasurer</li>
                        <li>
                          <p> <span className="fw-bold p-0 m-0">Mrs. Ramani Raghunath,</span> <br />
                            7334 Eagle Ledge, San Antonio, TX 78249.<br />
                            sats@satamilsangam.org<br />
                            Name of the Organization: SA Tamil Sangam.<br />
                            EIN # 81-0915793<br />
                          </p>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Fund raised here will be spent to meet the immediate needs
                      of the village including buying power generators, machines
                      to cut and clear trees, restoration of power infrastructure,
                      school renovation, and life sustenance.
                    </li>
                    <li>
                      To know more about current situation about this project,
                      please reach out to Venkatesh (210) 913-8584 and Rajaguru
                      (210) 900-6618.
                    </li>
                    <li>
                      Few videos about current situation at Ambalapattu Village.
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/News18TamilNadu/videos/351836262232899/"
                        target="_blank"
                        rel="noreferrer"
                        className=""
                      >
                        https://www.facebook.com/News18TamilNadu/videos/351836262232899/
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtu.be/ZKNYokeDhRU
                    "
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://youtu.be/ZKNYokeDhRU
                      </a>
                    </li>
                  </ul>
                  <h5
                    className="d-inline-block mt-3"
                    style={{ borderBottom: "2px solid black" }}
                  >
                    Thank You So Much For Your Generous Donation.
                  </h5>
                  <Table bordered hover responsive size="sm">
                    <thead className="text-center ">
                      <tr>
                        <th className="text-dark fw-bold">S.NO</th>
                        <th className="text-dark fw-bold">Full Name</th>
                        <th className="text-dark fw-bold">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cyclonedonate.map((item, ind) => (
                        <tr className="table-success" key={ind}>
                          <td>{item.no}</td>
                          <td>{item.name}</td>
                          <td>{item.Amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>


          </div>
        </section>


      </div>
    </>
  )
}

export default Cyclone
