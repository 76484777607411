import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AiTwotoneHome, AiFillMail, AiFillPhone } from "react-icons/ai"
import { TbWorldSearch } from "react-icons/tb"
import { SlOrganization, SlCalender } from "react-icons/sl"
import { BiSolidEditLocation, BiSolidBookAlt } from "react-icons/bi"
import { FaMoneyCheckAlt } from "react-icons/fa"
import { AiOutlineFieldNumber } from "react-icons/ai"
import { Table } from "reactstrap"
import president from "../../assets/images/TheSigningOfficers/selvagiri.jpg"
import PageBanner from "../../layouts/PageBanner"
import expl from "../../assets/images/SATS_KIDS_EVENT_V1.6.jpg"
import bg from "../../assets/images/banner/bnr5.jpg"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import { useDispatch, useSelector } from "react-redux"
import { fetchSelectedEvent } from "../../features/Event/EventTBSlice"
import {
  Event_Cover_Image_Api,
  ImageApi,
  phoneNumber,
} from "../../helpers/Api_help"
import moment from "moment"

import BlogDetailsLeftBar from '../../components/BlogDetailsLeftBar';

const Details = () => {
  const conatctblog = [
    { title: "San Antonio Tamil Sangam", image: <AiTwotoneHome /> },
    { title: "sats@satamilsangam.org", image: <AiFillMail /> },
    { title: "(210) 904-5052", image: <AiFillPhone /> },
    { title: "http://www.satamilsangam.org", image: <TbWorldSearch /> },
  ]
  const _id = localStorage.getItem("SelectEvent")
  const dispatch = useDispatch()
  const { SelectedEventData, TBisSuccess, TBisfail } = useSelector(
    (state) => state.EventTBReducer
  )

  useEffect(() => {
    console.log("comes in useeffect");
    dispatch(fetchSelectedEvent({ _id }))
  }, [])

  return (

    <div className="page-content bg-white">
      <PageBanner
          maintitle="Home"
          pagetitle="Event Information"
          bgColor={"red"}
        />
      <section className="content-inner-2" style={{paddingTop:30 }}>
        <div className="container">
          <div className="row ">
            <div className="col-xl-12 col-lg-12 m-b30">
              {SelectedEventData && <BlogDetailsLeftBar
                SelectedEventData={SelectedEventData}
              />}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Details
