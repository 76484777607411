import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Dropdown } from "react-bootstrap"
import moment from "moment"
import PageBanner from "../layouts/PageBanner"
import GalleryMission from "../components/GalleryMission"
import UpdateBlog from "../components/Home/UpdateBlog"
import { useDispatch, useSelector } from "react-redux"
import bg from "../assets/images/banner/bnr5.jpg"
import { fetchGalleryyear } from "../features/FetchData"
const Gallery = () => {
  const dispatch = useDispatch()
  const { Galleryyear, Success, fail } = useSelector(
    (state) => state.FetchDataReducer
  )
  const [searchQuery, setSearchQuery] = useState("")
  const currentyear = moment().format("YYYY")
  const [dropbtn2, setDropbtn2] = useState(currentyear)

  console.log(Galleryyear)
  const year = Galleryyear.map((it) => it._id)
  const yearlist = [...new Set(year.sort().reverse())]
  useEffect(() => {
    dispatch(fetchGalleryyear())
  }, [])

  const handleSearchChange = (event) => {
    const { value } = event.target
    setSearchQuery(value)
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Gallery" pagetitle="Gallery" background={bg} />
        <div className="find-bx-wrapper mt-4">
          <div className="container">
            <div className="find-bx bg-white">
              <form>
                <div className="row align-items-center">
                  <div className="col-lg-3 col-md-4">
                    <div className="">
                      <Dropdown className="select-drop-2">
                        <Dropdown.Toggle
                          as="div"
                          className="i-false select-drop-btn-2"
                        >
                          <span>{dropbtn2}</span>
                          <i className="fa-regular fa-angle-down"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {yearlist.map((item, ind) => (
                            <Dropdown.Item
                              onClick={() => setDropbtn2(item)}
                              key={ind}
                            >
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Looking For Specific Event Gallery? Find Here!"
                        value={searchQuery}
                        onChange={handleSearchChange}
                      />
                      <div className="input-group-prepend">
                        <button className="btn">
                          <i className="las la-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <section className="content-inner-2">
          <div className="container">
            <GalleryMission year={dropbtn2} searchQuery={searchQuery} />
          </div>
        </section>
        {/* <div className="call-action style-1 content-inner-1">
          <div className="container">
            <UpdateBlog />
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Gallery
