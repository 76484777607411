import React from "react"
import { Link } from "react-router-dom"
import { IMAGES } from "../constant/theme"
import { phoneNumber } from "../helpers/Api_help"

// import { IMAGES } from "../constant/theme"

const FooterCommonData = ({ logoImage, iconStyle }) => {
  return (
    <>
      {/* <div className="col-xl-2 col-lg-4 col-md-3 col-6"> */}
      <div className="col-xl-4 col-lg-4 col-md-3 col-6">
        <div className="widget widget_services">
          <h5 className="footer-title">Resources</h5>
          <ul>
            <li>
              <Link to={"/about-us"}>About Us</Link>
            </li>
            <li>
              <Link to={"/malligaimalar"}>SATS Mallaigai Malar</Link>
            </li>
            <li>
              <Link to={"/pastEvent"}>Event Media Links</Link>
            </li>
            <li>
              <Link to={"/gallery"}>Gallery</Link>
            </li>
            <li>
              <Link to={"/contact-us"}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="col-xl-2 col-lg-4 col-md-3 col-6"> */}
      <div className="col-xl-4 col-lg-4 col-md-3 col-6">
        <div className="widget widget_services">
          <h5 className="footer-title">To Become</h5>
          <ul>
            <li>
              {/* <Link to={"/about-us"}>Team</Link> */}
              <Link to={"/currentcommittee"}>Team</Link>
            </li>
            <li>
              {/* <Link to={"/lifeMemberShip"}>Life Members</Link> */}
              <Link to={"/lifeMemberShip"}>Lifetime Members</Link>
            </li>
            <li>
              {/* <Link to={"/membership"}>New To SA</Link> */}
              <Link to={"/newtosa"}>New to SA</Link>
            </li>
            <li>
              <Link to={"https://www.amazon.com/?redirectFromSmile=1"}>
                <img src={IMAGES.Amozan} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="col-xl-4 col-lg-4 col-md-6">
        <div className="widget widget_getintuch">
          <h5 className="footer-title">Get in Touch with Us</h5>
          <ul>
            <li>
              {iconStyle ? (
                <i className="fas fa-map-marker-alt "></i>
              ) : (
                <i className="fas fa-map-marker-alt"></i>
              )}
              <span>25647 Nabby Cove Rd, San Antonio,Texas USA - 78255</span>
            </li>
            <li>
              {iconStyle ? (
                <i className="fa-solid fa-phone "></i>
              ) : (
                <i className="fa-solid fa-phone"></i>
              )}
              <span>{phoneNumber}</span>
            </li>
            <li>
              {iconStyle ? (
                <i className="fa fa-envelope "></i>
              ) : (
                <i className="fa fa-envelope"></i>
              )}
              <span>sats@satamilsangam.org</span>
            </li>
          </ul>
        </div>
      </div> */}
      {/* <div className="col-xl-4 col-lg-12 col-md-12"> */}
      <div className="col-xl-4 col-lg-12 col-md-12">
        <div className="widget widget_about">
          <div className="dz-topbar-inner d-flex justify-content-center align-items-center p-2">
            <img
              src={logoImage}
              alt=""
              style={{ width: "50%", paddingBottom: "20px" }}
            />
          </div>
          <div className="dz-social-icon style-3 d-flex justify-content-center align-items-center">
            <ul>
              <li>
                <a
                  target="_blank"
                  className="fab fa-facebook-f"
                  rel="noreferrer"
                  href="https://www.facebook.com/groups/412326935471793/"
                ></a>
              </li>{" "}
              <li>
                <a
                  target="_blank"
                  className="fab fa-instagram"
                  rel="noreferrer"
                  href="https://www.instagram.com/satamilsangam/"
                ></a>
              </li>{" "}
              <li>
                <a
                  target="_blank"
                  className="fab"
                  rel="noreferrer"
                  href="https://twitter.com/satamilsangam"
                >
                  <svg style={{ height: 17, verticalAlign: "text-top" }} viewBox="0 0 24 24" aria-label="X" role="img"><g><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" fill="currentColor"></path></g></svg>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="fab fa-google-plus"
                  rel="noreferrer"
                  href="https://plus.google.com/109637063396653454683/posts"
                ></a>
              </li>
              <li>
                <a
                  target="_blank"
                  className="fab fa-youtube"
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCZvOnSZQRdqcV-Tg5JE9_6A"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterCommonData
