import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { ApiServer } from "../../helpers/Api_help"

const HoldSeatApi = `${ApiServer}manage-venue-seats/hold-seats`
const BookSeatApi = `${ApiServer}manage-venue-seats/book-seats`
const CancelSeatApi = `${ApiServer}manage-venue-seats/cancel-seats`
const TempBookingCreateApi = `${ApiServer}manage-booking-user/create-booking-user`
const initialStateTB = {
  success: false,
  fail: false,
  TempBookingSuccess: false,
  TempBookingFail: false,
  TempBookindDetails: null,
}

const TicketBooking = createSlice({
  name: "SeatSlice",
  initialState: initialStateTB,
  reducers: {
    SetDataSuccess: (state, action) => {
      state.success = true
    },
    SetTempBookingdata: (state, action) => {
      state.TempBookingSuccess = true
      state.TempBookindDetails = action.payload
    },
    SetTempBookingAlreadyExists: (state, action) => {
      state.TempBookingFail = true
      state.TempBookindDetails = action.payload
    },
    SetDataFail: (state, action) => {
      state.fail = true
    },
    Reset: (state, action) => {
      state.success = false
      state.fail = false
      state.TempBookingFail = false
      state.TempBookingSuccess = false
    },
  },
})

export const HoldSeat = (data) => {
  return async (dispatch) => {
    dispatch(Reset())
    try {
      let responseData = await axios.post(HoldSeatApi, { seatIds: data })
      console.log(responseData.data.response_code)
      if (responseData.data.response_code === 200) {
        console.log("on booking", responseData)
        dispatch(SetDataSuccess())
      }
    } catch (error) {
      console.log("error on booking", error.message)
    }
  }
}

export const BookSeat = (data) => {
  return async (dispatch) => {
    dispatch(Reset())
    try {
      let responseData = await axios.post(BookSeatApi, { seatIds: data })
      console.log(responseData.data.response_code)
      if (responseData.data.response_code === 200) {
        console.log("on booking", responseData)
        dispatch(SetDataSuccess())
      }
    } catch (error) {
      console.log("error on booking", error.message)
    }
  }
}

export const CancelSeat = (data) => {
  return async (dispatch) => {
    dispatch(Reset())
    try {
      let responseData = await axios.post(CancelSeatApi, { seatIds: data })
      console.log(responseData.data.response_code)
      if (responseData.data.response_code === 200) {
        console.log("on booking", responseData)
        dispatch(SetDataSuccess())
      }
    } catch (error) {
      console.log("error on booking", error.message)
    }
  }
}

export const TempBookingCreate = (data) => {
  return async (dispatch) => {
    dispatch(Reset())
    try {
      let responseData = await axios.post(TempBookingCreateApi, data)
      console.log("response --> ", responseData)
      if (responseData.data.response_code === 200) {
        console.log("on booking", responseData.data.data)
        dispatch(SetTempBookingdata(responseData?.data?.data))
      }
    } catch (error) {
      console.log("error on booking", error.message)

      SetTempBookingAlreadyExists(error.message)
    }
  }
}

export const {
  Reset,
  SetDataSuccess,
  SetTempBookingdata,
  SetTempBookingAlreadyExists,
} = TicketBooking.actions
export default TicketBooking.reducer
