import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Modal } from "react-bootstrap"

import PageBanner from "../../layouts/PageBanner"
import { fetchPastEvents } from "../../features/Event/EventTBSlice"
import { useDispatch, useSelector } from "react-redux"
import ApiServer from "../../helpers/Api_help"
import bg from "../../assets/images/banner/bnr5.jpg"
import eventImage from "../../assets/images/event.jpg"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import moment from "moment"

//components
function LeftImage({ data, SelectEvent }) {
  return (
    <div className="col-lg-10  m-b50">
      <div className="testimonial-3 right ">
        <div className="testimonial-content ">
          <h5 className="testimonial-title">{data.name}</h5>
          <p className="testimonial-text">
            <ul>
              <li>{data.event_type}</li>
              <li>{data.event_shortdesc}</li>
              <li
                className="btn btn-warning btn-sm"
                onClick={(e) => SelectEvent(e, data._id)}
              >
                View More
              </li>
            </ul>
          </p>
          <div className="testimonial-info">
            <div className="quotes">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <div className="clearfix">
              <h5 className="testimonial-name">{data.event_place}</h5>
              <span className="testimonial-position">
                {moment(data.start_date).format("MMMM Do YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="testimonial-media">
          <img
            src={`${ApiServer}assets/event_cover/${data.image_name}`}
            alt="event"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = eventImage
            }}
            style={{ width: "100%", height: "300px", objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  )
}

function RightImage({ data, SelectEvent }) {
  return (
    <div className="col-lg-10 offset-lg-2 m-b50">
      <div className="testimonial-3 right">
        <div className="testimonial-content">
          <h5 className="testimonial-title">{data.name}</h5>
          <p className="testimonial-text">
            <ul>
              <li>{data.event_type}</li>
              <li>{data.event_shortdesc}</li>
              <li
                className="btn btn-warning btn-sm"
                onClick={(e) => SelectEvent(e, data._id)}
              >
                View More
              </li>
            </ul>
          </p>
          <div className="testimonial-info">
            <div className="quotes">
              <i className="fa-solid fa-quote-left"></i>
            </div>
            <div className="clearfix">
              <h5 className="testimonial-name">{data.event_place}</h5>
              <span className="testimonial-position">
                {moment(data.start_date).format("MMMM Do YYYY")}
              </span>
            </div>
          </div>
        </div>
        <div className="testimonial-media">
          <img
            src={`${ApiServer}assets/event_cover/${data.image_name}`}
            alt="event"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = eventImage
            }}
            style={{ width: "100%", height: "300px", objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  )
}

const PastEvents = () => {
  const { TBisSuccess, PastEventsData } = useSelector(
    (state) => state.EventTBReducer
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [paraModal, setParaModal] = useState(false)
  const [showScroll, setShowScroll] = useState(false)
  const [showScrollDown, setShowScrollDown] = useState(false)

  useEffect(() => {
    dispatch(fetchPastEvents())

    const handleScroll = () => {
      const scrollY = window.scrollY
      const maxScrollY = document.documentElement.scrollHeight - window.innerHeight

      if (scrollY > 300) {
        setShowScroll(true)
      } else {
        setShowScroll(false)
      }

      if (scrollY < maxScrollY - 300) {
        setShowScrollDown(true)
      } else {
        setShowScrollDown(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const hideModal = () => {
    setParaModal(false)
  }

  const SelectEvent = (e, data) => {
    e.preventDefault()
    localStorage.setItem("SelectEvent", data)
    navigate("/EventsDetails")
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const scrollToBottom = () => {
    window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" })
  }

  return (
    <>
      <div className="page-content bg-white ">
        <PageBanner maintitle="Event" pagetitle="Past Events" background={bg} />

        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row">
              {PastEventsData.map((item, index) => (
                <div key={index}>
                  {index % 2 === 0 ? (
                    <LeftImage data={item} SelectEvent={SelectEvent} />
                  ) : (
                    <RightImage data={item} SelectEvent={SelectEvent} />
                  )}
                </div>
              ))}
            </div>
          </div>
          <img src={shape1} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape3} className="shape-3 move-1" alt="shape" />
          <img src={shape5} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-5 rotating" alt="shape" />
          <img src={shape6} className="shape-6 rotating" alt="shape" />
        </section>

        {showScroll && (
          <button
            className="scroll-to-top"
            onClick={scrollToTop}
            style={{
              position: "fixed",
              right: "20px",
              bottom: "80px",
              padding: "10px",
              borderRadius: "50%",
              backgroundColor: "#f39c12",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
              width: "45px",
              height: "47px"
            }}
          >
            ↑
          </button>
        )}

        {showScrollDown && (
          <button
            className="scroll-to-bottom"
            onClick={scrollToBottom}
            style={{
              position: "fixed",
              right: "20px",
              bottom: "20px",
              padding: "10px",
              borderRadius: "50%",
              backgroundColor: "#f39c12",
              color: "#fff",
              border: "none",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 1000,
              width: "45px",
              height: "47px"
            }}
          >
            ↓
          </button>
        )}
      </div>

      <Modal
        className="modal fade modal-wrapper"
        id="read"
        show={paraModal}
        onHide={hideModal}
        centered
      >
        <div className="modal-body">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
        </div>
      </Modal>
    </>
  )
}

export default PastEvents







// import React, { useEffect, useState } from "react"
// import { Link, useNavigate } from "react-router-dom"
// import { Modal } from "react-bootstrap"

// import PageBanner from "../../layouts/PageBanner"
// import { fetchPastEvents } from "../../features/Event/EventTBSlice"
// import { useDispatch, useSelector } from "react-redux"
// import ApiServer from "../../helpers/Api_help"
// import bg from "../../assets/images/banner/bnr5.jpg"
// import eventImage from "../../assets/images/event.jpg"
// // import test1 from "../../assets/images/testimonials/large/pic1.jpg"
// // import test2 from "../../assets/images/testimonials/large/pic2.jpg"
// // import test3 from "../../assets/images/testimonials/large/pic3.jpg"
// // import test4 from "../../assets/images/testimonials/large/pic1.jpg"
// import shape1 from "../../assets/images/pattern/shape1.png"
// import shape2 from "../../assets/images/pattern/shape2.png"
// import shape3 from "../../assets/images/pattern/shape3.png"
// import shape5 from "../../assets/images/pattern/shape5.png"
// import shape6 from "../../assets/images/pattern/shape6.png"

// import moment from "moment"

// //components
// function LeftImage({ data, SelectEvent }) {
//   return (
//     <>
//       <div className="col-lg-10  m-b50">
//         <div className="testimonial-3 right ">
//           <div className="testimonial-content ">
//             <h5 className="testimonial-title">{data.name}</h5>
//             <p className="testimonial-text">
//               <ul>
//                 <li>{data.event_type}</li>
//                 <li>{data.event_shortdesc}</li>
//                 <li
//                   className="btn btn-warning btn-sm"
//                   onClick={(e) => SelectEvent(e, data._id)}
//                 >
//                   View More
//                 </li>
//               </ul>
//             </p>
//             <div className="testimonial-info">
//               <div className="quotes">
//                 <i className="fa-solid fa-quote-left"></i>
//               </div>
//               <div className="clearfix">
//                 <h5 className="testimonial-name">{data.event_place}</h5>
//                 <span className="testimonial-position">
//                   {moment(data.start_date).format("MMMM Do YYYY")}
//                 </span>
//               </div>
//             </div>
//           </div>
//           <div className="testimonial-media">
//             <img
//               src={`${ApiServer}assets/event_cover/${data.image_name}`}
//               alt="event"
//               onError={(e) => {
//                 e.target.onerror = null
//                 e.target.src = eventImage // Use fallback image
//               }}
//               style={{ width: "100%", height: "300px", objectFit: "contain" }} // Show full image
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// function RightImage({ data, SelectEvent }) {
//   return (
//     <>
//       <div className="col-lg-10 offset-lg-2 m-b50">
//         <div className="testimonial-3 right">
//           <div className="testimonial-content">
//             <h5 className="testimonial-title">{data.name}</h5>
//             <p className="testimonial-text">
//               <ul>
//                 <li>{data.event_type}</li>
//                 <li>{data.event_shortdesc}</li>
//                 <li
//                   className="btn btn-warning btn-sm"
//                   onClick={(e) => SelectEvent(e, data._id)}
//                 >
//                   View More
//                 </li>
//               </ul>
//             </p>
//             <div className="testimonial-info">
//               <div className="quotes">
//                 <i className="fa-solid fa-quote-left"></i>
//               </div>
//               <div className="clearfix">
//                 <h5 className="testimonial-name">{data.event_place}</h5>
//                 <span className="testimonial-position">
//                   {moment(data.start_date).format("MMMM Do YYYY")}
//                 </span>
//               </div>
//             </div>
//           </div>
//            <div className="testimonial-media">
//             <img
//               src={`${ApiServer}assets/event_cover/${data.image_name}`}
//               alt="event"
//               onError={(e) => {
//                 e.target.onerror = null
//                 e.target.src = eventImage // Use fallback image
//               }}
//               style={{ width: "100%", height: "300px", objectFit: "contain" }} // Show full image
//             />
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

// const PastEvents = () => {
//   const { TBisSuccess, PastEventsData } = useSelector(
//     (state) => state.EventTBReducer
//   )
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const [paraModal, setParaModal] = useState(false)

//   useEffect(() => {
//     dispatch(fetchPastEvents())
//   }, [])
//   const hideModal = () => {
//     setParaModal(false)
//   }
//   const SelectEvent = (e, data) => {
//     console.log("edit")
//     e.preventDefault()
//     localStorage.setItem("SelectEvent", data)
//     navigate("/EventsDetails")
//   }

//   return (
//     <>
//       <div className="page-content bg-white ">
//         <PageBanner maintitle="Event" pagetitle="Past Events" background={bg} />

//         <section className="content-inner bg-light section-pattren1">
//           <div className="container">
//             <div className="row">
//               {PastEventsData.map((item, index) => (
//                 <div key={index}>
//                   {index % 2 === 0 ? (
//                     <LeftImage data={item} SelectEvent={SelectEvent} />
//                   ) : (
//                     <RightImage data={item} SelectEvent={SelectEvent} />
//                   )}
//                 </div>
//               ))}
//             </div>
//           </div>
//           <img src={shape1} className="shape-1 move-1" alt="shape" />
//           <img src={shape2} className="shape-2 move-2" alt="shape" />
//           <img src={shape3} className="shape-3 move-1" alt="shape" />
//           <img src={shape5} className="shape-4 rotating" alt="shape" />
//           <img src={shape6} className="shape-5 rotating" alt="shape" />
//           <img src={shape6} className="shape-6 rotating" alt="shape" />
//         </section>
//       </div>
//       <Modal
//         className="modal fade modal-wrapper"
//         id="read"
//         show={paraModal}
//         onHide={hideModal}
//         centered
//       >
//         <div className="modal-body">
//           <p>
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry. Lorem Ipsum has been the industry's standard dummy text
//             ever since the 1500s, when an unknown printer took a galley of type
//             and scrambled it to make a type specimen book. It has survived not
//             only five centuries, but also the leap into electronic typesetting,
//             remaining essentially unchanged. It was popularised in the 1960s
//             with the release of Letraset sheets containing Lorem Ipsum passages,
//             and more recently with desktop publishing software like Aldus
//             PageMaker including versions of Lorem Ipsum.
//           </p>
//         </div>
//       </Modal>
//     </>
//   )
// }

// export default PastEvents
