import React from "react"
import { Link } from "react-router-dom"
import CountUp from "react-countup"

import slide from "./../assets/images/main-slider/pic1.png"
import { IMAGES } from "../constant/theme"

const OurMission = () => {
  return (
    <>
      <div className="col-lg-5">
        <div className="p-3 ">
          <img
            src={IMAGES.Mission2}
            alt="image"
            style={{
              alignSelf: "center",

              objectFit: "cover",
            }}
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 m-b50">
        <div className="section-head">
          <h4 className="sub-title">Our Mission</h4>
          <div id="content1 text-center">
            <p className="mt-3">
              To foster fraternity among the Tamil Speaking community living in
              San Antonio to create and promote interest and knowledge of Tamil
              language and culture in the community, by organizing educational,
              literary, cultural, social and charitable activities in a
              non-partisan, secular and non-political manner strictly conforming
              to the laws of the country.
            </p>
            {/* <p className="mt-3">
              <span className="fw-bolder text-dark text-justify">
                Provide a unified forum for Tamils in this area:
              </span>{" "}
              SATS aims to provide a platform to facilitate interaction among
              the area tamil people.
            </p>
            <p>
              <span className="fw-bolder text-dark text-justify">
                Promote Tamil culture{" "}
              </span>
              :{" "}
              <span className="text-primary">
                ‘கல் தோன்றி மண் தோன்றாக் காலத்தே வாளோடு முன் தோன்றிய மூத்தக்
                குடி தமிழ் குடி’.
              </span>{" "}
              Tamil culture is unique in many respects. SATS strives to promote
              and preserve Tamil culture
            </p>
            <p>
              <span className="fw-bolder text-dark text-justify">
                Celebrate Tamil festivals:
              </span>{" "}
              To celebrate festivals such as Pongal, Tamil New Year and
              Deepavali every year.
            </p>
            <p>
              <span className="fw-bolder text-dark text-justify">
                Organize special projects in Tamil Nadu:
              </span>{" "}
              For the development of Tamil Nadu, several SATS members are
              involved in various projects such as College Scholarship fund and
              Charity clubs.
            </p>
            <p>
              <span className="fw-bolder text-dark text-justify">
                Promote Tamil scholars and artists:
              </span>{" "}
              To encourage Tamil Scholars and artists from Tamil Nadu to
              contribute for the growth of Tamil language.
            </p>
            <p>
              <span className="fw-bolder text-dark text-justify">
                Help other Tamils:
              </span>{" "}
              To help other Tamils in San Antonio and surrounding areas feel at
              home.
            </p>
            <p>
              <span className="fw-bolder text-dark text-justify">
                Actively participate in Federation of Tamil Associations of
                North America (FeTNA):
              </span>
              To foster unity among different tamilsangams and to support FeTNA,
              SATS actively participates in the annual FeTNA conference.
            </p>
            <p>
              <span className="fw-bolder text-dark text-justify">
                Make Tamil youth aware of our heritage :
              </span>{" "}
              To educate and encourage Tamil youth to learn, appreciate, and
              abide by the Tamil culture
            </p> */}
          </div>
        </div>
        {/* <div className="row col-lg-12">
          {CounterBlog.map((item, index) => (
            <div className="col-lg-3 col-sm-4 col-6 m-b30" key={index}>
              <h2>
                $
                <span className="counter">
                  <CountUp end={item.number} separator="," duration="3" />
                </span>
              </h2>
              <span className="counter-text">{item.title}</span>
            </div>
          ))}
        </div> */}
        <Link to={"/cyclone"} className="m-r30 m-sm-r10 btn btn-secondary">
          See Projects <i className="flaticon-right-arrows ps-3"></i>
        </Link>
        {/* <Link to={"/how-it-works"} className="btn btn-outline-dark">
          How It Works
        </Link> */}
      </div>
    </>
  )
}
export default OurMission
