import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { store } from "../src/store/store"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "react-toastify/dist/ReactToastify.css"
import ThemeContext from "./context/ThemeContext"
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <ThemeContext>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeContext>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
