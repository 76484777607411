import React from "react"
import { Link } from "react-router-dom"
import imgMalar from "../../assets/images/MalligaiMalar/malarcoverpage.png"
import PageBanner from "../../layouts/PageBanner"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Navigation } from "swiper"
import "swiper/swiper.min.css"
import { MdOutlineAdsClick } from "react-icons/md"
import { BsFilePdfFill } from "react-icons/bs"
import { Card, Row, Col } from "react-bootstrap"

const FetnaMalar = () => {
  const malar = [
    // {
    //   title: "Volume 6 - Issue 3:Dec, 2023",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/uplift/1404096#/?publicationId=1404096",
    //   pdflink:
    //     "https://api.satamilsangam.org/uploads/PDF/sats_maligai_malar_dec_2023.pdf",
    //   iframesrc:
    //     "https://issuu.com/satsemalar/docs/san_antonio_tamil_sangam?fr=xKAE9_zU1NQ",
    // },
    // {
    //   title: "Volume 6 - Issue 2:Aug, 2023",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/uplift/1319326#/?publicationId=1319326",
    //   pdflink:
    //     "https://satamilsangam.org/documents/SATS_MALIGAI_MALAR_AUGUST_2023.pdf",
    //   iframesrc:
    //     "https://issuu.com/satsemalar/docs/sats_maligai_malar_august_2023_5b5a02b2ca594e?fr=xKAE9_zU1NQ",
    // },
    // {
    //   title: "Volume 6 - Issue 1:Apr, 2023",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/uplift/1261052#/?publicationId=1261052",
    //   pdflink:
    //     "https://satamilsangam.org/documents/SATS_MALIGAI_MALAR_APR_2023.pdf",
    //   iframesrc:
    //     "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_maligai_malar_apr_2023",
    // },
    // {
    //   title: "Volume 5 - Issue 3:Dec, 2022",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/uplift/1217025#/?publicationId=1217025",
    //   pdflink:
    //     "https://satamilsangam.org/documents/SATS_MALIGAI_MALAR_DEC_2022.pdf",
    //   iframesrc:
    //     "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_maligai_malar_dec_2022",
    // },
    // {
    //   title: "Volume 5 - Issue 2:Aug, 2022",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/uplift/1160208#/?publicationId=1160208",
    //   pdflink:
    //     "https://satamilsangam.org/documents/SATS_MALIGAI_MALAR_AUGUST_2022.pdf",
    //   iframesrc:
    //     "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_maligai_malar_august_2022",
    // },
    // {
    //   title: "Volume 4 - Issue 1:Apr, 2022",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/uplift/1115650#?publicationId=1115650",
    //   pdflink:
    //     "https://satamilsangam.org/documents/sats_malligaimalar_apr_2022.pdf",
    //   iframesrc:
    //     "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_malligaimalar_apr_2022",
    // },
    // {
    //   title: "Volume 1 - Silver Jubilee Special Issue 1:NOV, 2021",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/uplift/1076390#/?publicationId=1076390",
    //   pdflink:
    //     "https://satamilsangam.org/documents/sats_silver_jubilee_malar_nov_2021.pdf",
    //   iframesrc:
    //     "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_silver_jubilee_malar_nov_2021",
    // },
    // {
    //   title: "Volume 3 - Issue 3:DEC, 2020",
    //   Clickhere:
    //     "https://www.peecho.com/checkout/154639354059119817/770268/sats-malligai-malar-dec-2020",
    //   pdflink:
    //     "https://satamilsangam.org/documents/SATS_Malligai_Malar_DEC_2020.pdf",
    //   iframesrc:
    //     "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=sats_malligai_malar_dec_2020",
    // },
    // {
    //   title: "Volume 3 - Issue 2:Aug, 2020",
    //   Clickhere: "https://www.peecho.com/checkout/154639354059119817/722709/",
    //   pdflink:
    //     "https://satamilsangam.org/documents/SATSMalligaiMalarAugust2020.pdf",
    //   iframesrc:
    //     "https://e.issuu.com/anonymous-embed.html?u=satsemalar&d=malligai_malar_final_15.08.2020",
    // },
  ]

  const cardiconBlog = [
    { title: "Technology", icon: "flaticon-vr-glasses" },
    { title: "Education", icon: "flaticon-open-book" },
    { title: "Videos", icon: "flaticon-video" },
    { title: "Medical", icon: "flaticon-doctor-bag" },
    { title: "Love", icon: "flaticon-like-1" },
    { title: "Design", icon: "flaticon-transformation" },
    { title: "Health", icon: "flaticon-raw-food" },
    { title: "Sports", icon: "flaticon-coins-1" },
    { title: "Care", icon: "flaticon-responsibility" },
    { title: "Support", icon: "flaticon-handshake" },
    { title: "Fashion", icon: "flaticon-coin-stack" },
    { title: "Events", icon: "flaticon-calendar" },
  ]
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="FeTNA Malar" />
        <section className="content-inner container " style={{paddingTop:40}}>
          {/* <img src={imgMalar} width={"100%"} height={"400px"}></img> */}
          <div className="container ">
            <div className="seaction_head">
              <h2 className="text-primary mb-4">பேரவையின் தமிழ் விழா 2024 மலருக்கான படைப்புகள் வரவேற்கப்படுகின்றன</h2>
              <p style={{ fontSize: 20, lineHeight: 1.3 }}>வரும் சூலைத் திங்கள் டெக்சாஸ் சான் ஆண்டோனியோவில் நடைபெறவிருக்கும் நமது பேரவையின் 37-வது தமிழ் விழாவையொட்டி சிறப்பு மலர் ஒன்று வெளியிடப்பட உள்ளது. இம்மலரில் வெளியிட கீழ்கண்ட பெரும் பிரிவுகள் / தலைப்புகளில் கட்டுரை, கதை, கவிதை போன்ற படைப்புகள் வரவேற்கப்படுகின்றன. மலர்க் குழுவின் பரிசீலனைக்கு பிறகு தேர்வாகும் படைப்புகள் விழா மலரில் வெளியிடப்படும்.
              </p>

              <ol style={{ listStyle: "none", padding: 0, margin: 0 }}>
                <li>
                  <div className="mb-3"><span style={{ fontWeight: "bold" }}>1. பேரவையின் 37-வது தமிழ் விழாவின் கருப்பொருள் : சமத்துவம் தமிழரின் தனித்துவம்</span></div>
                </li>
                <li style={{ fontWeight: "bold" }} className="mb-3">2. 2024-ஆம் ஆண்டு விழாவின் சிறப்பியல் :
                  •நூற்றாண்டு காணும் முத்தமிழறிஞர் கலைஞர் கருணாநிதி மற்றும் முதுபெரும் தமிழறிஞர் பேராசிரியர் மா. நன்னன்
                </li>
                <li className="mb-3"><span style={{ fontWeight: "bold" }} >3. கீழ்க்கண்ட தமிழ்ச் சான்றோர்கள் பற்றிய கட்டுரை:</span>
                  <ul style={{ paddingLeft: 20 }}>
                    <li>திருப்பூர் குமரன் (120-ஆம் நினைவு ஆண்டு)
                    </li>
                    <li>கல்வித் தந்தை காமராசர் (120-வது பிறந்த நாள்)
                    </li>
                    <li>பாவேந்தர் பாரதிதாசன் (133-வது பிறந்த நாள்)
                    </li>
                    <li>தவத்திரு குன்றக்குடி அடிகளார்  (99-ஆம் பிறந்த நாள்)
                    </li>
                    <li>ஜி. டி. நாயுடு  (130-வது பிறந்த நாள்)
                      •தில்லையாடி வள்ளியம்மை  (126-வது பிறந்த நாள்)</li>
                  </ul>
                </li>
                <li className="mb-3"><span style={{ fontWeight: "bold" }} >4. மற்றும் </span>
                  <ul style={{ paddingLeft: 20 }}>
                    <li>•சூலை 2023 முதல் நடந்த தமிழ், மற்றும் தமிழர்கள் சார்ந்த முக்கிய நிகழ்வுகள்</li>
                    <li>ஆராய்ச்சி்/ஆய்வுக் கட்டு்ரைகள்</li>
                    <li>ஈழப் படைப்புகள்</li>
                    <li>தமிழிசை மற்றும் தமிழ் திரைத்துறை சார்ந்த கட்டு்ரைகள்
                    </li>
                    <br />

                  </ul>
                </li>
                <li className="mb-3"><span style={{ fontWeight: "bold" }} >மலர்க்  குழுவின் படைப்புகளுக்கான பரிந்துரைகள்:</span>
                  <ul style={{ paddingLeft: 20 }}>
                    <li>1-மொழி சமத்துவம் - தமிழில் மொழிமாற்றம் பெற்ற இலக்கியங்கள்.</li>
                    <li>2-இந்திய விடுதலைப் போராட்ட காலத்தில் தமிழக சமூகத்தின் சமத்துவ நிலை</li>
                    <li>3-சமத்துவ கொள்கைகள் - மறு ஆய்வின் அவசியம்</li>
                    <li>4-யாதும் ஊரே யாவரும் கேளிர்</li>
                    <li>5-கல்வியில் சமத்துவம்</li>
                    <li>6-தமிழ் சமூகத்தில் ஆண் பெண் சமத்துவம் - சங்ககாலம் முதல் -நிகழ்காலம் வரை</li>
                    <li>7-குறள் கூறும் சமத்துவம்</li>
                    <li>8-சமத்துவத்தை வளர்த்த சான்றோர்கள்</li>
                    <li>9-சமத்துவபுரம் - உழவர் சந்தைகளில் சமத்துவம்</li>
                    <li>10-ஆண் பெண் பொருளாதார சமத்துவம்</li>
                    <li>11-சமத்துவ கொள்கைகள் தனித்துவத்தை பாதிக்கிறதா?</li>
                    <li>12-சாதி மத இன சமத்துவம் - அமெரிக்காவின் நிலைப்பாடும், தமிழரின் நிலைப்பாடும்</li>
                    <li>13-பெட்டிச் செய்திகள்: நகைச்சுவை துணுக்குகள் - (வீட்டு வேலையில் சமத்துவம், கலப்புத் திருமண கலாட்டக்கள், தினசரி வாழ்க்கையில் சமத்துவம் எப்படி உள்ளது?)</li>
                    <br />
                  </ul>
                </li>
                <li className="mb-3"><span style={{ fontWeight: "bold" }} >படைப்புகளுக்கான விதிமுறைகள்: </span>
                  <ul  style={{ paddingLeft: 20 }}>
                    <li>1-அனைத்து  படைப்புகளும் Fetna 2024 இதழுக்காக படைக்கப்பட்டதாகவும்,  வேறு எங்கும் வெளியிடப்  படாததாகவும் இருத்தல் வேண்டும்.  படைப்புகள் முடிந்த அளவு பிற மொழி சொற்கள் கலக்காமல், தமிழில் இருத்தல்  வேண்டும். </li>
                    <li>2-படைப்புகளில் அரசியல், மதம், தனிநபர் விமரிசனங்களை தவிர்த்தல் அவசியம். </li>
                    <li>3-படைப்புகள் ஒருங்குறி எழுத்துருவிலும் (Unicode), எழுத்துரு அளவு (font size) 12- லும் இருத்தல் வேண்டும்.</li>
                    <li>4-படைப்புகள் மூன்று பக்கங்களுக்கு மிகாமல் இருத்தல் வேண்டும். </li>
                    <li>5-படைப்புகள் MS-Word Format - இல் இருத்தல் அவசியம். PDF கள் ஏற்றுக்கொள்ளப்பட மாட்டாது. </li>
                    <li>6-படைப்புகளை அனுப்ப வேண்டிய மின்னஞ்சல் முகவரி: malar2024@fetna.org </li>
                    <li>7-படைப்புகளை அனுப்ப வேண்டிய கடைசி நாள்: மார்ச் -1, 2024</li>
                  </ul>
                </li>
                <li className="mb-3"><span style={{ fontWeight: "bold", }} >படைப்புகளுடன் கீழ்க்கண்ட விவரங்களை அனுப்பி வைக்கவும்:</span>
                  <ul  style={{ paddingLeft: 20,  }} >
                    <li>தமிழில் தங்களின் முழுப் பெயர் </li>
                    <li>மின்னஞ்சல் முகவரி</li>
                    <li>தொலைபேசி எண்</li>
                    <li>நாட்டின் பெயர் </li>
                    <li>தங்களின் பாஸ்போர்ட் அளவிலான வெற்று பின்னணியுடன் கூடிய வண்ணப் புகைப்படம்</li>
                  </ul>
                </li>
              </ol>


            </div>
            <div className="content-inner-2" style={{ paddingTop: 30 }}>
              <div className="container">
                <div className="row">
                  {malar.map((item, ind) => (
                    <div
                      className="col-xl-4 col-lg-4 col-sm-12 border-bottom mt-1"
                      key={ind}
                    >
                      <div className="icon-bx-wraper style-1 m-b30 text-center">
                        <div className="icon-lg m-b30">
                          <Link
                            to={"/project-categories"}
                            className="icon-cell"
                          >
                            {/* <i className={item.icon}></i> */}
                            <li className="my-3 text-center">
                              <a className="bg-light" href="#!">
                                <iframe
                                  src={item.iframesrc}
                                  width="100%"
                                  height="250px"
                                  allowFullScreen={true}
                                ></iframe>
                              </a>
                            </li>
                          </Link>
                        </div>
                        <div className="icon-content">
                          <h6 className="dz-tilte text-capitalize">
                            <Link to={"/project-categories"}>{item.title}</Link>
                          </h6>
                          <ul className="">
                            <li>
                              To Print, Click here
                              <a
                                href={item.Clickhere}
                                target="_blank"
                                className="btn-sm btn-danger mx-1 text-light"
                              >
                                <MdOutlineAdsClick className="" />
                              </a>
                            </li>
                            <li>
                              PDF book version-click here-
                              <a
                                href={item.pdflink}
                                target="_blank"
                                className="btn-sm btn-primary mx-1 link-warning "
                              >
                                <BsFilePdfFill className="" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default FetnaMalar
