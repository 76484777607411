// import React, { useEffect } from "react"
// import { Link, useNavigate } from "react-router-dom"
// import { useSelector, useDispatch } from "react-redux"
// import PageBanner from "../../layouts/PageBanner"
// import { Accordion } from "react-bootstrap"
// import moment from "moment"
// import { fetchMywallet } from "../../features/FetchData"
// const Mywallet = () => {
//   const User_Id_ = JSON.parse(localStorage.getItem("user_id"))
//   const dispatch = useDispatch()
//   const nav = useNavigate()
//   const { MywalletInfo } = useSelector((state) => state.FetchDataReducer)
//   console.log(MywalletInfo)
//   useEffect(() => {
//     dispatch(fetchMywallet(User_Id_))
//   }, [])
//   return (
//     <>
//       <div className="page-content bg-white">
//         <PageBanner maintitle="Home" pagetitle="My Wallet" />

//         <section></section>

//         <section className=" container content-inner-2">
//           <Accordion
//             className="accordion dz-accordion accordion-sm"
//             id="accordionFaq1"
//           >
//             {MywalletInfo.map((item, ind) => (
//               <Accordion.Item className="accordion-item" eventKey={ind}>
//                 <Accordion.Header as="h2" id="headingOne1">
//                   <div className="col d-flex justify-content-between">
//                     <p className="m-0 pe-5">{item.event_name}</p>
//                     <p className="m-0 ps-5">
//                       Total Ticket price : {`$ ${item.net_amount}`}
//                     </p>
//                   </div>

//                   <span className="toggle-close"></span>
//                 </Accordion.Header>
//                 <div
//                   id="collapseOne1"
//                   className="accordion-collapse "
//                   eventKey={ind}
//                 >
//                   <Accordion.Body>
//                     <ul className="d-flex flex-wrap gap-5">
//                       <li>
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Name
//                         </span>
//                         <br />
//                         <span>
//                           {item.firstname} {item.lastname}
//                         </span>
//                       </li>
//                       <li>
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Venue Name
//                         </span>
//                         <br />
//                         <span>{item.venue_name}</span>
//                       </li>
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Adult Amount
//                         </span>
//                         <br />
//                         <span>{`$ ${item.adult_amount}`}</span>
//                       </li>
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Child Amount
//                         </span>
//                         <br />
//                         <span>{`$ ${item.child_amount}`}</span>
//                       </li>{" "}
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           No. of Adult
//                         </span>
//                         <br />
//                         <span>{item.adult_count}</span>
//                       </li>
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           No. of Children
//                         </span>
//                         <br />
//                         <span>{item.child_count}</span>
//                       </li>
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Event Date
//                         </span>
//                         <br />
//                         <span>
//                           {" "}
//                           {item?.event_date
//                             ? moment(item?.event_date).format("YYYY-MM-DD")
//                             : "NAN"}
//                         </span>
//                       </li>
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Venue Date
//                         </span>
//                         <br />
//                         <span>
//                           {" "}
//                           {item?.venue_date
//                             ? moment(item?.venue_date).format(
//                                 "YYYY-MM-DD hh:mm a"
//                               )
//                             : "NAN"}
//                         </span>
//                       </li>
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Venue Time
//                         </span>
//                         <br />
//                         <span>
//                           {" "}
//                           {item?.venue_date
//                             ? moment(item?.venue_date).format("hh:mm a")
//                             : "NAN"}
//                         </span>
//                       </li>
//                       <li style={{ lineHeight: 1.3 }} className="my-2">
//                         <span style={{ fontWeight: "bold", fontSize: 14 }}>
//                           Payment Date
//                         </span>
//                         <br />
//                         <span>
//                           {moment(item?.createdAt).format("YYYY-MM-DD hh:mm a")}
//                         </span>
//                       </li>
//                     </ul>
//                   </Accordion.Body>
//                 </div>
//               </Accordion.Item>
//             ))}
//           </Accordion>
//         </section>
//       </div>
//     </>
//   )
// }

// export default Mywallet

import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import PageBanner from "../../layouts/PageBanner"
import "./evehome.css"

import { Accordion } from "react-bootstrap"
import moment from "moment"
import { fetchMywallet } from "../../features/FetchData"
import { ApiServer } from "./../../helpers/Api_help";
const Mywallet = () => {
  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  const dispatch = useDispatch()
  const nav = useNavigate()
  const { MywalletInfo } = useSelector((state) => state.FetchDataReducer)
  console.log(MywalletInfo)
  useEffect(() => {
    dispatch(fetchMywallet(userInfo))
  }, [])
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="My Wallet" />
        {MywalletInfo?.length > 0 ? (
          <section
            className="container content-inner-2"
            style={{ paddingBottom: 70 }}
          >
            <div className="px-3">
              {MywalletInfo?.map((item, ind) => (
                <div
                  className="row border-1 border-warning p-3 mb-3 " //bg-light
                  style={{ borderRadius: 10 }}
                >
                  <div
                    className="col-lg-5 border-warning"
                    // style={{
                    //   borderRight: "1px solid",
                    // }}
                  >
                    <div className="row mt-3">
                      <div className="col-4">
                        <div style={{ lineHeight: 0.6 }}>
                          <span className="text-dark " style={{ fontSize: 15 }}>
                            Adult {item.adult_count}
                          </span>
                        </div>
                        <div>
                          <span className="text-dark " style={{ fontSize: 15 }}>
                            Child {item.child_count}
                          </span>
                        </div>
                      </div>
                      <div className="col-8">
                        <div style={{ lineHeight: 0.6 }}>
                          <span className="text-dark " style={{ fontSize: 15 }}>
                            {item.firstname} {item.lastname}
                          </span>
                        </div>
                        <div>
                          <span className="text-dark " style={{ fontSize: 15 }}>
                            {item.email}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="mb-3 col-12">
                        <div className="d-flex flex-row">
                          <div className="col-auto py-2 me-5">
                            <div>
                              <span
                                className="text-dark"
                                style={{ fontSize: 20, lineHeight: 1 }}
                              >
                                Total Amount:{" "}
                              </span>
                              <br />
                              <span
                                className="text-primary fw-bold"
                                style={{ fontSize: 30, lineHeight: 1 }}
                              >{`$ ${item.net_amount}`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex flex-row">
                        <div className="col-auto py-1 me-5">
                          {item?.transaction_id && (
                            <div>
                              <span
                                className="text-dark"
                                style={{ fontSize: 13, lineHeight: 1 }}
                              >
                                Transaction ID:{" "}
                              </span>

                              <span
                                className="text-primary fw-bold"
                                style={{ fontSize: 13, lineHeight: 1 }}
                              >
                                {item.transaction_id}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`col-lg-5 border-warning border-setting align-items-center`}
                    // style={{
                    //   borderTop: "1px solid",
                    // }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center mt-3">
                      <h2
                        to={"#"}
                        className="fw-bold text-dark border-bottom border-light"
                        // style={{ fontSize: 30 }}
                      >
                        {item.event_name}
                      </h2>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center mt-2">
                      <div>
                        <p
                          className="text-dark fw-bold m-0 para"
                          // style={{ fontSize: 25, lineHeight: 0.7 }}
                        >
                          {item.venue_name}
                        </p>
                      </div>
                      <div>
                        <p
                          className="text-dark m-0 para"
                          // style={{ fontSize: 20, lineHeight: 0.7 }}
                        >
                          {moment.parseZone(item.event_date).format("LLLL")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={`col-lg-2 border-warning border-setting align-items-center`}>
                    <img src={`${ApiServer}${item.barcode}`} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </section>
        ) : (
          <div className="coming-soon background-luminosity" data-text="Wallet">
            <div className="inner-content text-justify text-center">
              <h4 className="dz-head">Your wallet is empty.</h4>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Mywallet
