import React from "react"
import { Link } from "react-router-dom"

import PageBanner from "../../layouts/PageBanner"
import bg from "../../assets/images/banner/bnr4.jpg"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import Bylawspdf from "../../assets/pdf/bylaws.pdf"
import Bylawimg from "../../assets/images/by-law.jpg"
const ByLaws = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="ByLaws" />
        <section className="content-inner-2 bg-light section-pattren1 d-flex flex-wrap justify-content-center">
          <div className="container col-12 d-flex justify-content-between flex-wrap text-dark">
            <div className="col-lg-6 col-md-12 project-bx">
              <div>
                <h1 className="h1 fw-bold text-primary sub-title">
                  <span
                    style={{ fontSize: 25, color: "#fea400", fontWeight: 600 }}
                  >
                    San Antonio Tamil Sangam
                  </span>{" "}
                  <br /> Constitution and By-laws
                </h1>
                <p className="mt-3">SATS Announcement:</p>
                <p>Vannakam..</p>
                <p>
                  We have updated our By-laws based on the GBM happened on
                  Saturday, February 3rd 2024 and agreed by all of us. Please
                  use this for your reference.
                </p>
                <p className="text-darks" style={{ lineHeight: 1.5 }}>
                  <span className="fw-bold">Dr. Yuvaraj Munian
                  </span>
                  <br />
                  Secretary
                  <br />
                  SA Tamil Sangam
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12  mb-5">
              <img src={Bylawimg} alt="" />
            </div>
          </div>
          <div className=" col-lg-8 col-md-12  mb-5 border-5 border-primary">
            <div className="col">
              <iframe src={Bylawspdf} width="100%" height="1000px"></iframe>
            </div>
            <div className="p-3">
              <span className="text-dark mt-3">
                Highlights of our Constitution and Bylaws :&nbsp;
                <a href={Bylawspdf} target="_blank" download>
                  Download pdf
                </a>{" "}
                <br />
                San Antonio Tamil Sangam(SATS)
              </span>
            </div>
          </div>
          <img src={shape2} className="shape-6 move-2" alt="shape" />
          <img src={shape3} className="shape-5 move-1" alt="shape" />
          <img src={shape5} className="shape-1 rotating" alt="shape" />
          <img src={shape1} className="shape-3 move-1" alt="shape" />
          <img src={shape6} className="shape-4 rotating" alt="shape" />
          <img src={shape6} className="shape-2 rotating" alt="shape" />
        </section>
      </div>
    </>
  )
}

export default ByLaws
