import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import moment, { parseZone } from "moment"
import Countdown from "react-countdown"
import { IMAGES } from "../../constant/theme"
// import { Card } from 'react-bootstrap';
import ApiServer from "../../helpers/Api_help"
import { Card, CardBody, CardTitle } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { fetchUpcomingEvents } from "../../features/Event/EventTBSlice"
import Landspace from "../../assets/images/banner/SATS-BACKDROP-1600px.png"
import Portrait from "../../assets/images/side-images/pic1.png"
import "./evehome.css"
import { FormFeedback, FormGroup, Input, Label, Form } from "reactstrap"
import { Modal, Spinner, Button } from "react-bootstrap"
import { GiH2O } from "react-icons/gi"

import { useFormik } from "formik"
import * as Yup from "yup"

const EventsHome = ({ UpcomingData }) => {
  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))
  // const { UpcomingData } = useSelector((state) => state.EventTBReducer)

  // const dispatch = useDispatch()
  const nav = useNavigate()

  // useEffect(() => {
  //   dispatch(fetchUpcomingEvents())
  // }, [])
  const [modalMember, SetModalMember] = useState(false)
  // Random component
  const Completionist = ({ countDown }) => (
    <span /* style={countDown} */ className="countDown">Today's Event!</span>
  )

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if ((days < 0 && hours < 0 && minutes < 0 && seconds < 0) || completed) {
      // Render a complete state
      return <Completionist /* countDown={countDown}  */ />
    } else {
      // Render a countdown

      return (
        <ul
          /*  style={countDown} */
          className="d-flex justify-content-center countDown"
        >
          <li className="countdown_element" style={{ fontStyle: "normal" }}>
            <span>{days}</span>
            <span className="letters"> Days</span>
          </li>
          <li className="countdown_element" style={{ fontStyle: "normal" }}>
            <span>{hours}</span>
            <span className="letters"> Hours</span>
          </li>
          <li className="countdown_element" style={{ fontStyle: "normal" }}>
            <span>{minutes}</span>
            <span className="letters"> Mins</span>
          </li>
          <li className="countdown_element" style={{ fontStyle: "normal" }}>
            <span>{seconds}</span>
            <span className="letters"> Secs</span>
          </li>
        </ul>
      )
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required(" select Your Category type "),
    }),
    onSubmit: (values, actions) => {
      console.log("reset", values, UpcomingData)

      if (values.category === "Member") {
        alert("Login and enjoy membership price for ticket booking")
        nav("/login")
      } else {
        if (UpcomingData[0]._id === "6596888d1705690eaaa9763a") {
          nav("/ticketbookingfetna")
        }
        else {
          nav("/ticketBooking")
        }

      }

      actions.resetForm()
      SetModalMember(false)
    },
  })

  const SelectEvent = (e, data) => {
    console.log("edit")
    e.preventDefault()
    localStorage.setItem("SelectEvent", data)
    nav("/EventsDetails")
  }

  return (
    <>
      <div className="col-12 ">
        {/* <CounterBlog /> */}
        {UpcomingData?.map((item, inde) => {
          return <div
            key={inde}
            className="counter-wrapper-1 content-inner-3"
            style={{ paddingTop: 10 }}
          >
            <div className="container">
              <div
                className="counter-inner bg-secondary"
                style={{ margin: 0, padding: "35px 80px 0px" }}
              >
                <div className="text-center">
                  <div className="row d-flexjustify-content-center align-items-center">
                    <div className="col-12 col-xl-4 col-lg-4">
                      <img
                        src={`${ApiServer}assets/event_cover/${item.image_name}`}
                        style={{ /*  height: "300px", */ objectFit: "contain" }}
                        alt=""
                      />
                    </div>
                    <div className="col-12 col-xl-8 col-lg-8 d-flex flex-column justify-content-center align-items-center">
                      {/* {item.preview===0/1} */}
                      {true == 1 ? (
                        <div>
                          <div
                            className="col-auto mt-4"
                            style={{ textAlign: "center" }}
                          >
                            <Link
                              to={"/UpComingEvent"}
                              className="fw-bold text-dark"
                              style={{ fontSize: 30, lineHeight: 1 }}
                            >
                              {item.name}
                            </Link>
                          </div>


                          {item.venues.map((venue, ind4) => {
                            return (
                              <div className="mt-3" key={ind4}>
                                <div style={{ textAlign: "center" }}>
                                  <span className="text-white " style={{ fontSize: 25, lineHeight: 0.7 }}>
                                    {venue.venue}
                                  </span>
                                </div>
                                <div className="my-2 ">
                                  <span className="text-white" style={{ fontSize: 20, lineHeight: 0.7 }}>
                                    {moment.parseZone(venue.start_datetime).format("LLLL")}
                                  </span>
                                </div>

                                {venue.ticket_allocations.map((allocation) => {
                                  if (allocation.booking_type === "early bird") {
                                    return (
                                      <div
                                        key={allocation._id}
                                        style={{
                                          color: "#333333", // Dark color
                                          fontSize: "18px",
                                          marginBottom: "10px",
                                          animation: "blink 1s steps(5, start) infinite",
                                          WebkitAnimation: "blink 1s steps(5, start) infinite", // For Safari
                                        }}
                                      >
                                        Early Bird Offer ends on {" "}
                                        {moment.parseZone(allocation.end_date).format("MMMM Do, YYYY")}
                                      </div>
                                    );
                                  }
                                  return null;
                                })}

                                <div className="d-flex justify-content-center">
                                  {(venue._id !== "65278e6a02c022ad330b9258" && item.type === "PAID") && (
                                    <Button
                                      className="btn btn-sm border-0 bg-warning me-2"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        localStorage.setItem(
                                          "SelectVenue",
                                          JSON.stringify({
                                            _id: venue._id,
                                            event_name: item?.name,
                                            venue_name: venue?.venue,
                                            event_date: item?.start_date,
                                            venue_date: venue?.start_datetime,
                                          })
                                        );
                                        if (User_Id_) {
                                          if (item._id === "6596888d1705690eaaa9763a") {
                                            nav("/ticketbookingfetna");
                                          } else {
                                            nav("/ticketBooking");
                                          }
                                        } else {
                                          !User_Id_ && SetModalMember(true);
                                        }
                                      }}
                                    >
                                      Book Now
                                    </Button>
                                  )}
                                  <Link onClick={(e) => SelectEvent(e, item._id)} className="btn btn-warning btn-sm">
                                    Event Information
                                  </Link>
                                </div>
                              </div>
                            );
                          })}


                          {/* {item.venues.map((venue, ind4) => {

                            return <div className="mt-3" key={ind4}>
                              <div style={{ textAlign: "center" }}>
                                <span
                                  className="text-white "
                                  style={{ fontSize: 25, lineHeight: 0.7 }}
                                >
                                  {venue.venue}
                                </span>
                              </div>
                              <div className="my-2 ">
                                <span
                                  className="text-white"
                                  style={{ fontSize: 20, lineHeight: 0.7 }}
                                >
                                  {moment
                                    .parseZone(venue.start_datetime)
                                    .format("LLLL")}
                                </span>
                              </div>
                              <div className="d-flex justify-content-center">
                                {
                                  (venue._id !== "65278e6a02c022ad330b9258" && item.type == 'PAID') &&  
                                  <Button
                                  className="btn btn-sm border-0 bg-warning me-2"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    localStorage.setItem(
                                      "SelectVenue",
                                      JSON.stringify({
                                        _id: venue._id,
                                        event_name: item?.name,
                                        venue_name: venue?.venue,
                                        event_date: item?.start_date,
                                        venue_date: venue?.start_datetime,
                                      })
                                    )
                                    if(User_Id_)
                                    {
                                      if(item._id === "6596888d1705690eaaa9763a")
                                      {
                                        nav("/ticketbookingfetna")
                                      }
                                      else
                                      {
                                        nav("/ticketBooking")
                                      }
                                    }

                                    {
                                      !User_Id_ && SetModalMember(true)
                                       
                                    }
                                  }}
                                >
                                  Book Now
                                </Button>

                                }
                                <Link
                                  onClick={(e) => SelectEvent(e, item._id)}
                                  className="btn btn-warning btn-sm"
                                >
                                  Event Information
                                </Link>
                              </div>
                            </div>
                          })} */}

                          {/* <div className="col-12  px-4 ">
                              <h2 className="fw-bolder text-primary">
                                {moment(item.start_date).format("DD")}
                              </h2>
                              <h6 className="fw-bold text-warning">
                                {moment(item.start_date).format("MMM")}
                              </h6>
                            </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="d-flex col-sm-12 border-top col-lg-12 align-items-center justify-content-center py-4 mt-5">
                    <Countdown
                      // date={new Date(item.start_date)}
                      date={moment.parseZone(item.start_date).format()}
                      renderer={renderer}
                    />
                  </div>
                </div>
                {/* <img src={IMAGES.Pattren1} className="pattren1 move-2" alt="" />
                  <img src={IMAGES.Pattren2} className="pattren2 move-2" alt="" />
                  <img src={IMAGES.Pattren3} className="pattren3 move-2" alt="" /> */}
                <img src={IMAGES.Pattren4} className="pattren4 move-2" alt="" />
                <img src={IMAGES.Pattren5} className="pattren5 move-2" alt="" />
                <img src={IMAGES.Pattren6} className="pattren6 move-2" alt="" />
              </div>
            </div>
          </div>
        })}

        {/* <Link
          to={"/UpComingEvent"}
          className="btn bg-primary rounded-0 text-white w-100 mt-2"
        >
          More View
        </Link> */}
      </div>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={modalMember}
        onHide={() => SetModalMember(false)}
        backdrop="static"
        centered
      >
        <h2 className="title text-white">Are you ?</h2>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
          }}
        >
          <div className="mb-3 col-12 col-sm-12">
            {/* <Label htmlFor="category">
              Category <span className="text-danger">*</span>
            </Label> */}
            <fieldset className="d-flex flex-column">
              <div
                className="p-3 border-1 border-warning mb-3"
                style={{ borderRadius: 5 }}
              >
                <input
                  type="radio"
                  name="category"
                  id="track"
                  className="me-2 form-check-input "
                  value={validation.values.category || ""}
                  defaultChecked={validation.values.category === "Member"}
                  onClick={(e) => {
                    validation.setFieldValue("category", "Member")
                  }}
                />
                <label htmlFor="Member" className="me-3">
                  Member
                </label>
              </div>
              <div
                className="p-3 border-1 border-warning mb-3"
                style={{ borderRadius: 5 }}
              >
                <input
                  type="radio"
                  name="category"
                  className="me-2 form-check-input"
                  id="Non-Member"
                  value={validation.values.category || ""}
                  defaultChecked={validation.values.category === "Non-Member"}
                  onClick={(e) => {
                    validation.setFieldValue("category", "Non-Member")
                  }}
                />
                <label htmlFor="event" className="me-3">
                  Non-Member
                </label>
              </div>
            </fieldset>
            {validation.touched.category && validation.errors.category ? (
              <div
                style={{
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {validation.errors.category}
              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="col-6 ">
              <button
                className="btn btn-outline-danger btn-block"
                data-bs-toggle="collapse"
                onClick={() => {
                  SetModalMember(false)
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-outline-primary btn-block"
                data-bs-toggle="collapse"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default EventsHome
