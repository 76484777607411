import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import BgImage from "../../assets/images/background/bg10.jpg"
import { ToastContainer, toast } from "react-toastify"
import swal from "sweetalert"
import PageBanner from "../../layouts/PageBanner"
import { IMAGES } from "../../constant/theme"
import { Label, FormFeedback, Input, Form, Col } from "reactstrap"
import { YouthWingForm } from "../../features/FormDataStore"
import { useSelector } from "react-redux"
const YouthwingForm = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { SuccessYouthwing } = useSelector((state) => state.FormDataReducer)

  useEffect(() => {
    if (SuccessYouthwing) {
      validation.resetForm()
      swal(
        "Form submitted!",
        "YouthWing Registration is successfuly submitted",
        "success"
      )
      nav("/")
    }
  }, [SuccessYouthwing])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: "",
      email: "",
      phone_no: "",
      parent_sats_member: "",
      grade: "",
      school: "",
      intrest: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),

      phone_no: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 ,+1 (201)78987678 (Enter country code and Mobile number)"
        )
        .required("Mobile Number is required"),
      email: Yup.string()
        .email("Invalid mail Formate")
        .required("Email is required"),
      parent_sats_member: Yup.string().required("This is required"),
      grade: Yup.string().required("Your Grade is required"),
      school: Yup.string().required("Your School is required"),
      intrest: Yup.string().required("Area of Interest is required"),
    }),
    onSubmit: (values, actions) => {
      console.log(values)
      dispatch(YouthWingForm(values))
    },
  })

  const handleKeyDown = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDownmobile = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9+()\-\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="SATS Youth Wing Registration" />
        <section
          className="content-inner-1 gradient-white  section-wrapper5"
          style={{
            // backgroundImage: "url(" + BgImage + ")",
            paddingBottom: 0,
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-md-9 col-sm-12">
                <div className="form-wrapper style-3">
                  <div className="contact-area">
                    <Form
                      onSubmit={(event) => {
                        event.preventDefault()
                        validation.handleSubmit()
                      }}
                    >
                      <div className="dzFormMsg"></div>
                      <input
                        type="hidden"
                        className="form-control"
                        name="dzToDo"
                        value="Contact"
                      />
                      <input
                        type="hidden"
                        className="form-control"
                        name="reCaptchaEnable"
                        value="0"
                      />

                      <div className="row sp15">
                        <div className="mb-3 col-12">
                          <Label htmlFor="name">
                            Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="name"
                            className={`form-control`}
                            placeholder="Enter Your name"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12">
                          <Label htmlFor="email">
                            Email<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="email"
                            className={`form-control`}
                            placeholder="Enter Your Email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12">
                          <Label htmlFor="phone_no">
                            Phone No<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="phone_no"
                            className="form-control  "
                            placeholder="Enter Your Phone Number"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_no || ""}
                            onKeyDown={handleKeyDownmobile}
                            invalid={
                              validation.touched.phone_no &&
                              validation.errors.phone_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone_no &&
                          validation.errors.phone_no ? (
                            <FormFeedback type="invalid">
                              {validation.errors.phone_no}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12 col-sm-11">
                          <Label htmlFor="parent_sats_member">
                            Is your Parents SATS Member?
                            <span className="text-danger">*</span>
                          </Label>
                          <fieldset className="d-flex flex-column flex-lg-row  flex-sm-column">
                            <div>
                              <input
                                type="radio"
                                name="parent_sats_member"
                                id="track"
                                className="me-2 form-check-input "
                                value={
                                  validation.values.parent_sats_member || ""
                                }
                                defaultChecked={
                                  validation.values.parent_sats_member === "YES"
                                }
                                onClick={(e) => {
                                  validation.setFieldValue(
                                    "parent_sats_member",
                                    "YES"
                                  )
                                }}
                              />
                              <label htmlFor="track" className="me-3">
                                YES
                              </label>
                            </div>
                            <div>
                              <input
                                type="radio"
                                name="parent_sats_member"
                                className="me-2 form-check-input"
                                id="event"
                                value={
                                  validation.values.parent_sats_member || ""
                                }
                                defaultChecked={
                                  validation.values.parent_sats_member === "No"
                                }
                                onClick={(e) => {
                                  validation.setFieldValue(
                                    "parent_sats_member",
                                    "No"
                                  )
                                }}
                              />
                              <label htmlFor="event" className="me-3">
                                No
                              </label>
                            </div>
                          </fieldset>
                          {validation.touched.parent_sats_member &&
                          validation.errors.parent_sats_member ? (
                            <div
                              style={{
                                fontSize: "12px",
                                color: "red",
                              }}
                            >
                              {validation.errors.parent_sats_member}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12">
                          <Label htmlFor="grade">
                            Your Grade<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="grade"
                            className={`form-control`}
                            placeholder="Enter Your grade"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.grade || ""}
                            invalid={
                              validation.touched.grade &&
                              validation.errors.grade
                                ? true
                                : false
                            }
                          />
                          {validation.touched.grade &&
                          validation.errors.grade ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.grade}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12">
                          <Label htmlFor="school">
                            Your School<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="school"
                            className={`form-control`}
                            placeholder="Enter Your school"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.school || ""}
                            invalid={
                              validation.touched.school &&
                              validation.errors.school
                                ? true
                                : false
                            }
                          />
                          {validation.touched.school &&
                          validation.errors.school ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.school}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3 col-12">
                          <Label htmlFor="intrest">
                            Area of Interest and strength
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="intrest"
                            className={`form-control`}
                            placeholder="Enter Your Area of Intrest"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.intrest || ""}
                            invalid={
                              validation.touched.intrest &&
                              validation.errors.intrest
                                ? true
                                : false
                            }
                          />
                          {validation.touched.intrest &&
                          validation.errors.intrest ? (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.intrest}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Col className="col-12 d-flex mt-3">
                          <div className=" text-end mt-3 col-12">
                            <button
                              type="submit"
                              className="btn btn-success px-5 "
                            >
                              {"Submit"}
                            </button>
                          </div>
                        </Col>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-inner-2 text-dark mb-5">
          <div className="container d-flex flex-wrap">
            <div className="col-lg-6">
              <h1 className="h1 fw-bold text-primary sub-title"> GIVE TIME</h1>
              <p>
                The San Antonio Tamil Sangam(SATS) volunteer opportunities range
                to oragnize various event like (Pongal, Deepavali, Tamil New
                Year..etc), sports event like(Cricket, Kids/Ladies tournament),
                Summer Picnic, Health Fair ..etc. Whether you’re an individual,
                group, or company there are many opportunities to volunteer.
              </p>
              {/* <p>
                Click here{" "}
                <a
                  target="_blank"
                  className="link-warning fw-bold"
                  rel="noreferrer"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSc8glufCkfjcJCK02-hDS37RGTR3O4WNvezu4OzCxseoJVWiw/viewform"
                >
                  To Signup
                </a>
              </p> */}
              <p> For Questions Regarding Volunteering, Please Contact:</p>
              <ul
                className="fw-bolder"
                style={{
                  lineHeight: "1.5",
                }}
              >
                <li>Mr. Suresh Selvaraju</li>
                <li>+1 (210) 668-7075</li>
                <li> sats@satamilsangam.org</li>
              </ul>
            </div>
            <div className="col-lg-5">
              <img src={IMAGES.volunteers} alt="" />
            </div>
          </div>
        </section>
      </div>
      <ToastContainer style={{ zIndex: "10999" }} />
    </>
  )
}

export default YouthwingForm
