import React, { useEffect, useState } from "react"
/* import Route from './pages/Route'; */
import { Routes, Route, Outlet } from "react-router-dom"
import "./assets/css/style.css"
//import "./assets/css/skin/skin-1.css";
import "swiper/css"
import Routerset from "./routes/Routerset"
import ScrollToTop from "./layouts/ScrollToTop"
function App() {
  return (
    <React.Fragment>
      <ScrollToTop />
      <Routerset />
    </React.Fragment>
  )
}

export default App
