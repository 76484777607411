import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import PageBanner from "../../layouts/PageBanner"
import { Dropdown, Row, Col } from "react-bootstrap"

// import UpdateBlog from "../../components/Home/UpdateBlog"
import { Table } from "reactstrap"
import bg from "../../assets/images/banner/bnr2.jpg"
import {
  fecthPreviousCommitte,
  fetchPerviousyear,
} from "../../features/FetchData"
import moment from "moment/moment"
const PreviousCommittee = () => {
  const dispatch = useDispatch()
  const currentyear = moment().format("YYYY")
  const [selectyear, setSelectyear] = useState(currentyear - 1)

  const { PreviousCommitte, Success, Perviousyear } = useSelector(
    (state) => state.FetchDataReducer
  )

  useEffect(() => {
    dispatch(fetchPerviousyear())
  }, [])

  console.log(PreviousCommitte)

  const year = Perviousyear.map((item, ind) => parseInt(item._id))

  const yearlist = [...new Set(year.sort().reverse())]

  console.log("year", yearlist)

  useEffect(() => {
    if (selectyear) {
      dispatch(fecthPreviousCommitte(selectyear))
    }
  }, [selectyear])
  useEffect(() => {
    dispatch(fecthPreviousCommitte(selectyear))
  }, [])

  // console.log(mainList)

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Previous Committee" />
        <section className="content-inner" style={{ paddingTop: 0 }}>
          <div className="container text-dark">
            <Row className="d-flex justify-content-center mt-4">
              <Col lg={3} md={3} sm={4}>
                <Dropdown className="select-drop-2 border-2 rounded border-info w-100 ">
                  <Dropdown.Toggle
                    as="div"
                    className="i-false select-drop-btn-2 py-0"
                  >
                    <span>{selectyear}</span>
                    <i className="fa-regular fa-angle-down me-2"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div
                      style={{
                        maxHeight: "200px",
                        overflowY: "auto",
                        width: "100%",
                      }}
                    >
                      {yearlist?.map((item, ind) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setSelectyear(item)}
                            key={ind}
                          >
                            {item}
                          </Dropdown.Item>
                        )
                      })}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <div className="bg-white">
              <Table className="mt-5" bordered responsive striped>
                <thead>
                  <tr className="">
                    <th colspan="2" className="text-white bg-primary fs-5">
                      Team of Executive Commitee -Start ({selectyear})
                    </th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {PreviousCommitte.map((item2, ind2) => (
                    <>
                      {item2.member_category_id === "1" && (
                        <tr>
                          <td className="py-2">
                            {item2.gender === "male" ? "Mr" : "Mrs"}.
                            {item2.firstname} {item2.lastname}
                          </td>
                          <td className="py-2">
                            {item2.designation
                              ? item2.designation
                              : item2.member_category}
                          </td>
                        </tr>
                      )}

                      {item2.member_category_id === "2" && (
                        <tr>
                          <td className="py-2">
                            {item2.gender === "male" ? "Mr" : "Mrs"}.
                            {item2.firstname} {item2.lastname}
                          </td>
                          <td className="py-2">
                            {item2.designation
                              ? item2.designation
                              : item2.member_category}
                          </td>
                        </tr>
                      )}

                      {item2.member_category_id === "3" && (
                        <tr>
                          <td className="py-2">
                            {item2.gender === "male" ? "Mr" : "Mrs"}.
                            {item2.firstname} {item2.lastname}
                          </td>
                          <td className="py-2">
                            {item2.designation
                              ? item2.designation
                              : item2.member_category}
                          </td>
                        </tr>
                      )}

                      {item2.member_category_id === "4" && (
                        <tr>
                          <td className="py-2">
                            {item2.gender === "male" ? "Mr" : "Mrs"}.
                            {item2.firstname} {item2.lastname}
                          </td>
                          <td className="py-2">
                            {item2.designation
                              ? item2.designation
                              : item2.member_category}
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PreviousCommittee
