/* Header */
import { Navigate } from "react-router-dom"
import Home3 from "../pages/Home3"
import ContactUs from "../pages/ContactUs"
import Error from "../pages/Error"
import Upgradeplan from "../pages/Authentication/UpgradePlan"
import UserLogin from "../pages/Authentication/UserLogin"
//Events Details and Booking
import UpComingEvent from "../pages/event/UpComingEvent"
import PastEvents from "../pages/event/PastEvents"
import SeatArrange from "../pages/event/SeatArrange"
import Details from "../pages/event/Details"
import TempBooking from "../pages/event/TempBooking"

import EventBooking from "../pages/event/eventBooking"
import EventBookingF from "../pages/event/eventBooking_f"

import AboutUs from "../pages/AboutUs/AboutUs"
import MissionVision from "../pages/AboutUs/MissionVision"
import CurrentCommittee from "../pages/AboutUs/CurrentCommittee"
import PreviousCommittee from "../pages/AboutUs/PreviousCommittee"
import FeTNA from "../pages/AboutUs/FeTNA"
import ByLaws from "../pages/AboutUs/ByLaws"
import Volunteers from "../pages/AboutUs/Volunteers"

import EventMediaLinks from "../pages/malligaiMalar/EventMediaLinks"
import MalligaiMalar from "../pages/malligaiMalar/MalligaiMalar"

import BecomeMember from "../pages/members/BecomeMember"
import LifeMember from "../pages/members/LifeMember"
import MemberShip from "../pages/members/Membership"

import Fit from "../pages/Satsclubs/Fit"
import Garden from "../pages/Satsclubs/Garden"
import MovieClub from "../pages/Satsclubs/MovieClub"
import Sports from "../pages/Satsclubs/Sports"
import ThinnaiBookClub from "../pages/Satsclubs/ThinnaiBookClub"
import YouthWing from "../pages/Satsclubs/YouthWing"

import OurSponsers from "../pages/sponsers/OurSponsores"
import SponserLevel from "../pages/sponsers/SponserLevel"
import Gallery from "../pages/Gallery"
import School from "../pages/projects/School"
import Flood from "../pages/projects/Flood"
import Cyclone from "../pages/projects/Cyclone"
import Thaimadi from "../pages/projects/Thaimadi"
import Register from "../pages/Authentication/Register"
import MyProfile from "../pages/MyProfile"
import Donate from "../pages/Donate"
import NewtoSA from "../pages/NewtoSA"
import YouthwingForm from "../pages/Satsclubs/YouthwingForm"
import Mywallet from "../components/Home3/Mywallet"
import FetnaMalar from './../pages/fetnamalar/FetnaMalar'
const publicRoutes = [
  { path: "/", component: <Home3 /> },
  { path: "contact-us", component: <ContactUs /> },
  { path: "*", component: <Error /> },
  {
    path: "/UpComingEvent",
    component: <UpComingEvent />,
  },
  {
    path: "/EventsDetails",
    component: <Details />,
  },
  {
    path: "/pastEvent",
    component: <PastEvents />,
  },
  // { path: "/ticketbooking", component: <TempBooking /> },
  { path: "/ticketbooking", component: <EventBooking /> },
  { path: "/ticketbookingfetna", component: <EventBookingF /> },
  {
    path: "/seatB",
    component: <SeatArrange />,
  },
  { path: "/mywallet", component: <Mywallet /> },

  {
    path: "/about-us",
    component: <AboutUs />,
  },
  {
    path: "/missionvision",
    component: <MissionVision />,
  },
  {
    path: "/currentcommittee",
    component: <CurrentCommittee />,
  },
  {
    path: "previouscommittee",
    component: <PreviousCommittee />,
  },
  // {
  //   path: "fetna",
  //   component: <FeTNA />,
  // },
  {
    path: "bylaws",
    component: <ByLaws />,
  },
  {
    path: "volunteers",
    component: <Volunteers />,
  },
  // {
  //   path: "eventmedialinks",
  //   component: <EventMediaLinks />,
  // },
  {
    path: "malligaimalar",
    component: <MalligaiMalar />,
  },
  {
    path: "fetnamalar",
    component: <FetnaMalar />,
  },
  {
    path: "becomemember",
    component: <BecomeMember />,
  },
  {
    path: "lifeMemberShip",
    component: <LifeMember />,
  },
  {
    path: "membership",
    component: <MemberShip />,
  },
  // {
  //   path: "satsfit",
  //   component: <Fit />,
  // },
  // {
  //   path: "satsgarden",
  //   component: <Garden />,
  // },
  // {
  //   path: "satsmovieclub",
  //   component: <MovieClub />,
  // },
  // {
  //   path: "satssports",
  //   component: <Sports />,
  // },
  // {
  //   path: "satsthinnaibookclub",
  //   component: <ThinnaiBookClub />,
  // },
  {
    path: "satsyouthwing",
    component: <YouthWing />,
  },
  {
    path: "youthwingForm",
    component: <YouthwingForm />,
  },
  {
    path: "oursponsers",
    component: <OurSponsers />,
  },
  {
    path: "sponserlevel",
    component: <SponserLevel />,
  },
  { path: "gallery", component: <Gallery /> },

  { path: "school", component: <School /> },
  { path: "flood", component: <Flood /> },
  { path: "cyclone", component: <Cyclone /> },
  {
    path: "thaimadi",
    component: <Thaimadi />,
  },
  {
    path: "/",
    exact: true,
    component: <Home3 />,
  },
  {
    path: "/registration",

    component: <Register />,
  },
  {
    path: "/login",
    component: <UserLogin />,
  },
  {
    path: "/upgradeplan",
    component: <Upgradeplan />,
  },
  {
    path: "/donate-form",

    component: <Donate />,
  },
  { path: "/newtosa", component: <NewtoSA /> },
]
const authProtectedRoutes = [
  { path: "*", component: <Error /> },
  {
    path: "/myprofile",

    component: <MyProfile />,
  },
]

export { authProtectedRoutes, publicRoutes }
