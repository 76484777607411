import React from "react"
import { Link } from "react-router-dom"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import "swiper/css"

//Images
import pic1 from "./../../assets/images/project/sats_p1_1.jpg"
import pic2 from "./../../assets/images/project/sats_p1_0.jpg"
import pic3 from "./../../assets/images/project/sats_p1_3.jpg"
import avt1 from "./../../assets/images/avatar/avatar1.jpg"
import avt2 from "./../../assets/images/avatar/avatar2.jpg"
import avt3 from "./../../assets/images/avatar/avatar3.jpg"

// import Swiper core and required modules
import { Autoplay, Pagination, Navigation } from "swiper"

//SwiperCore.use([EffectCoverflow,Pagination]);

const dataBlog = [
  { image: pic1, image2: avt1 },
  { image: pic2, image2: avt2 },
  { image: pic3, image2: avt3 },
]

const RecentProjectsSlider = () => {
  return (
    <>
      <Swiper
        className="recent-blog p-b5"
        speed={1500}
        //parallax= {true}
        slidesPerView={3}
        spaceBetween={30}
        loop={false}
        autoplay={{
          delay: 3000,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        breakpoints={{
          1200: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
      >
        <SwiperSlide>
          <div
            className="dz-card style-2 overlay-skew wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="dz-media">
              <Link to={"/school"}>
                <img src={pic1} alt="" />
              </Link>
            </div>
            <div className="dz-info">
              <ul className="dz-category">
                <li>
                  <Link to={"/school"} style={{ fontSize: "0.8rem" }}>
                    EDUCATION
                  </Link>
                </li>
              </ul>
              <h5 className="dz-title" style={{ fontSize: "1rem" }}>
                <Link to={"/school"}>School Project Munduvelampatti</Link>
              </h5>

              <div className="author-wrappper">
                <div className="author-content">
                  <ul className="author-meta">
                    <li className="campaign">August 8, 2021</li>
                    <li className="location">Tamilnadu, India</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="dz-card style-2 overlay-skew wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="dz-media">
              <Link to={"/thaimadi"}>
                <img src={pic2} alt="" />
              </Link>
            </div>
            <div className="dz-info">
              <ul className="dz-category">
                <li>
                  <Link to={"/thaimadi"} style={{ fontSize: "0.8rem" }}>
                    CYCLONE - GAJA
                  </Link>
                </li>
              </ul>
              <h5 className="dz-title" style={{ fontSize: "1rem" }}>
                <Link to={"/thaimadi"}>Thaimadi Project</Link>
              </h5>

              <div className="author-wrappper">
                <div className="author-content">
                  <ul className="author-meta">
                    <li className="campaign">February 20, 2020</li>
                    <li className="location">Tamilnadu, India</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="dz-card style-2 overlay-skew wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="dz-media">
              <Link to={"/cyclone"}>
                <img src={pic3} alt="" />
              </Link>
            </div>
            <div className="dz-info">
              <ul className="dz-category">
                <li>
                  <Link to={"/cyclone"} style={{ fontSize: "0.8rem" }}>
                    CYCLONE - GAJA
                  </Link>
                </li>
              </ul>
              <h5 className="dz-title" style={{ fontSize: "1rem" }}>
                <Link to={"/cyclone"}>Ambalapattu Village Project</Link>
              </h5>

              <div className="author-wrappper">
                <div className="author-content">
                  <ul className="author-meta">
                    <li className="campaign">November 16, 2018</li>
                    <li className="location">Tamilnadu, India</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default RecentProjectsSlider
