import React from "react"
import { Link } from "react-router-dom"

import PageBanner from "../../layouts/PageBanner"
import { Project } from "../../constant/theme"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"

const Thaimadi = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Home"
          pagetitle="Project"
        />

        <section className="content-inner bg-light section-pattren1">
          <div className="container">

            <div className="row align-items-center flex-column-reverse flex-lg-row">
              <div className="col-lg-6 align-self-center">
                <div className="section-head m-b30">
                  <h2 className="title mb-3">Thaimadi Project</h2>
                </div>
              </div>
            </div>

            <div className="p-3 border border-1">
              <img src={Project.thaimadi1} alt="" />
              <img src={Project.thaimadi2} alt="" style={{ marginLeft: 8 }} />
            </div>

          </div>
        </section>





      </div>
    </>
  )
}

export default Thaimadi
