import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { ToastContainer, toast } from "react-toastify"
import swal from "sweetalert"

import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import { SponsersForm, ResetBAM } from "../../features/FormDataStore"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const SpForm = () => {
  const [btnmem, SetBtnme] = useState("Select The membership")
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { SuccessSpons, FailSpons } = useSelector(
    (state) => state.FormDataReducer
  )
  console.log(SuccessSpons, FailSpons)

  useEffect(() => {
    if (SuccessSpons) {
      validation.resetForm()
      swal(
        "Form submitted!",
        "Sponser's Registration is successfully submitted",
        "success"
      )
      nav("/")
    }
    if (FailSpons) {
      swal("Bad job!", "Sponser's Registration Failed", "error")
    }
    dispatch(ResetBAM())
  }, [SuccessSpons, FailSpons])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      sponsorship: "", //number 0 and 1
      // corporate: "",
      // individual: "",
      sponsorship_level: "",
      sp_name: "",
      sp_ct_person: "",
      sp_address: "",
      sp_email: "",
      sp_phone: "",
    },
    validationSchema: Yup.object({
      sponsorship: Yup.string().required("sponsorship is required"),
      sp_name: Yup.string().required("Sponser's Name is required"),
      sp_ct_person: Yup.string().required("Contact Person Name is required"),
      sp_email: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      sp_phone: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 ,+1 (201)78987678 (Enter country code and Mobile number)"
        )
        .required("Mobile Number is required"),
      sponsorship_level: Yup.string().required(
        "Sponsorship level is required"
      ),
      // corporate: Yup.string().when("membership", {
      //   is: "Corporate",
      //   then: Yup.string().required(
      //     "Please select a corporate membership level"
      //   ),
      // }),
      // individual: Yup.string().when("membership", {
      //   is: "Individual",
      //   then: Yup.string().required(
      //     "Please select an individual membership level"
      //   ),
      // }),
    }),
    onSubmit: (values, actions) => {
      console.log(values)

      dispatch(SponsersForm(values))
      // actions.resetForm()
    },
  })

  const handleKeyDownmobile = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9+()\-\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }

  return (
    <div>
      <Form
        onSubmit={(event) => {
          event.preventDefault()
          validation.handleSubmit()
        }}
      >
        <Row className="col-12 ">
          <Col className="col-lg-12 d-flex flex-wrap justify-content-between   rounded pb-5">
            <div className="mb-3 col-12 col-sm-11">
              <Label htmlFor="sponsorship">
                Sponsorship <span className="text-danger">*</span>
              </Label>
              <fieldset className=" ms-3 d-flex  flex-lg-row  flex-sm-column flex-column">
                <div>
                  <input
                    type="radio"
                    name="sponsorship"
                    id="track"
                    className="me-2 form-check-input "
                    value={validation.values.sponsorship || ""}
                    defaultChecked={validation.values.sponsorship}
                    onClick={(e) => {
                      validation.setFieldValue("sponsorship", "Corporate")
                    }}
                  />
                  <label htmlFor="track" className="me-3">
                    Corporate Sponsorship
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="sponsorship"
                    className="me-2 form-check-input"
                    id="event"
                    value={validation.values.sponsorship || ""}
                    defaultChecked={validation.values.sponsorship}
                    onClick={(e) => {
                      validation.setFieldValue("sponsorship", "Individual")
                    }}
                  />
                  <label htmlFor="event" className="me-3">
                    Individual Sponsorship
                  </label>
                </div>
              </fieldset>
              {validation.touched.sponsorship && validation.errors.sponsorship ? (
                <div
                  style={{
                    fontSize: "12px",
                    color: "red",
                  }}
                >
                  {validation.errors.sponsorship}
                </div>
              ) : null}
            </div>
            {validation.values.sponsorship != "" && (
              <div className="mb-3 col-12">
                <Label htmlFor="sponsorship_level">
                  {validation.values.sponsorship === "Corporate"
                    ? "Corporate Sponsorship"
                    : "Individual Sponsorship"}
                  <span className="text-danger">*</span>
                </Label>
                <select
                  id="sponsorship_level"
                  name="sponsorship_level"
                  className={`form-select ${
                    validation.touched.sponsorship_level &&
                    validation.errors.sponsorship_level &&
                    "is-invalid"
                  }`}
                  value={validation.values.sponsorship_level}
                  onChange={(e) =>
                    validation.setFieldValue(
                      "sponsorship_level",
                      e.target.value
                    )
                  }
                >
                  <option value={" "}>Select level</option>
                  {validation.values.sponsorship === "Corporate" && (
                    <>
                      <option value={"Grand"}>Grand </option>
                      <option value={"Platinum"}>Platinum </option>
                      <option value={"Gold"}>Gold </option>
                      <option value={"Silver"}>Silver </option>
                      <option value={"Bronze"}>Bronze </option>
                    </>
                  )}
                  {validation.values.sponsorship === "Individual" && (
                    <>
                      <option value={"Platinum"}>Platinum </option>
                      <option value={"Gold"}>Gold </option>
                      <option value={"Silver"}>Silver </option>
                      <option value={"Bronze"}>Bronze </option>
                    </>
                  )}
                </select>

                {validation.touched.sponsorship_level &&
                validation.errors.sponsorship_level ? (
                  <div
                    style={{
                      fontSize: "12px",
                      color: "red",
                    }}
                  >
                    {validation.errors.sponsorship_level}
                  </div>
                ) : null}
              </div>
            )}

            <div className="mb-3 col-12">
              <Label htmlFor="emasp_nameil">
                Sponsor’s Name as it will appear in all printed material
                <span className="text-danger">*</span>
              </Label>
              <Input
                name="sp_name"
                className={`form-control`}
                placeholder="Enter Sponser Name"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.sp_name || ""}
                invalid={
                  validation.touched.sp_name && validation.errors.sp_name
                    ? true
                    : false
                }
              />
              {validation.touched.sp_name && validation.errors.sp_name ? (
                <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                  {validation.errors.sp_name}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mb-3 col-12">
              <Label htmlFor="sp_ct_person">
                Contact Person<span className="text-danger">*</span>
              </Label>
              <Input
                name="sp_ct_person"
                className={`form-control`}
                placeholder="Enter Contact Person Name"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.sp_ct_person || ""}
                invalid={
                  validation.touched.sp_ct_person &&
                  validation.errors.sp_ct_person
                    ? true
                    : false
                }
              />
              {validation.touched.sp_ct_person &&
              validation.errors.sp_ct_person ? (
                <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                  {validation.errors.sp_ct_person}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3 col-12">
              <Label htmlFor="sp_address">Address</Label>
              <Input
                name="sp_address"
                className="form-control  "
                placeholder="Enter Address"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.sp_address || ""}
                invalid={
                  validation.touched.sp_address && validation.errors.sp_address
                    ? true
                    : false
                }
              />
              {validation.touched.sp_address && validation.errors.sp_address ? (
                <FormFeedback type="invalid">
                  {validation.errors.sp_address}
                </FormFeedback>
              ) : null}
            </div>

            <div className="mb-3 col-12">
              <Label htmlFor="sp_email">
                Email<span className="text-danger">*</span>
              </Label>
              <Input
                name="sp_email"
                className={`form-control`}
                placeholder="Enter Email Id"
                type="sp_email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.sp_email || ""}
                invalid={
                  validation.touched.sp_email && validation.errors.sp_email
                    ? true
                    : false
                }
              />
              {validation.touched.sp_email && validation.errors.sp_email ? (
                <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
                  {validation.errors.sp_email}
                </FormFeedback>
              ) : null}
            </div>

            <div className="col-12">
              <Label htmlFor="sp_phone">
                Phone<span className="text-danger">*</span>
              </Label>
              <Input
                name="sp_phone"
                className="form-control  "
                placeholder="Enter Phone Number"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                maxLength={20}
                value={validation.values.sp_phone || ""}
                onKeyDown={handleKeyDownmobile}
                invalid={
                  validation.touched.sp_phone && validation.errors.sp_phone
                    ? true
                    : false
                }
              />
              {validation.touched.sp_phone && validation.errors.sp_phone ? (
                <FormFeedback type="invalid">
                  {validation.errors.sp_phone}
                </FormFeedback>
              ) : null}
            </div>

            <Col className="col-12 d-flex mt-3">
              <div className=" text-end mt-3 col-12">
                <button type="submit" className="btn btn-warning px-5 ">
                  {"Submit"}
                </button>
              </div>
            </Col>
          </Col>
        </Row>
      </Form>
      <ToastContainer />
    </div>
  )
}

export default SpForm
