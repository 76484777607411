import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';


import slide from './../assets/images/main-slider/pic1.png';
import { YouthWingsimg } from "../constant/theme"

const CounterBlog = [
    {title:"Total Donor", number:"4556"},
    {title:"Volunteer", number:"874"},
    {title:"Total Donation", number:"195234"},
];

const YouthWingBlock = () =>{
    return(
        <>
            <div className="col-lg-5">
                <div className="">
                    <img src={YouthWingsimg.logo2} alt="image" />
                </div>
            </div>
            <div className="col-lg-7 m-b50">
                <div className="section-head">
                    <h3 className='text-primary'>Benefits Of Joining</h3>
                    <div className="">
                    <p>
                        Diverse Perspective <br />
                        Leadership Qualities <br />
                        Dedication and Commitment <br />
                        Understand Life Skills <br />
                        Inspire their Peers/Friends <br />
                        Participating in Activities <br />
                        Instilling a Sense of Competence <br />
                    </p>
                  </div>
                </div>
            </div>
        </>
    )
}
export default YouthWingBlock;