import React, { useEffect, useState } from "react"
import swal from "sweetalert"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import { SubscribeForm, ResetBAM } from "../features/FormDataStore"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
const Subscribe = () => {
  const dispatch = useDispatch()
  const { SuccessSubs, FailSubs } = useSelector(
    (state) => state.FormDataReducer
  )
  useEffect(() => {
    if (SuccessSubs) {
      swal(
        "Form submitted!",
        "Your Subscribe Registration is successfully submitted",
        "success"
      )
    }
    if (FailSubs) {
      toast.error("Subscribe Form is Failed  ...!", {
        position: "top-center",
      })
    }
    dispatch(ResetBAM())
  }, [SuccessSubs, FailSubs])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      lastname: "",
      firstname: "",
    },
    validationSchema: Yup.object({
      // lastname: Yup.string().required("Enter your Contact Person"),
      // firstname: Yup.string().required("Enter your Contact Person"),
      // email: Yup.string()
      //   .eemail("Invalid eemail address")
      //   .required("Eemail address is required"),
    }),
    onSubmit: (values, actions) => {
      console.log(values)

      dispatch(SubscribeForm(values))
      actions.resetForm()
    },
  })
  return (
    <div>
      <Form
        className="d-flex flex-wrap justify-content-evenly"
        onSubmit={(event) => {
          event.preventDefault()
          validation.handleSubmit()
        }}
      >
        {/* <h6 className="title">
          Join our emailing List and stay connected with us
        </h6> */}
        <div className="mb-3 col-12 col-xl-3 col-lg-3 col-md-3">
          {/* <Label htmlFor="email" className="text-dark">
            Eemail<span className="text-danger">*</span>
          </Label> */}
          <Input
            name="email"
            className={`form-control`}
            placeholder="Enter Your email"
            type="email"
            required
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.email || ""}
            invalid={
              validation.touched.email && validation.errors.email ? true : false
            }
          />
          {validation.touched.email && validation.errors.email ? (
            <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
              {validation.errors.email}
            </FormFeedback>
          ) : null}
        </div>

        <div className="mb-3 col-12 col-xl-3 col-lg-3 col-md-3">
          {/* <Label htmlFor="firstname" className="text-dark">
            First Name <span className="text-danger">*</span>
          </Label> */}
          <Input
            name="firstname"
            className={`form-control`}
            placeholder="Enter Your First Name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.firstname || ""}
            invalid={
              validation.touched.firstname && validation.errors.firstname
                ? true
                : false
            }
          />
          {validation.touched.firstname && validation.errors.firstname ? (
            <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
              {validation.errors.firstname}
            </FormFeedback>
          ) : null}
        </div>
        <div className="mb-3 col-12 col-xl-3 col-lg-3 col-md-3">
          {/* <Label htmlFor="lastname" className="text-dark">
            Last Name <span className="text-danger">*</span>
          </Label> */}
          <Input
            name="lastname"
            className={`form-control`}
            placeholder="Enter Your Last Name"
            type="text"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.lastname || ""}
            invalid={
              validation.touched.lastname && validation.errors.lastname
                ? true
                : false
            }
          />
          {validation.touched.lastname && validation.errors.lastname ? (
            <FormFeedback type="invalid" style={{ fontSize: "12px" }}>
              {validation.errors.lastname}
            </FormFeedback>
          ) : null}
        </div>

        <p className="form-submit text-end mx-3">
          <button type="submit" className="btn  btn-warning " id="submit">
            SUBSCRIBE
          </button>
        </p>
      </Form>
    </div>
  )
}

export default Subscribe
