import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Modal, Spinner, Button } from "react-bootstrap"
import { FormFeedback, FormGroup, Input, Label, Form } from "reactstrap"

import large from "../assets/images/blog/large/pic1.jpg"
import blog2 from "../assets/images/blog/blog-grid/pic2.jpg"
import blog1 from "../assets/images/blog/blog-grid/pic1.jpg"
import test from "../assets/images/testimonials/pic2.jpg"
import avat1 from "../assets/images/avatar/avatar1.jpg"
import avat2 from "../assets/images/avatar/avatar2.jpg"
import avat3 from "../assets/images/avatar/avatar3.jpg"
import avat4 from "../assets/images/avatar/avatar4.jpg"
import moment from "moment"

import ApiServer from "../helpers/Api_help"
import { useFormik } from "formik"
import * as Yup from "yup"
export const CommentBlog = (props) => {
  return (
    <>
      <div className="comment-body">
        <div className="comment-author vcard">
          <img className="avatar photo" src={props.image} alt="" />
          <cite className="fn">{props.title}</cite>
        </div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="reply">
          <Link to={"#"} className="comment-reply-link">
            <i className="fa fa-reply"></i>Reply
          </Link>
        </div>
      </div>
    </>
  )
}

const BlogDetailsLeftBar = (props) => {
  const nav = useNavigate()
  const [modalMember, SetModalMember] = useState(false)
  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required(" select Your Category type "),
    }),
    onSubmit: (values, actions) => {
      console.log("reset", values)

      if (values.category === "Member") {
        alert("Login and enjoy membership price for ticket booking")
        nav("/login")
      } else {
        if(props.SelectedEventData[0]._id === "6596888d1705690eaaa9763a")
        {
          nav("/ticketbookingfetna")
        }
        else
        {
          nav("/ticketBooking")
        }
      }

      actions.resetForm()
      SetModalMember(false)
    },
  })
  const SelectEvent = (e, data) => {
    console.log("edit")
    e.preventDefault()
    localStorage.setItem("SelectEvent", data)
    nav("/EventsDetails")
  }
  return (
    <>
      {props.SelectedEventData.map((event, eind) => (
        <div key={eind + "evntinfo"}>
          <div className="dz-card blog-single sidebar">
            <h1 className="dz-title">{event?.event_name || event.name}</h1>

            <div className="row">
              <div className="col-md-6">
                <div className="dz-media">
                  <img
                    src={`${ApiServer}assets/event_cover/${event?.event_photos || event.image_name
                      }`}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="dz-info">
                  <div className="dz-meta">
                    <ul>
                      <li className="post-date">
                        <i className="fa-solid fa-calendar-days"></i>
                        {moment
                          .parseZone(
                            event?.event_startdate_time || event.start_date
                          )
                          .format("MMMM Do YYYY")}
                      </li>
                    </ul>
                  </div>
                  <div className="dz-post-text">
                    <h4 className="title">Description </h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: event?.event_desc || event?.description,
                      }}
                    ></div>
                    {/* <p className="text-=justify" style={{ lineHeight: 1.5 }}>
                      RULES: <br />
                      1. All participants should be an Active SATS member for
                      the 2023 - 2024 year.
                      <br />
                      2. Non Members, please use this opportunity to become a
                      New member or Renew
                      <br />
                      @https://satamilsangam.org/become-a-member
                      <br /> 3. Updates will be shared in the WhatsApp group.
                      <br /> 4. Age category is based on Grade ( Grade for
                      2023/24 to be considered)
                    </p> */}

                    {event?.venues &&
                      event?.venues.map((venue, index) => (
                        <div key={index} className="">
                          <h6 className="title mt-3 ">{venue.venue} </h6>
                          <div
                            className="text-dark"
                            style={{ lineHeight: 1.2 }}
                          >
                            <span style={{ fontSize: 14 }}>
                              {moment
                                .parseZone(venue.start_datetime)
                                .format("LLLL")}
                            </span>
                            <br />
                            <span style={{ fontSize: 14 }}>
                              {venue.address},{venue.city},
                            </span>
                            <br />
                            <span style={{ fontSize: 14 }}>
                              {venue.country}.
                            </span>
                            <br />
                          </div>

                          {venue.organisers.map((organiser, index2) => (
                            <div
                              key={"org" + index2}
                              className="mt-3 text-dark"
                              style={{ lineHeight: 1.2 }}
                            >
                              <span
                                style={{ lineHeight: 1.7, fontWeight: "bold" }}
                              >
                                Organizer
                              </span>
                              <br />
                              <span style={{ fontSize: 14 }}>
                                {organiser.name}
                              </span>
                              <br />
                              <span style={{ fontSize: 14 }}>
                                {organiser.role}
                              </span>
                              <br />
                              <span style={{ fontSize: 14 }}>
                                {organiser.contact_number}
                              </span>
                              <br />
                              <span style={{ fontSize: 14 }}>
                                {organiser.email_id}
                              </span>
                              <br />
                            </div>
                          ))}
                          <div className="d-flex justify-content-start mt-3">
                            {venue._id !== "65278e6a02c022ad330b9258" &&
                              venue.ticket_allocations && venue.ticket_allocations.length > 0 && (
                                <Button
                                  className="btn btn-sm border-0 bg-warning mx-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    localStorage.setItem(
                                      "SelectVenue",
                                      JSON.stringify({
                                        _id: venue._id,
                                        event_name: event?.name,
                                        venue_name: venue?.venue,
                                        event_date: event?.start_date,
                                        venue_date: venue?.start_datetime,
                                      })
                                    );

                                    if(User_Id_)
                                    {
                                      if(event._id === "6596888d1705690eaaa9763a")
                                      {
                                        nav("/ticketbookingfetna")
                                      }
                                      else
                                      {
                                        nav("/ticketBooking")
                                      }
                                    }

                                    {
                                      !User_Id_ && SetModalMember(true)
                                       
                                    }
                                  }}
                                >
                                  Book Now
                                </Button>
                              )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={modalMember}
        onHide={() => SetModalMember(false)}
        backdrop="static"
        centered
      >
        <h2 className="title text-white">Are you ?</h2>
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            validation.handleSubmit()
          }}
        >
          <div className="mb-3 col-12 col-sm-12">
            {/* <Label htmlFor="category">
              Category <span className="text-danger">*</span>
            </Label> */}
            <fieldset className="d-flex flex-column">
              <div
                className="p-3 border-1 border-warning mb-3"
                style={{ borderRadius: 5 }}
              >
                <input
                  type="radio"
                  name="category"
                  id="track"
                  className="me-2 form-check-input "
                  value={validation.values.category || ""}
                  defaultChecked={validation.values.category === "Member"}
                  onClick={(e) => {
                    validation.setFieldValue("category", "Member")
                  }}
                />
                <label htmlFor="Member" className="me-3">
                  Member
                </label>
              </div>
              <div
                className="p-3 border-1 border-warning mb-3"
                style={{ borderRadius: 5 }}
              >
                <input
                  type="radio"
                  name="category"
                  className="me-2 form-check-input"
                  id="Non-Member"
                  value={validation.values.category || ""}
                  defaultChecked={validation.values.category === "Non-Member"}
                  onClick={(e) => {
                    validation.setFieldValue("category", "Non-Member")
                  }}
                />
                <label htmlFor="event" className="me-3">
                  Non-Member
                </label>
              </div>
            </fieldset>
            {validation.touched.category && validation.errors.category ? (
              <div
                style={{
                  fontSize: "12px",
                  color: "red",
                }}
              >
                {validation.errors.category}
              </div>
            ) : null}
          </div>

          <div className="row">
            <div className="col-6 ">
              <button
                className="btn btn-outline-danger btn-block"
                data-bs-toggle="collapse"
                onClick={() => {
                  SetModalMember(false)
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-outline-primary btn-block"
                data-bs-toggle="collapse"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default BlogDetailsLeftBar
