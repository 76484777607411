import React, { useState } from "react"
import { Link } from "react-router-dom"
import { AiTwotoneHome, AiFillMail, AiFillPhone } from "react-icons/ai"
import { TbWorldSearch } from "react-icons/tb"
import indivdual_donar from "../../assets/pdf/SATS_Individual_Donor_Levels_2023.pdf"
import { VscOrganization } from "react-icons/vsc"
import corporate_pdf from "../../assets/pdf/SATS Sponsorship Levels 2023-2024 10162023.pdf"
import president from "../../assets/images/TheSigningOfficers/selvagiri.jpg"
import PageBanner from "../../layouts/PageBanner"
import { phoneNumber } from "../../helpers/Api_help"
import SpForm from "./SpForm"
import bg from "../../assets/images/banner/bnr5.jpg"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"

import { IMAGES } from "../../constant/theme"

const SponserLevel = () => {
  const conatctblog = [
    {
      title: (
        <p>
          Mr. Bharathi Rathinavel,
          <br />
          4835 Usaa Blvd Apt# 1102, San Antonio, Texas - 78240
        </p>
      ),
      image: <AiTwotoneHome />,
    },
    { title: "sats@satamilsangam.org", image: <AiFillMail /> },

    {
      title: "www.satamilsangam.com",
      image: <TbWorldSearch />,
    },
    { title: "EIN # 81-0915793", image: <VscOrganization /> },
  ]

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Home"
          pagetitle="Sponsorship Levels"
          background={bg}
        />
        <section className="content-inner-2  section-pattren1">
          <div className="container">
            <div className="row project-bx">
              <div className="col-xl-8 col-lg-8 ">
                <div
                  className="fundraiser-single"
                  style={{ borderBottom: "1px solid #707070" }}
                >
                  {/* <div className="swiper fundraiser-gallery-wrapper"></div> */}
                  <h2 className="title">Sponsorship Levels</h2>
                  <p style={{ lineHeight: 1.5 }}>
                    There are three sponsorship levels for corporate levels and
                    four sponsorship levels for individual levels, defined by
                    donation amount and in-return benefits of sponsorship.
                  </p>
                  <div className="row justify-content-center">
                    <div className="col-3 me-3">
                      <div style={{ display: "block", textAlign: "center" }}>
                        <a
                          href={corporate_pdf}
                          target="_blank"
                          rel="noreferrer"
                          className="mx-1"
                        >
                          <div>
                            {" "}
                            <img
                              src={IMAGES.corporate}
                              style={{ width: "30%" }}
                            />
                          </div>
                          <div>
                            {" "}
                            <span className="fw-bold">Corporate</span>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-3">
                      <div style={{ textAlign: "center" }}>
                        <a
                          href={corporate_pdf}
                          target="_blank"
                          rel="noreferrer"
                          className="mx-1"
                        >
                          <div>
                            {" "}
                            <img
                              src={IMAGES.individual}
                              style={{ width: "30%" }}
                            />
                          </div>
                          <div>
                            {" "}
                            <span className="fw-bold">Individual</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="contact-area">
                    <h2 className="title">Submit Your Details</h2>
                    <SpForm />
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4">
                <aside className="side-bar sticky-top">
                  {/* <!-- Fundraising Team --> */}

                  <div className="widget style-1 widget_avatar">
                    <div className="widget-title" style={{ marginBottom: 10 }}>
                      <h5 className="title">Payment:</h5>
                      <p style={{ lineHeight: 1.5 }}>
                        Please make checks payable to SA TAMIL SANGAM and mail
                        the payment with this form to:
                      </p>
                    </div>
                    <div className="avatar-wrapper">
                      <div className="fw-bold text-dark mb-2">Treasurer</div>
                      {conatctblog.map((item, ind) => (
                        <div className="avatar-item" key={ind}>
                          <div
                            className="avatar-media"
                            style={{ fontSize: "27px", marginBottom: "2px" }}
                          >
                            <Link to={"#!"}>{item.image}</Link>
                          </div>
                          <div className="avatar-info">
                            <h6
                              className="title"
                              style={{ fontSize: "15px", marginBottom: "2px" }}
                            >
                              {item.title}
                            </h6>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
          <img src={shape2} className="shape-1 move-1" alt="shape" />
          <img src={shape2} className="shape-2 move-2" alt="shape" />
          <img src={shape6} className="shape-3 move-1" alt="shape" />
        </section>
      </div>
    </>
  )
}

export default SponserLevel
