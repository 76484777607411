import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
// import "swiper/css"
// import "swiper/css/pagination"
// import "swiper/css/navigation"
// import "../../assets/css/mainSlider.css"
import { Autoplay, Pagination, Navigation } from "swiper"
import { ImLocation2 } from "react-icons/im"
import { RiTimerFlashFill } from "react-icons/ri"

import PageBanner from "../../layouts/PageBanner"

const TBC = [
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-21.jpg"),
    caption: "",
  },
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-22.jpeg"),
    caption: "",
  },
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-23.jpeg"),
    caption: "",
  },
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-24.jpeg"),
    caption: "",
  },
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-25.jpeg"),
    caption: "",
  },
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-26.jpeg"),
    caption: "",
  },
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-27.jpeg"),
    caption: "",
  },
  {
    img: require("../../assets/images/SATS club/thinnai/thinnai-slide-28.jpeg"),
    caption: "hii",
  },
]

const ThinnaiBookClub = () => {
  const progressCircle = useRef(null)
  const progressContent = useRef(null)
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress)
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
  }
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Home"
          pagetitle="ThinnaiBookClub"
          // background={bg}
        />
        <section className="content-inner">
          <div className="container text-dark">
            <h1 className="h1 fw-bold text-primary sub-title">
              Thinnai Book Club
            </h1>
            <div className="col-12 d-flex flex-wrap justify-content-between  mb-5">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <h6 className="fw-bolder">
                  சான் ஆண்டோனியோ-வாழ் வாசகர்களுக்கு வணக்கம்!
                </h6>
                <p>தமிழ் வாசிப்பின் சுகம் அறிந்தவரா நீங்கள் ?</p>
                <p>
                  தேர்ந்தெடுத்துப் படித்த, பிடித்த நூல்களை விவாதிக்க ஆர்வம்
                  உள்ளவரா ?
                </p>
                <p>தேடித்தேடி சேர்த்த புத்தகங்களை ஊரில் விட்டுவிட்டு,</p>
                <p>
                  இங்கு பிள்ளைகளின் பாடநூல்களை வாஞ்சையோடு வருடியபடியே இணையத்தில்
                  தமிழ் படிக்கும் நவீனத் தமிழ் வாசகரா ?
                </p>
              </div>
              <div className="container col-lg-6 col-md-12 col-sm-12 mb-3">
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                >
                  {TBC.map((item, ind) => (
                    <SwiperSlide key={ind}>
                      <img src={item.img} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            {/* tamil */}
            <div>
              <div className="section-head">
                <h6 className="sub-title">
                  ‘திண்ணைக்கு உங்களை அன்புடன் வரவேற்கிறோம் !
                </h6>
                <p>
                  ‘திண்ணை’, சான் ஆண்டோனியோவின் முதல் தமிழ் வாசகர்வட்டம்.தமிழ்
                  இலக்கியம், வாழ்வியல் மற்றும் கருத்தியல் போக்குகளை விவாதிக்கும்
                  தளம். பிப்ரவரி 2017 முதல் இயங்கிவரும் திண்ணையில் ஒவ்வொரு
                  மாதமும் ஒரு புத்தகம் தேர்வு செய்யப்பட்டு அது உறுப்பினர்கள்
                  அனைவருக்கும் தெரிவிக்கப்படும். அப்புத்தகத்தின் மின்னூல் வடிவம்
                  அனைவருக்கும் அனுப்பப்படும். மாதத்தின் இறுதி ஞாயிறன்று
                  நடைபெறும் திண்ணைக் கூட்டத்தில் உறுப்பினர்கள் கலந்துகொண்ட அந்த
                  புத்தகத்தைப் பற்றி உரையாடலாம்.
                </p>
                <address>
                  <div
                    className="text-dark text-start d-block bg-light p-3 rounded mb-2"
                    style={{ lineHeight: "16px" }}
                  >
                    <p>
                      <ImLocation2 fontSize={"20px"} className="text-warning" />
                      இடம்:
                    </p>
                    <p>எட்மண்ட் கோடி நூலகம்,</p>
                    <p>11441 வான்ஸ் ஜாக்சன்,</p>
                    <p>சான் அன்டோனியோ, TX78230.</p>
                  </div>
                  <br />
                  <div
                    className="text-dark text-start d-block  bg-light p-3 rounded"
                    style={{ lineHeight: "16px" }}
                  >
                    <p>
                      <RiTimerFlashFill
                        fontSize={"20px"}
                        className="text-warning"
                      />
                      நேரம்:
                    </p>
                    <p>ஒவ்வொரு மாதமும் இறுதி ஞாயிறன்று</p>
                    <p>காலை 10.30 முதல் 12.30 வரை</p>{" "}
                    <p>அனைவரும்வருக!வாசிப்போம்...</p>
                    <p>தமிழில்... </p>
                    <p>சுவாசிப்போம்!</p>
                  </div>

                  {/* <span><img src="../images/clock-fill.svg" alt="" />நேரம்:<br /></span>
                    <pre> ஒவ்வொரு மாதமும் இறுதி ஞாயிறன்று<br/> காலை 10.30 முதல் 12.30 வரை<br/> அனைவரும்வருக!வாசிப்போம்...<br> தமிழில்... <br> சுவாசிப்போம்!</pre> */}
                </address>
                <p>
                  வாசகர்வட்டத்தில் இணைய, மேலும் விபரங்களுக்கு தொடர்பு கொள்ளவும்:
                  தன்யாமாதவன் 210-454-4802/venusgirl.waitin@gmail.com
                </p>
              </div>
              <p className="h6 fw-bold">Hello, fellow book-lovers!</p>
              <ul
                className="fw-bolder h6 list-check-3"
                style={{
                  lineHeight: "2.7",
                  paddingLeft: "2rem",
                  marginLeft: "1rem",
                  borderLeft: "0.9rem solid #416bdb",
                }}
              >
                <li> Do you love to read in Tamil?</li>
                <li>
                  Would you like to meet the other book-heads from our Tamil
                  community?
                </li>
                <li>
                  How about a cup of tea and some ‘heart-to-heart’ over a book?
                </li>
              </ul>
            </div>
            {/* //English */}
            <div>
              <div className="section-head mt-5">
                <h6 className="sub-title">
                  Welcome to ‘Thinnai’ - San Antonio’s First Tamil Book Club!
                </h6>
                <p>
                  Started in February 2017, Thinnaicreates a platform to bring
                  together the readers of Tamil language in the city.In Thinnai,
                  we seek to establish an active circle of Tamil readers and
                  eventually build a collection of books for them to read. The
                  club meets on the last Sunday of every month at the public
                  library to discuss that month's selection.
                </p>
                <address>
                  <div
                    className="text-dark text-start d-block bg-light p-3 rounded mb-2"
                    style={{ lineHeight: "16px" }}
                  >
                    <p>
                      <ImLocation2 fontSize={"20px"} className="text-warning" />
                      Place:
                    </p>
                    <p>Edmund Cody Library,</p>
                    <p>11441 Vance Jackson,</p>
                    <p>San Antonio, TX 78230</p>
                  </div>
                  <br />
                  <div
                    className="text-dark text-start d-block  bg-light p-3 rounded"
                    style={{ lineHeight: "16px" }}
                  >
                    <p>
                      <RiTimerFlashFill
                        fontSize={"20px"}
                        className="text-warning"
                      />
                      Time:
                    </p>
                    <p>Last Sunday of every month</p>
                    <p>Between 10.30 am and 12.30 pm</p>{" "}
                    <p>அனைவரும்வருக!வாசிப்போம்...</p>
                    <p>தமிழில்... </p>
                    <p>சுவாசிப்போம்!</p>
                  </div>

                  {/* <span><img src="../images/clock-fill.svg" alt="" />நேரம்:<br /></span>
                    <pre> ஒவ்வொரு மாதமும் இறுதி ஞாயிறன்று<br/> காலை 10.30 முதல் 12.30 வரை<br/> அனைவரும்வருக!வாசிப்போம்...<br> தமிழில்... <br> சுவாசிப்போம்!</pre> */}
                </address>
                <p>
                  For more information, please contact: Dhanya Madhavan
                  210-454-4802 / venusgirl.waitin@gmail.com
                </p>
              </div>
              <p className="h6 fw-bold">Hello, fellow book-lovers!</p>
              <ul
                className="fw-bolder h6 list-check-3"
                style={{
                  lineHeight: "2.7",
                  paddingLeft: "2rem",
                  marginLeft: "1rem",
                  borderLeft: "0.9rem solid #416bdb",
                }}
              >
                <li>
                  Related links and resources:
                  <a
                    className="btn-sm mx-2 btn-primary"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://www.facebook.com/ThinnaiTheTamilBookClubOfSAT"
                  >
                    Click here!
                  </a>
                </li>
                <li>
                  Thinnai Photo Gallery:
                  <a
                    className="btn-sm mx-2 btn-primary"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://goo.gl/photos/dqqeYFuswtxTrxQ69"
                  >
                    Click here!
                  </a>
                </li>
                <li>
                  Thinnai - A Video Introduction:
                  <a
                    className="btn-sm mx-2 btn-primary"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://youtu.be/RYYkEyDDesA"
                  >
                    Click here!
                  </a>
                </li>
                <li>
                  Thinnai at SATS New Year Cultural Program:
                  <a
                    className="btn-sm mx-2 btn-primary"
                    target={"_blank"}
                    rel="noreferrer"
                    href="https://youtu.be/I5efdhjP4HA"
                  >
                    Click here!
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default ThinnaiBookClub
