import React, { useRef } from "react"

import PageBanner from "../../layouts/PageBanner"
// import "../assets/css/mainSlider.css"
import { AboutUSBanner } from "../../constant/theme"
//images
import bgImage from "../../assets/images/banner/bnr1.jpg"
import pic1 from "../../assets/images/main-slider/slider2/pic1.png"
import bg4 from "../../assets/images/background/bg4.jpg"

import NewsSlider from "../../components/Home/NewsSlider"
import UpdateBlog from "../../components/Home/UpdateBlog"
import OurMission from "../../components/OurMission"
import { IMAGES } from "../../constant/theme"
import { MobileQRCode } from "../../constant/theme"

const iconBlog = [
  {
    title: "Mission",
    image: IMAGES.Mission,
    link: "/missionvision",
    para: (
      <p className="text-justify">
        To foster fraternity among the Tamil Speaking community living in San
        Antonio to create and promote interest and knowledge of Tamil language
        and culture in the community, by organizing educational, literary,
        cultural, social and charitable activities in a non-partisan, secular
        and non-political manner strictly conforming to the laws of the country
      </p>
    ),
  },
  {
    title: "Vision",
    image: IMAGES.Vision,
    limk: "/missionvision",
    para: (
      <p className="text-justify">
        To cultivate, promote, foster, and develop the advancement of knowledge
        in Tamil language, literature and culture.
      </p>
    ),
  },
]

// const wrapperBlog = [
//   { icon: "flaticon-vr-glasses", title: "Quo Maxime Qui Impedit Odio Soluta" },
//   {
//     icon: "flaticon-transformation",
//     title: "Ut Nisi Ea Vero Itaque Error Magnam",
//   },
//   { icon: "flaticon-doctor-bag", title: "Quaerat Nobis Est" },
//   { icon: "flaticon-open-book", title: "Deleniti Iure Ipsa Eos Eaque Under" },
// ]

const AboutUs = () => {
  const progressCircle = useRef(null)
  const progressContent = useRef(null)
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress)
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
  }
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="About us" />
        <section className="content-inner-2 section-wrapper5">
          <div className="container text-dark">
            <div className="row align-items-center">
              <div
                className="col-lg-12 d-flex flex-wrap justify-content-between align-items-center
              "
              >
                <div className="section-head col-lg-6 col-md-6 col-sm-12">
                  {/* <h5 className="sub-title">Who We Are</h5> */}
                  <h3>
                    வணக்கம்!!
                    <br />
                    <span style={{ color: "#f7c80f" }}>
                      சான் ஆண்டோனியோ <br />
                      தமிழ்ச் சங்கம்!
                    </span>{" "}
                    <br />
                    <span style={{ fontSize: "24px" }}>
                      உங்களை அன்புடன் வரவேற்கிறது
                    </span>
                  </h3>
                  <h2>
                    Welcome To <br />
                    San Antonio Tamil Sangam
                  </h2>
                  <p className="m-t20 text-justify">
                    San Antonio Tamil Sangam (SATS) is registered, non-profit,
                    tax-exempt 501(c)(3) organization. The SATS was founded in
                    1990 by a group of Tamil people living in San Antonio. The
                    San Antonio Tamil Sangam, also commonly known as SATS,
                    actively promotes the Tamil Language and Culture within the
                    Tamil Community in San Antonio and to the larger Western
                    American Community. SATS promotes harmonious integration of
                    Tamils in their new home.
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12 ">
                  <img src={IMAGES.Thiruvalluvar} alt="aboutus" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="call-action style-1 pb-5 ">
          <p className="text-center fw-bolder mb-3 h3">
            தமிழால் இணைவோம் வாருங்கள்
          </p>
          <div className=" col-12 container d-flex justify-content-center ">
            <div
              className="col-2 p-3 me-5"
              // style={{ borderRight: "1px solid #dedede" }}
            >
              <img src={MobileQRCode.Android} />
              <p className="m-0 mt-2 p-0" style={{ textAlign: "center" }}>
                Android Play Store
              </p>
            </div>
            <div className="col-2 p-3">
              <img src={MobileQRCode.IOS} />
              <p className="m-0 mt-2 p-0" style={{ textAlign: "center" }}>
                IOS App Store
              </p>
            </div>

            {/* <UpdateBlog /> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
