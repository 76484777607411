import React from 'react';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';


import slide from './../assets/images/main-slider/pic1.png';
import { YouthWingsimg } from "../constant/theme"

const CounterBlog = [
    {title:"Total Donor", number:"4556"},
    {title:"Volunteer", number:"874"},
    {title:"Total Donation", number:"195234"},
];

const YouthWingBlock = () =>{
    return(
        <>
            <div className="col-lg-5">
                <div className="dz-media">
                    <img src={YouthWingsimg.logo1} alt="image" />
                </div>
            </div>
            <div className="col-lg-7 m-b50">
                <div className="section-head">
                    <h3 className='text-primary'>SATS Youth Wing (இளைஞர் பாசறை)</h3>
                    <p className="m-t20" style={{lineHeight:1.5}}>San Antonio Tamil Sangam Youth Wing proudly welcomes our future leaders. Today’s youth are tomorrow’s leaders and SATS empowers future leaders with the power for the development of the community.</p>
                </div>

                <div className="section-head">
                    <h6 className='text-primary'>First Priority</h6>
                    <p className="m-t20" style={{ lineHeight: 1.5, fontSize:13 }}> Our first priority is to promote participation in
                        community volunteering, mentoring relationships that
                        support the critical, social, and emotional
                        development needed to help build resilience among
                        youth.</p>
                </div>

                <div className="section-head">
                    <h6 className='text-primary'>Our Mission</h6>
                    <p className="m-t20" style={{ lineHeight: 1.5, fontSize:13 }}>To support and mentor our youth and to ignite their
                        power and potential. In addition, SATS will provide a
                        safe, productive and social environment to youths and
                        promote self-growth.</p>
                </div>

            
                <div className="section-head">
                    <h6 className='text-primary'>Interested to join Youth of San Antonio Tamil Sangam (YOSATS)</h6>
                    <Link
                      to={"/youthwingForm"}
                      className="btn btn-sm btn-warning text-light "
                    >
                      Join Here
                    </Link>
                </div>
                
            </div>
        </>
    )
}
export default YouthWingBlock;