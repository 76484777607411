import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { contactblog } from "../../components/ContactBlog"
import president from "../../assets/images/TheSigningOfficers/vasudevan.jpeg"
import PageBanner from "../../layouts/PageBanner"
import { phoneNumber } from "../../helpers/Api_help"
import CountUp from "react-countup"
// import BMform from "./BMform"

import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import { IMAGES } from "../../constant/theme"
import Subscribe from "../../components/Subscribe"
import { fetchMembershipTypes } from "../../features/FetchData"
import { useSelector, useDispatch } from "react-redux"
const BecomeMember = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { MembershipTypes } = useSelector((state) => state.FetchDataReducer)
  useEffect(() => {
    dispatch(fetchMembershipTypes())
  }, [])
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Become a member" />

        {/* <section
          className="gradient-white"
          style={{
            // backgroundImage: "url(" + BgImage + ")",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-9 col-md-9 col-sm-12">
                <div className="form-wrapper style-3">
                  <div className="contact-area">
                    <BMform />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="content-inner-2 bg-white" style={{paddingTop:30}}>
          <div className="container">
            <div className="row about-bx3 align-items-center">
              <div className="col-lg-5">
                <div className="dz-media">
                  <img src={IMAGES.BecomeaMember} alt="image" />
                </div>
              </div>
              <div className="col-lg-7 m-b50">
                <div className="section-head">
                  <p className="m-t20" style={{lineHeight:1.5}}>
                    Welcome! Joining SATS is the perfect way to stay connected
                    with the most recent developments in the San Antonio Tamil
                    Sangam (SATS) world. Join SATS today, and put the power of
                    membership on your side.
                  </p>
                  <p style={{lineHeight:1.5}}>
                    Use this secure online application
                    {/* <a
                      href="https://satamilsangam.org/documents/satsmembershipform.pdf"
                      target="_blank"
                      rel="noreferrer"
                      className="mx-1"
                    >
                      download
                    </a> 
                    application*/}
                    . All applicants are welcome and encouraged to apply. Should
                    you have any questions as you complete the admissions
                    process, please contact our admissions office at
                    {` ${phoneNumber} or `}
                    <a
                      href="mailto:sats@satamilsangam.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      sats@satamilsangam.org
                    </a>
                    . Life Membership and Annual Family, Annual Single and
                    Student Membership is available. see below for fee
                    structure.
                  </p>

                  <div className="col-12   mb-3 d-flex flex-column ">
                    <div className="btn-group d-flex flex-nowrap " role="group">
                      {MembershipTypes.map((item, ind) => (
                        <>
                          <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id={item._id}
                            autoComplete="off"
                            onClick={(e) => {
                              e.preventDefault()
                              nav("/registration")
                            }}
                          />
                          <label
                            className="btn btn-outline-warning d-flex flex-column"
                            htmlFor={item._id}
                          >
                            <p>{`${item.membership_name}  ${
                              item.term === 1 ? "(Yr)" : ""
                            }`}</p>

                            <h6>
                              $
                              <span className="counter">
                                <CountUp
                                  end={item.fees}
                                  separator=","
                                  duration="0"
                                />
                              </span>
                              *
                            </h6>
                          </label>
                        </>
                      ))}
                    </div>
                  </div>
                  <Link
                    to={"/registration"}
                    className="btn btn-warning align-items-end "
                  >
                    {" "}
                    To Join with Us
                  </Link>
                </div>
              </div>
            </div>
            <div className="container mt-5 pb-5">
              <div className="dz-card style-4 bg-secondary ">
                <div className="dz-info" style={{ padding: 50 }}>
                  <div className="row align-items-center">

                  <div className="col-sm-12 col-lg-6">
                    <div className="dz-meta">
                      <ul>
                        <li className="post-author">
                          <img src={president} alt="" style={{ width: 80, height: 80, marginBottom: 10 }} />
                          <div style={{ lineHeight: 1, fontSize:18 }}> Mr.Vasudevan Natarajan </div>
                          <div> President </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-sm-12 col-lg-6">
                  <h5 className="dz-title">
                    <Link to={"/blog-details"} style={{ fontSize: 15 }}>
                      Contact us
                    </Link>
                  </h5>
                  <div className="avatar-wrapper">
                    {contactblog.map((item, ind) => (
                      <div className="avatar-item" key={ind}>
                        <div className="avatar-info">
                          <h6
                            className="title"
                            style={{ fontSize: "15px", marginBottom: "2px" }}
                          >
                            <Link to={"/contact-us"} style={{ color: "#fff" }}>
                              {item.title}
                            </Link>
                          </h6>
                        </div>
                      </div>
                    ))}
                  </div>
                  </div>

                  </div>
                  
                  
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default BecomeMember
