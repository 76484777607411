import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { fetchLifeMember } from "../../features/FetchData"

import PageBanner from "../../layouts/PageBanner"
import { useDispatch, useSelector } from "react-redux"
import Avatarpng from "../../assets/images/lifememberavatar.png"

import { ApiServer } from "../../helpers/Api_help"

const LifeMember = () => {
  const dispatch = useDispatch()
  const { LifeMember, Success, fail } = useSelector(
    (state) => state.FetchDataReducer
  )

  useEffect(() => {
    dispatch(fetchLifeMember())
  }, [])
  // console.log(LifeMember)
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Lifetime members" />
        <section className="content-inner-2">
          <div className="container">
            <div className="row">
              {LifeMember.map((item, ind) => (
                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4" key={ind}>
                  <div className="dz-team style-2 m-b30">
                    <div className="dz-media">
                      <Link to={"#!"}>
                      <img
                          src={item.profile_img ? `${ApiServer}uploads/${item.profile_img}` : Avatarpng}
                          alt=""
                          style={{
                            height: "230px",
                            objectFit: "contain",
                          }}
                        />
                      </Link>
                      {/* <ul className="team-social">
                        <li>
                          <a
                            href="https://www.facebook.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="fab fa-facebook-f"
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="fab fa-instagram"
                          ></a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="fab fa-twitter"
                          ></a>
                        </li>
                      </ul> */}
                    </div>
                    <div className="dz-content">
                      <h6 className="dz-name">
                        {item.firstname} {item.lastname}
                      </h6>
                      {/* <span className="dz-position text-primary">
                        {item.subtitle}
                      </span> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default LifeMember
