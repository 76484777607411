import React, { useState, useEffect } from "react"
import { Dropdown } from "react-bootstrap"
import { Link } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion"
import GalleryCoverPic from "../assets/images/Gallerycoverpic.png"

import { useDispatch, useSelector } from "react-redux"
import { fetchGallery } from "../features/FetchData"
// import { ImageApi } from "../API_hepler/Api_help"
import ApiServer from "../helpers/Api_help"

const GalleryMission = ({ year, searchQuery }) => {
  const { Gallery, Success, fail } = useSelector(
    (state) => state.FetchDataReducer
  )

  // const [popular, setPopular] = useState(Gallery)
  const [filtered, setFiltered] = useState([])
  // const [activeGenre, setActiveGenre] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    if (year) dispatch(fetchGallery(year))
    // console.log("hii")
  }, [year])

  useEffect(() => {
    setFiltered(Gallery)
  }, [Success])

  useEffect(() => {
    const searchedData = Gallery.filter((item) => {
      const itemName = item.ga_title.toLowerCase() // Convert item name to lowercase for case-insensitive search
      const searchValue = searchQuery.toLowerCase() // Convert search query to lowercase for case-insensitive search

      // Check if the item's name contains the search query
      return itemName.includes(searchValue)
    })
    setFiltered(searchedData)
    console.log(searchedData)
  }, [searchQuery])

  // function fetchPopular() {
  //   setPopular(cardData)
  //   setFiltered(cardData)
  // }
  // console.log(Gallery)
  // useEffect(() => {
  //   if (activeGenre === 0) {
  //     setFiltered(cardData)
  //     return
  //   }
  //   const filtered = popular.filter((card) => card.cardid.includes(activeGenre))
  //   setFiltered(filtered)
  // }, [activeGenre])

  return (
    <>
      <div className="row m-b30">
        {/* <div className="col-xl-10 col-lg-9">
          <div className="site-filters style-1 clearfix">
            <ul className="filters" data-bs-toggle="buttons">
              <li className={`btn ${activeGenre === 0 ? "active" : ""}`}>
                <Link to={"#"} onClick={() => setActiveGenre(0)}>
                  All Projects
                </Link>
              </li>
              <li
                data-filter=".Technology"
                className={`btn ${activeGenre === 1 ? "active" : ""}`}
              >
                <Link to={"#"} onClick={() => setActiveGenre(1)}>
                  Technology
                </Link>
              </li>
              <li
                data-filter=".Medical"
                className={`btn ${activeGenre === 2 ? "active" : ""}`}
              >
                <Link to={"#"} onClick={() => setActiveGenre(2)}>
                  Medical
                </Link>
              </li>
              <li
                data-filter=".Business"
                className={`btn ${activeGenre === 3 ? "active" : ""}`}
              >
                <Link to={"#"} onClick={() => setActiveGenre(3)}>
                  Business
                </Link>
              </li>
              <li
                data-filter=".Fashion"
                className={`btn ${activeGenre === 4 ? "active" : ""}`}
              >
                <Link to={"#"} onClick={() => setActiveGenre(4)}>
                  Fashion
                </Link>
              </li>
            </ul>
          </div>
        </div> */}
        {/* <div className="col-xl-2 col-lg-3 text-start text-lg-end m-b20">
          <Dropdown className="select-drop">
            <Dropdown.Toggle as="div" className="i-false select-drop-btn">
              <span>{dropbtn}</span>
              <i className="fa-regular fa-angle-down"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setDropbtn("Newest")}>
                Newest
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setDropbtn("Oldest")}>
                Oldest
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div>
      <div className="clearfix">
        <ul
          //layout
          id="masonry"
          className="row"
          //transition={{ duration: 0.3 }}
        >
          <AnimatePresence>
            {filtered.length > 0
              ? filtered.map((item, index) => {
                  return (
                    <motion.li
                      layout
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="card-container col-xl-4 col-lg-6 col-md-6 col-sm-12 Fashion m-b30"
                      key={index}
                      //transition={{ duration: 0.5 }}
                    >
                      <div className="dz-card style-2 overlay-skew card h-100">
                        <div className="dz-media">
                          <Link to={"#!"}>
                            <img
                              src={
                                item.ga_cover_pic == null
                                  ? GalleryCoverPic
                                  : `${ApiServer}assets/gallery_cover/${item.ga_cover_pic}`
                              }
                              style={{
                                objectFit: "contain",
                                // width: "auto",
                                height: "300px",
                              }}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="dz-info">
                          <ul className="dz-category">
                            <li>
                              <Link to={"#"}>{item.ga_title}</Link>
                            </li>
                          </ul>
                          {/* <h5 className="dz-title"></h5> */}
                          <div className="progress-bx style-1 d-flex gap-2 ">
                            {item?.ga_photo_url && (
                              <a
                                href={item.ga_photo_url}
                                className="text-info fw-bold"
                                target="_blank"
                              >
                                View Photos
                              </a>
                            )}
                            {item?.ga_video_url && (
                              <a
                                href={item.ga_video_url}
                                className="text-warning fw-bold"
                                target="_blank"
                              >
                                View vidoes
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </motion.li>
                  )
                })
              : Gallery.map((item, index) => {
                  return (
                    <motion.li
                      layout
                      initial={{ scale: 0, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0, opacity: 0 }}
                      transition={{ duration: 0.2 }}
                      className="card-container col-xl-4 col-lg-6 col-md-6 col-sm-12 Fashion m-b30"
                      key={index}
                      //transition={{ duration: 0.5 }}
                    >
                      <div className="dz-card style-2 overlay-skew card h-100">
                        <div className="dz-media">
                          <Link to={"#!"}>
                            <img
                              src={
                                item.ga_cover_pic == null
                                  ? GalleryCoverPic
                                  : `${ApiServer}assets/gallery_cover/${item.ga_cover_pic}`
                              }
                              style={{
                                objectFit: "contain",
                                // width: "auto",
                                height: "300px",
                              }}
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="dz-info">
                          <ul className="dz-category">
                            <li>
                              <Link to={"#"}>{item.ga_title}</Link>
                            </li>
                          </ul>
                          {/* <h5 className="dz-title"></h5> */}
                          <div className="progress-bx style-1 d-flex gap-2 ">
                            {item?.ga_photo_url && (
                              <a
                                href={item.ga_photo_url}
                                className="text-info fw-bold"
                                target="_blank"
                              >
                                View Photos
                              </a>
                            )}
                            {item?.ga_video_url && (
                              <a
                                href={item.ga_video_url}
                                className="text-warning fw-bold"
                                target="_blank"
                              >
                                View vidoes
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </motion.li>
                  )
                })}
          </AnimatePresence>
        </ul>
      </div>
    </>
  )
}

export default GalleryMission
