import React, { useRef, useState } from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
import { BannerMain } from "../../constant/theme"
// Import Swiper styles

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import { ApiServer } from "../../helpers/Api_help"
import "../../assets/css/mainSlider.css"
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper"

export default function MainSliderIndex3({ Banner }) {
  const progressCircle = useRef(null)
  const progressContent = useRef(null)
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress)
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
  }
  // console.log(Banner)
  return (
    <>
      <div id="sliderbody">
        <div id="sliderapp">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoHeight={true}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            onAutoplayTimeLeft={onAutoplayTimeLeft}
            className="mySwiper"
          >
            {/* {BannerMain.map((img, index) => (
              <SwiperSlide key={index}>
                <img src={img.HomemainSlider} />
              </SwiperSlide>
            ))} */}
            {Banner?.map((img, index) => (
              <SwiperSlide key={index} className="position-relative">
                <img
                  src={`${ApiServer}assets/banner/${img.ba_bannerphoto}`}
                  alt="Not Found"
                />
                {img?.ba_description && (
                  <div
                    className="text-justify d-none d-md-block position-absolute top-50  bg-dark rounded col-7 p-3 mb-5 "
                    data-aos="slide-up"
                    // data-aos-delay="800"
                    style={{ opacity: "0.7", color: "#fff" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: img.ba_description }}
                    ></div>

                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 16 16"
                      className="position-absolute top-100 start-50 translate-middle mt-1 bi bi-caret-down-fill"
                      fill="#212529"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                    </svg>
                  </div>
                )}
              </SwiperSlide>
            ))}

            {/* <SwiperSlide>
              <img src={IMAGES.HomemainSlider1} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={IMAGES.HomemainSlider2} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={IMAGES.HomemainSlider3} />
            </SwiperSlide>
            <SwiperSlide>
              <img src={IMAGES.HomemainSlider4} />
            </SwiperSlide> */}

            <div className="autoplay-progress" slot="container-end">
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <span ref={progressContent}></span>
            </div>
          </Swiper>
        </div>
      </div>
    </>
  )
}
