import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { SlClose } from "react-icons/sl"

import { useFormik } from "formik"
import { Alert, Modal } from "react-bootstrap"
import { Label, FormFeedback, Input, Form, Col, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { fetchMyProfileData } from "../../features/FetchData.jsx"

import PageBanner from "../../layouts/PageBanner.jsx"
import bg from "../../assets/images/banner/bnr4.jpg"
import shape2 from "../../assets/images/pattern/shape2.png"
import shape3 from "../../assets/images/pattern/shape3.png"
import shape5 from "../../assets/images/pattern/shape5.png"
import shape1 from "../../assets/images/pattern/shape1.png"
import shape6 from "../../assets/images/pattern/shape6.png"
import Bylawspdf from "../../assets/pdf/bylaws.pdf"
import Bylawimg from "../../assets/images/by-law.jpg"

import { TempBookingCreate, Reset } from "../../features/Event/BookingSlice.jsx"
import TicketBookingButton from "../../components/Paypal/TicketBookingButton.jsx.jsx"
import {
  fetchSelectedVenue,
  FindRemainingTickets,
} from "../../features/Event/EventTBSlice.jsx"
import { fetchMywallet } from "../../features/FetchData.jsx"
import _ from "lodash"

import moment from "moment"

const EventBookingF = () => {

  const dispatch = useDispatch()


  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))
  const selectedEvent = JSON.parse(localStorage.getItem("SelectVenue"))


  const { MyProfileInfo } = useSelector((state) => state.FetchDataReducer)

  const { TempBookindDetails, TempBookingFail, TempBookingSuccess } = useSelector((state) => state.BookingReducer)
  const { SelectedVenueData, Ticketperuser } = useSelector((state) => state.EventTBReducer)

  const [PaypalModal, setPaypalModal] = useState(false)
  const [bookingType, setBookingType] = useState(null)
  const [isBookingOpen, setBookingOpen] = useState(null)

  const [dataloading, setDataLoading] = useState(true)

  const maxTicketPerPerson = 6
  const [availableSeats, setAvaialbleSeats] = useState(6)
  const [totalTickets, setTotalTickets] = useState(0)

  const [adultCount, setAdultCount] = useState(0);
  const [childCount, setChildCount] = useState(0);
  const [adultTotalTicketPrice, setATTP] = useState(0);
  const [childTotalTicketPrice, setCTTP] = useState(0);
  const [totalTicketPrice, setTTP] = useState(0);

  const [errormsg, setErrormsg] = useState("")

  const [ticketBookingInfo, setTicketBookingInfo] = useState(null)

  const [selectedCategory, setSelectedCategory] = useState(null)




  useEffect(() => {
    console.log("am in", selectedEvent)
    //const selectedEvent = JSON.parse(localStorage.getItem("SelectVenue"))
    dispatch(fetchSelectedVenue(selectedEvent))
  }, [])

  useEffect(() => {
    console.log("SelectedVenueData", SelectedVenueData)
    setDataLoading(true)
    if (SelectedVenueData) {
      var earlyBirdIndex = _.findIndex(SelectedVenueData.ticket_allocations, { "booking_type": "early bird" })
      if (earlyBirdIndex !== -1) {
        setBookingType(SelectedVenueData.ticket_allocations[earlyBirdIndex])
        var startDate = moment.parseZone(SelectedVenueData.ticket_allocations[earlyBirdIndex].start_date).format("YYYY-MM-DD")
        var endDate = moment.parseZone(SelectedVenueData.ticket_allocations[earlyBirdIndex].end_date).format("YYYY-MM-DD")
        var date = new Date()
        var isBookingOpen = moment(date).isBetween(startDate, endDate, 'day', '[)')

        setBookingOpen(isBookingOpen)
        if (!isBookingOpen) {
          setBookingType(SelectedVenueData.ticket_allocations[SelectedVenueData.ticket_allocations.length - 1])
          var startDate = moment.parseZone(SelectedVenueData.ticket_allocations[SelectedVenueData.ticket_allocations.length - 1].start_date).format("YYYY-MM-DD")
          var endDate = moment.parseZone(SelectedVenueData.ticket_allocations[SelectedVenueData.ticket_allocations.length - 1].end_date).format("YYYY-MM-DD")
          var date = new Date()
          isBookingOpen = moment(date).isBetween(startDate, endDate, 'day', '[)')
          setBookingOpen(isBookingOpen)
        }
        setDataLoading(false)
      }
      else {
        setBookingType(SelectedVenueData.ticket_allocations[0])
        var startDate = moment.parseZone(SelectedVenueData.ticket_allocations[0].start_date).format("YYYY-MM-DD")
        var endDate = moment.parseZone(SelectedVenueData.ticket_allocations[0].end_date).format("YYYY-MM-DD")
        var date = new Date()
        var isBookingOpen = moment(date).isBetween(startDate, endDate, 'day', '[)')
        setBookingOpen(isBookingOpen)
        setDataLoading(false)

      }
    }
    else {
      //setDataLoading(false)
      //dispatch(fetchSelectedVenue(selectedEvent))
    }
  }, [SelectedVenueData])

  useEffect(() => {
    console.log("booking info", userInfo, SelectedVenueData)
    if (SelectedVenueData) {
      dispatch(
        FindRemainingTickets({
          memberID: userInfo?._id,
          master_venue_id: SelectedVenueData?._id,
        })
      )

    }
  }, [SelectedVenueData])



  useEffect(() => {
    console.log("TempBookindDetails", Ticketperuser)
    if (Ticketperuser) {
      if (!userInfo) {
        setAvaialbleSeats(6)
      }
      else {
        setAvaialbleSeats(Ticketperuser.remaining_tickets)
      }
    }
  }, [Ticketperuser])

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (SelectedVenueData && bookingType && selectedCategory) {
      var total = adultCount + childCount
      var adtPrice = userInfo && selectedCategory === "wf" ? bookingType.adult_price_wf : userInfo && selectedCategory === "wof" ? bookingType.adult_price : !userInfo && selectedCategory === "wf" ? bookingType.n_adult_price_wf : !userInfo && selectedCategory === "wof" && bookingType.n_adult_price
      var kdsPrice = userInfo && selectedCategory === "wf" ? bookingType.kids_price_wf : userInfo && selectedCategory === "wof" ? bookingType.kids_price : !userInfo && selectedCategory === "wf" ? bookingType.n_kids_price_wf : !userInfo && selectedCategory === "wof" && bookingType.n_kids_price
      // var kdsPrice = userInfo ? bookingType.kids_price : bookingType.n_kids_price
      var adltTicketPrice = adultCount * Number(adtPrice)
      var chldTicketPrice = childCount * Number(kdsPrice)
      setATTP(adltTicketPrice)
      setCTTP(chldTicketPrice)
      setTotalTickets(total)
    }
  }, [adultCount, childCount, SelectedVenueData, bookingType, selectedCategory])



  useEffect(() => {
    var totalPrice = adultTotalTicketPrice + childTotalTicketPrice
    setTTP(totalPrice)
  }, [adultTotalTicketPrice, childTotalTicketPrice])

  const getAmountWithTax = (fielddata) => {

    let fees = (2.25 / 100) * Number(totalTicketPrice) + 0.31
    let Amountvalue = (Number(totalTicketPrice) + fees).toFixed(2)

    return Amountvalue
  }

  useEffect(() => {
    if (TempBookingSuccess) {
      validation.resetForm()
      setTicketBookingInfo(TempBookindDetails)
      setPaypalModal(true)
    }
    dispatch(Reset())
  }, [TempBookingSuccess, TempBookingFail])


  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      sats_member_id: userInfo?.member_id || "",
      memberID: userInfo?._id || null,
      master_event_id: SelectedVenueData?.master_event_id || "",
      master_venue_id: SelectedVenueData?._id || "",
      event_name: SelectedVenueData?.event[0].name || "",
      venue_name: SelectedVenueData?.venue || "",
      event_date: SelectedVenueData?.event[0].start_date || "",
      venue_date: SelectedVenueData?.start_datetime || "",
      email: userInfo?.email || "",
      firstname: userInfo?.firstname || "",
      lastname: userInfo?.lastname || "",
      mobile: userInfo?.mobile || "",
      max_tickets: SelectedVenueData?.max_tickets || 0,
      remaining_tickets: SelectedVenueData?.remaining_tickets || 0,
      ticket_sold: SelectedVenueData?.ticket_sold || 0,
      adult_count: "",
      child_count: "",
      adult_amount: "",
      child_amount: "",
      net_amount: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email Id is required"),
      firstname: Yup.string().required("Enter your First Name"),
      lastname: Yup.string().required("Enter your Last Name"),

      mobile: Yup.string()
        .matches(
          /^[0-9+\-\(\) ]{8,20}$/,
          "Invalid phone number,Example :+91 7289789898 (Enter country code and Mobile number)"
        )
        .required("Mobile Number is required"),
    }),
    onSubmit: (values, actions) => {
      values["adult_count"] = adultCount
      values["child_count"] = childCount
      values["child_amount"] = adultTotalTicketPrice
      values["adult_amount"] = childTotalTicketPrice
      values["net_amount"] = getAmountWithTax()
      values["food_type"] = selectedCategory
      // console.log(values)
      if (getAmountWithTax()) {
        dispatch(TempBookingCreate(values))
        setErrormsg("")
      } else {
        setErrormsg("Your count is empty fill it to continue")
      }
    },
  })

  const handleAdultCountChange = (e) => {
    e.preventDefault()

    const re = /^[0-6\b]+$/

    // console.log("re.test(e.target.value)", re.test(e.target.value))

    if (e.target.value === "" || re.test(e.target.value)) {
      var value = String(e.target.value).charAt(
        String(e.target.value).length - 1
      )

      const newCount = Number(e.target.value)
      var getCount = newCount + Number(childCount)

      if (getCount > availableSeats) {
        setErrormsg(
          `Maximum number of tickets allowed for booking 6 per person ${User_Id_ ? `and you have remaining ${availableSeats}` : ""
          }. `
        )
        setAdultCount(0)
      } else {
        setErrormsg()
        setAdultCount(value)
      }
    } else {
      setErrormsg(`Not More than 6 allowed `)
    }
  }

  const handleChildCountChange = (e) => {
    e.preventDefault()
    const re = /^[0-6\b]+$/

    if (e.target.value === "" || re.test(e.target.value)) {
      var value = String(e.target.value).charAt(
        String(e.target.value).length - 1
      )

      const newCount = Number(e.target.value)
      var getCount = newCount + Number(adultCount)

      // console.log("am in", e.target.value, getCount)
      // if (getCount > 6) {
      if (getCount > availableSeats) {
        setErrormsg(
          `Maximum number of tickets allowed for booking 6 per person ${User_Id_ ? `and you have remaining ${availableSeats}` : ""
          }. `
        )
        setChildCount(0)
      } else {
        setErrormsg()
        setChildCount(value)
      }
    } else {
      setErrormsg(`Not More than 6 allowed `)
    }
  }

  const handleKeyDownmobile = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[0-9()\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }
  const handleKeyDowncharcters = (event) => {
    // Allow numbers and special keys like Backspace, Delete, Arrow keys, etc.
    if (
      !/^[A-Za-z\s]*$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Delete" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight"
    ) {
      event.preventDefault()
    }
  }




  if (!dataloading) {

    return (
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Booking" background={bg} />

        <div className="container mt-3">
          <div className="row">

            <div className="col-sm-12 col-lg-4">
              <div className="row">

                <div className="col-12 ">
                  <Link
                    to={"/UpComingEvent"}
                    className="fw-bold text-primary"
                    style={{ fontSize: 25, lineHeight: 1.2 }}
                  >
                    {SelectedVenueData?.event[0].name}
                  </Link>
                </div>
                <div className="col-12 mt-2">
                  <div>
                    <span
                      className="text-dark"
                      style={{ fontSize: 20, lineHeight: 0.5 }}
                    >
                      {SelectedVenueData.venue}
                    </span>
                  </div>

                  <div className="mb-3">
                    <span
                      className="text-dark"
                      style={{ fontSize: 16, lineHeight: 0.5 }}
                    >
                      {moment.parseZone(SelectedVenueData.start_datetime).format("LLLL")}
                    </span>
                  </div>
                </div>

                {
                  SelectedVenueData.organisers.length > 0 &&
                  <div
                    style={{ paddingTop: 5, marginBottom: 5 }}
                  >
                    {
                      SelectedVenueData.organisers?.map((item, ind) => (
                        <div className="col" key={ind}>
                          <p className="text-dark" style={{ lineHeight: 1.5 }}>
                            <span className="">{item.name}</span>

                            <br />
                            {item.contact_number}
                            <br />
                            {item.email_id}
                          </p>
                        </div>
                      ))}
                  </div>
                }

                <div className="col-12 ">
                  <span style={{ fontSize: 13, color: "0000ff" }}> Maximum {maxTicketPerPerson} tickets allowed per person </span>
                </div>

                {
                  isBookingOpen && bookingType.booking_type === "early bird" &&
                  <div className="col-12 ">
                    <span style={{ fontSize: 15, color: "#1f70f2", fontWeight: "700" }}>Early Bird Booking</span>
                  </div>
                }
                {
                  <div className="col-12 ">
                    <span style={{ fontSize: 13, fontWeight: "bold" }}>{userInfo ? "Member" : "Non-Member"}</span>
                  </div>
                }
                {userInfo && availableSeats !== 0 &&
                  <div className="col-12 ">
                    <span style={{ fontSize: 12, color: "red", marginBottom: 15 }}>{availableSeats} Tickets available to book</span>
                  </div>
                }

                {isBookingOpen && availableSeats !== 0 &&
                  <div className="col">
                    <div>
                      <span
                        className="text-dark fw-bold"
                        style={{ fontSize: 20, lineHeight: 0.7 }}
                      >
                        Ticket Price
                      </span>
                    </div>
                    <div className="my-1"> 
                      <span className="text-dark " style={{ fontSize: 13, lineHeight:1}}>Select Type</span><span className="text-danger">*</span>
                    </div>

                    <div className="d-flex flex-column gap-2 mb-3">
                      <div className={`bg-${selectedCategory === "wf" ? "info" : "white"} bg-opacity-25`} style={{ borderRadius: 15, cursor:"pointer" }} onClick={() => {
                        setSelectedCategory("wf")
                      }}>
                        <div className="p-2 px-3 border border-dark d-flex flex-row align-items-center" style={{ borderRadius: 15 }}>
                          <div className="me-5 text-dark" style={{ width: 130 }}>With Food</div>
                          <div className="d-flex flex-row">
                            <div className="col-auto  me-3">
                              <div>
                                <span
                                  className="text-dark "
                                  style={{ fontSize: 15, lineHeight: 1 }}
                                >
                                  Adult{" "}
                                </span>
                                <br />
                                <span
                                  className="text-primary"
                                  style={{ fontSize: 20, lineHeight: 1 }}
                                >{`$ ${!userInfo ? bookingType.n_adult_price_wf : bookingType.adult_price_wf}`}</span>
                              </div>
                            </div>
                            <div className="col-auto ">
                              <div>
                                <span
                                  className="text-dark "
                                  style={{ fontSize: 15, lineHeight: 1 }}
                                >
                                  Child{" "}
                                </span>
                                <br />
                                <span
                                  className="text-primary"
                                  style={{ fontSize: 20, lineHeight: 1 }}
                                >{`$ ${!userInfo ? bookingType.n_kids_price_wf : bookingType.kids_price_wf}`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`bg-${selectedCategory === "wof" ? "info" : "white"} bg-opacity-25`} style={{ borderRadius: 15,  cursor:"pointer" }} onClick={() => {
                        setSelectedCategory("wof")
                      }}>
                        <div className="p-2 px-3 border border-dark d-flex flex-row align-items-center" style={{ borderRadius: 15 }}>
                          <div className="me-5  text-dark" style={{ width: 130 }}>Without Food</div>
                          <div className="d-flex flex-row">
                            <div className="col-auto  me-3">
                              <div>
                                <span
                                  className="text-dark "
                                  style={{ fontSize: 15, lineHeight: 1 }}
                                >
                                  Adult{" "}
                                </span>
                                <br />
                                <span
                                  className="text-primary"
                                  style={{ fontSize: 20, lineHeight: 1 }}
                                >{`$ ${!userInfo ? bookingType.n_adult_price : bookingType.adult_price}`}</span>
                              </div>
                            </div>
                            <div className="col-auto ">
                              <div>
                                <span
                                  className="text-dark "
                                  style={{ fontSize: 15, lineHeight: 1 }}
                                >
                                  Child{" "}
                                </span>
                                <br />
                                <span
                                  className="text-primary"
                                  style={{ fontSize: 20, lineHeight: 1 }}
                                >{`$ ${!userInfo ? bookingType.n_kids_price : bookingType.kids_price}`}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                }

              </div>

            </div>
            {
              availableSeats !== 0 &&
              <div className="col-sm-12 col-lg-8">

                <Form
                  onSubmit={(event) => {
                    event.preventDefault()
                    validation.handleSubmit()
                  }}
                >
                  <div className="row">
                    <small className="px-5 text-primary"></small>
                    <div className="row">
                      <div className="mb-3 col-12 col-lg-6 col-md-6">
                        <Label htmlFor="firstname">
                          First Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="firstname"
                          className={`form-control`}
                          placeholder="Enter Your First Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstname || ""}
                          onKeyDown={handleKeyDowncharcters}
                          invalid={
                            validation.touched.firstname &&
                              validation.errors.firstname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstname &&
                          validation.errors.firstname ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.firstname}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3 col-12 col-lg-6 col-md-6">
                        <Label htmlFor="lastname">
                          Last Name<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="lastname"
                          className={`form-control`}
                          placeholder="Enter Your Last Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.lastname || ""}
                          onKeyDown={handleKeyDowncharcters}
                          invalid={
                            validation.touched.lastname &&
                              validation.errors.lastname
                              ? true
                              : false
                          }
                        />
                        {validation.touched.lastname &&
                          validation.errors.lastname ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.lastname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-12 col-lg-6 col-md-6">
                        <Label htmlFor="email">
                          Email<span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="email"
                          className={`form-control`}
                          placeholder="Enter Your Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                              validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email &&
                          validation.errors.email ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3 col-12 col-lg-6 col-md-6">
                        <Label htmlFor="mobile">
                          Mobile Number
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="mobile"
                          type="tel"
                          min={4}
                          className="form-control "
                          placeholder="Enter Mobile Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.mobile || ""}
                          maxLength={20}
                          onKeyDown={handleKeyDownmobile}
                          invalid={
                            validation.touched.mobile &&
                              validation.errors.mobile
                              ? true
                              : false
                          }
                        />
                        {validation.touched.mobile &&
                          validation.errors.mobile ? (
                          <FormFeedback
                            type="invalid"
                            style={{ fontSize: "12px" }}
                          >
                            {validation.errors.mobile}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </div>
                    <p style={{ fontSize: "13px", color: "blue" }}>
                      {User_Id_ && Ticketperuser < 6
                        ? `'${Ticketperuser} tickets available for you'`
                        : ""}
                    </p>

                    <div className="row">
                      <div className="mb-3 col-sm-12 col-lg-2 col-md-2">
                        <Label htmlFor="adult_count">
                          Adult
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="adult_count"
                          type="number"
                          min={0}
                          max={6}
                          className="form-control"
                          placeholder="0"
                          onChange={handleAdultCountChange}
                          value={adultCount}
                          style={{ fontSize: 30 }}
                          disabled={!selectedCategory}
                          invalid={
                            validation.touched.adult_count &&
                            validation.errors.adult_count
                          }
                        />
                        {validation.touched.adult_count &&
                          validation.errors.adult_count && (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.adult_count}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3 col-sm-12 col-lg-2 col-md-2">
                        <Label htmlFor="child_count">
                          Children
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="child_count"
                          type="number"
                          min={0}
                          max={6}
                          className="form-control"
                          placeholder="0"
                          onChange={handleChildCountChange}
                          value={childCount}
                          style={{ fontSize: 30 }}
                          disabled={!selectedCategory}
                          invalid={
                            validation.touched.child_count &&
                            validation.errors.child_count
                          }
                        />
                        {validation.touched.child_count &&
                          validation.errors.child_count && (
                            <FormFeedback
                              type="invalid"
                              style={{ fontSize: "12px" }}
                            >
                              {validation.errors.child_count}
                            </FormFeedback>
                          )}
                      </div>
                    </div>
                    <p
                      className="text-danger text-justify "
                      style={{ fontSize: 10, lineHeight: 1 }}
                    >
                      {errormsg}
                    </p>
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-lg-4 col-md-4">
                        <div className="d-flex flex-row">
                          <div className="col-auto py-2 me-5">
                            <div>
                              <span
                                className="text-dark"
                                style={{ fontSize: 20, lineHeight: 1 }}
                              >
                                Total Amount:{" "}
                              </span>

                              <br />
                              <span
                                className="text-primary fw-bold"
                                style={{ fontSize: 30, lineHeight: 1 }}
                              >{`$ ${adultTotalTicketPrice > 0 || childTotalTicketPrice > 0 ? getAmountWithTax() : 0}`}</span>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="col-sm-12 col-lg-4 col-md-4">
                        <button
                          type="submit"
                          className="btn btn-warning mx-3"
                          disabled={
                            errormsg ===
                            "Your count is empty fill it to continue"
                          }
                        >
                          {"Submit"}
                        </button>
                      </div>
                    </div>
                    <div className="mb-3">
                      <span className="text-danger">*</span>{" "}
                      <small>
                        PayPal Transaction Fee (2.25%+31 Cents) Extra
                      </small>
                    </div>
                  </div>
                </Form>
              </div>

            }

          </div>
        </div>

        <Modal
          className="modal fade modal-wrapper auth-modal"
          show={PaypalModal}
          onHide={() => setPaypalModal(false)}
          centered
          backdrop="static"
        >
          <h2 className="title">
            Payment Process{" "}
            <span
              className="position-absolute top-0 start-100 translate-middle text-light  rounded-circle"
              style={{
                fontSize: "2rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setPaypalModal(false)
              }}
            >
              <SlClose />
            </span>
          </h2>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              //ValidationRegister.handleSubmit()
            }}
          >
            <TicketBookingButton
              data2={ticketBookingInfo}
              setPaypalModal={setPaypalModal}
            />
            {/* <PaymentPaypal data2={validation.values} /> */}
          </Form>
        </Modal>




      </div>
    )
  }
  else {
    return null
  }
}

export default EventBookingF