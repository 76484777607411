import React, { useState, useEffect } from "react"

import FooterCommonData from "./FooterCommonData"
import { IMAGES } from "../constant/theme"
import moment from "moment"
import { Newsletter, ResetBAM } from "../features/FormDataStore"
import { useDispatch, useSelector } from "react-redux"
import Subscribe from "../components/Subscribe"
import swal from "sweetalert"
import { toast } from "react-toastify"
const Footer3 = () => {
  const [email, SetEmail] = useState()
  const dispatch = useDispatch()
  const { SuccessNewsletter, FailNewsletter } = useSelector(
    (state) => state.FormDataReducer
  )
  useEffect(() => {
    if (SuccessNewsletter) {
      swal(
        "Form submitted!",
        "Subscriber Registration is successfuly submitted",
        "success"
      )
    }
    if (FailNewsletter) {
      toast.error("Subscribe is Failed  ...!", {
        position: "top-center",
      })
    }
    dispatch(ResetBAM())
  }, [SuccessNewsletter, FailNewsletter])
  return (
    <>
      <footer
        className="site-footer style-3 background-luminosity "
        // style={{ background: "#416bdb" }}
      >
        <div className="footer-subscribe-wrapper">
          <div className="container">
            <div className="wrapper-inner">
              <div className="row align-items-center">
                <div
                  className="col-xl-12 col-lg-12 mb-4 wow fadeInUp "
                  data-wow-delay="0.2s"
                >
                  <h2 className="title text-white m-b0">
                    Don’t miss our Newsletter {/* about donations */}
                  </h2>
                </div>
                <div
                  className="col-xl-12 col-lg-12 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <Subscribe />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <FooterCommonData logoImage={IMAGES.logoSats} iconStyle={true} />
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="text-center">
              <span>
                © {moment().format("YYYY")} San Antonio Tamil Sangam - All
                Rights Reserved.
              </span>
              <br />
              <span className="copyright-text">
                SA Tamil Sangam is a tax-exempt, 501(c)(3) non profit
                organization registered in Texas.
                <br />
                Designed by -{" "}
                <a href="http://quasarpixels.com/" target="_blank">
                  QuasarPixels
                </a>
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer3
