import { AiTwotoneHome, AiFillMail, AiFillPhone } from "react-icons/ai"
import { TbWorldSearch } from "react-icons/tb"
import { phoneNumber } from "../helpers/Api_help"

export const contactblog = [
  {
    title: "San Antonio Tamil Sangam",
    image: <AiTwotoneHome color="#fea500" />,
  },
  { title: "sats@satamilsangam.org", image: <AiFillMail color="#fea500" /> },
  { title: phoneNumber, image: <AiFillPhone color="#fea500" /> },
  {
    title: "http://www.satamilsangam.org",
    image: <TbWorldSearch color="#fea500" />,
  },
]
