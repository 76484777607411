import React, { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { DonateHistory } from "../../features/FormDataStore"
import { useNavigate, Link } from "react-router-dom"
import { DonateToken, Donateenv } from "../../helpers/Api_help"
import swal from "sweetalert"
// import Swal from "sweetalert2"
const DonateButton2 = ({ data2, setModal, Modal }) => {
  const buttonRef = useRef(null)
  const dispatch = useDispatch()
  const nav = useNavigate()
  const { donateDetails, DonateSus, FailBAM } = useSelector(
    (state) => state.FormDataReducer
  )
  console.log("hii", data2?.donate_amount)
  const donationAmount = parseFloat(data2?.donate_amount)
  console.log("sdds", donationAmount)
  useEffect(() => {
    const button = window.PayPal.Donation.Button({
      env: Donateenv,
      hosted_button_id: DonateToken,
      item_name: `${data2?.firstname} ${data2?.lastname}`,
      item_number: `${data2?.project_chapter}`,
      // amount: `${data2?.donate_amount}`,
      option_amount0: `25`,
      image: {
        src: "https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif",
        alt: "Donate with PayPal button",
        title: "PayPal - The safer, easier way to pay online!",
      },
      onComplete: function (params) {
        console.log(params)
        console.log({
          donate_id: data2?._id,
          ...params,
        })
        setModal(false)
        dispatch(
          DonateHistory({
            payer_id: data2?._id,
            payment_status: params?.st,
            transaction_id: params?.tx,
            currency_code: params?.cc,
            payer_name: params?.item_name,
            transaction_amount: params?.amt,
            donate_for: params?.item_number,
          })
        )
        window.close()

        /* var message = document.createElement("div")
        message.innerHTML =
          "Payment successfully completed! Please close this window."
        document.body.appendChild(message) */

        swal(
          "Donate  Payment!",
          (params.st = "Completed"
            ? `
            Thank You for supporting SATS for good causes. Your Transaction reference ID is '${params.tx}' and amount of $${params?.amt} USD.`
            : `Your Donation has failed. Please try again.`),
          (params.st = "Completed" ? `success` : "error")
        )

        // Swal.fire({
        //   icon: `${(params.st = "Completed" ? `success` : "error")}`,
        //   title: "Donate  Payment!",
        //   html: `<p>${
        //     params.st === "Completed"
        //       ? `Thank You for supporting SATS for good causes. Your Transaction reference ID is '${params.tx}' and amount of $${params?.amt} USD.`
        //       : `Your Donation has failed. Please try again.`
        //   }</p> <br></br> <Link to="/donate-form">Donate Again?</Link>`,
        //   footer: '<Link to="/">Click to Home</Link>',
        // })

        // swal({
        //   title: `Donate  Payment!`,
        //   html: `Some Text here.
        //          <br> <br> <b>
        //          <p>${
        //            params.st === "Completed"
        //              ? `Thank You for supporting SATS for good causes. Your Transaction reference ID is '${params.tx}' and amount of $${params?.amt} USD.`
        //              : `Your Donation has failed. Please try again.`
        //          }</p>

        //          <br> <br> <br>
        //         <button type="button" class="btn btn-doante-again swl-cstm-btn-yes-sbmt-rqst">Donate Again</button>

        //         <button type="button" class="btn btn-home swl-cstm-btn-cancel" >Home</button>`,
        //   showCancelButton: false,
        //   showConfirmButton: false,
        //   onBeforeOpen: () => {
        //     const Donate = document.querySelector(".btn-doante-again")
        //     const no = document.querySelector(".btn-no")
        //     const Home = document.querySelector(".btn-home")

        //     Donate.addEventListener("click", () => {
        //       nav("/donate-form")
        //     })

        //     Home.addEventListener("click", () => {
        //       nav("/")
        //     })
        //   },
        // })
        nav("/")
        // setTimeout(function () {}, 5000)
      },
    })
    if (DonateSus) {
      button.render(`#paypal-donate-button-container`)
    }
  }, [DonateSus])
  return <div id="paypal-donate-button-container" className="text-center"></div>
}

export default DonateButton2
