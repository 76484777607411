import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { ApiServer } from "../../helpers/Api_help"

import moment from "moment"
import _ from "lodash"

// const UpcoimgEventApi = `${ApiServer}sats-eventinfo/fetch-active-eventlists`
const UpcoimgEventApi = `${ApiServer}manage-event/fetch-event-and-venues-publish`
const PastEventApi = `${ApiServer}manage-event/fetch-past-eventlists`
const SelectEventApi = `${ApiServer}manage-event/fetch-event-full-details`
const SelectVenueApi = `${ApiServer}manage-event-venue/fetch-venue-full-details`
const TicketbookingshowApi = `${ApiServer}manage-venue-seats/fetch-venue-seats`
const FindRemainingTicketsApi = `${ApiServer}manage-booking-user/fetch-remaining-tickets-for-user`

const initialStateTB = {
  UpcomingData: [],
  PastEventsData: [],
  TicketbookingData: [],
  SelectedEventData: null,
  SelectedVenueData: null,
  Ticketperuser: null,
  TBisSuccess: false,
  TBisfail: false,
}

const EventTBSlice = createSlice({
  name: "TBSlice",
  initialState: initialStateTB,
  reducers: {
    ResetTB: (state, action) => {
      state.TBisSuccess = false
      state.TBisfail = false
    },
    SetTBisSuccess: (state, action) => {
      state.TBisSuccess = true
    },
    SetTicketBooking: (state, action) => {
      state.TicketbookingData = action.payload
    },
    SetUpcoming: (state, action) => {
      var eventItems = _.sortBy(action.payload, function(o) { return new moment(o.event_startdate_time); }) ;

      state.UpcomingData = action.payload
    },
    SetSelectedEvent: (state, action) => {
      state.SelectedEventData = action.payload
    },
    SetSelectedVenue: (state, action) => {
      state.SelectedVenueData = action.payload
    },
    SetRemainingTicketsPerUser: (state, action) => {
      state.TBisSuccess = true
      state.Ticketperuser = action.payload
    },
    SetPast: (state, action) => {
      state.PastEventsData = action.payload
    },
  },
})

export const fetchUpcomingEvents = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(UpcoimgEventApi)
      // console.log("fetchUpcomingEvents", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetTBisSuccess())
        dispatch(SetUpcoming(responseData.data.data))
      }
    } catch (error) {
      console.log("Upcoimg Events", error.message)
    }
  }
}

export const fetchPastEvents = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(PastEventApi)
       console.log("fetchPastEvents", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetTBisSuccess())
        dispatch(SetPast(responseData.data.data))
      }
    } catch (error) {
      console.log("Past Events", error.message)
    }
  }
}

export const Ticketbookingshow = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.post(TicketbookingshowApi, data)

      if (responseData.data.response_code === 200) {
        // console.log("Tickets Data", responseData)
        dispatch(SetTicketBooking(responseData.data.data))
        dispatch(SetTBisSuccess())
      }
    } catch (error) {
      console.log("ticket Events", error.message)
    }
  }
}
export const fetchSelectedEvent = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      console.log("try block");
      let responseData = await axios.post(SelectEventApi, data)

      console.log("responseData", responseData);
      // console.log("fetchSelectedEvent", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetTBisSuccess())
        dispatch(SetSelectedEvent(responseData.data.data))
      }
    } catch (error) {
      console.log("Past Events", error.message)
    }
  }
}

export const fetchSelectedVenue = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.post(SelectVenueApi, data)
       console.log("fetchSelectedEvent", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetTBisSuccess())
        dispatch(SetSelectedVenue(responseData.data.data[0]))
      }
    } catch (error) {
      console.log("Past Events", error.message)
    }
  }
}

export const FindRemainingTickets = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.post(FindRemainingTicketsApi, data)
       console.log("find Remaining Tickets", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(
          SetRemainingTicketsPerUser(responseData.data)
        )
      }
      if (responseData.data.response_code === 404) {
        dispatch(SetRemainingTicketsPerUser(6))
      }
    } catch (error) {
      console.log("Past Events", error.message)
    }
  }
}

export const {
  ResetTB,
  SetTBisSuccess,
  SetUpcoming,
  SetPast,
  SetSelectedVenue,
  SetTicketBooking,
  SetSelectedEvent,
  SetRemainingTicketsPerUser,
} = EventTBSlice.actions
export default EventTBSlice.reducer
