import React from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
//import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Swiper styles
import "swiper/css"

//Images
// import { Sponsers } from "../../constant/theme"

// import Swiper core and required modules
import { Autoplay, Pagination, Navigation } from "swiper"
import { ApiServer } from "../../helpers/Api_help"

//SwiperCore.use([EffectCoverflow,Pagination]);

const PartnershipSlider = ({ Sponser }) => {
  return (
    <>
      <Swiper
        className="clients-swiper"
        speed={1500}
        // parallax={true}
        slidesPerView={5}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        //  pagination={{
        //       clickable: true,
        //     }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        // onAutoplayTimeLeft={onAutoplayTimeLeft}
        breakpoints={{
          1191: {
            slidesPerView: 5,
          },
          992: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          575: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
          280: {
            slidesPerView: 1,
          },
        }}
      >
        {Sponser?.map((d, i) => (
          <SwiperSlide key={i}>
            <div className="clients-logo">
              <img
                className="img-thumbnail"
                src={`${ApiServer}assets/sponsorship/${d.logo}`}
                alt="NOT FOUND"
                style={{
                  width: 200,
                  height: 200,
                  // contain: "content",
                  contain: "size",
                  // objectFit: "contain",
                }}
              />
              <h6>{d.esp_name}</h6>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

export default PartnershipSlider
