import React, { useEffect, useState } from "react"
/* import Route from './pages/Route'; */
import { Routes, Route, Outlet } from "react-router-dom"

//import "./assets/css/skin/skin-1.css";

import {
  publicRoutes,
  authProtectedRoutes,
  AdminProtectedRoutes,
} from "./index"
import Authmiddleware from "./Authmiddleware"

import NonAuthLayout from "./NonAuthLayout"
import { PropTypes } from "prop-types"
import Header2 from "../layouts/Header2"
import Footer3 from "../layouts/Footer3"
import Header from "../layouts/Header"
import { useSelector } from "react-redux"

function Routerset() {
  return (
    <React.Fragment>
      <Routes>
        <Route element={<Layout />}>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.component}</NonAuthLayout>}
              key={idx}
              exact={route?.exact || false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<Authmiddleware>{route.component}</Authmiddleware>}
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  )
}

/* App.propTypes = {
  layout: PropTypes.any,
} */
const Layout = () => {
  const { LoginUser } = useSelector((state) => state.auth)
  return (
    <>
      {/* {LoginUser || localStorage.getItem("Login") ? (
        <Header changeStyle="header-transparent" changeLogo={true} />
      ) : (
        <Header2 changeStyle="header-transparent" changeLogo={true} />
      )} */}
      <Header changeStyle="header-transparent" changeLogo={true} />
      <Outlet />
      <Footer3 />
    </>
  )
}

export default Routerset
