import React from "react"
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js"
import { Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { ResetBAM, userLoginOn } from "../../features/FormDataStore"
import { Reset } from "../../features/Event/BookingSlice"
import { useDispatch } from "react-redux"
import { ApiServer, clientID } from "../../helpers/Api_help"
import swal from "sweetalert"
// var Api = "http://192.168.1.13:8064/"
// var Api = "http://localhost:8888/"
const ButtonWrapper = ({ showSpinner, fielddata, setPaypalModal }) => {
  console.log("fielddata",fielddata)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [{ isPending }] = usePayPalScriptReducer()

  const style = { layout: "vertical" }
  /*  label: "donate" */
  const User_Id = JSON.parse(localStorage.getItem("user_id"))

  let fees = (2.25 / 100) * Number(fielddata.net_amount) + 0.31
  let Amountvalue = (Number(fielddata.net_amount) + fees).toFixed(2)

  console.log("Fees",fielddata.net_amount, fees, Amountvalue)

  async function createOrder(data, actions) {
    try {
      const response = await fetch(
        `${ApiServer}ticket-booking/my-server/create-ticket-paypal-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product: {
              description: fielddata.event_name,
              cost: Amountvalue,
              //   currency: "USD",
              user_id: User_Id ? User_Id._id : null,
              booking_id: fielddata._id,
            },
            intent: "CAPTURE",
            customer: {
              firstName: fielddata.firstname,
              lastName: fielddata.lastname,
              email: fielddata.email,
              //   city: fielddata.city,
              //   state: fielddata.state,
              //   mobile: fielddata.mobile,
              //   country: fielddata.country,
              //   address: fielddata.address,
              //   address2: fielddata.address2,
              // Add other customer information if needed
            },
          }),
        }
      )
      if (!response.ok) {
        console.log(response)
        throw new Error("Error creating order on the server.")
      }
      const order = await response.json()
      console.log("Order ID from server:", order.id, order)
      return order.data.id
    } catch (error) {
      console.error(" creating Error:", error)
    }
  }

  function onApprove(data, actions) {
    try {
      // replace this url with your server
      console.log("data on capture", data)
      return fetch(
        `${ApiServer}ticket-booking/my-server/capture-ticket-paypal-order`,

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            master_venue_id: fielddata?.master_venue_id,
            orderID: data?.orderID,
            user_id: User_Id ? User_Id._id : null,
            booking_id: fielddata._id,
        }),
        }
      )
        .then((response) => response.json())
        .then((orderData) => {
          console.log("final", orderData)
          if (orderData.response_code === 200) {
            console.log(orderData.response_code)
            setPaypalModal(false)
            swal(
              "Payment Success",
              `Your ticket booking is confirmed and payment successfully processed, with a transaction id of '${data.orderID}' and the amount paid $ '${fielddata?.net_amount}' for Ticket Booking.`,
              `success`
            )

            User_Id ? navigate("/mywallet") : navigate("/")
            // localStorage.setItem("Register", JSON.stringify("Xxx-67556-Xxx"))
          } else {
            toast.error("Failed On Payment ...!", {
              position: "top-center",
            })
          }
          dispatch(Reset())
          // Your code here after capture the order
        })
    } catch (error) {
      console.log("Approve Error", error)
    }
  }

  async function onCancel(data, actions) {
    console.log("Cancel By Mannual", data, actions)
    try {
      // Show a cancel page, or return to cart

      return fetch(`${ApiServer}ticket-booking/cancel-ticket-paypal-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
          user_id: User_Id ? User_Id : null,
          booking_id: fielddata._id,
          cost: fielddata?.net_amount,
          payment_error: "Cancel by User",
          payer_name: `${fielddata?.firstname} ${fielddata?.lastname}`, // Add the first name herer
          payer_email: fielddata?.email,
          // payer_details: { ...fielddata },
        }),
      }).then((response) => {
        console.log("Process Cancel!", response.status)
        swal("Payment Cancel ", `Your payment has been not processed.`, "info")
        setPaypalModal(false)

        dispatch(Reset())
        // return order
      })
    } catch (err) {
      console.log("Canacel api error", err, actions)
    }
  }
  async function onError(data, actions) {
    console.log("onError By Mannual", data, actions)
    try {
      // Show a cancel page, or return to cart
      return fetch(`${ApiServer}ticket-booking/error-ticket-paypal-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
          user_id: User_Id ? User_Id : null,
          booking_id: fielddata._id,
          cost: fielddata?.net_amount,
          payer_name: `${fielddata?.firstname} ${fielddata?.lastname}`, // Add the first name herer
          payer_email: fielddata?.email,
          payment_error: "Payment Failed or Windows Closed before Payment",
        }),
      }).then((response) => {
        console.log("Error on payment", response)
        swal("Payment Error ", `Your payment has been not processed.`, "error")
        dispatch(Reset())
        setPaypalModal(false)
        // return order
      })
    } catch (err) {
      console.log("ERRor api err", err, actions)
    }
  }

  return (
    <>
      {isPending && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={undefined}
        createOrder={createOrder}
        onApprove={onApprove}
        // onClick={handlePayment}
        onCancel={onCancel}
        onError={onError}
      />
    </>
  )
}

const TicketBookingButton = ({ data2, setPaypalModal }) => {
  const initialOptions = {
    "client-id": clientID,
    intent: "capture",
    currency: "USD",
  }

  return (
    <div style={{ maxWidth: "750px", minHeight: "200px" }}>
      <PayPalScriptProvider options={initialOptions}>
        <ButtonWrapper
          setPaypalModal={setPaypalModal}
          showSpinner={false}
          fielddata={data2}
        />
        <ToastContainer style={{ zIndex: "10999" }} />
      </PayPalScriptProvider>
    </div>
  )
}

export default TicketBookingButton
