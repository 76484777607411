import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
} from "reactstrap"
import { Spinner, Modal } from "react-bootstrap"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment"

import { useNavigate, Link, useParams } from "react-router-dom"
import { IMAGES } from "../../constant/theme"
import PageBanner from "../../layouts/PageBanner"
import { Forgetpassword, Reset } from "../../features/auth/authSlice"
import {
  fetchMembershipTypes,
  fetchMyProfileData,
} from "../../features/FetchData"
import MembershipButton from "../../components/Paypal/MembershipButton"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import * as Yup from "yup"
import swal from "sweetalert"

const UpgradePlan = () => {
  const dispatch = useDispatch()
  const nav = useNavigate()

  // const User_Id = useParams()
  // console.log("params", User_Id)

  const { MembershipTypes, MyProfileInfo } = useSelector(
    (state) => state.FetchDataReducer
  )

  const User_Id_ = JSON.parse(localStorage.getItem("user_id"))
  const userInfo = JSON.parse(localStorage.getItem("userInfo"))

  useEffect(() => {
    dispatch(fetchMyProfileData(userInfo))
  }, [])

  //upgrade
  const [UpgradeModal, setUpgradeModal] = useState(false)
  const [MemShipagree, setmemShipagree] = useState(false)
  const [ConfirmMemshipModal, setConfirmMemshipModal] = useState(false)
  const [TempMembership, setTempMembership] = useState()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      _id: MyProfileInfo?._id || "",
      email: MyProfileInfo?.email || "",
      firstname: MyProfileInfo?.firstname || "",
      lastname: MyProfileInfo?.lastname || "",
      gender: MyProfileInfo?.gender || "",
      mobile: MyProfileInfo?.mobile || "",
      member_id: MyProfileInfo?.member_id || "",
      membership_type: MyProfileInfo?.membership_type || "",
      membership_fees: MyProfileInfo?.membership_fees || "",
      membership_type_id: MyProfileInfo?.membership_type_id || "",
      address: MyProfileInfo?.address || "",
      address2: MyProfileInfo?.address2 || "",
      city: MyProfileInfo?.city || "",
      state: MyProfileInfo?.state || "",
      country_code: MyProfileInfo?.country_code || "",
      country: MyProfileInfo?.country || "",
      zipcode: MyProfileInfo?.zipcode || "",
      dob: MyProfileInfo?.dob || "",
      profile_img: MyProfileInfo?.profile_img || "",
      spouse: MyProfileInfo?.spouse || "",
      children: MyProfileInfo?.children || "",
      currency: MyProfileInfo?.currency || "",
      credit: MyProfileInfo?.credit || 0,
      credit_expired_on: MyProfileInfo?.credit_expired_on || null,
      // login_status: MyProfileInfo?.login_status || "",
      // status: MyProfileInfo?.status || "", //subscribe
      // dobcheck: MyProfileInfo?.dobcheck || "",
      // membership_expired_on: MyProfileInfo?.membership_expired_on || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values, actions) => {
      // console.log("njkk", values)
      // dispatch(UpadteBAM(values))
    },
  })

  const memShipConfirm = (item) => {
    console.log(item)

    if (item.membership_name_id === "1") {
      setmemShipagree(true)
      setConfirmMemshipModal(false)
    } else {
      setmemShipagree(false)
      setConfirmMemshipModal(true)
    }
    setTempMembership(item)
  }
  useEffect(() => {
    if (MemShipagree) {
      validation.setFieldValue(
        "membership_type",
        TempMembership?.membership_name
      )
      validation.setFieldValue("membership_fees", TempMembership?.fees)
      validation.setFieldValue(
        "membership_type_id",
        TempMembership?.membership_name_id
      )
      // if (TempMembership?.membership_name === "Life Membership") {
      //   validation.setFieldValue("membership_expired_on", "lifetime")
      // } else {
      //   validation.setFieldValue("membership_expired_on", daysRemaining())
      // }
      console.log("gay", TempMembership)
    } else {
      validation.setFieldValue("membership_type", "")
      validation.setFieldValue("membership_fees", "")
      validation.setFieldValue("membership_type_id", "")
    }
  }, [TempMembership, MemShipagree])

  function daysRemaining() {
    const today = moment()
    // const today = moment("4/30/2023")

    let year = today.year()
    var curMonth = moment().format("MM")

    if (curMonth > 4) {
      const nextApril30d = moment(`${year + 1}-04-30`)
      const Dateexpiry = moment(nextApril30d._i).format("Do MMMM YYYY")

      const remainingDays = nextApril30d.diff(today, "days")

      // console.log(remainingDays)
      return Dateexpiry
    } else {
      const nextApril30s = moment(`${year}-04-30`)
      const Dateexpiry = moment(nextApril30s._i).format("Do MMMM YYYY")
      const remainingDays = nextApril30s.diff(today, "days")
      // console.log(remainingDays)

      return Dateexpiry
    }
  }
  return (
    <>
      <div className="page-content bg-white">
        {/* <PageBanner maintitle="Home" pagetitle="Login" /> */}
        <section
          className="content-inner-1 gradient-white mb-3 section-wrapper5 mt-5"
          style={{
            backgroundPosition: "center",
          }}
        >
          <div className=" d-flex justify-content-center container mt-5">
            <div className="row d-flex justify-content-center">
              <Col className="col-lg-3  align-self-center me-5 d-none d-md-block">
                <img src={IMAGES.logoSats}></img>
              </Col>
              <Col lg={6} className="">
                <h3 className="text-primary text-center">Renew / Upgrade</h3>
                <div className="col-12 col-md-11 col-lg-11 mt-5  d-flex flex-column">
                  {" "}
                  {/* // flex-wrap */}
                  <Label htmlFor="member">
                    SATS Membership
                    <span className="text-danger">*</span>
                  </Label>
                  <div className="btn-group d-flex flex-nowrap " role="group">
                    {MembershipTypes.map((item, ind) => (
                      <>
                        <input
                          type="radio"
                          className="btn-check" //flex-fill
                          name="btnradio"
                          id={item._id}
                          autoComplete="off"
                          value={validation.values.membership_type || ""}
                          defaultChecked={
                            validation.values.membership_type_id ===
                            item.membership_name_id
                          }
                          onClick={(e) => {
                            memShipConfirm(item)
                          }}
                          invalid={
                            validation.errors.membership_type ? true : false
                          }
                        />
                        <label
                          className="btn btn-outline-warning d-flex flex-column"
                          htmlFor={item._id}
                        >
                          <p>{item.membership_name}</p>

                          <h6>${item.fees}*</h6>
                        </label>
                      </>
                    ))}
                  </div>
                  <div className="text-center">
                    <span className="text-danger">*</span>{" "}
                    <small>PayPal Transaction Fee (2.25%+31 Cents) Extra</small>
                  </div>
                  {validation.touched.membership_type &&
                  validation.errors.membership_type ? (
                    <div style={{ fontSize: "12px", color: "red" }}>
                      {validation.errors.membership_type}
                    </div>
                  ) : null}
                  {MemShipagree && (
                    <div className="mt-3">
                      <MembershipButton
                        data2={validation.values}
                        setModal={setUpgradeModal}
                        upgrade={"Up"}
                        purpose={"Membership upgrade"}
                        membershipSelected={TempMembership}
                        ReturnUpgradeshow={null}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </div>
          </div>
        </section>
      </div>
      <Modal
        className="modal fade modal-wrapper auth-modal"
        show={ConfirmMemshipModal}
        onHide={() => setConfirmMemshipModal(false)}
        backdrop="static"
        centered
      >
        <h2 className="title text-warning">Are you Sure</h2>
        <p className="text-justify">
          Membership expires on
          <span className="fw-bold"> {daysRemaining()}</span> . If you agree
          with the Membership plan, please confirm it.
        </p>
        <div className="d-flex gap-1 justify-content-end">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              setmemShipagree(true)
              setConfirmMemshipModal(false)
            }}
          >
            Confirm
          </button>
          <button
            className="btn btn-light btn-sm"
            onClick={() => {
              setmemShipagree(false)
              setConfirmMemshipModal(false)
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
      <ToastContainer style={{ zIndex: "10999" }} />
    </>
  )
}

export default UpgradePlan
