import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { ApiServer } from "../helpers/Api_help"

const GalleryApi = `${ApiServer}sats-galleryinfo/fetch-gallery-year`
const SponserApi = `${ApiServer}sats-sponsors/fetch-sponsors`
const CurrentCommitteeApi = `${ApiServer}sats-memberinfo/fetch-memberinfo`
const BannerApi = `${ApiServer}sats-banner/fetch-bannerinfo`
const LifeMemberApi = `${ApiServer}sats-memberinfo/fetch-life-time-user-members`
const PreviousCommitteApi = `${ApiServer}sats-memberinfo/fetch-previous-committee-year`
const BecomeMembershipType = `${ApiServer}sats-memberinfo/fetch-member-type-list`
const MyProfile_Data_API = `${ApiServer}manage-user/fetch-profileinfo`
const GalleryyearApi = `${ApiServer}sats-galleryinfo/fetch-gallery-find-years`
const PerviousyearApi = `${ApiServer}sats-memberinfo/fetch-previous-committee-find-years`
const fetchFamilyMemberApi = `${ApiServer}manage-user/fetch-family-user-members`
const MywalletApi = `${ApiServer}manage-booking-user/fetch-ticket-booking-user`
const initialState = {
  Gallery: [],
  Sponser: [],
  Committe: [],
  Banner: [],
  LifeMember: [],
  Galleryyear: [],
  Perviousyear: [],
  PreviousCommitte: [],
  MembershipTypes: [],
  MyProfileInfo: {},
  MywalletInfo: [],
  FamilyDetails: [],

  Success: false,
  fail: false,
}

const FetchData = createSlice({
  name: "Datafetching",
  initialState: initialState,
  reducers: {
    ResetTB: (state, action) => {
      state.Success = false
      state.fail = false
    },
    SetSuccess: (state, action) => {
      state.Success = true
    },
    SetFail: (state, action) => {
      state.false = true
    },
    SetGallery: (state, action) => {
      state.Gallery = action.payload
    },
    SetGalleryyear: (state, action) => {
      state.Galleryyear = action.payload
    },
    SetPerviousyear: (state, action) => {
      state.Perviousyear = action.payload
    },
    SetSponsers: (state, action) => {
      state.Sponser = action.payload
    },
    SetCommittee: (state, action) => {
      state.Committe = action.payload
    },
    SetPreviousCommitte: (state, action) => {
      state.PreviousCommitte = action.payload
    },
    SetBanner: (state, action) => {
      state.Banner = action.payload
    },
    SetLifeMember: (state, action) => {
      state.LifeMember = action.payload
    },
    SetMembershipTypes: (state, action) => {
      state.MembershipTypes = action.payload
    },
    SetMyProfileData: (state, action) => {
      state.MyProfileInfo = action.payload
    },
    SetMywalletInfo: (state, action) => {
      state.MywalletInfo = action.payload
    },
    SetFamilyisDetails: (state, action) => {
      state.FamilyDetails = action.payload
    },
    RemoveProfileData: (state, action) => {
      state.MyProfileInfo = {}
    }
  },
})
export const fetchGalleryyear = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(GalleryyearApi)
      // console.log("fetchGalleryyear", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetGalleryyear(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchGalleryyear", error.message)
    }
  }
}

export const fetchGallery = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.post(GalleryApi, { ga_year: data })
      // console.log("fetchGallery", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetGallery(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchGallery", error.message)
    }
  }
}
export const fetchSponsers = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(SponserApi)
      // console.log("fetchSponsers", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetSponsers(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchSponsers", error.message)
    }
  }
}
export const fetchCommittee = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(CurrentCommitteeApi)
      // console.log("fetchCommittee", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetCommittee(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchCommittee", error.message)
    }
  }
}
export const fetchLifeMember = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(LifeMemberApi)
      // console.log("fetchLifeMember", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetLifeMember(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchLifeMember", error.message)
    }
  }
}
export const fetchBanner = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(BannerApi)
      // console.log("fetchBanner", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetBanner(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchBanner", error.message)
    }
  }
}
export const fecthPreviousCommitte = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.post(PreviousCommitteApi, {
        year_start: data,
      })
      // console.log("fecthPreviousCommitte", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetPreviousCommitte(responseData.data.data))
      }
    } catch (error) {
      console.log("fecthPreviousCommitte ", error.message)
    }
  }
}

export const fetchPerviousyear = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(PerviousyearApi)
      // console.log("fetchGalleryyear", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetPerviousyear(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchGalleryyear", error.message)
    }
  }
}

export const fetchMembershipTypes = () => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.get(BecomeMembershipType)
      // console.log("fetchMembershipTypes", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetMembershipTypes(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchMembershipTypes ", error.message)
    }
  }
}
export const fetchMyProfileData = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.post(MyProfile_Data_API, data)
       //console.log("fetchMyProfileData", responseData.data.data[0])
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetMyProfileData(responseData.data.data[0]))
      }
    } catch (error) {
      console.log("fetchMyProfileDataerror ", error.message)
    }
  }
}
export const fetchMywallet = (data) => {
  console.log("mywall", data)
  return async (dispatch) => {
    dispatch(ResetTB())
    try {
      let responseData = await axios.post(MywalletApi, data)
      // console.log("fetchMywallet", responseData.data)
      if (responseData.data.response_code === 200) {
        dispatch(SetSuccess())
        dispatch(SetMywalletInfo(responseData.data.data))
      }
    } catch (error) {
      console.log("fetchMywallet ", error.message)
    }
  }
}
export const fetchFamilyMember = (data) => {
  return async (dispatch) => {
    dispatch(ResetTB())

    try {
      let responseData = await axios.post(fetchFamilyMemberApi, data)
      // console.log("CreateBAM", responseData)
      if (responseData.data.response_code === 200) {
        dispatch(SetFamilyisDetails(responseData.data.data))
      }
    } catch (error) {
      console.log("Fetch Family Member", error.response.data.message)
    }
  }
}

export const {
  ResetTB,
  SetSuccess,
  SetUpcoming,
  SetGallery,
  SetSponsers,
  SetPerviousyear,
  SetCommittee,
  SetPreviousCommitte,
  SetBanner,
  SetLifeMember,
  SetMembershipTypes,
  SetMyProfileData,
  SetGalleryyear,
  SetMywalletInfo,
  SetFamilyisDetails,
  RemoveProfileData
} = FetchData.actions
export default FetchData.reducer
