import React from "react"
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js"
import swal from "sweetalert"
import { Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { ResetBAM, CreateBAM, userLoginOn } from "../../features/FormDataStore"
import { useDispatch } from "react-redux"
import { ApiServer, clientID } from "../../helpers/Api_help"

const ButtonWrapper = ({
  showSpinner,
  fielddata,
  setPaypalModal,
  upgrade,
  ReturnUpgradeshow,
  membershipSelected,
  purpose
}) => {
  console.log(fielddata)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [{ isPending }] = usePayPalScriptReducer()

  const style = { layout: "vertical" }
  const creditamt = fielddata?.membership_fees - (fielddata?.credit || 0)
  console.log("credit", creditamt)
  let fees = (2.25 / 100) * creditamt + 0.31
  // let fees = (2.25 / 100) * (fielddata?.membership_fees || 0) + 0.31

  let Amountvalue = (creditamt + fees).toFixed(2)
  // let Amountvalue = (fielddata?.membership_fees + fees).toFixed(2)

  console.log(Amountvalue)
  async function createOrder(data, actions) {
    try {
      const response = await fetch(
        `${ApiServer}payment-info/my-server/create-paypal-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product: {
              description: "SATS " + purpose + " - "+ membershipSelected.membership_name,
              cost: Amountvalue,
              // currency: fielddata?.currency,
              user_id: fielddata?._id,
            },
            customer: {
              firstName: fielddata?.firstname,
              lastName: fielddata?.lastname,
              email: fielddata?.email,
              city: fielddata?.city,
              state: fielddata?.state,
              mobile: fielddata?.mobile,
              country: fielddata?.country,
              address: fielddata?.address,
              address2: fielddata?.address2,
              // Add other customer information if needed
            },
          }),
        }
      )
      console.log("response", response.ok)
      if (!response.ok) {
        throw new Error("Error creating order on the server.")
      }
      const order = await response.json()
      console.log("Order ID from server:", order.id, order)

      return actions.order.create({
        purchase_units: [
          {
            description: "SATS " + purpose + " - "+ membershipSelected.membership_name,
            amount: {
              value: Amountvalue,
              // currency_code: fielddata?.currency,
            },
            billing: {
              address: {
                given_name: fielddata?.firstname, // Add the first name here
                surname: fielddata?.lastname,
                address_line_1: fielddata?.address,
                address_line_2: fielddata?.address2,
                admin_area_2: fielddata?.city,
                admin_area_1: fielddata?.state,
                postal_code: fielddata?.zipcode,
                country_code: fielddata?.country_code,
                country: fielddata?.country,
              },
            },
            shipping: {
              address: {
                given_name: fielddata?.firstname, // Add the first name here
                surname: fielddata?.lastname,
                address_line_1: fielddata?.address,
                address_line_2: fielddata?.address2,
                admin_area_2: fielddata?.city,
                admin_area_1: fielddata?.state,
                postal_code: fielddata?.zipcode,
                country_code: fielddata?.country_code,
                country: fielddata?.country,
              },
            },
          },
        ],
        payer: {
          name: {
            given_name: fielddata?.firstname, // Add the first name here
            surname: fielddata?.lastname, // Add the last name here
          },
          email_address: fielddata?.email,
          phone: {
            phone_type: "MOBILE",
            phone_number: {
              national_number: fielddata?.mobile, // Add the mobile number here
            },
          },
          address: {
            address_line_1: fielddata?.address,
            address_line_2: fielddata?.address2,
            admin_area_2: fielddata?.city,
            admin_area_1: fielddata?.state,
            postal_code: fielddata?.zipcode,
            country_code: fielddata?.country_code,
            country: fielddata?.country,
          },
        },
      })
    } catch (error) {
      console.error(" creating Error:", error)
      // throw error
    }
    // return fetch(`${ApiServer}my-server/create-paypal-order`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     product: {
    //       description: "Quasar pixels",
    //       cost: "99.00",
    //     },
    //   }),
    // })
    //   .then((response) => response.json())
    //   .then((order) => {
    //     return order.id
    //   })
  }

  async function onApprove(data, actions) {
    try {
      console.log(data.orderID)
      console.log("fielddata", fielddata)
      // replace this url with your server
      return fetch(`${ApiServer}payment-info/my-server/capture-paypal-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
          user_id: fielddata?._id,
          member_id:fielddata?.member_id,
          // membership_fees: fielddata?.membership_fees,
          // membership_type: fielddata?.membership_type,
          // membership_type_id: fielddata?.membership_type_id,

          membership_fees: membershipSelected?.fees,
          membership_type: membershipSelected?.membership_name,
          membership_type_id: membershipSelected?.membership_name_id,

          email:fielddata?.email
        }),
      })
        .then((response) => response.json())
        .then((orderData) => {
          console.log("Capturedata", orderData)
          if (orderData.response_code === 200) {
            console.log("data.", orderData.response_code)
            setPaypalModal(false)
            // toast.success("Successfully Register ...!", {
            //   position: "top-center",
            // })
            swal(
              "Payment Success",

              `${
                upgrade === "Res"
                  ? `Welcome to SATS community, Your registeration has beeen completed successfully. Your transaction reference id is'${data.orderID} .Now you are redirect to login page`
                  : upgrade === "Up"
                  ? `Your membership upgrade has beeen completed successfully. Your transaction reference id is'${data.orderID}'.`
                  : `Your membership upgrade has beeen completed successfully. Your transaction reference id is'${data.orderID}'. Now you are redirect to login page.`
              }'`,

              `success`
            )
            // upgrade ? ReturnUpgradeshow(4) : navigate("/login")
            console.log("upgrade", upgrade)
            // upgrade === "Up" ? navigate("/") : navigate("/login")
            upgrade === "Renew"
              ? ReturnUpgradeshow(1)
              : upgrade === "Res"
              ? navigate("/login")
              : upgrade === "Up" && navigate("/")
            localStorage.setItem("Register", JSON.stringify("Xxx-67556-Xxx"))
            // dispatch(userLoginOn())
            // navigate("/")
            dispatch(ResetBAM())
          } else {
            toast.error("Failed On Payment ...!", {
              position: "top-center",
            })
            dispatch(ResetBAM())
          }

          // Your code here after capture the order
        })
    } catch (error) {
      console.log("Approve Error", error)
    }
  }

  async function onCancel(data, actions) {
    console.log("onError By Mannual", data, actions)
    try {
      // Show a cancel page, or return to cart

      return fetch(`${ApiServer}payment-info/cancel-paypal-order`, {
        // return fetch(`${ApiServer}payment-info/cancel-paypal-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
          user_id: fielddata?._id,
          cost: fielddata?.membership_fees,
          payment_error: "Cancel by User",
          payer_name: `${fielddata?.firstname} ${fielddata?.lastname}`, // Add the first name herer
          payer_email: fielddata?.email,
          login_status: fielddata?.login_status,
        }),
      }).then((response) => {
        console.log("Process Cancel!", response.status)
        // toast.info("Process Cancel!", {
        //   position: "top-center",
        // })
        setPaypalModal(false)
        swal(
          "Payment Cancel ",
          `Your Registeration has Cancel. Please try again.`,
          "info"
        )
        console.log("upgrade", upgrade)

        upgrade === "Renew"
          ? ReturnUpgradeshow(4)
          : upgrade === "Res"
          ? navigate("/registration")
          : upgrade === "Up" && navigate("/upgradeplan")
        // return order
      })
    } catch {
      console.log("Canacel api error", data, actions)
    }
  }
  async function onError(data, actions) {
    // console.log(err)
    console.log("onError By Mannual", data, actions)
    try {
      // Show a cancel page, or return to cart
      return fetch(`${ApiServer}payment-info/error-paypal-order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderID: data.orderID,
          user_id: fielddata?._id,
          cost: fielddata?.membership_fees,
          payment_error: "Payment Failed or Windows Closed before Payment",
          payer_name: `${fielddata?.firstname} ${fielddata?.lastname}`, // Add the first name herer
          payer_email: fielddata?.email,
          login_status: fielddata?.login_status,
        }),
      }).then((response) => {
        console.log("Error on payment", response)
        // toast.error("Registion is Incompleted or Fail ...!", {
        //   position: "top-center",
        // })
        setPaypalModal(false)
        swal(
          "Payment Error ",
          `Your Registeration has failed. Please try again.`,
          "error"
        )
        console.log("upgrade", upgrade)

        upgrade === "Renew"
          ? ReturnUpgradeshow(4)
          : upgrade === "Res"
          ? navigate("/registration")
          : upgrade === "Up" && navigate("/upgradeplan")

        // return order
      })
    } catch (err) {
      console.log("ERRor api err", err, actions)
    }
  }

  return (
    <>
      {isPending && (
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={undefined}
        createOrder={createOrder}
        onApprove={onApprove}
        // onClick={handlePayment}
        onCancel={onCancel}
        onError={onError}
      />
    </>
  )
}

const MembershipButton = ({ data2, setModal, upgrade, ReturnUpgradeshow, membershipSelected, purpose }) => {
  console.log("client-id", clientID)
  const initialOptions = {
    "client-id": clientID,
    intent: "capture",
    currency: "USD",
  }

  return (
    <div style={{ maxWidth: "750px", minHeight: "200px" }}>
      <PayPalScriptProvider options={initialOptions}>
        <ButtonWrapper
          setPaypalModal={setModal}
          showSpinner={false}
          fielddata={data2}
          upgrade={upgrade}
          purpose={purpose}
          membershipSelected={membershipSelected}
          ReturnUpgradeshow={ReturnUpgradeshow}
        />
        <ToastContainer style={{ zIndex: "10999" }} />
      </PayPalScriptProvider>
    </div>
  )
}

export default MembershipButton
