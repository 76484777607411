import React, { useEffect, useState, use } from "react"
import {
  fetchUpcomingEvents,
  Ticketbookingshow,
} from "../../features/Event/EventTBSlice"
import "./seat.css"
import PageBanner from "../../layouts/PageBanner"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Col,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap"
import {
  HoldSeat,
  BookSeat,
  CancelSeat,
} from "../../features/Event/BookingSlice"

const SeatArrange = () => {
  const { TBisSuccess, UpcomingData, TicketbookingData } = useSelector(
    (state) => state.EventTBReducer
  )

  const { success, fail } = useSelector((state) => state.BookingReducer)

  const dispatch = useDispatch()
  const [value, SetValue] = useState([])
  const [selectedSeat, SetselectesSeat] = useState([])
  const data = JSON.parse(localStorage.getItem("venueIdPq"))
  // console.log("jj", data)

  const [ModalPay, SetModalPay] = useState(false)
  const [focusAfterClose, setFocusAfterClose] = useState(true)

  useEffect(() => {
    dispatch(Ticketbookingshow(data))
    SetselectesSeat([])
  }, [])
  useEffect(() => {
    console.log(success)
    if (success) {
      dispatch(Ticketbookingshow(data))
      // SetselectesSeat([])
    }
  }, [success])
  useEffect(() => {
    if (TBisSuccess) {
      // console.log("Seat Ticket Booking Data", TicketbookingData)
      SetValue(TicketbookingData[0]?.valueBlock)
    }
  })

  const toggleClass = (e, seat) => {
    if (
      e.target.classList.contains("seat") &&
      !e.target.classList.contains("sold") &&
      !e.target.classList.contains("hold")
    ) {
      e.target.classList.toggle("selected")

      if (e.target.classList.contains("selected")) {
        selectedSeat.push(seat.id)
        SetselectesSeat(selectedSeat)
      } else {
        const filterdata = selectedSeat.filter((item) => item !== seat.id)
        SetselectesSeat(filterdata)
      }
    }

    // return console.log(selectedSeat)
  }
  const tooglePaynow = () => {
    SetModalPay(!ModalPay)
  }

  const seatHold = () => {
    dispatch(HoldSeat(selectedSeat))
    SetModalPay(!ModalPay)
  }

  const seatBook = () => {
    console.log("hiiiii")
    console.log("c", selectedSeat)
    dispatch(BookSeat(selectedSeat))
    SetModalPay(!ModalPay)
  }

  const seatCancel = () => {
    dispatch(CancelSeat(selectedSeat))
    SetselectesSeat([])
    SetModalPay(!ModalPay)
  }

  return (
    <>
      <div className="page-content bg-white">
        <PageBanner maintitle="Home" pagetitle="Ticket Booking" />
        <div className="d-flex justify-content-around flex-wrap">
          {value?.map((SDT, index) => (
            <div key={index}>
              <Col className="col-12 mt-3 mx-3">
                <h1>
                  <i className="fa fa-columns" aria-hidden="true"></i>
                  {SDT.Blocksv}
                </h1>
                <div className="mx-1">
                  {SDT.rows.map((rows1, index1) => (
                    <Col key={index1} className="col-12 d-flex">
                      <small style={{ fontSize: "8px" }}>{rows1.Rowsv}</small>

                      <div className="d-flex mt-1 flex-wrap  justify-content-start ">
                        {(rows1[0] || rows1).seats.map((seats1, index2) => (
                          <Col key={index2}>
                            <div
                              className="btn-group me-1 "
                              role="group"
                              aria-label="First group"
                            >
                              <p
                                className={`seat ${
                                  seats1.status === 2
                                    ? "sold"
                                    : seats1.status === 1
                                    ? "hold"
                                    : null
                                }
                                `}
                                onClick={(e) => toggleClass(e, seats1)}
                              >
                                {seats1.Seat}
                              </p>
                            </div>
                          </Col>
                        ))}
                      </div>
                    </Col>
                  ))}
                </div>
              </Col>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-center mt-5">
          <button className="btn btn-success btn-sm" onClick={seatHold}>
            PayNOW
          </button>
        </div>
      </div>

      <Modal returnFocusAfterClose={focusAfterClose} isOpen={ModalPay} centered>
        <ModalBody>
          Once you have made your selections, proceed to the Payment mode page.
        </ModalBody>
        <ModalFooter>
          {selectedSeat.length > 0 ? (
            <Button color="info" onClick={seatBook}>
              Go For Online Payment
            </Button>
          ) : (
            ""
          )}
          <Button color="light" onClick={seatCancel}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default SeatArrange
