import React from "react"
import { Link } from "react-router-dom"
import { Accordion } from "react-bootstrap"
import PageBanner from "../../layouts/PageBanner"
import { Project } from "../../constant/theme"
const School = () => {
  return (
    <>
      <div className="page-content bg-white">
        <PageBanner
          maintitle="Home"
          pagetitle="Project"
        />
        <section className="content-inner bg-light section-pattren1">
          <div className="container">
            <div className="row align-items-center flex-column-reverse flex-lg-row">
              <div className="col-lg-6 align-self-center">
                <div className="section-head m-b30">
                  <h2 className="title mb-3">School Project Munduvelampatti</h2>
                  <h4 >
                    News coverage from different Channels.
                  </h4>
                  <ul className="fw-bold ">
                    <li>
                      <a
                        href="https://www.youtube.com/watch?v=sr9v39ZWalY"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link: Raj TV News
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/watch?v=76K2Ly_yovA"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link: Dinamalar Video News
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.dinamalar.com/nri/details.asp?id=14573&lang=ta"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Link: Dinamalar Article
                      </a>
                    </li>
                    <li style={{fontWeight:500}}> 
                      Thanks to Sheela for publishing below News Article on
                      www.dinamalar.com
                    </li>
                    
                  </ul>
                </div>
              </div>
              
              <div className="col-lg-6 order-lg-2 order-1 m-b30">
                <div className="dz-media split-box rounded">
                  <img src={Project.school} alt="FAQ Image" />
                </div>
              </div>
            </div>

            <hr className="container my-2" />

            <div className="mt-3">
              <div className="border border-1 p-3 mb-3">
                <img src={Project.schoolimg1} alt="" />
              </div>

              <div className="border border-1 p-3">
                <img src={Project.schoolimg2} alt="" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default School
